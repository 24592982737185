var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "custom-select",
      attrs: { tabindex: _vm.tabindex },
      on: { blur: _vm.onBlur },
    },
    [
      _c(
        "div",
        {
          staticClass: "selected",
          class: { open: _vm.open },
          on: { click: _vm.onClickSelect },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.selected ? _vm.selected.text : "") + "\n  "
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "items", class: { selectHide: !_vm.open } },
        _vm._l(_vm.options, function (option, i) {
          return _c(
            "div",
            {
              key: i,
              on: {
                click: function ($event) {
                  _vm.selected = option
                  _vm.open = false
                  _vm.$emit("input-selected", option)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(option.text) + "\n    ")]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }