<template>
  <div class="permissions-notice">
    <div class="permissions-notice__header">
      <div class="permissions-notice__header__icon">
        <info-icon :width="22" :height="22" />
      </div>
    </div>
    <div class="permissions-notice__body">
      <div class="permissions-notice__body__wording-main">
        {{ $tp('buttonAndHints.permissionsNoticeFailWordingMain') }}
      </div>
      <div class="permissions-notice__body__wording">
        {{ $tp('buttonAndHints.permissionsNoticeFailWording1') }}
      </div>
      <div class="permissions-notice__body__wording">
        {{ $tp('buttonAndHints.permissionsNoticeFailWording2') }}
      </div>
      <div class="permissions-notice__body__icon">
        <div class="permissions-notice__body__icon-container">
          <mic-mute-icon :width="20" :height="20" color="#f05541" />
        </div>
      </div>
      <div class="permissions-notice__body__icon">
        <div class="permissions-notice__body__icon-container">
          <video-call-off-icon :width="20" :height="20" color="#f05541" />
        </div>
      </div>
    </div>
    <div class="permissions-notice__footer">
      <button class="permissions-notice__footer__btn" @click="clickHandler">
        {{ $tp('buttonAndHints.permissionsNoticeFailBtn') }}
      </button>
    </div>
  </div>
</template>

<script>
import MicMuteIcon from './icons/MicMuteIcon.vue'
import VideoCallOffIcon from './icons/VideoCallOffIcon'
import InfoIcon from './icons/InfoIcon'

export default {
  name: 'PermissionsRequestFailed',
  components: {
    MicMuteIcon,
    VideoCallOffIcon,
    InfoIcon
  },
  methods: {
    clickHandler() {
      this.$emit('buttonClicked')
    }
  }
}
</script>

<style lang="scss" scoped>
.permissions-notice {
  &__header {
    background: #f05541;
    height: 35px;
    border-radius: 6px 6px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    &__icon {
      padding: 7px 7px 0 0;
    }
  }

  &__body {
    &__icon {
      display: flex;
      margin-bottom: 10px;
      padding-left: 30px;

      &-container {
        background: #fff;
        width: 100px;
        height: 35px;
        display: flex;
        justify-content: space-around;
        border-radius: 6px;
        padding-top: 8px;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 20px 20px 20px;

    &__btn {
      font-family: 'Lato', sans-serif;
      background: none;
      outline: none;
      border: 1px solid #f05541;
      border-radius: 6px;
      width: 100%;
      padding: 5px 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: #f05541;
      cursor: pointer;
    }
  }
}
</style>
