var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "video",
    {
      staticClass: "dialog-video",
      class: _vm.videoClass,
      attrs: {
        autoplay: "",
        loop: !_vm.activateVideoSound,
        playsinline: "",
        src: _vm.videoURL,
      },
      domProps: { muted: !_vm.activateVideoSound },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.videoURL.indexOf(".webm") > -1
        ? _c("source", { attrs: { src: _vm.videoURL, type: _vm.webm } })
        : _vm._e(),
      _vm.videoURL.indexOf(".mp4") > -1
        ? _c("source", { attrs: { src: _vm.videoURL, type: _vm.mp4 } })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }