<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    :stroke="color"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-video-off"
  >
    <path d="M16 16v1a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2m5.66 0H14a2 2 0 0 1 2 2v3.34l1 1L23 7v10"></path>
    <line x1="1" y1="1" x2="23" y2="23"></line>
  </svg>
</template>

<script>
export default {
  name: 'NoVideoIcon',
  props: {
    color: {
      type: String,
      default: '#3B86F7'
    },
    width: {
      type: Number,
      default: 35
    },
    height: {
      type: Number,
      default: 35
    }
  }
}
</script>

<style scoped>

</style>
