<template>
  <div v-if="isShowedDialog && visitorId">
    <div v-if="dialog.type === 'button-card'" class="pathadvice pathadvice-popup" :style="sizeStylesForVisitorChatWhenCoBrowsing">
      <dialog-video v-if="displayDialogVideo" :embedVideoURL="embedVideoURL" />
      <div v-if="viewType === 'connect-dialog'">
        <div class="pathadvice__main-container">
          <img v-if="!dialog.showVideo" ref="bgImage" class="pathadvice__main-container" :src="backgroundImage" alt="bg__image" />
        </div>

        <banner @bannerClicked="proceedToChatSelection" :logoImage="logoImage" />
      </div>
    </div>
    <template v-if="['button-card'].includes(dialog.type)">
      <div v-show="viewType !== 'connect-dialog'" class="pathadvice pathadvice-popup" :style="sizeStylesForVisitorChatWhenCoBrowsing">
        <ChatSelection @optionSelected="proceedToChat" @proceedToLeadForm="proceedToLeadForm" @sessionEnd="endSession(false)" :logoImage="logoImage" />

        <LeadForm
          v-if="dialog.isShowLeadFormBeforeConversation && viewType === 'lead-form'"
          :contactForm="dialog.contactForm"
          :leadForm="dialog.leadForm"
          @submitLeadForm="submitLeadForm"
          @goBackToCreateNewRequest="goBackToCreateNewRequest"
        />

        <VisitorChat
          v-if="viewType === 'visitor-chat'"
          :logoImage="logoImage"
          :noticeType="noticeType"
          :messages="filteredMessages"
          :backgroundImage="backgroundImage"
          :embedVideoURL="embedVideoURL"
          :displayDialogVideoInAVSettings="displayDialogVideoInAVSettings"
          :widthVisitorDialog="widthVisitorDialog"
          :heightVisitorDialog="heightVisitorDialog"
          :local-video-key="localVideoKey"
          :remote-video-key="remoteVideoKey"
          :waitingRoomBackgroundImage="dialog.waitingRoomBackground"
          :waitingRoomContentChatMessage="dialog.waitingRoomContentChatMessage"
          @sendMessage="sendVisitorMessage"
          @visitorIsTypingInWaitingRoom="visitorIsTypingInWaitingRoom"
          @sessionEnd="endSession($event)"
          @finishCommunication="finishCommunication"
          @invokeCoBrowsing="invokeCoBrowsing"
          @deviceSelected="onDeviceSelected"
          @on-join-meeting="onJoinMeeting"
          @avCallActivated="avCallActivated"
          @startContactFormTimeout="showContactFormIfNeeded"
          @callActivated="callActivated"
          @fullScreenModeChanged="onFullScreenModeChanged"
          @coBrowsingModeChanged="onCoBrowsingModeChanged"
          @setNoticeType="noticeType = $event"
          @closeNotice="noticeType = ''"
          @goToTheChat="proceedToChat('chat')"
          @upload-files="onUploadFiles"
          @dropzone-upload-files="onDropzoneUploadFiles"
        />

        <VisitorContactForm
          v-if="viewType === 'visitor-contact-form' && trackingData && dialog && dialog.contactForm"
          :trackingData="trackingData"
          :contactForm="dialog.contactForm"
          @sessionEnd="onCloseContactForm"
          @close-contact-form="closeContactForm"
          @hide-contact-form="hideContactForm"
        />

        <webrtc-communication
          v-if="!['connect-dialog', 'chat-selection', 'finish-communication'].includes(viewType) && visitor"
          ref="webrtc"
          @webrtc-stream="onWebrtcStream"
          @update-webrtc-stream="onWebrtcStreamUpdated"
          @permissionsDenied="permissionsDenied"
          @requestPermissions="requestPermissions"
          @permissionsAccepted="permissionsAccepted"
          @update-local-video="onLocalVideoUpdate"
          @update-remote-video="onRemoteVideoUpdate"
        />

        <finish-communication
          v-if="viewType === 'finish-communication'"
          :title="communicationCompleteTitle"
          :message="communicationCompleteMessage"
          :fontColor="communicationCompleteFontColor"
          :backgroundColor="communicationCompleteBackgroundColor"
          :wasAgentConnected="wasAgentConnected"
          @close-finish-communication="closeFinishCommunication"
        />
      </div>
    </template>
    <LandscapeNotice v-if="isMobile && isLandscapeMode" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { callcenter } from '@/mixins/callcenter'

/* eslint-disable */
import ChatSelection from './ChatSelection'
import VisitorChat from './VisitorChat'
import VisitorContactForm from './VisitorContactForm'
import MinimizeIcon from './icons/MinimizeIcon.vue'
import CoBrowsingIframe from './CoBrowsingIframe'
import WebrtcCommunication from './WebrtcCommunication'
import Banner from './Banner'
import FinishCommunication from './FinishCommunication'
import LandscapeNotice from './LandscapeNotice'
import DialogVideo from './DialogVideo.vue'
import LeadForm from './LeadForm.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

import firebase from 'firebase/app'

export default {
  mixins: [callcenter],
  components: {
    FinishCommunication,
    Banner,
    MinimizeIcon,
    VisitorContactForm,
    VisitorChat,
    ChatSelection,
    WebrtcCommunication,
    CoBrowsingIframe,
    LandscapeNotice,
    DialogVideo,
    LeadForm
  },
  data() {
    return {
      viewTypeBeforeLeadForm: '',
      isClosedLeadForm: false,
      hasProcessedQueryStringStart: false,
      defaultLogoImage: require('@/assets/images/elements/LetsConnectLogo.svg'),
      wasAgentConnected: false,
      email: '',
      visitorName: '',
      invalidCampaign: false,
      leaderLink: null,
      isLandscapeMode: false,
      isDialogDataLoading: true,
      isShowedDialog: false,
      wasMinimized: false,
      unsubscribeVisitor: null,
      noticeType: '',
      localStream: null,
      remoteStream: null,
      skipUpdate: false,
      session: null,
      default: null,
      campaignId: null,
      showContactFormTimeout: null,
      imageURL: false,
      videoURL: null,
      transcodedURL: null,
      webm: 'video/webm',
      mp4: 'video/mp4',
      functionsUrl: null,
      iframeLocation: '',
      json: {},
      trackingData: {},
      settings: {
        widget_key: '74b3b4312b7e458bb0eea0df3fc3c4aa',
        private_session: false,
        require_password: false,
        auto_restore: false,
        start_docked: false,
        show_cursors: false,
        start_muted: false,
        meta: {
          abc: 'def'
        }
      },
      colors: [
        { name: 'primary', value: '#3b86f7' },
        { name: 'secondary', value: '#12598d' },
        { name: 'success', value: '#28C76F' },
        { name: 'danger', value: '#EA5455' },
        { name: 'warning', value: '#FF9F43' },
        { name: 'dark', value: '#1E1E1E' }
      ],
      height: 189,
      leaderLink: '',
      maxFrameWidth: 640,
      isFullScreen: false,
      widthVisitorDialog: '',
      heightVisitorDialog: '',
      communicationCompleteTitle: '',
      communicationCompleteMessage: '',
      communicationCompleteFontColor: '',
      communicationCompleteBackgroundColor: '',
      localVideoKey: Math.random().toString(36).substring(2, 15),
      remoteVideoKey: Math.random().toString(36).substring(2, 15),
      pickedFiles: [],
      progressUpload: 0,
      uploadTask: null,
      chatFilesUploadTasks: [],
      onlineUserStatusRef: null
    }
  },

  computed: {
    ...mapState({
      files: (state) => state.chatFiles.files
    }),
    ...mapGetters({
      selectedAudio: 'webrtc/selectedAudio',
      selectedOutput: 'webrtc/selectedOutput',
      selectedVideo: 'webrtc/selectedVideo',

      micEnabled: 'webrtc/micEnabled',

      chatFilesListCurrent: 'chatFiles/chatFilesListCurrent',

      videoEnabled: 'webrtc/videoEnabled',

      isAudioVideoAllowed: 'webrtc/isAudioVideoAllowed',

      microphoneOptions: 'webrtc/microphoneOptions',
      audioOutputOptions: 'webrtc/audioOutputOptions',
      cameraOptions: 'webrtc/cameraOptions',
      visitor: 'visitor',
      visitorId: 'visitorId',
      onlineUsers: 'onlineUsers',

      visitorInfo: 'campaign/visitorInfo',
      deviceSelected: 'campaign/deviceSelected',
      callStarted: 'campaign/callStarted',
      communicationMode: 'campaign/communicationMode',
      clearWebRtc: 'campaign/clearWebRtc',
      viewType: 'campaign/viewType',
      dialog: 'campaign/dialog',
      isMobile: 'campaign/isMobile',
      agentInfo: 'campaign/agentInfo',
      isWaiting: 'campaign/isWaiting',
      volumeLevel: 'campaign/volumeLevel',
      surflyURL: 'campaign/surflyURL',
      preShowAVSettings: 'campaign/preShowAVSettings',
      isCobrowse: 'campaign/isCobrowse',
      messages: 'campaign/messages',
      company: 'campaign/company'
    }),

    HAS_CONTACTFORM_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('contact-form') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    logoImage() {
      if (this.dialog && this.dialog.logoImage) {
        return this.dialog.logoImage
      }
      return this.defaultLogoImage
    },

    agentsToBeNotified() {
      if (this.dialog && this.dialog.recipients && this.dialog.recipients.length) {
        return this.dialog.recipients.map(({ id }) => id)
      }
      return []
    },

    campaignOnlineAgents() {
      if (!this.dialog || !this.dialog.company || !this.onlineUsers || this.onlineUsers.length == 0) return []
      if (this.agentsToBeNotified.length === 0) return []

      const onlineAgents = this.onlineUsers.filter((x) => x.state === 'online' && x.company === this.dialog.company).map((x) => x.userId)
      return onlineAgents.filter((x) => this.agentsToBeNotified.includes(x))
    },

    areCampaignAgentsAvailable() {
      return this.campaignOnlineAgents && this.campaignOnlineAgents.length > 0
    },

    allowNewRequest() {
      if (!this.visitor) {
        return true
      }
      if (this.visitor && !this.visitor.connectedAgentId) {
        return true
      }
      return false
    },
    embedVideoURL() {
      return this.transcodedURL ? this.transcodedURL : this.videoURL ? this.videoURL : this.defaultVideoURL
    },
    defaultImageURL() {
      return '/embed/img/MicrosoftTeams-image.9cd90357.jpg'
    },
    defaultVideoURL() {
      return '/Mann-Winken-SD.mp4'
    },
    backgroundImage() {
      return this.dialog && this.dialog.imageURL ? this.dialog.imageURL : this.defaultImageURL
    },
    mainColour() {
      return this.dialog ? this.dialog.step2ButtonColor : '#12598d'
    },
    defaultMessage() {
      return {
        isDefault: true,
        sender: 'agent',
        timestamp: new Date().getTime(),
        message: this.dialog
          ? this.dialog.waitingRoomMessage
          : `<b>Schön, dass du da bist!</b> <br> Du hast Interesse an einem unserer Produkte? Gib uns kurz bescheid, wie wir dir weiterhelfen können und du wirst direkt mit einem unserer Berater verbunden. <br> <b>DANKE FÜR DEINE GEDULD!</b>`
      }
    },
    buttonText() {
      return this.dialog ? this.dialog.confirmButtonText : 'Yes gladly!'
    },

    sizeStylesForVisitorChatWhenCoBrowsing() {
      if (this.viewType !== 'visitor-chat' || !this.isCobrowse || !this.widthVisitorDialog || !this.heightVisitorDialog) {
        return ''
      }

      return `margin: auto 20px 20px auto; width: ${this.widthVisitorDialog}; height: ${this.heightVisitorDialog};`
    },
    displayDialogVideo() {
      if (this.dialog && this.dialog.showVideo && (this.viewType === 'connect-dialog' || this.viewType === 'chat-selection')) {
        return true
      } else {
        return false
      }
    },
    displayDialogVideoInAVSettings() {
      if (this.dialog && this.dialog.showVideo && this.viewType === 'visitor-chat' && this.preShowAVSettings && this.isWaiting) {
        return true
      } else {
        return false
      }
    },
    filteredMessages() {
      if (!this.messages) {
        return []
      }

      const messages = this.messages.filter((x) => !x.isDefault)

      messages.unshift(this.defaultMessage)

      return messages.filter((item) => item.type !== 'agent-private-message')
    }
  },

  async mounted() {
    await this.$vs.loading()
    const vm = this
    vm.campaignId = vm.$route.query.cid
    this.setVisitorInfo(null)
    if (sessionStorage.getItem('pa-campaign') !== null) {
      const visitorInfo = JSON.parse(sessionStorage.getItem('pa-campaign'))
      this.setVisitorInfo(visitorInfo)
      if (this.visitorInfo && (!this.visitorInfo.visitorId || !this.visitorInfo.campaignId || this.visitorInfo.campaignId !== vm.campaignId)) {
        sessionStorage.removeItem('pa-campaign')
        this.setVisitorInfo(null)
      }
    }
    if (!vm.campaignId) {
      vm.invalidCampaign = true
      return
    }
    if (!this.visitorInfo) {
      const addVisitorForCampaign = this.$functions.httpsCallable('addVisitorForCampaign')
      const result = await addVisitorForCampaign({
        campaignId: vm.campaignId,
        referrer: document.referrer,
        origin: document.location.origin,
        location: document.location.href
      })
      if (result && result.data && result.data && result.data.visitor) {
        this.updateVisitorId(result.data.visitor.visitorId)
        this.setVisitorInfo(result.data.visitor)
        sessionStorage.setItem('pa-campaign', JSON.stringify(this.visitorInfo))
      } else {
        vm.invalidCampaign = true
        return
      }
    }

    vm.updateCampaignDisplayedInBigQuery()

    if (this.visitorInfo && this.visitorInfo.visitorId && vm.campaignId) {
      const ref = vm.$database.ref(`/status/${this.visitorInfo.visitorId}`)
      const isOfflineForDatabase = {
        state: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP
      }
      const isOnlineForDatabase = {
        state: 'online',
        location: location.href,
        last_changed: firebase.database.ServerValue.TIMESTAMP
      }
      vm.$database.ref('.info/connected').on('value', function (snapshot) {
        if (snapshot.val() === false) {
          return
        }
        ref
          .onDisconnect()
          .update(isOfflineForDatabase)
          .then(function () {
            ref.update(isOnlineForDatabase)
          })
      })
    }

    this.setPreShowAVSettings(false)

    this.$serverBus.$on('on-volume-changed', (volume) => {
      this.setVolumeLevel(volume.level)
    })

    this.$serverBus.$on('show-chat-selection', () => {
      this.onRemoveStreams()
      this.proceedToChatSelection()
      this.setPreShowAVSettings(false)
    })

    this.$serverBus.$on('send-visitor-message', (msg) => {
      this.sendVisitorMessage(msg)
    })

    const isMobile = this.mobileCheck(navigator.userAgent || navigator.vendor || window.opera)
    this.setIsMobile(isMobile)
    const visitorId = this.visitorInfo && this.visitorInfo.visitorId ? this.visitorInfo.visitorId : null
    this.updateVisitorId(visitorId)

    vm.campaignId = this.$route.query.cid

    if (vm.visitorId) {
      vm.$database.ref('.info/connected').on('value', (snapshot) => {
        if (!snapshot.val()) {
          vm.$database
            .ref(`/av-call/${vm.visitorId}`)
            .onDisconnect()
            .set({ callDisconnected: 'visitor', callTypeState: 'visitor-disconnected' })
            .then(() => {
              vm.callInitiator = 'visitor'
              vm.$database.ref(`/webrtc/${vm.visitorId}`).set(null)
            })
        }
      })
    }

    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
    const eventer = window[eventMethod]
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
    eventer(
      messageEvent,
      async function (e) {
        if (e.data && e.data.name === 'connect' && e.data.action === 'size-visitor-dialog') {
          vm.widthVisitorDialog = e.data.width
          vm.heightVisitorDialog = e.data.height
        }

        if (e.data && e.data.name === 'connect' && e.data.action === 'orientation-changed') {
          vm.onOrientationChange(e.data)
        }

        if (e.data && e.data.name === 'connect' && e.data.action === 'pa-agent-request') {
          vm.proceedToChatSelection()
        }

        if (e.data && e.data.name === 'connect' && e.data.action === 'client-sessions') {
          vm.saveClientSessions(e.data.client_sessions)
        }

        if (e.data && e.data.name === 'connect' && e.data.action === 'adjust-height-only') {
          if (vm.$route.query.prompted === 'true') {
            vm.adjustDialogMinMaxWidth(e.data.json)
          }
          vm.adjustHeight(e.data.json)
        }

        if (e.data && e.data.name === 'connect' && e.data.action === 'form-load') {
          vm.setData({
            iframeLocation: e.data.iframeLocation,
            functionsUrl: e.data.functionsUrl,
            json: e.data.json
          })
        }

        if (e.data && e.data.name === 'connect' && e.data.action === 'display-connect-dialog') {
          vm.adjustDialogMinMaxWidth(e.data.json)
          vm.setData({
            iframeLocation: e.data.iframeLocation,
            functionsUrl: e.data.functionsUrl,
            json: e.data.json
          })
          const json = e.data.json
          const appearance = e.data.appearance
          const dialog = {}
          dialog.type = json.type
          dialog.date = new Date()
          dialog.status = 'displayed'
          if (json.showVideo) {
            dialog.ui = 'video'
            dialog.appearance = appearance
          } else {
            dialog.ui = 'image'
            dialog.appearance = appearance
          }
        }

        if (e.data && e.data.name === 'connect' && e.data.action === 'display-connect-popup') {
          vm.setData({
            iframeLocation: e.data.iframeLocation,
            functionsUrl: e.data.functionsUrl,
            json: e.data.json
          })

          const json = e.data.json
          const appearance = e.data.appearance
          const dialog = {}

          dialog.date = new Date()
          dialog.status = 'displayed'
          if (json.showVideo) {
            dialog.ui = 'video'
            dialog.appearance = appearance
          } else {
            dialog.ui = 'image'
            dialog.appearance = appearance
          }
        }

        if (e.data && e.data.name === 'connect' && e.data.action === 'dialog-accepted') {
          const json = e.data.json
          const appearance = e.data.appearance

          const dialog = {}
          dialog.date = new Date()
          dialog.status = 'accepted'
          if (json.showVideo) {
            dialog.ui = 'video'
            dialog.appearance = appearance
          } else {
            dialog.ui = 'image'
            dialog.appearance = appearance
          }
        }

        if (e.data && e.data.name === 'connect' && e.data.action === 'client-cobrowse') {
          const locale = navigator.language.slice(0, 2)
          const name = locale === 'de' ? 'Besucher' : 'Visitor'
          const joinURL = `${e.data.joinURL}?name=${name}`
          parent.postMessage({ name: 'connect', action: 'joined-session', leader_link: joinURL }, '*')
        }
      },
      false
    )
    this.isShowedDialog = true
    await this.$vs.loading.close()

    this.campaignId = this.$route.query.cid
    window.addEventListener('orientationchange', this.setLandscapeMobileSettings)

    if (this.campaignId) {
      await this.getCampaignInfo(this.campaignId)
    }
    await this.getCompany()
    if (this.campaignId && this.company && this.company.id) {
      const locale = navigator.language.slice(0, 2)
      this.updateCurrentLanguage(locale)
      await this.getSavedTranslationOfAccount(this.company.id, locale)
    }
    this.showDialog()
    this.proceedToChatSelection()

    this.$serverBus.$on('on-conversation-ended', () => {
      this.$database.ref(`/av-call/${this.visitorId}`).set({ callDisconnected: 'visitor', callTypeState: 'visitor-chat-complete' })
      this.$database.ref(`/webrtc/${this.visitorId}`).set(null)
      this.hasSessionStarted = false
      this.setClearWebRtc(true)
    })

    this.$serverBus.$on('on-destroyed-webrtc', () => {
      this.setClearWebRtc(false)
    })

    this.$serverBus.$on('delete-send-file', (closeUrl, index) => {
      this.pickedFiles = this.pickedFiles.filter((el, idx) => idx !== index)
      this.filterChatFilesUpload(closeUrl)
    })
    this.$serverBus.$on('clean-sent-files', () => {
      this.pickedFiles = []
      this.clearChatFilesUpload()
    })

    if (this.visitorId && !this.visitor) {
      const visitorRef = await this.$db.collection('visitors').doc(this.visitorId).get()
      const visitor = visitorRef.data()
      this.updateVisitor(visitor)
    }

    if (this.dialog && this.dialog.isNotifyCallCenterEnabled && this.company && this.company.callCenterSettingsId) {
      this.getCallCenterCompanyUsers(this.company.callCenterSettingsId)
    }

    this.openChatIfCallWasReopened()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setLandscapeMobileSettings)
    this.$serverBus.$off('on-conversation-ended')
    this.$serverBus.$off('on-volume-changed')
    this.$serverBus.$off('show-chat-selection')
    this.$serverBus.$off('on-destroyed-webrtc')
    this.$serverBus.$off('delete-send-file')
    this.$serverBus.$off('clean-sent-files')
    this.$serverBus.$off('send-visitor-message')

    if (this.onlineUserStatusRef) {
      this.onlineUserStatusRef.off()
    }

    this.$database.ref(`/webrtc/${this.visitorId}/agent`).off('value')
    this.$database.ref(`/webrtc/${this.visitorId}/visitor`).off('value')
    this.$database.ref(`/webrtc/${this.visitorId}/visitor/offer`).off('value')
    this.$database.ref(`/webrtc/${this.visitorId}/agent/offer`).off('value')
    this.$database.ref(`/av-call/${this.visitorId}`).off('value')
    this.$database.ref(`/chats/${this.visitorId}`).off('value')
    if (this.unsubscribeVisitor) {
      this.unsubscribeVisitor()
    }
    this.updateVisitor(null)
  },

  watch: {
    viewType() {
      if (this.viewType === 'visitor-chat') {
        if (this.localStream) {
          this.localVideoKey = Math.random().toString(36).substring(2, 15)
        }
        if (this.remoteStream) {
          this.localVideoKey = Math.random().toString(36).substring(2, 15)
          this.remoteVideoKey = Math.random().toString(36).substring(2, 15)
        }
      }
    },
    'visitor.connectedAgentId'() {
      if (this.visitor && this.visitor.connectedAgentId) {
        this.wasAgentConnected = true
      }
    },
    dialog() {
      if (this.dialog && this.dialog.company) {
        this.getCompanyOnlineUsers()
      }
    }
  },
  methods: {
    ...mapActions({
      updateChatFilesUpload: 'chatFiles/updateChatFilesUpload'
    }),
    ...mapMutations({
      setSelectedAudio: 'webrtc/setSelectedAudio',
      setSelectedVideo: 'webrtc/setSelectedVideo',
      setSelectedOutput: 'webrtc/setSelectedOutput',

      setMicEnabled: 'webrtc/setMicEnabled',
      setVideoEnabled: 'webrtc/setVideoEnabled',

      setChatFilesUpload: 'chatFiles/setChatFilesUpload',
      filterChatFilesUpload: 'chatFiles/filterChatFilesUpload',
      clearChatFilesUpload: 'chatFiles/clearChatFilesUpload',

      setIsAudioVideoAllowed: 'webrtc/setIsAudioVideoAllowed',

      setMicrophoneOptions: 'webrtc/setMicrophoneOptions',
      setAudioOutputOptions: 'webrtc/setAudioOutputOptions',
      setCameraOptions: 'webrtc/setCameraOptions',
      addOnlineUsers: 'ADD_ONLINE_USERS',
      removeOnlineUsers: 'REMOVE_ONLINE_USERS',

      updateVisitor: 'UPDATE_VISITOR',
      updateVisitorId: 'UPDATE_VISITOR_ID',

      setVisitorInfo: 'campaign/setVisitorInfo',
      setDeviceSelected: 'campaign/setDeviceSelected',
      setCallStarted: 'campaign/setCallStarted',
      setCommunicationMode: 'campaign/setCommunicationMode',
      setClearWebRtc: 'campaign/setClearWebRtc',
      setViewType: 'campaign/setViewType',
      setDialog: 'campaign/setDialog',
      setIsMobile: 'campaign/setIsMobile',
      setAgentInfo: 'campaign/setAgentInfo',
      setIsWaiting: 'campaign/setIsWaiting',
      setVolumeLevel: 'campaign/setVolumeLevel',
      setSurflyURL: 'campaign/setSurflyURL',
      setPreShowAVSettings: 'campaign/setPreShowAVSettings',
      setIsCobrowse: 'campaign/setIsCobrowse',
      setCompany: 'campaign/setCompany',
      setMessages: 'campaign/setMessages'
    }),

    async goBackToCreateNewRequest() {
      this.setViewType('chat-selection')

      await this.setLastReadChatByVisitor()
    },

    getCompanyOnlineUsers() {
      if (this.dialog && this.dialog.company) {
        this.onlineUserStatusRef = this.$firebase.database().ref(`/users/${this.dialog.company}`)

        if (this.onlineUserStatusRef) {
          /* Query realtime db for users online status */
          this.onlineUserStatusRef.on('value', async (snapshot) => {
            const data = snapshot.val()
            const now = this.$firebase.firestore.Timestamp.now().seconds * 1000
            if (data) {
              for (const key of Object.keys(data)) {
                const entry = data[key]
                const user = {
                  userId: key,
                  state: entry.state,
                  company: this.dialog.company,
                  displayName: entry.displayName || '',
                  time: (now - entry.last_changed) / 1000
                }

                if (user && user.userId) {
                  const index = this.onlineUsers.findIndex((x) => x.userId === user.userId)

                  /* Add or update user in onlineUsers list if the user is online */
                  if (user.state === 'online' && user.time < 300) {
                    if (index === -1) {
                      /* Add user to the onlineUsers List */
                      const userRef = await this.$db.collection('users').doc(user.userId).get()
                      const userData = userRef.data()
                      user.photoURL = userData.photoURL
                      user.displayName = `${userData.firstname} ${userData.lastname}`
                      this.addOnlineUsers(user)
                    }
                  }

                  /* Remove from onlineUsers list if user is offline */
                  if (user.state !== 'online' || user.time > 300) {
                    if (this.onlineUsers.length > 0) {
                      if (index > -1) {
                        this.removeOnlineUsers(index)
                      }
                    }
                  }
                }
              }
            }
          })

          /* Remove user from the list when user logs out */
          this.onlineUserStatusRef.on('child_removed', (snapshot) => {
            if (this.onlineUsers.length > 0) {
              const index = this.onlineUsers.findIndex((x) => x.userId === snapshot.key)
              if (index > -1) {
                this.removeOnlineUsers(index)
              }
            }
            /* Detach listner of the user */
            const userListener = this.$firebase.database().ref(`/users/${this.dialog.company}/${snapshot.key}`)
            if (userListener && userListener.off) {
              userListener.off()
            }
          })
        }
      }
    },

    updateCampaignDisplayedInBigQuery() {
      if (this.campaignId && this.visitorInfo && this.visitorInfo.visitorId) {
        const campaign = {}
        campaign.type = 'campaign'
        campaign.date = dayjs().utc().toDate()
        campaign.status = 'displayed'
        campaign.appearance = 'automatic'
        this.$db.collection('campaign-tracking').doc(this.campaignId).set({ updated: dayjs().utc().toDate() }, { merge: true })
        const campaingRef = this.$db.collection('campaign-tracking').doc(this.campaignId).collection('visitors').doc(this.visitorInfo.visitorId)
        campaingRef.set(campaign, { merge: true })
      }
    },

    mobileCheck(a) {
      let check = false
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      ) {
        check = true
        return check
      }
      return check
    },

    onCloseContactForm(event) {
      if (event.callTypeState === 'displayed-contact-form' || event.callTypeState === 'contact-form-submitted') {
        this.closeAgentRequest(event.callTypeState)
      } else {
        this.endSession(true, event.callTypeState)
      }
    },

    mapFilesToUpload(files, ref) {
      this.chatFilesUploadTasks = []
      this.$serverBus.$emit('chat-files-upload-complete', false)

      if (this.pickedFiles && this.pickedFiles.length) {
        this.pickedFiles = [...this.pickedFiles, ...files]
      } else {
        this.pickedFiles = Array.from(files)
      }

      this.pickedFiles.forEach((_, index) => {
        this.uploadFile(this.pickedFiles[index], index, ref)
      })

      this.completeChatFilesUploadTasks()
    },

    completeChatFilesUploadTasks() {
      Promise.all(this.chatFilesUploadTasks)
        .then(() => {
          this.chatFilesUploadTasks = []
          this.$serverBus.$emit('chat-files-upload-complete', true)
          this.$vs.notify({
            time: 3000,
            title: this.$i18n.t('vue.success'),
            text: this.$i18n.t('uploads.uploadFile'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        })
        .catch((err) => {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },

    onUploadFiles(files) {
      this.mapFilesToUpload(files)
    },

    onDropzoneUploadFiles(files, ref) {
      this.mapFilesToUpload(files, ref)
    },

    uploadFile(file, index, ref) {
      if (this.visitorId) {
        const storageRef = this.$firebase.storage().ref(`uploads/chat-files/${this.visitorId}/${file.name}`)

        this.updateChatFilesUpload({
          index,
          file: {
            percentage: 0,
            name: file.name,
            url: ''
          }
        })

        this.uploadTask = storageRef.put(file)
        this.chatFilesUploadTasks.push(this.uploadTask)

        if (ref) {
          ref.removeFile(file)
        }

        this.uploadTask.on(
          'state_changed',
          (snapshot) => {
            this.progressUpload = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            this.updateChatFilesUpload({
              index,
              file: {
                percentage: this.progressUpload,
                name: snapshot.ref.name
              }
            })
          },
          (err) => {
            this.$vs.notify({
              time: 8800,
              title: this.$i18n.t('vue.error'),
              text: err.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        )
        this.uploadTask
          .then(({ ref }) => {
            return ref.getDownloadURL()
          })
          .then((url) => {
            const currentFile = this.chatFilesListCurrent(index)
            this.updateChatFilesUpload({
              index,
              file: {
                ...currentFile,
                url
              }
            })
          })
      }
    },

    setLandscapeMobileSettings() {
      if (this.isMobile && this.viewType !== 'connect-dialog') {
        switch (window.orientation) {
          case -90:
          case 90:
            this.isLandscapeMode = true

            this.onFullScreenModeChanged(true)

            break
          default:
            this.adjustDialogMinMaxWidth(this.json, true)

            this.isLandscapeMode = false

            break
        }
      }
    },

    showDialog() {
      //this.isShowedDialog = true
      const isButtonCard = this.dialog && this.dialog.type === 'button-card'
      const action = isButtonCard ? 'add-box-shadow' : ''
      const frameMinHeight = 'auto'

      parent.postMessage({ action, name: 'connectDialog', frameMinHeight }, '*')
    },

    onLocalVideoUpdate() {
      this.localVideoKey = Math.random().toString(36).substring(2, 15)
    },
    onRemoteVideoUpdate() {
      this.remoteVideoKey = Math.random().toString(36).substring(2, 15)
    },
    onFullScreenModeChanged(isFullScreen) {
      this.isFullScreen = isFullScreen

      let params = {
        name: 'connectDialog',
        action: 'adjust-height'
      }

      if (this.isFullScreen) {
        params = {
          ...params,
          option: 'full-screen-maximized',
          bottom: '0',
          right: '0',
          width: '100%',
          height: '100%'
        }
      } else {
        params = {
          ...params,
          option: 'full-screen-minimized',
          bottom: '20px',
          right: '20px'
        }
      }

      parent.postMessage(params, '*')
    },

    onCoBrowsingModeChanged() {
      const params = {
        name: 'connectDialog',
        action: 'adjust-height',
        option: 'full-screen-maximized',
        isCoBrowsing: true,
        bottom: '0',
        right: '0',
        width: '100%',
        height: '100%'
      }

      parent.postMessage(params, '*')
    },

    async onWebrtcStream(stream) {
      this.localStream = stream.localStream && stream.localStream.active ? stream.localStream : null
      this.remoteStream = stream.remoteStream && stream.remoteStream.active ? stream.remoteStream : null

      if (!this.isAudioVideoAllowed && stream.localStream && stream.localStream.active) {
        this.setIsAudioVideoAllowed(true)
      }
    },
    async onWebrtcStreamUpdated(webrtc) {
      if (
        (!this.isAudioVideoAllowed && webrtc.localStream) ||
        (webrtc.localStream && ((this.micEnabled && !this.microphoneOptions.length) || (this.videoEnabled && this.cameraOptions.length <= 1)))
      ) {
        const deviceInfos = await navigator.mediaDevices.enumerateDevices()

        const microphoneOptions = []
        const cameraOptions = []
        const audioOutputOptions = []

        for (let i = 0; i !== deviceInfos.length; ++i) {
          const deviceInfo = deviceInfos[i]
          const option = {}

          option.value = deviceInfo.deviceId

          if (deviceInfo.kind === 'audioinput') {
            option.text = deviceInfo.label || `Microphone ${microphoneOptions.length + 1}`

            microphoneOptions.push(option)
          } else if (deviceInfo.kind === 'audiooutput') {
            option.text = deviceInfo.label || `Speaker ${audioOutputOptions.length + 1}`

            audioOutputOptions.push(option)
          } else if (deviceInfo.kind === 'videoinput' && this.videoEnabled) {
            option.text = deviceInfo.label || `Camera ${cameraOptions.length + 1}`

            cameraOptions.push(option)
          }
        }

        microphoneOptions.push({
          text: 'Microphone Off',
          value: 'microphone-off'
        })

        this.setMicrophoneOptions(microphoneOptions)

        cameraOptions.push({
          text: 'Camera Off',
          value: 'camera-off'
        })

        this.setCameraOptions(cameraOptions)

        audioOutputOptions.push({
          text: 'Audio Output Off',
          value: 'output-off'
        })

        this.setIsAudioVideoAllowed(true)

        this.setAudioOutputOptions(audioOutputOptions)

        if (!this.micEnabled && this.videoEnabled) {
          this.setSelectedVideo(cameraOptions[0])
          this.setSelectedAudio(microphoneOptions[microphoneOptions.length - 1])
        } else if (this.micEnabled && !this.videoEnabled) {
          if (this.cameraOptions.length > 1) {
            this.setSelectedVideo(cameraOptions[cameraOptions.length - 1])
          }

          this.setSelectedAudio(microphoneOptions[0])
        } else {
          if (cameraOptions.length > 1) {
            this.setSelectedVideo(cameraOptions[0])
          }

          this.setSelectedAudio(microphoneOptions[0])
        }

        this.setSelectedOutput(audioOutputOptions[0])
      }

      if (this.micEnabled && (!this.selectedAudio || this.selectedAudio.value === 'microphone-off') && this.localStream && this.microphoneOptions.length) {
        this.setSelectedAudio(this.microphoneOptions[0])
      }

      this.localStream = webrtc.localStream
      this.remoteStream = webrtc.remoteStream

      if (!this.isAudioVideoAllowed && webrtc.localStream) {
        this.setIsAudioVideoAllowed(true)
      }
    },
    requestPermissions() {
      this.noticeType = 'request-permissions'
    },
    permissionsAccepted() {
      this.noticeType = ''
    },
    permissionsDenied() {
      this.noticeType = 'permissions-denied'
    },
    onOrientationChange(params) {
      const { width, height, angle } = params

      parent.postMessage(
        {
          name: 'connectPopup',
          action: 'pa-adjust-height',
          frameHeight: height,
          maximumWidth: width
        },
        '*'
      )
    },

    adjustDialogMinMaxWidth(json, isOpenedDialog) {
      this.json = json
      if (this.$route.query.prompted === 'true') {
        const page = document.getElementsByClassName('pathadvice-popup')
        parent.postMessage(
          { name: 'connectDialog', action: 'adjust-dialog-minmax-width', frameHeight: page[0].scrollHeight, maximumWidth: page[0].scrollWidth + 50 },
          '*'
        )
      } else {
        let minWidth = 500
        let maxWidth = this.maxFrameWidth
      }

      if (isOpenedDialog) {
        parent.postMessage({ name: 'connectDialog', action: 'open-dialog' }, '*')
      }
    },
    adjustHeight(json) {
      this.json = json
    },
    getColor(color) {
      const c = this.colors.filter((x) => x.name === color)
      return c.length > 0 ? c[0].value : color
    },

    setData(data) {
      const vm = this
      vm.iframeLocation = data.iframeLocation
      vm.functionsUrl = data.functionsUrl
      vm.json = data.json
    },
    getDialogDefaultValues(dialog) {
      if (dialog) {
        /* STEP 2 Properties */
        if (typeof dialog.isMoreInformationEnabled === 'undefined') {
          dialog.isMoreInformationEnabled = false
          dialog.moreInformationButtonText = this.$i18n.t('qrConnect.moreInformation', 'en')
        }

        if (!dialog.textStep2) {
          dialog.textStep2 = this.$i18n.t('qrConnect.textStep2DefaultText')
        }
        if (!dialog.step2ButtonColor) {
          dialog.step2ButtonColor = '#3B86F7'
          dialog.step2TextColor = '#ffffff'
        }
        if (!dialog.confirmButtonColor) {
          dialog.confirmButtonColor = '#238000'
        }
        if (!dialog.confirmButtonTextColor) {
          dialog.confirmButtonTextColor = '#ffffff'
        }
        /* STEP 2 Properties */

        if (!dialog.waitingRoomMessage) {
          dialog.waitingRoomMessage = this.$i18n.t('qrConnect.waitingRoomDefaultMessage')
        }

        if (typeof dialog.waitingRoomContentChatMessage === 'undefined') {
          dialog.waitingRoomContentChatMessage = {
            backgroundColor: '#C4C4C4',
            fontColor: '#12598d'
          }
        }

        if (typeof dialog.sessionEndScreen === 'undefined') {
          dialog.sessionEndScreen = {
            titleClosingMessage: this.$i18n.t('qrConnect.sessionEndScreenDefaultTitle'),
            copyClosingMessage: this.$i18n.t('qrConnect.sessionEndScreenDefaultCopy'),
            backgroundColor: '#3B86F7',
            fontColor: '#FFFFFF'
          }
        }

        if (typeof dialog.isShowFeedbackForm === 'undefined') {
          dialog.isShowFeedbackForm = false
        }

        if (typeof dialog.isSendFeedbackViaMessage === 'undefined') {
          dialog.isSendFeedbackViaMessage = false
        }

        if (typeof dialog.feedbackFormQuestionFirst === 'undefined') {
          dialog.feedbackFormQuestionFirst = ''
        }

        if (typeof dialog.feedbackFormQuestionSecond === 'undefined') {
          dialog.feedbackFormQuestionSecond = ''
        }

        if (typeof dialog.feedbackFormQuestionThird === 'undefined') {
          dialog.feedbackFormQuestionThird = ''
        }

        if (typeof dialog.contactForm === 'undefined') {
          dialog.contactForm = {
            message: this.$i18n.t('qrConnect.contactFormText'),
            backgroundColor: '#3B86F7',
            fontColor: '#FFFFFF',
            isBookingEnabled: false,
            selectedAppointment: null,
            fields: {
              name: {
                enabled: true,
                required: true
              },
              email: {
                enabled: true,
                required: true
              },
              phone: {
                enabled: true,
                required: true
              },
              message: {
                enabled: true,
                required: true
              },
              date: {
                enabled: false,
                required: false
              }
            },
            sendMessageTitle: this.$i18n.t('qrConnect.sendMessageDefaultTitle'),
            sendMessageCopy: this.$i18n.t('qrConnect.sendMessageDefaultCopy'),
            bookingSlotTitle: this.$i18n.t('qrConnect.bookingSlotDefaultTitle'),
            bookingSlotCopy: this.$i18n.t('qrConnect.bookingSlotDefaultCopy'),
            isPrivacyInformationEnabled: false,
            privacyInformationHtml: this.$i18n.t('qrConnect.privacyInformationText')
          }
        }

        /*
    Privacy Information added in later stage of development, so adding this check here
  */
        if (dialog.contactForm && typeof dialog.contactForm.isPrivacyInformationEnabled === 'undefined') {
          dialog.contactForm.isPrivacyInformationEnabled = false
          dialog.contactForm.privacyInformationHtml = this.$i18n.t('qrConnect.privacyInformationHtml', 'en')
        }
      }

      return dialog
    },
    async getCampaignInfo(campaignId) {
      let dialog = null
      const campaignRef = await this.$db.collection('campaigns').doc(campaignId).get()
      if (campaignRef && campaignRef.data()) {
        dialog = campaignRef.data()
      }
      /* Check if new properties exist. If not add the default values */
      dialog = this.getDialogDefaultValues(dialog)

      const language = navigator.language.slice(0, 2) || 'en'
      if (dialog && dialog.company) {
        const translationRef = await this.$db
          .collection('translations')
          .doc(dialog.company)
          .collection('qr-connect')
          .doc(campaignId)
          .collection('languages')
          .doc(language)
          .get()

        if (translationRef && translationRef.data()) {
          const translation = translationRef.data().qrConnect
          if (translation) {
            dialog.locale = language
            dialog.title = translation.dialogTitleDefault || dialog.title
            dialog.text = translation.popupTextDefault || dialog.text
            dialog.cancelButtonText = translation.cancelButtonTextDefault || dialog.cancelButtonText
            dialog.confirmButtonText = translation.confirmButtonTextDefault || dialog.confirmButtonText
            dialog.textStep2 = translation.textStep2DefaultText || dialog.textStep2
            dialog.waitingRoomMessage = translation.waitingRoomDefaultMessage || dialog.waitingRoomMessage
            dialog.moreInformationButtonText = translation.moreInformation || dialog.moreInformationButtonText

            if (dialog.sessionEndScreen) {
              dialog.sessionEndScreen.titleClosingMessage = translation.sessionEndScreenDefaultTitle || dialog.sessionEndScreen.titleClosingMessage
              dialog.sessionEndScreen.copyClosingMessage = translation.sessionEndScreenDefaultCopy || dialog.sessionEndScreen.copyClosingMessage
            }

            if (dialog.contactForm) {
              dialog.contactForm.message = translation.contactFormText || dialog.contactForm.message
              dialog.contactForm.sendMessageTitle = translation.sendMessageDefaultTitle || dialog.contactForm.sendMessageTitle
              dialog.contactForm.sendMessageCopy = translation.sendMessageDefaultCopy || dialog.contactForm.sendMessageCopy
              dialog.contactForm.bookingSlotTitle = translation.bookingSlotDefaultTitle || dialog.contactForm.bookingSlotTitle
              dialog.contactForm.bookingSlotCopy = translation.bookingSlotDefaultCopy || dialog.contactForm.bookingSlotCopy
              dialog.contactForm.privacyInformationHtml = translation.privacyInformationHtml || dialog.contactForm.privacyInformationHtml
            }

            if (translation.feedbackFormQuestionFirst) {
              dialog.feedbackFormQuestionFirst = translation.feedbackFormQuestionFirst
            }
            if (translation.feedbackFormQuestionSecond) {
              dialog.feedbackFormQuestionSecond = translation.feedbackFormQuestionSecond
            }
            if (translation.feedbackFormQuestionThird) {
              dialog.feedbackFormQuestionThird = translation.feedbackFormQuestionThird
            }
          }
        }
      }

      this.setDialog(dialog)

      this.communicationCompleteTitle = this.dialog.sessionEndScreen.titleClosingMessage
      this.communicationCompleteMessage = this.dialog.sessionEndScreen.copyClosingMessage
      this.communicationCompleteFontColor = this.dialog.sessionEndScreen.fontColor
      this.communicationCompleteBackgroundColor = this.dialog.sessionEndScreen.backgroundColor

      this.isDialogDataLoading = false

      if (this.$refs.bgImage && this.$refs.bgImage.complete) {
        //this.isShowedDialog = true
        const action = this.dialog.type === 'button-card' ? 'add-box-shadow' : ''

        const frameMinHeight = 'auto'

        parent.postMessage({ action, name: 'connectDialog', frameMinHeight }, '*')
      }

      if (this.dialog && !this.dialog.created) {
        this.dialog.created = new Date()
      }

      if (this.dialog.imageURL) {
        this.imageURL = this.dialog.imageURL
        this.isDefaultPhoto = false
      } else {
        this.imageURL = this.defaultImageURL
        this.isDefaultPhoto = true
      }

      if (this.dialog.videoURL) {
        this.videoURL = this.dialog.videoURL
      } else {
        this.videoURL = this.defaultVideoURL
      }

      if (this.dialog.transcodedURL) {
        this.transcodedURL = this.dialog.transcodedURL
      }
    },
    async connect() {
      this.dialog.type = this.dialog.type || 'button-card'
      this.setViewType('chat-selection')
    },
    async saveClientSessions(client_sessions) {
      if (client_sessions) {
        const data = {
          clientSessions: client_sessions
        }

        await this.$db.collection('visitors').doc(this.visitorId).set(data, { merge: true })
      }
    },
    cancelPopup() {
      parent.postMessage({ name: 'connect', action: 'cancel-popup' }, '*')
    },

    submitLeadForm() {
      this.proceedToChat(this.viewTypeBeforeLeadForm)
    },

    openChatIfCallWasReopened() {
      if (this.visitor && this.visitor.isCallStarted) {
        this.setClearWebRtc(false)
        this.setCommunicationMode('chat')
        this.setPreShowAVSettings(false)

        this.$nextTick(() => {
          this.$serverBus.$emit('show-chat-selection')
        })
      }
    },

    proceedToChatSelection() {
      this.setViewType('chat-selection')
      this.setMessages([])
      this.skipUpdate = false

      this.adjustDialogMinMaxWidth(this.json, true)
      this.setLandscapeMobileSettings()

      if (this.visitor && this.visitor.isCallStarted) {
        this.onVisitorEntityChange()
        /* Save sessions of Visitor (used in Cobrowsing) */
        parent.postMessage({ name: 'connect', action: 'get-client-sessions' }, '*')
      }

      if (this.$route.query.start && ['video', 'audio', 'chat'].includes(this.$route.query.start) && !this.hasProcessedQueryStringStart) {
        this.proceedToChat(this.$route.query.start)
        this.hasProcessedQueryStringStart = true
      }
    },

    proceedToLeadForm(type) {
      this.viewTypeBeforeLeadForm = type
      this.setViewType('lead-form')
    },

    proceedToChat(type) {
      this.setCommunicationMode(type)

      switch (type) {
        case 'audio':
        case 'video':
          this.setPreShowAVSettings(true)

          break
        case 'chat':
          if (this.viewType === 'visitor-chat') {
            this.requestAgent()
            this.showContactFormIfNeeded()
          }

          break
        default:
          break
      }

      this.setViewType('visitor-chat')
      if (!this.visitor) {
        this.onVisitorEntityChange()
      }
    },

    async onVisitorEntityChange() {
      const visitorEntity = await this.$db.collection('visitors').doc(this.visitorId)

      if (this.visitor && !this.visitor.isCallStarted) {
        const dialogInfo = {
          type: (this.dialog && this.dialog.type) || null,
          showVideo: (this.dialog && this.dialog.showVideo) || false,
          name: (this.dialog && this.dialog.name) || null,
          design: (this.dialog && this.dialog.design) || null
        }
        this.updateVisitor({
          ...this.visitor,
          dialogInfo,
          agentRequest: this.visitor.callType === 'incoming-request' || !this.visitor.callType ? true : false, // for some reason in some cases we have no callType property... Just to make sure that incoming request will come in this case.
          agentRequestType: this.communicationMode,
          isNewFlowCampaign: true,
          campaignLocation: this.visitorInfo.url,
          campaignName: this.dialog && this.dialog.name
        })
      }

      this.unsubscribeVisitor = visitorEntity.onSnapshot(async (doc) => {
        if (this.skipUpdate) {
          return
        }
        const visitor = doc.data()

        if (visitor && !visitor.agentRequest && visitor.connectedAgentId) {
          this.setMessages(visitor.chats || [])
        }

        this.updateVisitor(visitor)

        const isWaiting = !(visitor.connectedAgent && visitor.connectedAgentId)
        this.setIsWaiting(isWaiting)

        if (this.viewType !== 'visitor-contact-form') {
          this.setViewType('visitor-chat')
        }
        if (visitor && visitor.connectedAgent && visitor.connectedAgentImage && visitor.connectedAgentId) {
          const { connectedAgent, connectedAgentImage, connectedAgentId } = visitor
          const agentInfo = {
            connectedAgent,
            connectedAgentImage,
            connectedAgentId
          }

          if (this.company && this.company.name) {
            agentInfo.dialogCompanyName = this.company.name || ''
          }

          this.setAgentInfo(agentInfo)
        }

        const isCobrowse = !!visitor.cobrowse
        this.setIsCobrowse(isCobrowse)
        const surflyURL = isCobrowse && visitor.surfly ? visitor.surfly.surlfyURL : ''
        this.setSurflyURL(surflyURL)

        if (visitor.callTypeState === 'agent-chat-complete') {
          if (this.showContactFormTimeout) {
            clearTimeout(this.showContactFormTimeout)

            this.showContactFormTimeout = null
          }

          if (!this.isMobile) {
            this.onFullScreenModeChanged(false)
          }
          this.setViewType('finish-communication')
          this.setIsWaiting(true)
          this.skipUpdate = true
          this.$serverBus.$emit('on-conversation-ended')

          if (this.unsubscribeVisitor) {
            this.onRemoveStreams()
            this.setSelectedAudio(null)
            this.setSelectedVideo(null)
            this.setSelectedOutput(null)
            this.setMicEnabled(false)
            this.setVideoEnabled(false)
            this.setIsAudioVideoAllowed(false)
            this.unsubscribeVisitor()
          }
        }
      })
    },
    async requestAgent() {
      if (!this.allowNewRequest) {
        return
      }

      if (this.unsubscribeVisitor) {
        await this.unsubscribeVisitor()
      }
      this.setClearWebRtc(false)
      if (this.communicationMode === 'audio' || this.communicationMode === 'video') {
        this.setCallStarted(true)
      }

      if (this.dialog && this.dialog.isNotifyCallCenterEnabled && this.callCenterSettings && !this.callCenterSettings.waitingTime) {
        this.waitForNextPriorityAgent(0)
        const visitorRef = await this.$db.collection('visitors').doc(this.visitorId).get()
        this.updateVisitor(visitorRef.data())

        this.onVisitorEntityChange()
        /* Save sessions of Visitor (used in Cobrowsing) */
        parent.postMessage({ name: 'connect', action: 'get-client-sessions' }, '*')
        return
      }

      this.wasAgentConnected = false

      const getTurnServers = this.$functions.httpsCallable('getturnservers')
      await getTurnServers({ visitorId: this.visitorId })

      const data = {
        agentRequest: true,
        requestFrom: 'campaign',
        agentRequestType: this.communicationMode,
        modified: dayjs().utc().toDate(),
        ttlDate: dayjs().utc().add(6, 'month').toDate(),
        lastRequestDate: dayjs().utc().toDate(),
        hasSentAutomatedResponse: false,
        shouldNotifyAgents: true,
        connectedAgentId: null,
        connectedAgent: null,
        callType: 'incoming-request',
        callTypeState: 'incoming',
        reworkData: null,
        audioPlayed: false,
        start_time: dayjs().utc().toDate(),
        campaignId: this.$route.query.cid,
        locale: this.$i18n.locale,
        agentsToBeNotified: this.agentsToBeNotified,
        sessionId: Math.random().toString(36).substring(2, 15),
        initiatedBy: 'visitor',
        dialogId: this.$firebase.firestore.FieldValue.delete(),
        abTestId: this.$firebase.firestore.FieldValue.delete(),
        disconnect: this.$firebase.firestore.FieldValue.delete(),
        end_time: this.$firebase.firestore.FieldValue.delete(),
        vegaSessionId: this.$firebase.firestore.FieldValue.delete()
      }

      await this.$db.collection('visitors').doc(this.visitorId).set(data, { merge: true })
      const visitorRef = await this.$db.collection('visitors').doc(this.visitorId).get()
      this.updateVisitor(visitorRef.data())

      this.onVisitorEntityChange()

      /* Save sessions of Visitor (used in Cobrowsing) */
      parent.postMessage({ name: 'connect', action: 'get-client-sessions' }, '*')

      if (this.dialog && this.dialog.isNotifyCallCenterEnabled) {
        if (this.areCompanyAgentsAvailable) {
          /* eslint-disable no-console */
          console.log('company agents are available')
          this.waitForNextPriorityAgent()
        } else if (this.callCenterSettings) {
          /* eslint-disable no-console */
          console.log('company agents are not available, check call center agents')
          this.waitForNextPriorityAgent(0)
        } else {
          /* eslint-disable no-console */
          console.log('company agents are not available')

          if (this.dialog.isShowedUpContactFormWhenNoAgentOnline) {
            this.showContactForm()
          }
        }
      } else if (!this.areCampaignAgentsAvailable) {
        /* eslint-disable no-console */
        if (this.HAS_CONTACTFORM_ACCESS) {
          console.log('COMPANY AGENTS NOT available')
          this.closeAgentRequest('displayed-contact-form')
          this.showContactForm()
        }
      }
    },

    showContactForm() {
      if (!this.HAS_CONTACTFORM_ACCESS) {
        return
      }
      this.setViewType('visitor-contact-form')
      this.trackingData = {
        companyId: this.dialog.company || null,
        campaignId: this.$route.query.cid || null,
        visitorId: this.visitorInfo.visitorId || null
      }
    },

    showContactFormIfNeeded() {
      if (this.showContactFormTimeout) {
        return
      }

      this.showContactFormTimeout = setTimeout(() => {
        if (this.isWaiting) {
          if (this.HAS_CONTACTFORM_ACCESS) {
            this.setViewType('visitor-contact-form')
          } else {
            this.closeAgentRequest('contact-form-timedout')
          }

          this.trackingData = {
            companyId: this.visitorInfo.company || null,
            campaignId: this.$route.query.cid || null,
            visitorId: this.visitorInfo.visitorId || null
          }
        }
      }, this.dialog.contactFormTimeout * 1000)
    },

    visitorIsTypingInWaitingRoom(value) {
      if (value) {
        clearTimeout(this.showContactFormTimeout)

        this.showContactFormTimeout = null
      } else {
        this.showContactFormIfNeeded()
      }
    },

    onSendFeedbackWithMessage({ text, feedbackQuizAnswers }) {
      this.sendMessageRQ(text, 'visitor', feedbackQuizAnswers)
    },

    sendVisitorMessage(text) {
      this.sendMessageRQ(text, 'visitor')
    },

    sendMessageFromCoBrowsing(text) {
      this.sendMessageRQ(text, 'visitor')
    },

    onFilesSend() {
      if (this.files && this.files.length) {
        const files = this.files.map((file) => {
          file = {
            ...file,
            createdAt: dayjs().tz(this.$defaultTimezone).$d,
            expiresAt: dayjs().tz(this.$defaultTimezone).add(7, 'day').$d
          }
          return file
        })
        return files
      }
      return null
    },

    async sendMessageRQ(msg, sender, feedbackQuizAnswers = null) {
      const visitorRef = this.$db.collection('visitors').doc(this.visitorId)
      const files = this.onFilesSend()
      const { feedbackFormQuestionFirst, feedbackFormQuestionSecond, feedbackFormQuestionThird } = this.dialog
      const feedbackQuizQuestions = {
        question1: feedbackFormQuestionFirst || '',
        question2: feedbackFormQuestionSecond || '',
        question3: feedbackFormQuestionThird || ''
      }

      const isShowFeedbackForm = this.dialog && this.dialog.isShowFeedbackForm
      let feedbackType
      if (isShowFeedbackForm) {
        const isAnyAnswerExist = feedbackQuizAnswers && (feedbackQuizAnswers.grade1 || feedbackQuizAnswers.grade2 || feedbackQuizAnswers.grade3)
        const isNoOneAnswer = feedbackQuizAnswers && !feedbackQuizAnswers.grade1 && !feedbackQuizAnswers.grade2 && !feedbackQuizAnswers.grade3

        if (msg && msg.trim('') !== '' && isAnyAnswerExist) {
          feedbackType = 'message'
        } else if (!msg && msg.trim('') === '' && isAnyAnswerExist) {
          feedbackType = 'rating'
        } else if (!msg && msg.trim('') === '' && isNoOneAnswer) {
          feedbackType = 'closed'
        }
      }

      const message = {
        message: msg,
        time: new Date(),
        chatTime: dayjs().tz(this.$defaultTimezone).diff('2021-01-01'),
        sender,
        files,
        feedbackQuizAnswers: feedbackQuizAnswers || null,
        feedbackQuizQuestions: feedbackQuizQuestions || null,
        feedbackType: feedbackType || null,
        agentInfo: this.agentInfo || null
      }

      if (this.visitor && !this.visitor.connectedAgentId) {
        this.messages.push(message)
      }

      if (!feedbackType || feedbackType !== 'closed') {
        await visitorRef
          .update({
            shouldNotifyAgents: false,
            chats: this.$firebase.firestore.FieldValue.arrayUnion(message),
            callTypeState: 'visitor-chatting',
            visitorMessages: this.$firebase.firestore.FieldValue.increment(1)
          })
          .then(() => {
            this.cleanSentFiles()
          })
      }
      if (feedbackType) {
        message.companyId = this.visitorInfo.company
        message.visitorId = this.visitorId
        message.campaignId = this.campaignId
        message.dialogName = this.dialog.name

        const endScreenFeedbackRef = this.$db.collection('end-screen-feedback-tracking').doc()

        await endScreenFeedbackRef.set(message)
      }
    },

    cleanSentFiles() {
      this.$serverBus.$emit('clean-sent-files')
    },

    async closeFinishCommunication(feedbackData = null) {
      if (feedbackData && (feedbackData.text || feedbackData.feedbackQuizAnswers)) {
        const { text, feedbackQuizAnswers } = feedbackData
        await this.onSendFeedbackWithMessage({ text, feedbackQuizAnswers })
      }
      this.communicationCompleteTitle = this.dialog.sessionEndScreen.titleClosingMessage
      this.communicationCompleteMessage = this.dialog.sessionEndScreen.copyClosingMessage
      this.communicationCompleteFontColor = this.dialog.sessionEndScreen.fontColor
      this.communicationCompleteBackgroundColor = this.dialog.sessionEndScreen.backgroundColor
      this.resetUI()
      this.proceedToChatSelection()
    },

    async resetUI() {
      this.setViewType('connect-dialog')
      this.setCommunicationMode(null)
      this.skipUpdate = true
      this.setIsWaiting(true)
      this.setDeviceSelected(false)

      this.setSelectedAudio(null)
      this.setSelectedVideo(null)
      this.setMicEnabled(false)

      this.setPreShowAVSettings(false)
      this.setCallStarted(false)

      clearTimeout(this.showContactFormTimeout)

      this.showContactFormTimeout = null
    },
    closeContactForm() {
      this.closeAgentRequest('visitor-closed-contact-form')
      this.resetUI()
      this.proceedToChatSelection()
    },
    hideContactForm(type) {
      if (type === 'contact-form-booking-complete') {
        this.communicationCompleteTitle = this.dialog.contactForm.bookingSlotTitle
        this.communicationCompleteMessage = this.dialog.contactForm.bookingSlotCopy
        this.communicationCompleteFontColor = this.dialog.contactForm.fontColor
        this.communicationCompleteBackgroundColor = this.dialog.contactForm.backgroundColor
      }
      if (type === 'contact-form-info-saved') {
        this.communicationCompleteTitle = this.dialog.contactForm.sendMessageTitle
        this.communicationCompleteMessage = this.dialog.contactForm.sendMessageCopy
        this.communicationCompleteFontColor = this.dialog.contactForm.fontColor
        this.communicationCompleteBackgroundColor = this.dialog.contactForm.backgroundColor
      }
      this.finishCommunication()
    },
    finishCommunication() {
      this.setViewType('finish-communication')
      this.setIsWaiting(true)
      this.skipUpdate = true
    },
    async closeAgentRequest(callTypeState) {
      const data = {
        end_time: dayjs().utc().toDate(),
        modified: dayjs().utc().toDate(),
        ttlDate: dayjs().utc().add(6, 'month').toDate(),
        connectedAgentId: this.$firebase.firestore.FieldValue.delete(),
        callCenterId: this.$firebase.firestore.FieldValue.delete(),
        callCenterName: this.$firebase.firestore.FieldValue.delete(),
        connectedAgent: this.$firebase.firestore.FieldValue.delete(),
        connectedAgentImage: this.$firebase.firestore.FieldValue.delete(),
        connectedAgentDate: this.$firebase.firestore.FieldValue.delete(),
        connectedAgentChatDate: this.$firebase.firestore.FieldValue.delete(),
        //agentsToBeNotified: this.$firebase.firestore.FieldValue.delete(), DELETE AGENTS TO BE NOTIFIED
        clientSessions: this.$firebase.firestore.FieldValue.delete(),
        agentRequestTypeUpdated: this.$firebase.firestore.FieldValue.delete(),
        lastReadChatByVisitor: this.$firebase.firestore.FieldValue.serverTimestamp(),
        callTypeState: callTypeState,
        agentRequest: false,
        declinedByAgents: this.$firebase.firestore.FieldValue.delete(),
        audioPlayed: false,
        cobrowse: this.$firebase.firestore.FieldValue.delete(),
        surfly: this.$firebase.firestore.FieldValue.delete(),
        sessionId: null,
        isCallStarted: this.$firebase.firestore.FieldValue.delete(),
        visitorMessages: this.$firebase.firestore.FieldValue.delete(),
        agentMessages: this.$firebase.firestore.FieldValue.delete(),
        initiatedBy: this.$firebase.firestore.FieldValue.delete()
      }

      /* Set agentId as null to display call as missed */
      if (callTypeState === 'displayed-contact-form' || callTypeState === 'cancelled-contact-form' || callTypeState === 'visitor-closed-contact-form') {
        data.agentId = this.$firebase.firestore.FieldValue.delete()
      }

      this.setMicEnabled(false)
      this.setVideoEnabled(false)
      this.setMessages([])

      await this.$db.collection('visitors').doc(this.visitorId).set(data, { merge: true })
    },
    async endSession(isCallStarted, callTypeState) {
      clearTimeout(this.showContactFormTimeout)

      this.showContactFormTimeout = null

      if (!this.wasMinimized) {
        this.wasMinimized = true
      }

      if (!this.isMobile) {
        this.onFullScreenModeChanged(false)
      }

      this.closeAgentRequest(callTypeState || 'visitor-chat-complete')

      await this.$database.ref(`/webrtc/${this.visitorId}/visitor`).set(null)

      this.onRemoveStreams()

      if (isCallStarted) {
        this.finishCommunication()
      } else {
        if (this.visitor && this.visitor.isPreparedLeadFormToBeSent && !this.visitor.shouldLeadFormToBeSent) {
          await this.$db.collection('visitors').doc(this.visitorId).set({ shouldLeadFormToBeSent: true }, { merge: true })
        }

        this.resetUI()
        this.proceedToChatSelection()
      }

      this.$serverBus.$emit('on-conversation-ended')

      if (this.unsubscribeVisitor) {
        this.unsubscribeVisitor()
      }

      /* This is used to remove the iframe when the dialog is opened using pathadvice.connect() commmand */
      if (this.$route.query.proceedToChat === 'true') {
        parent.postMessage({ name: 'connect', action: 'remove-iframe' }, '*')
      }
    },

    onRemoveStreams() {
      this.onRemoveLocalStream()
      this.onRemoveRemoteStream()
    },
    onRemoveLocalStream() {
      if (this.localStream) {
        if (typeof this.localStream.getTracks === 'undefined') {
          this.localStream.stop()
        } else {
          this.localStream.getTracks().forEach(function (track) {
            track.stop()
          })
        }
        this.localStream = null
      }
    },
    onRemoveRemoteStream() {
      if (this.remoteStream) {
        if (typeof this.remoteStream.getTracks === 'undefined') {
          this.remoteStream.stop()
        } else {
          this.remoteStream.getTracks().forEach(function (track) {
            track.stop()
          })
        }
        this.remoteStream = null
      }
    },
    callActivated() {
      this.setCallStarted(true)
    },
    invokeCoBrowsing() {
      this.setViewType('co-browsing')
    },
    onDeviceSelected() {
      this.setDeviceSelected(true)
    },
    async onJoinMeeting() {
      if (this.visitorId) {
        this.$database.ref(`/chats/${this.visitorId}`).update({ visitorVideoOn: this.videoEnabled, visitorAudioOn: this.micEnabled })
      }
      this.setDeviceSelected(true)
      await this.requestAgent()
      this.$refs.webrtc.joinMeeting()
    },
    avCallActivated(type) {
      this.setCommunicationMode(type)
      this.setDeviceSelected(true)
      this.setCallStarted(true)

      if (!this.agentInfo && !this.agentInfo.connectedAgent) {
        this.requestAgent()
      }
    },
    async getCompany() {
      if (this.dialog && this.dialog.company) {
        const query = await this.$db.collection('company').doc(this.dialog.company).get()
        const company = query.data()
        this.setCompany(company)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pathadvice {
  position: absolute;

  //width: 375px;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  &__main {
    &-container {
      position: absolute;
      object-fit: cover;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      bottom: 0;
      right: 0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      }

      &__video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
      }

      &__wording {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
        text-shadow: 0 2px 5px #b2b2b2;
        margin-bottom: 10px;
      }

      &__actions {
        &__btn {
          font-family: 'Lato', sans-serif;
          width: 100%;
          padding: 10px;
          background: #3b86f7;
          border-radius: 6px;
          color: #fff;
          border: none;
          outline: none;

          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          text-align: center;

          cursor: pointer;
        }
      }

      &__actions__mobile {
        display: none;

        &__btn {
          font-family: 'Lato', sans-serif;
          width: 100%;
          padding: 5px 0;
          background: #3b86f7;
          border-radius: 0 0 6px 6px;
          color: #fff;
          border: none;
          outline: none;

          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          text-align: center;

          cursor: pointer;
        }
      }

      &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 100;

        &__btn {
          width: 25px;
          height: 25px;
          background: #12598d;
          border-radius: 6px;
          border: none;
          color: #fff;
          font-weight: 700;
          cursor: pointer;
          padding: 3px 1px 0 0;
        }
      }
    }
  }
}

.pathadvice-popup {
  justify-content: flex-end;
  align-items: flex-end;
}

body {
  background-color: transparent;
}
.float-right {
  float: right !important;
}
.switchLabel {
  font-size: 1.2em;
  font-weight: 500;
}
.grey {
  color: grey;
}
.primary {
  color: #3b86f7;
}
.vs-progress-primary {
  min-width: 340px !important;
}
.button-container {
  margin: 0;
  padding: 0;
  background-color: transparent;
  display: flex;

  .button-item {
    margin-left: 0.75rem;
    position: relative;
    border-radius: 0.3125rem;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    padding: 0;
    border: none;
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    background-color: transparent;
    background-clip: padding-box;
    cursor: pointer;
    position: relative;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    display: flex;
    z-index: 2;
  }
  .video-item {
    position: relative;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    padding: 0;
    border: none;
    flex-shrink: 0;
    height: 114px;
    background-color: transparent;
    background-clip: padding-box;
    cursor: pointer;
    position: relative;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    display: flex;
    z-index: 2;
    border-radius: 5px;
  }
  .darkenButton {
    top: 0;
    height: 0;
    width: 0;
    transition: background-color 0.25s;
  }
  .badge-container {
    position: absolute;
    right: 0px;
    bottom: 102px;
    z-index: 2;
  }
  .badge {
    display: table-cell;
    overflow: hidden;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 100%;
    border: 3px solid #fff;
    text-align: center;
    vertical-align: middle;
    background-color: #ff001f;
    color: #fff;
    background-clip: padding-box;
    box-sizing: border-box;
  }
  .speech-bubble {
    display: flex;
  }
  .speech-bubble-item {
    display: flex;
    position: relative;
    background-color: #fff;
    border-radius: 0.625rem;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    //-ms-overflow-style: -ms-;
  }
  .speech-bubble-text-container {
    overflow: hidden;
    position: relative;
    border-radius: inherit;
    cursor: pointer;
  }
  .speech-bubble-text {
    font-size: var(--font-size-copy);
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 1rem;
    font-size: 1rem;
    white-space: normal;
  }
  .speech-bubble-text span {
    text-align: justify;
    text-justify: inter-word;
    display: inline-block;
  }
  .pa-close-button {
    z-index: 999999;
    width: 22px;
    height: 22px;
    color: #777;
    font: 10px/100% arial, sans-serif;
    text-decoration: none;
    text-shadow: 0 1px 0 #fff;
    top: 3px;
    position: absolute;
    left: 2px;
    box-shadow: 0 0 5px 1px grey;
    border: 1px solid gray;
    border-radius: 15px;
    opacity: 0.9;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pa-video-close-button {
    z-index: 999999;
    width: 22px;
    height: 22px;
    color: #777;
    font: 10px/100% arial, sans-serif;
    text-decoration: none;
    text-shadow: 0 1px 0 #fff;
    position: absolute;
    box-shadow: 0 0 5px 1px grey;
    border: 1px solid gray;
    border-radius: 15px;
    opacity: 0.9;
    background-color: white;
    margin-top: -10px;
    margin-right: -5px;
    top: 0;
    right: 0;
  }
  .pa-close-thik:after {
    content: '✖'; /* UTF-8 symbol */
  }
  .pa-close-button a {
    color: black;
    opacity: 0.6;
  }
  .speech-bubble-container:hover .close-button {
    opacity: 1;
  }
}
.pa-w3 {
  background-color: transparent;
  border-radius: 5px;
  align-self: flex-end;
  margin-top: 10px;
}
.pa-w3-badge {
  position: relative;
  border-radius: 5px;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-left: 5px;
}
.pa-w3-badge-text {
  margin-left: 5px;
}
.pa-w3-green,
.pa-w3-hover-green:hover {
  color: #fff !important;
  background-color: #4caf50 !important;
}
.label-right {
  text-align: right;
  display: block;
  position: absolute;
  width: 100%;
  right: 25px;
}
.pa-button-confirm {
  border-radius: 5px;
  margin: 5px;
  float: right;
  cursor: pointer;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  border: none;
}
.powered-by {
  float: left;
  border: none;
  font-size: 0.6rem;
}
.powered-by.pb-button {
  margin: 10px;
}
.powered-by.pb-popup {
  margin-left: -14px !important;
  margin-top: -3px !important;
  position: absolute;
}
.pa-button-confirm p {
  padding: 7px 20px;
  margin: 0;
}
.justify-flex-end {
  justify-content: flex-end;
}
.bold {
  font-weight: bold;
}
.gray {
  color: gray;
}
.fl-left {
  float: left;
}
.fl-left.mg {
  margin-left: 4px;
  margin-top: 3px;
}
@media only screen and (min-width: 481px) {
  .button-container .button-content {
    position: relative;
    padding: 0.75rem 0.75rem 0;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: end;
    align-items: flex-end;
    display: flex;
  }
  .speech-bubble-container.image {
    display: flex;
    max-width: 400px;
    min-width: 132px;
    margin-bottom: 25px;
    justify-content: flex-end;
    align-self: flex-start;
  }
  .speech-bubble-container.video {
    display: flex;
    max-width: 400px;
    margin-bottom: 96px;
    margin-right: 10px;
    min-width: 85px;
    justify-content: flex-end;
    align-self: flex-start;
  }
  .speech-bubble-arrow {
    display: block;
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    right: -10px;
    bottom: 10px;
    background-color: inherit;
    transform: translateX(-6px) rotate(45deg);
    pointer-events: none;
    box-shadow: 2px -2px 2px 0 rgba(0, 0, 0, 0.2);
  }
}
@media only screen and (max-width: 480px) {
  .button-container .button-content {
    position: relative !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
    display: flex !important;

    &__video {
      padding: 0.75rem 0 0 0.75rem !important;
    }
  }
  .speech-bubble-arrow {
    display: block !important;
    position: absolute !important;
    content: '' !important;
    width: 10px !important;
    height: 10px !important;
    right: 5px !important;
    bottom: -5px !important;
    background-color: inherit !important;
    transform: translateX(-6px) rotate(45deg) !important;
    pointer-events: none !important;
    box-shadow: 2px -2px 2px 0 rgba(0, 0, 0, 0.2) !important;
    transform: translateX(-6px) rotate(135deg) !important;
  }
  .flex-column {
    flex-direction: column !important;
  }
  .justify-start {
    justify-content: flex-start;
  }
  .items-center {
    align-items: center;
  }
}
</style>
