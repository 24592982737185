var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pathadvice__face-to-face",
      style: { height: _vm.height },
      on: { click: _vm.onClick },
    },
    [
      _c("div", { staticClass: "pathadvice__face-to-face__wrapper" }, [
        _c("img", {
          attrs: { src: _vm.logoImage, alt: "visitor-campaign-logo" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }