<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :color="color" viewBox="0 0 18 15" fill="none" stroke="currentColor" class="feather">
    <path
      d="M2.62275 1H15.6048C16.4973 1 17.2275 1.73024 17.2275 2.62275V12.3593C17.2275 13.2518 16.4973 13.982 15.6048 13.982H2.62275C1.73024 13.982 1 13.2518 1 12.3593V2.62275C1 1.73024 1.73024 1 2.62275 1Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M17.2275 2.62274L9.11377 8.30238L1 2.62274" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'MailIcon',

  props: {
    color: {
      type: String,
      default: '#fff'
    },

    width: {
      type: Number,
      default: 35
    },

    height: {
      type: Number,
      default: 35
    }
  }
}
</script>
