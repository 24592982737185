var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pathadvice-notice",
      class: {
        "pathadvice-notice_full-size": _vm.isFullSize,
      },
    },
    [
      _vm.noticeType === "request-permissions"
        ? _c("permissions-requested")
        : _vm._e(),
      _vm.noticeType === "permissions-denied"
        ? _c("permissions-request-failed", {
            on: { buttonClicked: _vm.goToTheChat },
          })
        : _vm._e(),
      _vm.noticeType === "permissions-denied-base"
        ? _c("permission-denied", { on: { buttonClicked: _vm.closeNotice } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }