<template>
  <div v-show="isChat" class="ss-chat-container">
    <div class="ss-chat-container-close-btn" @click="showHideChat">
      <close-icon color="#3034F7" :width="24" :height="24" />
    </div>
    <chat-log class="ss-chat-container-chats" />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import ChatLog from '../ChatLog.vue'

export default {
  name: 'ScreenSharingChat',
  components: {
    CloseIcon,
    ChatLog
  },

  computed: {
    ...mapGetters({
      isChat: 'webrtc/isChat',
      visitorId: 'visitorId'
    })
  },
  methods: {
    ...mapMutations({
      setIsChat: 'webrtc/setIsChat'
    }),
    async showHideChat() {
      this.$db.collection('visitors').doc(this.visitorId).set({ agentMessages: this.$firebase.firestore.FieldValue.delete() }, { merge: true })
      this.setIsChat(!this.isChat)
    }
  }
}
</script>
<style lang="scss" scoped>
.ss-chat-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 280px;
  height: calc(100vh);
  position: fixed;
  background-color: white;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
  overflow-wrap: break-word;
  word-wrap: break-word;

  z-index: 100001;

  &-close-btn {
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
    position: inherit;
    z-index: 1;
  }

  &-chats {
    width: 100%;
    height: calc(88vh);
    padding-top: 10px;
  }
}
</style>
