<template>
  <div
    class="pathadvice__waiting-room"
    :style="{
      '--waiting-room-background': waitingRoomBackground
    }"
  >
    <div class="pathadvice__waiting-room__inner">
      <div class="pathadvice__waiting-room__header">
        <div class="pathadvice__waiting-room__header__actions">
          <button class="pathadvice__waiting-room__header__actions__close-btn" @click="close">
            <phone-call-icon :width="25" :height="25" />
          </button>
        </div>
      </div>

      <div
        class="pathadvice__waiting-room__body"
        :class="{
          'pathadvice__waiting-room__body--with-whitelabel': hasWhiteLabel
        }"
      >
        <scroll-view ref="waitingRoomScrollArea">
          <div
            class="pathadvice__waiting-room__body__message"
            v-for="item in messages"
            v-bind:key="item.timestamp"
            :class="item.sender === 'agent' ? 'pathadvice__waiting-room__body__message--received' : 'pathadvice__waiting-room__body__message--sent'"
          >
            <div
              class="pathadvice__waiting-room__body__message__item"
              :style="waitingRoomContentChatMessageStyle"
              :class="
                item.sender === 'agent' ? 'pathadvice__waiting-room__body__message__item--received' : 'pathadvice__waiting-room__body__message__item--sent'
              "
            >
              <span v-html="item.message" />
            </div>
          </div>
        </scroll-view>
      </div>

      <div class="pathadvice__waiting-room__footer">
        <div
          class="pathadvice__waiting-room__footer__actions"
          :style="{
            '--toolbar-background': toolBarBackground
          }"
        >
          <div class="pathadvice__waiting-room__footer__actions__btn pathadvice__waiting-room__footer__actions__add-attachment"></div>
          <div class="pathadvice__waiting-room__footer__actions__btn pathadvice__waiting-room__footer__actions__other"></div>
        </div>

        <div class="pathadvice__waiting-room__footer__send-msg">
          <div class="pathadvice__waiting-room__footer__send-msg__icon" @click="sendMessage">
            <send-icon :width="25" :height="25" :color="toolBarBackground" />
          </div>

          <div class="pathadvice__waiting-room__footer__send-msg__area">
            <textarea
              class="pathadvice__waiting-room__footer__send-msg__area__input"
              :placeholder="$tp('buttonAndHints.writeMessage')"
              v-model="msg"
              v-on:keyup.enter="sendMessage"
              @keydown="onVisitorTyping"
              @input="inputField"
            />
          </div>
        </div>
      </div>

      <powered-by />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ScrollView from '@blackbp/vue-smooth-scrollbar'

import SendIcon from './icons/SendIcon.vue'
import PhoneCallIcon from './icons/PhoneIcon.vue'
import PoweredBy from '@/components/dialog/PoweredBy.vue'

export default {
  name: 'WaitingRoom',
  data() {
    return {
      msg: '',
      isTyping: false,
      typingTimeout: null,
      defaultWaitingRoomBackground: require('@/assets/images/visitor/waiting-room-bg.png'),
      defaultWaitingRoomContentChatMessageBackgroundColor: '#C4C4C4',
      defaultWaitingRoomContentChatMessageFontColor: '#12598d'
    }
  },
  props: {
    messages: {
      type: Array,
      required: true
    },
    waitingRoomBackgroundImage: {
      type: String,
      required: false
    },
    waitingRoomContentChatMessage: {
      type: Object,
      required: false
    },
    toolBarColor: {
      type: String,
      required: false
    }
  },
  components: {
    PoweredBy,
    PhoneCallIcon,
    SendIcon,
    ScrollView
  },
  updated() {
    this.scrollToBottom()
  },
  computed: {
    ...mapGetters({
      hasWhiteLabel: 'hasWhiteLabel'
    }),
    waitingRoomBackground() {
      const waitingRoomBackground = this.waitingRoomBackgroundImage || this.defaultWaitingRoomBackground
      return `url('${waitingRoomBackground}')`
    },
    waitingRoomContentChatMessageStyle() {
      return {
        backgroundColor: this.waitingRoomContentChatMessage.backgroundColor || this.defaultWaitingRoomContentChatMessageBackgroundColor,
        color: this.waitingRoomContentChatMessage.fontColor || this.defaultWaitingRoomContentChatMessageFontColor
      }
    },
    toolBarBackground() {
      return this.toolBarColor || '#3B86F7'
    }
  },
  methods: {
    onVisitorTyping() {
      this.isTyping = true
      clearTimeout(this.typingTimeout)
      this.$emit('visitorIsTypingInWaitingRoom', this.isTyping)

      this.typingTimeout = setTimeout(() => {
        this.isTyping = false
        this.$emit('visitorIsTypingInWaitingRoom', this.isTyping)
      }, 1000)
    },

    inputField(event) {
      this.$emit('input-field', event.target.value)
    },

    close() {
      const custom_event = new CustomEvent('custom_gtm_events')
      custom_event.info = {
        name: 'gtm.click',
        gtm: {
          uniqueEventId:
            Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
          start: new Date().getTime(),
          location: window.location.href,
          event_type: 'End Session - Waiting Room'
        }
      }
      window.dispatchEvent(custom_event)
      this.$emit('sessionEnd')
    },
    sendMessage() {
      this.$emit('messageSent', this.msg)

      this.msg = ''

      this.$emit('input-field', this.msg)
      this.scrollToBottom()
    },
    scrollToBottom() {
      setTimeout(() => {
        const scrollElement = this.$refs.waitingRoomScrollArea
        if (scrollElement) {
          const scrollBar = scrollElement.scrollBar
          if (scrollElement && scrollBar && scrollBar.size && scrollBar.size.content && scrollBar.size.content.height) {
            scrollElement.scrollTo(0, scrollBar.size.content.height, 600)
          }
        }
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.pathadvice {
  &__waiting-room {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: var(--waiting-room-background);
    background-color: rgb(255, 255, 255);

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
    }

    &__inner {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &__header {
      padding: 5px;
      position: relative;

      &__actions {
        position: absolute;
        top: 20px;
        right: 10px;
        z-index: 1;

        &__close-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;
          background: #f05541;
          border-radius: 6px;
          border: none;
          color: #fff;
          font-weight: 700;
          cursor: pointer;
        }
      }
    }

    &__body {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: calc(100% - 126px);
      &.pathadvice__waiting-room__body--with-whitelabel {
        height: calc(100% - 100px);
      }
      @media only screen and (max-height: 650px) {
        max-height: 500px;
      }

      &__message {
        display: flex;

        &--received {
          justify-content: flex-start;
          border-radius: 6px 6px 0 6px;
          padding-left: 15px;
        }

        &--sent {
          justify-content: flex-end;
          border-radius: 6px 6px 6px 0;
          padding-right: 15px;
        }

        &__item {
          display: flex;
          flex-direction: column;
          color: rgb(18, 89, 141);
          font-size: 14px;
          line-height: 18px;
          background: #c4c4c4;
          padding: 15px;
          max-width: calc(100% - 21px);
          margin-bottom: 10px;

          span {
            word-break: break-word;
          }

          &--received {
            border-radius: 6px 6px 6px 0;
          }

          &--sent {
            background: rgb(230, 236, 241);
            border-radius: 6px 6px 0 6px;
          }
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      background: white;

      &__actions {
        background: var(--toolbar-background);
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;

        &__btn {
          cursor: pointer;
          padding-top: 5px;
        }
      }

      &__send-msg {
        height: 64px;
        padding: 0 5px;
        position: relative;

        &__icon {
          position: absolute;
          top: 15px;
          right: 15px;
          cursor: pointer;
        }

        &__area {
          height: 100%;

          &__input {
            outline: none;
            resize: none;
            overflow: auto;
            height: 100%;
            width: 100%;
            border: none;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #979797;
            padding: 10px 40px 10px 10px;
            font-family: 'Lato';

            &::placeholder {
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
              color: #979797;
            }
          }
        }
      }
    }
  }
}
</style>
