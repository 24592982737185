<script>
import adapter from 'webrtc-adapter'
import { mmultistreammixer } from '@/mixins/mmultistreammixer'
import { mapGetters, mapMutations } from 'vuex'
/* eslint-disable */
export default {
  render() {
    // This component does not render anything.
    return null
  },
  mixins: [mmultistreammixer],
  data() {
    return {
      avCallListener: null,
      screenShareListener: null,
      connectionInProgress: false,
      agentOfferListener: null,
      roomRef: null,
      makingOffer: null,
      callerCandidates: null,
      calleeCandidates: null,
      rtcSessionDescription: null,
      hasSessionStarted: false,
      peerConnection: null,
      iceCandidates: [],
      callInitiator: 'visitor',
      isAgentOfferProcessed: false,
      shouldNegotiateForSafari: false,
      mixer: null
    }
  },
  beforeMount() {
    const vm = this

    /* ADD LISTENER */
    vm.avCallListener = vm.$database.ref(`/av-call/${vm.visitorId}`).on('value', async (snapshot) => {
      if (snapshot && snapshot.exists()) {
        const data = snapshot.val()
        if (data.initiator) {
          vm.callInitiator = data.initiator
          if (data.agentIsCalling && !vm.isAgentOfferProcessed) {
            vm.isAgentOfferProcessed = true
            vm.initiateCall('agent')
          }
        }
      }
    })

    vm.screenShareListener = vm.$database.ref(`/screen-share/${vm.visitorId}`).on('value', async (snapshot) => {
      if (snapshot && snapshot.exists()) {
        const data = snapshot.val()
        if (data) {
          this.setScreenSharingStatus(data)
        } else {
          this.setScreenSharingStatus({
            initiatedBy: 'visitor',
            status: this.SCREEN_SHARING_STATUS.STOPPED,
            updateKey: Math.random().toString(36).substring(2, 15)
          })
        }
      }
    })
  },
  beforeDestroy() {
    this.destroyWebRTC()
    this.closeListeners()
  },
  computed: {
    ...mapGetters({
      selectedAudio: 'webrtc/selectedAudio',
      selectedOutput: 'webrtc/selectedOutput',
      selectedVideo: 'webrtc/selectedVideo',
      micEnabled: 'webrtc/micEnabled',
      videoEnabled: 'webrtc/videoEnabled',
      visitor: 'visitor',
      visitorId: 'visitorId',
      localStream: 'webrtc/localStream',
      remoteStream: 'webrtc/remoteStream',
      cameraStream: 'webrtc/cameraStream',
      screenStream: 'webrtc/screenStream',
      screenSharingStatus: 'webrtc/screenSharingStatus',
      SCREEN_SHARING_STATUS: 'webrtc/SCREEN_SHARING_STATUS',

      deviceSelected: 'campaign/deviceSelected',
      callStarted: 'campaign/callStarted',
      communicationMode: 'campaign/communicationMode',
      clearWebRtc: 'campaign/clearWebRtc',
      hasWebrtcSessionStarted: 'webrtc/hasWebrtcSessionStarted'
    }),
    configuration() {
      const configuration = {
        iceServers: [
          {
            url: 'stun:global.stun.twilio.com:3478',
            urls: ['stun:global.stun.twilio.com:3478']
          }
        ],
        iceCandidatePoolSize: 5
      }
      if (this.visitor.iceServers) {
        configuration.iceServers = this.visitor.iceServers
        configuration.iceTransportPolicy = 'relay'
      }

      const turnServer = configuration.iceServers.filter((x) => x.url.indexOf('turn') > -1) //.slice(0, 2)
      configuration.iceServers = turnServer

      return configuration
    },

    videoStream() {
      if (this.IS_VISITOR_SCREEN_SHARING) {
        return this.cameraStream
      }

      return this.localStream
    },

    videoTrackStream() {
      if (!this.IS_VISITOR_SCREEN_SHARING && this.cameraStream) {
        return this.cameraStream
      }

      return this.localStream
    },
    IS_VISITOR_SCREEN_SHARING() {
      return (
        this.screenSharingStatus &&
        this.screenSharingStatus.initiatedBy === 'visitor' &&
        (this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.STARTED ||
          this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.SCREEN_SHARING)
      )
    },
    audioDevice() {
      if (this.micEnabled) {
        if (!this.selectedAudio || this.selectedAudio.value === 'microphone-off') {
          return {
            deviceId: 'default',
            echoCancellation: true
          }
        } else {
          return {
            deviceId: this.selectedAudio.value,
            echoCancellation: true
          }
        }
      } else {
        if (this.selectedAudio && this.selectedAudio.value !== 'microphone-off') {
          return {
            deviceId: this.selectedAudio.value,
            echoCancellation: true
          }
        } else {
          return false
        }
      }
    },
    videoDevice() {
      if (this.videoEnabled) {
        if (!this.selectedVideo || this.selectedVideo.value === 'camera-off') {
          return {
            deviceId: 'default',
            width: 1280,
            height: 720
          }
        } else {
          return {
            deviceId: this.selectedVideo.value,
            width: 1280,
            height: 720
          }
        }
      } else {
        if (this.selectedVideo && this.selectedVideo.value !== 'camera-off') {
          return {
            deviceId: this.selectedVideo.value,
            width: 1280,
            height: 720
          }
        } else {
          return false
        }
      }
    }
  },
  watch: {
    async screenSharingStatus() {
      if (this.screenSharingStatus.initiatedBy === 'visitor' && this.visitorId) {
        if (this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.STARTED) {
          if (!this.peerConnection) {
            this.initiateCall(this.screenSharingStatus.initiatedBy)
          } else if (!this.screenStream) {
            const result = await this.addScreenToCameraStream()
            if (!result) {
              return false
            }

            const screenShareRef = this.$database.ref(`/screen-share/${vm.visitorId}`)
            screenShareRef.set({
              status: this.SCREEN_SHARING_STATUS.SCREEN_SHARING,
              initiatedBy: this.screenSharingStatus.initiatedBy,
              updateKey: Math.random().toString(36).substring(2, 15)
            })
          }
        }

        if (this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.STOPPED) {
          this.stopStreamTracks(this.screenStream)
          this.setScreenStream(null)

          if (!this.hasWebrtcSessionStarted) {
            if (this.peerConnection) {
              this.peerConnection.close()
              this.peerConnection = null
            }
            this.stopStreamTracks(this.cameraStream)
            this.setCameraStream(null)
            const visitorChatsRef = this.$database.ref(`/chats/${vm.visitorId}`)
            visitorChatsRef.update({ visitorVideoOn: this.videoEnabled, visitorAudioOn: this.micEnabled })
          } else {
            this.removeMixer()
          }

          this.replaceAudioTrack()
          this.replaceVideoTrack()
        }
      }
    },
    clearWebRtc() {
      if (this.clearWebRtc) {
        this.destroyWebRTC()
        this.closeListeners()
      }
    },

    micEnabled(now, before) {
      this.$serverBus.$emit('media-state', { micEnabled: this.micEnabled, videoEnabled: this.videoEnabled })

      if (!this.callStarted || this.clearWebRtc) {
        return
      }

      if (!this.peerConnection) {
        this.initiateCall('visitor')
        return
      }

      if (this.localStream.getAudioTracks() && this.localStream.getAudioTracks().length > 0) {
        const audioTrack = this.localStream.getAudioTracks()[0]
        audioTrack.enabled = this.micEnabled
      } else if (this.micEnabled) {
        // We need to attach a localStream to the Peer Connection
        this.startLocalStream()
      }
    },

    async videoEnabled() {
      if (this.videoStream && this.videoStream.getVideoTracks() && this.videoStream.getVideoTracks().length > 0) {
        const videoTrack = this.videoStream.getVideoTracks()[0]
        videoTrack.enabled = this.videoEnabled
      }

      this.$serverBus.$emit('media-state', { micEnabled: this.micEnabled, videoEnabled: this.videoEnabled })

      if (!this.callStarted || this.clearWebRtc) {
        return
      }

      if (!this.peerConnection) {
        this.initiateCall('visitor')
        return
      }

      if (this.IS_VISITOR_SCREEN_SHARING) {
        if (!this.cameraStream) {
          await this.addCameraToStream()
        }
        if (this.cameraStream) {
          this.updateCameraInStream()
        }
      } else {
        if (this.localStream.getVideoTracks().length > 0) {
          const videoTrack = this.localStream.getVideoTracks()[0]
          videoTrack.enabled = this.videoEnabled
        } else if (this.videoEnabled) {
          // In case the camera was turned off before, use default device
          this.startLocalStream()
        }
      }
    },
    'selectedVideo.value'() {
      if (this.peerConnection && this.selectedVideo && this.selectedVideo.value && this.localStream) {
        // Stop the tracks
        const tracks = this.localStream.getVideoTracks()
        tracks.forEach((track) => track.stop())
        this.startLocalStream()
      }
    },
    'selectedAudio.value'() {
      if (this.peerConnection && this.selectedAudio && this.selectedAudio.value && this.localStream) {
        // Stop the tracks
        const tracks = this.localStream.getAudioTracks()
        tracks.forEach((track) => track.stop())
        this.startLocalStream()
      }
    }
  },

  methods: {
    ...mapMutations({
      setScreenSharingStatus: 'webrtc/setScreenSharingStatus',
      setCameraStream: 'webrtc/setCameraStream',
      setScreenStream: 'webrtc/setScreenStream',
      setLocalStream: 'webrtc/setLocalStream',
      setRemoteStream: 'webrtc/setRemoteStream',
      setHasWebrtcSessionStarted: 'webrtc/setHasWebrtcSessionStarted'
    }),
    getConstraints() {
      const constraints = {
        audio: this.micEnabled ? this.audioDevice : false,
        video: this.videoEnabled ? (this.videoDevice && this.videoDevice.deviceId === 'default' ? true : this.videoDevice) : false
      }

      return constraints
    },

    setVideoTrackContentHints(stream, hint) {
      const tracks = stream.getVideoTracks()
      tracks.forEach((track) => {
        if ('contentHint' in track) {
          track.contentHint = hint
          if (track.contentHint !== hint) {
            /* eslint-disable no-console */
            console.log(`Invalid video track contentHint: ${hint}`)
          }
        } else {
          /* eslint-disable no-console */
          console.log('MediaStreamTrack contentHint attribute not supported')
        }
      })
    },

    stopStreamTracks(stream) {
      if (stream) {
        if (typeof stream.getTracks === 'undefined') {
          stream.stop()
        } else {
          stream.getAudioTracks().forEach(function (track) {
            track.stop()
          })

          stream.getVideoTracks().forEach(function (track) {
            track.stop()
          })
        }
      }
    },

    removeMixer() {
      if (this.mixer && this.mixer.releaseStreams) {
        this.mixer.releaseStreams()
      }
      this.mixer = null
      this.setLocalStream(this.cameraStream)
    },

    async addScreenToCameraStream() {
      try {
        const videoConstraints = {}

        videoConstraints.frameRate = { ideal: 12 }
        videoConstraints.width = screen.width
        videoConstraints.height = screen.height
        videoConstraints.cursor = 'always'

        let screenStream = null

        try {
          screenStream = await navigator.mediaDevices.getDisplayMedia({
            audio: false,
            video: videoConstraints
          })
        } catch (error) {
          /* eslint-disable no-console */
          console.log(error.message)
          if (!this.hasWebrtcSessionStarted && this.peerConnection) {
            this.peerConnection.close()
            this.peerConnection = null
          }
          const screenShareRef = this.$database.ref(`/screen-share/${this.visitorId}`)
          screenShareRef.set({
            status: this.SCREEN_SHARING_STATUS.ENDED,
            initiatedBy: this.screenSharingStatus.initiatedBy,
            updateKey: Math.random().toString(36).substring(2, 15)
          })
          return false
        }

        screenStream.fullcanvas = true
        screenStream.width = screen.width // or 3840
        screenStream.height = screen.height // or 2160
        screenStream.videoType = 'screen'

        this.setScreenStream(screenStream)
        this.setVideoTrackContentHints(screenStream, 'detail')

        const constraints = this.getConstraints()

        if (this.videoEnabled) {
          constraints.video = constraints.video ? constraints.video : { deviceId: 'default', width: 1280, height: 720 }
        } else {
          constraints.video = false
          constraints.audio = { echoCancellation: true }
        }
        const cameraStream = await navigator.mediaDevices.getUserMedia(constraints)

        const cameraWidth = 150
        const cameraHeight = 100

        cameraStream.width = cameraWidth
        cameraStream.height = cameraHeight

        cameraStream.top = this.screenStream.height - cameraHeight - 30
        cameraStream.left = this.screenStream.width - cameraWidth - 30

        cameraStream.videoType = 'camera'
        if (this.videoEnabled) {
          this.setCameraStream(cameraStream)
        }

        if (!this.mixer) {
          this.mixer = new this.MultiStreamsMixer([this.screenStream, cameraStream])
          this.mixer.frameInterval = 30
          this.mixer.startDrawingFrames()
          this.setLocalStream(this.mixer.getMixedStream())
        } else {
          this.updateCameraInStream()
        }

        if (!this.videoEnabled) {
          this.mixer.resetVideoStreams([this.screenStream])
        }

        if (this.screenStream && this.screenStream.getVideoTracks().length > 0) {
          this.screenStream.getVideoTracks()[0].addEventListener('ended', async () => {
            if (this.visitorId) {
              this.stopStreamTracks(this.screenStream)
              this.setScreenStream(null)
              if (!this.hasWebrtcSessionStarted) {
                this.stopStreamTracks(this.cameraStream)
                this.setCameraStream(null)
              } else {
                this.removeMixer()
              }
              this.replaceAudioTrack()
              this.replaceVideoTrack()

              const screenShareRef = this.$database.ref(`/screen-share/${this.visitorId}`)
              screenShareRef.set({
                status: this.SCREEN_SHARING_STATUS.ENDED,
                initiatedBy: this.screenSharingStatus.initiatedBy,
                updateKey: Math.random().toString(36).substring(2, 15)
              })
            }
          })
        }
        const screenShareRef = this.$database.ref(`/screen-share/${this.visitorId}`)
        screenShareRef.set({
          status: this.SCREEN_SHARING_STATUS.SCREEN_SHARING,
          initiatedBy: this.screenSharingStatus.initiatedBy,
          updateKey: Math.random().toString(36).substring(2, 15)
        })
        this.replaceAudioTrack()
        this.replaceVideoTrack()

        return true
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
        return false
      }
    },

    async addCameraToStream() {
      try {
        const constraints = this.getConstraints()

        if (this.cameraStream) {
          this.stopStreamTracks(this.cameraStream)
        }
        const cameraStream = await navigator.mediaDevices.getUserMedia(constraints)

        const cameraWidth = 150
        const cameraHeight = 100

        cameraStream.width = cameraWidth
        cameraStream.height = cameraHeight
        cameraStream.top = this.screenStream.height - cameraHeight - 30
        cameraStream.left = this.screenStream.width - cameraWidth - 30

        cameraStream.videoType = 'camera'
        this.setCameraStream(cameraStream)

        if (!this.mixer) {
          this.mixer = new this.MultiStreamsMixer([this.screenStream, this.cameraStream])
          this.mixer.frameInterval = 30
          this.mixer.startDrawingFrames()
          this.setLocalStream(this.mixer.getMixedStream())
        } else {
          this.updateCameraInStream()
          this.setLocalStream(this.mixer.getMixedStream())
        }

        this.$emit('update-local-video')
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
      }
    },

    async updateCameraInStream() {
      if (!this.videoEnabled) {
        this.mixer.resetVideoStreams([this.screenStream])
      } else {
        this.mixer.resetVideoStreams([this.screenStream, this.cameraStream])
      }
    },

    async setupInitiator() {
      const snapshot = await this.$database.ref(`/av-call/${this.visitorId}`).once('value')
      const data = snapshot.val()

      if (!data || !data.initiator || (data.initiator === 'visitor' && !this.shouldNegotiateForSafari)) {
        this.callInitiator = 'visitor'

        this.$database
          .ref(`/av-call/${this.visitorId}`)
          .set({ initiator: 'visitor', visitorIsCalling: true, agentIsCalling: false, key: Math.random().toString(36).substring(2, 15) })
        this.$database.ref(`/webrtc/${this.visitorId}/agent`).set(null)
      }
    },

    async replaceAudioTrack() {
      // Replace the Audio Track
      if (this.peerConnection && this.localStream.getAudioTracks().length > 0) {
        const audioTrack = this.localStream.getAudioTracks()[0]
        const sender = this.peerConnection.getSenders().find((s) => {
          if (!s.track) return false
          return s.track.kind === audioTrack.kind
        })
        if (sender) {
          sender.replaceTrack(audioTrack)
        } else {
          await this.peerConnection.addTrack(audioTrack, this.localStream)
        }
      }
    },

    async replaceVideoTrack() {
      // Replace the Video Track
      if (this.peerConnection && this.videoTrackStream.getVideoTracks().length > 0) {
        const videoTrack = this.videoTrackStream.getVideoTracks()[0]
        const sender = this.peerConnection.getSenders().find((s) => {
          if (!s.track) return false
          return s.track.kind === videoTrack.kind
        })
        if (sender) {
          sender.replaceTrack(videoTrack)
        } else {
          await this.peerConnection.addTrack(videoTrack, this.videoTrackStream)
        }
      }
    },

    joinMeeting() {
      const callInitiator = 'visitor'
      this.$database.ref(`/webrtc/${this.visitorId}/agent`).set(null)
      this.initiateCall(callInitiator)
    },
    async startLocalStream() {
      this.setupInitiator()
      if (!this.selectedVideo && !this.selectedAudio && !this.micEnabled && !this.videoEnabled) {
        this.setLocalStream(new MediaStream())
        return
      }

      if (this.callInitiator !== 'visitor') {
        this.hasAcceptedPermissions()
      }

      const constraints = {
        audio: this.micEnabled ? this.audioDevice : false,
        video: this.videoEnabled ? (this.videoDevice && this.videoDevice.deviceId === 'default' ? true : this.videoDevice) : false
      }

      try {
        if (!constraints.video && !constraints.audio) {
          const mediaStream = new MediaStream()
          this.setLocalStream(mediaStream)
          this.setCameraStream(null)
        } else {
          const mediaStream = await navigator.mediaDevices.getUserMedia(constraints)

          if (this.localStream) {
            this.localStream.getTracks().forEach((track) => track.stop())
          }

          this.setLocalStream(mediaStream)
          if (this.videoEnabled) {
            this.setCameraStream(mediaStream)
          }
          this.setHasWebrtcSessionStarted(true)
        }

        this.$emit('permissionsAccepted')
      } catch (e) {
        // eslint-disable-next-line
        console.log(e.message)
        const mediaStream = new MediaStream()
        this.setLocalStream(mediaStream)
        this.setCameraStream(mediaStream)
        this.$emit('permissionsDenied')
      }

      // Replace the Audio Track
      this.replaceAudioTrack()
      // Replace the Video Track
      this.replaceVideoTrack()

      this.$emit('update-webrtc-stream', {
        localStream: this.localStream,
        remoteStream: this.remoteStream
      })
      this.$emit('update-local-video')
    },
    initiateCall(callerType) {
      if (this.clearWebRtc) {
        return
      }
      this.connectionInProgress = true
      this.callInitiator = callerType
      switch (callerType) {
        case 'agent':
          this.roomRef = this.$database.ref(`/webrtc/${this.visitorId}/agent`)
          this.invokeCommunicationAsAgent()
          break
        case 'visitor':
          this.roomRef = this.$database.ref(`/webrtc/${this.visitorId}/visitor`)
          this.invokeCommunicationAsUser()
          break
      }
      this.callerCandidates = this.roomRef.child('callerCandidates')
      this.calleeCandidates = this.roomRef.child('calleeCandidates')
    },
    invokeCommunicationAsUser() {
      if (!this.hasSessionStarted && this.deviceSelected) {
        if (this.communicationMode === 'audio') {
          this.startWebRTC(false)
        } else if (this.communicationMode === 'video') {
          this.startWebRTC(true)
        }
      }
    },
    invokeCommunicationAsAgent() {
      if (!this.hasSessionStarted) {
        this.startWebRTC(true)
      }
    },
    hasAcceptedPermissions() {
      if (navigator && navigator.permissions && !navigator.permissions.query) {
        return true
      }
      let audioPermission = null
      let videoPermission = null

      try {
        navigator.permissions
          .query({ name: 'camera' })
          .then((permissionStatus) => {
            audioPermission = permissionStatus.state // granted, denied, prompt
            if (audioPermission !== 'granted') {
              this.$emit('requestPermissions')
            }
          })
          .catch((error) => {
            /* eslint-disable no-console */
            console.log('error')
          })

        navigator.permissions
          .query({ name: 'microphone' })
          .then((permissionStatus) => {
            videoPermission = permissionStatus.state // granted, denied, prompt
            if (videoPermission !== 'granted') {
              this.$emit('requestPermissions')
            }
          })
          .catch((error) => {
            /* eslint-disable no-console */
            console.log('error')
          })
      } catch (error) {
        return true
      }

      return audioPermission === 'granted' && videoPermission === 'granted'
    },
    async startWebRTC(joinMuted) {
      await this.startLocalStream()
      this.setRemoteStream(new MediaStream())
      this.$emit('webrtc-stream', {
        localStream: this.localStream,
        remoteStream: this.remoteStream
      })
      this.createPeerConnection()
    },
    async createPeerConnection() {
      if (this.peerConnection) {
        return
      }
      this.hasSessionStarted = true

      this.peerConnection = new RTCPeerConnection(this.configuration)

      this.localStream.getTracks().forEach((track) => {
        this.peerConnection.addTrack(track, this.localStream)
        this.$emit('update-local-video')
      })

      // Code for collecting ICE candidates below
      this.peerConnection.addEventListener('icecandidate', (event) => {
        if (!event.candidate) {
          return
        }
        if (this.callInitiator === 'agent') {
          this.calleeCandidates.push().set(event.candidate.toJSON())
        } else {
          this.callerCandidates.push().set(event.candidate.toJSON())
        }
      })
      // Code for collecting ICE candidates above
      this.peerConnection.addEventListener('track', (event) => {
        event.streams[0].getTracks().forEach((track) => {
          this.remoteStream.addTrack(track)
          const remoteVideoTracks = this.remoteStream.getVideoTracks()
          const remoteAudioTracks = this.remoteStream.getAudioTracks()
          if (remoteVideoTracks && remoteVideoTracks.length > 0) {
            this.remoteStreamCount = remoteVideoTracks.filter((x) => x.enabled).length
            if (this.remoteStreamCount > 0) {
              this.$serverBus.$emit('visitor-video-ready', true)
            }
          }
          if (remoteAudioTracks && remoteAudioTracks.length > 0) {
            const audioStreamCount = remoteAudioTracks.filter((x) => x.enabled).length
            if (audioStreamCount > 0) {
              this.$serverBus.$emit('visitor-audio-ready', true)
            }
          }
          this.$emit('webrtc-stream', {
            localStream: this.localStream,
            remoteStream: this.remoteStream
          })
          this.$emit('update-remote-video')
        })
      })

      if (this.callInitiator === 'visitor') {
        /* CallInitiator: */
        /* Visitor */
        const offerOptions = {
          offerToReceiveAudio: 1,
          offerToReceiveVideo: 1
        }
        const offer = await this.peerConnection.createOffer(offerOptions)
        await this.peerConnection.setLocalDescription(offer)
        const roomWithOffer = {
          offer: {
            type: offer.type,
            sdp: offer.sdp,
            messageMode: this.communicationMode
          },
          negotiate: true
        }
        await this.roomRef.set(roomWithOffer)
        const info = { initiator: 'visitor', visitorIsCalling: true, agentIsCalling: false, key: Math.random().toString(36).substring(2, 15) }
        if (this.shouldNegotiateForSafari) {
          info.renegotiate = true
        }

        this.$database.ref(`/av-call/${this.visitorId}`).set(info)

        if (this.calleeCandidates) {
          this.calleeCandidates.on('child_added', async (snapshot) => {
            const data = snapshot.val()
            const iceCandidate = new RTCIceCandidate(data)
            if (this.peerConnection) {
              this.peerConnection.addIceCandidate(iceCandidate)
            } else {
              this.iceCandidates.push(iceCandidate)
            }
          })
        }
        // END CallInitiator Visitor
      } else if (this.callInitiator === 'agent') {
        /* CallInitiator: */
        /* Agent */
        this.agentOfferListener = this.$database.ref(`/webrtc/${this.visitorId}/agent/offer`).on('value', async (snapshot) => {
          const data = snapshot.val()
          if (!data) {
            return
          }
          this.rtcSessionDescription = new RTCSessionDescription(data)
          await this.peerConnection.setRemoteDescription(this.rtcSessionDescription)
          const answer = await this.peerConnection.createAnswer()
          await this.peerConnection.setLocalDescription(answer)
          const rq = {
            answer: {
              type: answer.type,
              sdp: answer.sdp
            }
          }
          await this.roomRef.update(rq)
          this.callerCandidates.on('child_added', async (snapshot) => {
            //console.log('child_added cancidate')
            const data = snapshot.val()
            const iceCandidate = new RTCIceCandidate(data)
            if (iceCandidate) {
              if (this.peerConnection && this.peerConnection.remoteDescription && this.peerConnection.remoteDescription.type) {
                await this.peerConnection.addIceCandidate(iceCandidate)
              } else {
                this.iceCandidates.push(iceCandidate)
              }
            }
          })
          // END CallInitiator Agent
        })
      }
      // Listening for remote session description below
      if (this.roomRef) {
        this.roomRef.on('value', async (snapshot) => {
          const data = snapshot.val()
          if (!data) {
            return
          }

          if (this.peerConnection && data.negotiate && data.initiator === 'agent') {
            const description = { sdp: data.sdp, type: data.type }
            try {
              this.rtcSessionDescription = new RTCSessionDescription(description)
              await this.peerConnection.setRemoteDescription(this.rtcSessionDescription)
            } catch (error) {}
            if (data.type == 'offer') {
              await this.peerConnection.setLocalDescription()
              const dataDescription = {
                type: this.peerConnection.localDescription.type,
                sdp: this.peerConnection.localDescription.sdp,
                negotiate: true,
                initiator: 'agent'
              }
              await this.roomRef.set(dataDescription)
            }
          }

          if (this.peerConnection && data.updateAgentDescription) {
            this.rtcSessionDescription = new RTCSessionDescription(data.description)
            await this.peerConnection.setRemoteDescription(this.rtcSessionDescription)
            for (const iceCandidate of this.iceCandidates) {
              await this.peerConnection.addIceCandidate(iceCandidate)
            }
          }
          if (this.peerConnection && !this.peerConnection.currentRemoteDescription && data && data.answer && !data.calleeCandidates) {
            this.rtcSessionDescription = new RTCSessionDescription(data.answer)
            await this.peerConnection.setRemoteDescription(this.rtcSessionDescription)
            for (const iceCandidate of this.iceCandidates) {
              await this.peerConnection.addIceCandidate(iceCandidate)
            }
          }
        })
      }
      if (this.peerConnection) {
        this.peerConnection.onnegotiationneeded = async () => {
          if (adapter.browserDetails.browser === 'safari') {
            await this.destroyWebRTC()
            this.closeListeners()
            this.shouldNegotiateForSafari = true
            this.joinMeeting()
            return
          }
          if (this.peerConnection.connectionState !== 'connected') {
            return false
          }

          try {
            this.makingOffer = true
            await this.peerConnection.setLocalDescription()
            const dataDescription = {
              type: this.peerConnection.localDescription.type,
              sdp: this.peerConnection.localDescription.sdp,
              negotiate: true,
              initiator: 'visitor'
            }
            await this.roomRef.set(dataDescription)
          } catch (err) {
            console.error(err)
          } finally {
            this.makingOffer = false
          }
        }
        this.peerConnection.onconnectionstatechange = () => {
          if (this.peerConnection) {
            switch (this.peerConnection.connectionState) {
              case 'closed':
                // The connection has been closed
                this.destroyWebRTC()
                this.closeListeners()
                break
            }
          }
        }
      }
    },
    resetWebRTC() {
      this.stopStreamTracks(this.cameraStream)
      this.stopStreamTracks(this.screenStream)
      this.stopStreamTracks(this.localStream)
      this.stopStreamTracks(this.remoteStream)

      if (this.mixer && this.mixer.releaseStreams) {
        this.mixer.releaseStreams()
      }

      this.setCameraStream(null)
      this.setScreenStream(null)

      this.connectionInProgress = null

      if (this.agentOfferListener) {
        this.$database.ref(`/webrtc/${this.visitorId}/agent/offer`).off('value')
      }

      /* Set realtime db to null */
      if (this.roomRef) {
        this.roomRef.off('value')
        this.roomRef.set(null)
      }

      this.agentOfferListener = null
      this.roomRef = null
      if (this.callerCandidates) {
        this.callerCandidates.off('child_added')
      }
      this.callerCandidates = null
      if (this.calleeCandidates) {
        this.calleeCandidates.off('child_added')
      }

      this.calleeCandidates = null
      this.rtcSessionDescription = null
      this.setHasWebrtcSessionStarted(false)
      this.hasSessionStarted = false
      if (this.peerConnection) {
        this.peerConnection.close()
      }
      this.peerConnection = null
      this.iceCandidates = []
      this.setLocalStream(null)
      this.setRemoteStream(null)
      this.isAgentOfferProcessed = false
      this.shouldNegotiateForSafari = false
    },

    async destroyWebRTC() {
      this.resetWebRTC()
      if (this.visitorId) {
        this.$database.ref(`/av-call/${this.visitorId}`).off('value')
        this.$database.ref(`/webrtc/${this.visitorId}`).set(null)
        this.$database.ref(`/av-call/${this.visitorId}`).set(null)
        this.$database.ref(`/chats/${this.visitorId}`).set(null)
        this.$database.ref(`/screen-share/${this.visitorId}`).set(null)
      }
      this.callInitiator = 'visitor'

      this.setScreenSharingStatus({
        initiatedBy: null,
        status: this.SCREEN_SHARING_STATUS.STOPPED,
        updateKey: Math.random().toString(36).substring(2, 15)
      })

      this.$serverBus.$emit('on-destroyed-webrtc')
    },
    closeListeners() {
      if (this.avCallListener) {
        this.avCallListener()
      }
      this.avCallListener = null
      if (this.screenShareListener) {
        this.screenShareListener()
      }
      this.screenShareListener = null
    }
  }
}
</script>
