<template>
  <div
    class="pathadvice__visitor-chat-wrapper"
    :class="{
      'pathadvice__visitor-chat-wrapper_filled': isMobile && (isCobrowse || isAgentScreenSharing),
      'pathadvice__visitor-chat-wrapper_mobile': isMobile
    }"
  >
    <draggable-modal
      v-show="!isAgentScreenSharing"
      :isDraggable="!isMobile && isCobrowse"
      :width="'280px'"
      :height="!isChat && isCobrowse && !isMobile ? (wasAudioVideoCallActivated ? '265px' : '115px') : heightVisitorDialog"
    >
      <div
        class="pathadvice__visitor-chat"
        :style="{
          '--toolbar-background': toolBarBackground
        }"
      >
        <div v-show="isMaximized && !isWaiting" class="pathadvice__visitor-chat__fullscreen-header" :style="{ background: toolBarBackground }">
          <div class="pathadvice__visitor-chat__fullscreen-header__container">
            <div class="pathadvice__visitor-chat__header__agent__info">
              <div class="pathadvice__visitor-chat__header__agent__avatar">
                <img v-if="showAgentPhoto" class="pathadvice__visitor-chat__header__agent__avatar__img" :src="agentPhoto" />

                <div v-else class="pathadvice__visitor-chat__header__agent__avatar__placeholder">
                  {{ agentInfo.connectedAgent | generateAgentAcronym }}
                  <div class="pathadvice__visitor-chat__header__agent__avatar__placeholder--online"></div>
                </div>
              </div>

              <div class="pathadvice__visitor-chat__header__agent__info__container">
                <div class="pathadvice__visitor-chat__header__agent__info__company">
                  <span>{{ agentInfo.dialogCompanyName }}</span>
                </div>

                <div class="pathadvice__visitor-chat__header__agent__info__name">
                  <span>{{ agentInfo.connectedAgent }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="pathadvice__visitor-chat__fullscreen-header__communication-controls">
            <div class="pathadvice__visitor-chat__fullscreen-header__communication-controls__main">
              <div
                class="pathadvice__visitor-chat__fullscreen-header__communication-controls__btn pathadvice__visitor-chat__fullscreen-header__communication-controls__chat"
                :class="
                  micEnabled
                    ? 'pathadvice__visitor-chat__fullscreen-header__communication-controls__btn--all-active'
                    : 'pathadvice__visitor-chat__fullscreen-header__communication-controls__btn--active'
                "
              >
                <chat-icon :width="16" :height="16" color="#fff" />
              </div>

              <div
                class="pathadvice__visitor-chat__fullscreen-header__communication-controls__btn pathadvice__visitor-chat__fullscreen-header__communication-controls__audio"
                :class="getClassForAudioCallBtn"
                @click.stop="toggleAudioCall"
              >
                <mic-icon v-if="micEnabled" :width="16" :height="16" :color="micEnabled ? '#fff ' : '#12598D'" />
                <mic-mute-icon v-else :width="16" :height="16" :color="isAudioDisabled ? '#fff' : '#12598d'" />
              </div>

              <div
                class="pathadvice__visitor-chat__fullscreen-header__communication-controls__btn pathadvice__visitor-chat__fullscreen-header__communication-controls__video"
                :class="getClassForVideoCallBtn"
                @click.stop="toggleVideoCall"
              >
                <video-call-icon v-if="videoEnabled" color="#fff" :width="16" :height="16" />
                <video-call-off-icon v-else :color="isVideoDisabled ? '#fff' : '#12598d'" :width="16" :height="16" />
              </div>
            </div>

            <div
              v-if="micEnabled || videoEnabled || agentVideoOn || agentAudioOn"
              class="pathadvice__visitor-chat__fullscreen-header__communication-controls__btn--default"
              @click="toggleSettings"
            >
              <settings-icon :width="16" :height="16" color="#12598D" />
            </div>

            <!-- <div class="pathadvice__visitor-chat__fullscreen-header__communication-controls__btn--default" @click="toggleMaximize">
              <minimize-icon v-if="isMaximized" :width="16" :height="16" color="#12598D" />
              <maximize-icon v-else :width="16" :height="16" color="#12598D" />
            </div> -->

            <div class="pathadvice__visitor-chat__fullscreen-header__communication-controls__btn--end-call" @click="endConversation">
              <phone-call-icon :width="18" :height="16" />
            </div>
          </div>
        </div>

        <div
          class="pathadvice__visitor-chat__main-container"
          :class="{
            'pathadvice__visitor-chat__main-container_full-screen': isMaximized && !isWaiting
          }"
        >
          <WaitingRoom
            v-show="isWaiting"
            :messages="modifiedMessages"
            :waitingRoomBackgroundImage="waitingRoomBackgroundImage"
            :waitingRoomContentChatMessage="waitingRoomContentChatMessage"
            :toolBarColor="toolBarColor"
            @messageSent="sendMessageFromWaitingRoom"
            @visitorIsTypingInWaitingRoom="visitorIsTypingInWaitingRoom"
            @sessionEnd="close"
            @input-field="msg = $event"
          />

          <div v-show="isMaximized && !isWaiting" class="pathadvice__visitor-chat__fullscreen-body">
            <div class="pathadvice__visitor-chat__fullscreen-body__video-wrapper">
              <div
                class="pathadvice__visitor-chat__fullscreen-body__video__inner"
                :style="{
                  '--toolbar-background': toolBarBackground
                }"
              >
                <template v-if="!agentVideoOn">
                  <div v-if="!agentVideoOn && !agentAudioOn" class="pathadvice__visitor-chat__fullscreen-body__video__muted-icon-wrapper">
                    <AudioCrossedIcon class="pathadvice__visitor-chat__fullscreen-body__video__muted-icon" />
                  </div>

                  <AudioMobileIcon class="pathadvice__visitor-chat__fullscreen-body__video__audio-icon" :width="380" :height="150" />
                </template>

                <video
                  v-show="agentVideoOn"
                  playsinline
                  autoplay
                  ref="remoteVideoFullScreen"
                  id="fullscreen-remote-video"
                  class="pathadvice__visitor-chat__fullscreen-body__video__output"
                />

                <template v-if="wasAudioVideoCallActivated && !agentVideoOn">
                  <div v-if="!agentVideoOn && !agentAudioOn" class="pathadvice__visitor-chat__fullscreen-body__video__muted-icon-wrapper">
                    <AudioCrossedIcon class="pathadvice__visitor-chat__fullscreen-body__video__muted-icon" />
                  </div>

                  <AudioMobileIcon class="pathadvice__visitor-chat__fullscreen-body__video__audio-icon" :width="380" :height="150" />
                </template>

                <div v-if="agentVideoOn && !agentAudioOn" class="muted-user-icon-wrapper">
                  <mic-mute-icon class="muted-user-icon" :color="'#ffffff'" :width="18" :height="18" />
                </div>
              </div>
            </div>

            <div>
              <div
                :style="{
                  background: toolBarBackground
                }"
              >
                <video
                  v-show="videoEnabled"
                  muted
                  autoplay
                  playsinline
                  ref="localVideoFullScreen"
                  id="fullscreen-local-video"
                  class="pathadvice__visitor-chat__fullscreen-body__video__local"
                />
              </div>

              <template v-if="!videoEnabled">
                <div class="pathadvice__visitor-chat__fullscreen-body__video__local">
                  <div class="pathadvice__visitor-chat__fullscreen-body__video__local__wrapper">
                    <div v-if="!videoEnabled && !micEnabled" class="pathadvice__visitor-chat__fullscreen-body__video__local__wrapper__muted">
                      <AudioCrossedIcon :width="65" :height="65" />
                    </div>

                    <AudioMobileIcon class="pathadvice__visitor-chat__fullscreen-body__video__audio-icon" :width="300" :height="200" />
                  </div>
                </div>
              </template>
            </div>
          </div>

          <notice :notice-type="noticeType" @closeNotice="closeNotice" @goToTheChat="goToTheChat" />

          <settings
            v-if="showSettings"
            :logoImage="logoImage"
            :backgroundImage="backgroundImage"
            :displayDialogVideoInAVSettings="displayDialogVideoInAVSettings"
            :embedVideoURL="embedVideoURL"
            :showProceedBtn="showProceedBtn"
            :localStream="localStream"
            :selectedAudio="selectedAudio"
            :selectedVideo="selectedVideo"
            :selectedOutput="selectedOutput"
            :isDesktopMaximized="!isMobile && isMaximized"
            @settingsClosed="settingsClosed"
            @on-join-meeting="onJoinMeeting"
            @videoPreviewRequest="onVideoPreviewRequest"
            @setNoticeType="$emit('setNoticeType', $event)"
            @goToTheChat="goToTheChat"
          />

          <end-conversation v-if="showEndConversationDialog" :isFullScreen="isMaximized" @dialogClosed="closeEndConversationDialog" @endConversation="close" />

          <div
            v-show="!isWaiting"
            class="pathadvice__visitor-chat__container"
            :class="{
              'pathadvice__visitor-chat__container_full-screen': isMaximized
            }"
          >
            <div
              v-show="!isMaximized"
              class="pathadvice__visitor-chat__header"
              :style="{
                background: toolBarBackground
              }"
              :class="{
                'pathadvice__visitor-chat__header_hidden-content': isMobile && isCobrowse
              }"
            >
              <div v-if="!(isMobile && isCobrowse)" class="pathadvice__visitor-chat__header__container">
                <div v-if="!(isCobrowse && (micEnabled || videoEnabled || agentVideoOn || agentAudioOn))" class="pathadvice__visitor-chat__header__agent__info">
                  <div class="pathadvice__visitor-chat__header__agent__avatar">
                    <img v-if="showAgentPhoto" class="pathadvice__visitor-chat__header__agent__avatar__img" :src="agentPhoto" />

                    <div class="pathadvice__visitor-chat__header__agent__avatar__placeholder" v-else>
                      {{ agentInfo.connectedAgent | generateAgentAcronym }}
                      <div class="pathadvice__visitor-chat__header__agent__avatar__placeholder--online"></div>
                    </div>
                  </div>

                  <div class="pathadvice__visitor-chat__header__agent__info__container">
                    <div class="pathadvice__visitor-chat__header__agent__info__company">
                      <span>{{ agentInfo.dialogCompanyName }}</span>
                    </div>

                    <div class="pathadvice__visitor-chat__header__agent__info__name">
                      <span>{{ agentInfo.connectedAgent }}</span>
                    </div>
                  </div>
                </div>

                <div class="pathadvice__visitor-chat__header__actions">
                  <div v-if="!isMobile && !isCobrowse" class="pathadvice__visitor-chat__header__actions__btn__container">
                    <button
                      class="pathadvice__visitor-chat__header__actions__btn pathadvice__visitor-chat__header__actions__btn__maximize"
                      @click="toggleMaximize"
                    >
                      <minimize-icon v-if="isMaximized" :width="16" :height="16" color="#12598D" />

                      <maximize-icon v-else :width="16" :height="16" color="#12598D" />
                    </button>
                  </div>

                  <div
                    v-if="(micEnabled || videoEnabled) && ((isMobile && !isCobrowse) || (!isMobile && isCobrowse) || (!isMobile && !isCobrowse))"
                    class="pathadvice__visitor-chat__header__actions__btn__container"
                  >
                    <button
                      class="pathadvice__visitor-chat__header__actions__btn pathadvice__visitor-chat__header__actions__btn__settings"
                      @click="toggleSettings"
                    >
                      <settings-icon :width="16" :height="16" color="#12598D" />
                    </button>
                  </div>

                  <div v-if="!isMobile && isCobrowse" class="pathadvice__visitor-chat__header__actions__btn__container">
                    <div
                      class="mr-0 pathadvice__visitor-chat__header__communication-controls__btn--end-call pathadvice__visitor-chat__header__communication-controls__end-call"
                      @click="endConversation"
                    >
                      <phone-call-icon :width="18" :height="16" />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="pathadvice__visitor-chat__header__communication-controls"
                :class="{
                  'pathadvice__visitor-chat__header__communication-controls_cobrowse-mobile': isMobile && isCobrowse,
                  'pathadvice__visitor-chat__header__communication-controls_cobrowse-desktop': !isMobile && isCobrowse
                }"
              >
                <div class="pathadvice__visitor-chat__header__communication-controls__main">
                  <div
                    class="pathadvice__visitor-chat__header__communication-controls__btn pathadvice__visitor-chat__header__communication-controls__chat"
                    :class="
                      (!isChat && isCobrowse) || (isHiddenChat && isCobrowse && isMobile)
                        ? unreadChatMessages.length
                          ? 'pathadvice__visitor-chat__header__communication-controls__btn--unread'
                          : ''
                        : 'pathadvice__visitor-chat__header__communication-controls__btn--active'
                    "
                    @click.stop="toggleChat"
                  >
                    <chat-icon
                      :width="16"
                      :height="16"
                      :color="((!isChat && isCobrowse) || (isHiddenChat && isCobrowse && isMobile)) && !unreadChatMessages.length ? '#12598D' : '#ffffff'"
                    >
                      <div
                        v-if="((!isChat && isCobrowse) || (isHiddenChat && isCobrowse && isMobile)) && unreadChatMessages.length"
                        class="pathadvice__visitor-chat__header__communication-controls__btn__chat-unread-count"
                      />
                    </chat-icon>
                  </div>

                  <div
                    class="pathadvice__visitor-chat__header__communication-controls__btn pathadvice__visitor-chat__header__communication-controls__audio"
                    :class="getClassForAudioCallBtn"
                    @click.stop="toggleAudioCall"
                  >
                    <mic-icon v-if="micEnabled" :width="16" :height="16" :color="micEnabled ? '#fff ' : '#12598D'" />
                    <mic-mute-icon v-else :width="16" :height="16" :color="isAudioDisabled ? '#fff' : '#12598d'" />
                  </div>

                  <div
                    class="pathadvice__visitor-chat__header__communication-controls__btn pathadvice__visitor-chat__header__communication-controls__video"
                    :class="getClassForVideoCallBtn"
                    @click.stop="toggleVideoCall"
                  >
                    <video-call-icon v-if="videoEnabled" :width="16" :height="16" color="#fff" />
                    <video-call-off-icon v-else :width="16" :height="16" :color="isVideoDisabled ? '#fff' : '#12598d'" />
                  </div>
                </div>

                <div
                  class="pathadvice__visitor-chat__header__communication-controls__btn--end-call pathadvice__visitor-chat__header__communication-controls__end-call"
                  @click="endConversation"
                >
                  <phone-call-icon :width="18" :height="16" />
                </div>
              </div>

              <div
                v-if="wasAudioVideoCallActivated && !agentVideoOn && ((isMobile && !isCobrowse) || (!isMobile && isCobrowse) || (!isMobile && !isCobrowse))"
                class="pathadvice__visitor-chat__header__audio-call-body"
                :class="{
                  'pathadvice__visitor-chat__header__audio-call-body_audio-only': !videoEnabled,
                  'pathadvice__visitor-chat__header__audio-call-body_desktop': !isMobile,
                  'pathadvice__visitor-chat__header__audio-call-body_desktop-co-browse': isCobrowse && !isMobile,
                  'pathadvice__visitor-chat__header__audio-call-body_desktop-co-browse-audio-only':
                    isCobrowse && !isMobile && (micEnabled || !videoEnabled || agentVideoOn || agentAudioOn || !isChat)
                }"
              >
                <div
                  v-if="!agentAudioOn"
                  class="pathadvice__visitor-chat__header__audio-call-body__muted-icon-wrapper"
                  :class="{
                    'pathadvice__visitor-chat__header__audio-call-body__muted-icon-wrapper_mobile': isMobile
                  }"
                >
                  <AudioCrossedIcon class="pathadvice__visitor-chat__header__audio-call-body__muted-icon" />
                </div>

                <audio-icon
                  class="pathadvice__visitor-chat__header__audio-call-body__audio-icon"
                  :width="!videoEnabled && !isMobile ? 200 : 250"
                  :height="!videoEnabled && !isMobile ? 62 : 150"
                />
              </div>

              <!-- <div class="pathadvice__visitor-chat__header__audio-call-body__typing-indicator" v-if="!remoteVideoReady">
                <span style="background-color: rgba(255, 255, 255, 0.6)"></span>
                <span style="background-color: rgba(255, 255, 255, 0.8)"></span>
                <span style="background-color: rgba(255, 255, 255, 1)"></span>
              </div> -->

              <div
                v-show="(agentVideoOn || videoEnabled) && !(isMobile && isCobrowse)"
                class="pathadvice__visitor-chat__header__video-call-body"
                :class="{
                  'pathadvice__visitor-chat__header__video-call-body_showed-remote-video': agentVideoOn,
                  'pathadvice__visitor-chat__header__video-call-body_showed-remote-video_mobile': agentVideoOn && isMobile,
                  'pathadvice__visitor-chat__header__video-call-body_showed-remote-video_desktop': agentVideoOn && !isMobile,
                  'pathadvice__visitor-chat__header__video-call-body_showed-remote-video_desktop_cobrowse': agentVideoOn && !isMobile && isCobrowse
                }"
              >
                <div v-show="agentVideoOn" class="pathadvice__visitor-chat__header__video-call-body__remote-video">
                  <video
                    playsinline
                    class="pathadvice__visitor-chat__header__video-call-body__remote-video__output"
                    ref="remoteVideo"
                    id="remote-video"
                    autoplay
                  />

                  <div v-if="agentVideoOn && !agentAudioOn" class="muted-user-icon-wrapper">
                    <mic-mute-icon class="muted-user-icon" :color="'#ffffff'" :width="18" :height="18" />
                  </div>
                </div>

                <div
                  v-show="videoEnabled || agentVideoOn || micEnabled"
                  class="pathadvice__visitor-chat__header__video-call-body__user-video"
                  :class="{
                    'pathadvice__visitor-chat__header__video-call-body__user-video_muted': !videoEnabled
                  }"
                >
                  <div v-if="!videoEnabled && !micEnabled" class="pathadvice__visitor-chat__header__video-call-body__user-video__muted-icon-wrapper">
                    <AudioCrossedIcon class="pathadvice__visitor-chat__header__video-call-body__user-video__muted-icon" />
                  </div>

                  <AudioMobileIcon
                    v-if="!videoEnabled"
                    class="pathadvice__visitor-chat__header__video-call-body__user-video__audio-icon"
                    :width="95"
                    :height="37"
                  />

                  <video
                    v-show="videoEnabled"
                    autoplay
                    playsinline
                    class="pathadvice__visitor-chat__header__video-call-body__user-video__output"
                    ref="localVideo"
                    id="local-video"
                    muted="muted"
                  />
                </div>
              </div>
            </div>

            <button
              v-if="isMobile && isCobrowse && isHiddenChat"
              class="pathadvice__visitor-chat__body__btn-toggle-chat pathadvice__visitor-chat__body__btn-toggle-chat_filled"
              @click.prevent="isHiddenChat = false"
            >
              <ArrowIcon :color="'rgb(18, 89, 141)'" :direction="'top'" @click="isHiddenChat = false" />
            </button>

            <div v-show="agentVideoOn && isCobrowse && isMobile" class="pathadvice__visitor-chat__body__user-video">
              <video
                autoplay
                playsinline
                id="remote-video-cobrowse"
                ref="remoteVideoCobrowse"
                class="pathadvice__visitor-chat__body__user-video__output"
                muted="muted"
              />
            </div>

            <template v-if="!isWaiting">
              <div
                v-show="!(!isChat && isCobrowse && !isMobile)"
                class="pathadvice__visitor-chat__inner"
                :style="{
                  '--toolbar-background': toolBarBackground
                }"
                :class="{
                  'pathadvice__visitor-chat__inner_above': isMobile && isCobrowse,
                  'pathadvice__visitor-chat__inner_hidden': isMobile && isCobrowse && isHiddenChat,
                  'pathadvice__visitor-chat__inner_rounded': isChat && isCobrowse && !isMobile
                }"
              >
                <div
                  class="pathadvice__visitor-chat__body-wrapper"
                  :class="{
                    'pathadvice__visitor-chat__body-wrapper_mobile-co-browsing': isMobile && isCobrowse,
                    'pathadvice__visitor-chat__body-wrapper_hidden': isMobile && isCobrowse && isHiddenChat
                  }"
                >
                  <button v-if="isMobile && isCobrowse" class="pathadvice__visitor-chat__body__btn-toggle-chat" @click.prevent="isHiddenChat = true">
                    <ArrowIcon :color="'rgb(18, 89, 141)'" :direction="'bottom'" @click="isHiddenChat = true" />
                  </button>

                  <div
                    ref="chatBody"
                    class="pathadvice__visitor-chat__body"
                    :style="isMaximized ? '' : 'padding-top: 20px;'"
                    :class="{
                      'pathadvice__visitor-chat__body--collapsed': !isMaximized && (micEnabled || agentVideoOn)
                    }"
                  >
                    <scroll-view ref="chatScrollArea" :style="isCobrowse && !isMobile ? chatHeightStyle : ''">
                      <div
                        class="pathadvice__visitor-chat__body__message"
                        v-for="item in modifiedMessages"
                        v-bind:key="item.chatTime"
                        :class="
                          item.sender === 'agent'
                            ? 'pathadvice__visitor-chat__body__message--received'
                            : `pathadvice__visitor-chat__body__message--sent ${
                                item.type === 'lead-form' && !item.leadForm ? 'pathadvice__visitor-chat__body__message--widget' : ''
                              }`
                        "
                      >
                        <div class="pathadvice__visitor-chat__body__message-body">
                          <LeadForm
                            v-if="dialog && visitor && !item.leadForm && item.type === 'lead-form' && visitor.activeLeadFormId === item.id"
                            :contactForm="dialog.contactForm"
                            :leadForm="dialog.leadForm"
                            :isInChat="true"
                            :modifiedMessages="modifiedMessages"
                            :activeLeadFormId="visitor.activeLeadFormId"
                          />

                          <template v-else>
                            <div v-if="loadingIds.find((x) => x.loaderId === item.chatTime)" class="pathadvice__visitor-chat__body__message-body-overlay-inner">
                              <div class="pathadvice__visitor-chat__body__message-body-overlay-content">
                                <span class="pathadvice__visitor-chat__body__message-body-spinner"></span>
                              </div>
                            </div>

                            <div class="pathadvice__visitor-chat__body__message-body__header">
                              <div class="pathadvice__visitor-chat__body__message-body__agent-name" v-if="item.sender === 'agent'">
                                <span class="msg-agent-name">{{ agentInfo.connectedAgent }}</span>
                              </div>

                              <div class="pathadvice__visitor-chat__body__message-body__time">
                                <span class="msg-time">{{ chatTime(item.time) }}</span>
                              </div>
                            </div>

                            <div class="pathadvice__visitor-chat__body__message-body__msg">
                              <pre class="msg-text" v-html="sanitizeHtml(item.message)"></pre>

                              <div v-if="item.leadForm && item.type === 'lead-form'" class="msg-text">
                                <div>Name: {{ item.leadForm && item.leadForm.name ? item.leadForm.name : '' }}</div>
                                <div>Email: {{ item.leadForm && item.leadForm.email ? item.leadForm.email : '' }}</div>
                                <div v-if="item.leadForm.phone">Phone: {{ item.leadForm.phone }}</div>
                              </div>

                              <div v-if="item.files && item.files.length">
                                <div class="message-file__wrapper flex flex-row flex-wrap -mb-2 justify-end">
                                  <template v-for="(file, index) in item.files">
                                    <message-file
                                      :id="item.chatTime"
                                      class="my-2"
                                      :isWhite="true"
                                      :hasDeleteIcon="false"
                                      :hasDownload="true"
                                      :key="index"
                                      :file="file"
                                      @download-file="downloadFile"
                                    />
                                  </template>
                                </div>
                                <div
                                  class="chat-message-transfer-date flex flex-row justify-end text-xs w-full"
                                  :class="{ _expired: isFilesExpired(item.files) }"
                                >
                                  {{ getChatFilesTransferMessage(item.files) }}
                                </div>
                              </div>
                              <div v-if="item.feedbackQuizAnswers">
                                <div class="flex flex-row flex-wrap -mb-2 -mr-4 justify-end w-full">
                                  <div class="pathadvice__finish-communication__form">
                                    <div
                                      class="pathadvice__finish-communication__field"
                                      style="margin-bottom: 10px"
                                      v-if="item.feedbackQuizAnswers.grade1 !== ''"
                                    >
                                      <div class="pathadvice__finish-communication__field-title" style="margin-bottom: 5px">
                                        {{ dialog.feedbackFormQuestionFirst }}
                                      </div>
                                      <rating-stars :grade="item.feedbackQuizAnswers.grade1" :maxStars="5" :disabled="true" />
                                    </div>
                                    <div
                                      class="pathadvice__finish-communication__field"
                                      style="margin-bottom: 10px"
                                      v-if="item.feedbackQuizAnswers.grade2 !== ''"
                                    >
                                      <div class="pathadvice__finish-communication__field-title" style="margin-bottom: 5px">
                                        {{ dialog.feedbackFormQuestionSecond }}
                                      </div>
                                      <rating-stars :grade="item.feedbackQuizAnswers.grade2" :maxStars="5" :disabled="true" />
                                    </div>
                                    <div
                                      class="pathadvice__finish-communication__field"
                                      style="margin-bottom: 10px"
                                      v-if="item.feedbackQuizAnswers.grade3 !== ''"
                                    >
                                      <div class="pathadvice__finish-communication__field-title" style="margin-bottom: 5px">
                                        {{ dialog.feedbackFormQuestionThird }}
                                      </div>
                                      <rating-stars :grade="item.feedbackQuizAnswers.grade3" :maxStars="5" :disabled="true" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div v-if="item.contactFormData">
                                <div class="flex flex-row flex-wrap -mb-2 -mr-4 justify-end w-full">
                                  <div class="flex" style="flex-direction: column">
                                    <div>Contact Form Data:</div>
                                    <div>
                                      Type: <br />
                                      {{ item.contactFormData.type === 'contact-form-info-saved' ? 'Message' : 'Booking' }}
                                    </div>
                                    <div>
                                      Visitor's name: <br />
                                      {{ item.contactFormData.data.name }}
                                    </div>
                                    <div v-if="item.contactFormData.data.email">
                                      Visitor's email: <br />
                                      {{ item.contactFormData.data.email }}
                                    </div>
                                    <div v-if="item.contactFormData.data.phone">
                                      Visitor's phone: <br />
                                      {{ item.contactFormData.data.phone }}
                                    </div>
                                    <div v-if="item.contactFormData.data.message">
                                      Visitor's message: <br />
                                      {{ item.contactFormData.data.message }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>

                      <div v-show="agentIsTyping" class="chat-message-typing">
                        <span class="chat-message-typing__wording">{{ $tp('buttonAndHints.agentIsTyping') }}</span>

                        <div class="chat-message-typing-indicator">
                          <span class="chat-message-typing-indicator__animated-dots" style="background-color: rgba(18, 89, 141, 0.6)"></span>
                          <span class="chat-message-typing-indicator__animated-dots" style="background-color: rgba(18, 89, 141, 0.8)"></span>
                          <span class="chat-message-typing-indicator__animated-dots" style="background-color: rgba(18, 89, 141, 1)"></span>
                        </div>
                      </div>
                    </scroll-view>
                  </div>
                </div>

                <div
                  class="pathadvice__visitor-chat__footer"
                  :class="{
                    'pathadvice__visitor-chat__footer_full-screen': isMaximized
                  }"
                >
                  <div
                    class="pathadvice__visitor-chat__footer__actions"
                    :style="{
                      '--toolbar-background': toolBarBackground
                    }"
                  >
                    <toolbar @upload-files="onUploadFiles" />
                  </div>

                  <div class="pathadvice__visitor-chat__footer__send-msg send-chat-message-container" ref="sendChatMessageContainer">
                    <div class="pathadvice__visitor-chat__footer__send-msg__icon" @click="sendMessage">
                      <send-icon v-if="isMaximized" :color="canSendMessage ? '#12598D' : '#cccccc'" :width="25" :height="25" />

                      <send-icon v-else :color="canSendMessage ? '#12598D' : '#cccccc'" :width="25" :height="25" />
                    </div>

                    <div class="pathadvice__visitor-chat__footer__send-msg__area">
                      <div v-if="hasFilesizeError" class="pathadvice__visitor-chat__footer__send-msg__area__error">
                        {{ $tp('buttonAndHints.errorsChatFileUploadLimit') }}
                      </div>
                      <message-dropzone
                        v-if="isDropzoneVisible"
                        ref="filesDropzone"
                        @files-added="onFilesAdded"
                        @on-reset="hideDropzone"
                        @vd-drag-enter="isDropzoneVisible = true"
                        @vd-drag-over="isDropzoneVisible = true"
                        @vd-drag-drop="isDropzoneVisible = true"
                      />
                      <textarea
                        v-else
                        ref="message"
                        class="pathadvice__visitor-chat__footer__send-msg__area__input"
                        :placeholder="$tp('buttonAndHints.writeMessage')"
                        v-model="msg"
                        v-on:keyup.enter.exact.prevent="sendMessage"
                        @click="setFocusToField('message')"
                        @keyup="onVisitorTyping"
                      />
                      <div class="pathadvice__visitor-chat__footer__send-msg__area__files flex flex-wrap" v-if="files && files.length">
                        <message-file class="mb-4" v-for="(file, index) in files" :key="index" :file="file" :index="index" @delete-file="onDeleteFile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <powered-by v-show="!(isCobrowse && !isMobile)" />

            <CoBrowsingIframe v-if="isCobrowse && isMobile" />
          </div>
        </div>
      </div>
    </draggable-modal>

    <CoBrowsingIframe v-if="isCobrowse && !isMobile" />
    <ScreenSharing
      v-if="isAgentScreenSharing"
      :remote-video-key="remoteVideoKey"
      @toggle-audio-call="toggleAudioCall"
      @toggle-video-call="toggleVideoCall"
      @end-conversation="close"
    />
  </div>
</template>

<script>
import 'firebase/storage'
/* eslint-disable */
import { mapMutations, mapState, mapGetters } from 'vuex'
import Autolinker from 'autolinker'

import ChatIcon from './icons/ChatIcon.vue'
import ClipIcon from './icons/ClipIcon.vue'
import PhoneCallIcon from './icons/PhoneIcon.vue'
import MaximizeIcon from './icons/MaximizeIcon.vue'
import MinimizeIcon from './icons/MinimizeIcon.vue'
// import MoreIcon from './icons/MoreIcon.vue'
import MicIcon from './icons/MicIcon.vue'
import MicMuteIcon from './icons/MicMuteIcon.vue'
import SendIcon from './icons/SendIcon.vue'
import VideoCallIcon from './icons/VideoCallIcon.vue'
import AudioIcon from './icons/AudioIcon.vue'
import SettingsIcon from './icons/SettingsIcon.vue'

import PoweredBy from '@/components/dialog/PoweredBy.vue'
import WaitingRoom from './WaitingRoom'
import ScrollView from '@blackbp/vue-smooth-scrollbar'
import EndConversation from './EndConversation'
import Settings from './Settings'
import VideoCallOffIcon from './icons/VideoCallOffIcon'
import Notice from './Notice'
import CoBrowsingIframe from './CoBrowsingIframe'
import ScreenSharing from './ScreenSharing'
import ArrowIcon from './icons/ArrowIcon'
import AudioCrossedIcon from './icons/AudioCrossedIcon'
import AudioMobileIcon from './icons/AudioMobileIcon'
import DraggableModal from './DraggableModal'
import MessageFile from './MessageFile.vue'
import MessageDropzone from './MessageDropzone.vue'
import RatingStars from '@/components/rating-stars/RatingStars.vue'
import LeadForm from './LeadForm.vue'

import Toolbar from './Toolbar.vue'

import sanitizeHtml from 'sanitize-html'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export default {
  name: 'VisitorChat',

  components: {
    Notice,
    VideoCallOffIcon,
    Settings,
    EndConversation,
    WaitingRoom,
    PoweredBy,
    AudioIcon,
    MaximizeIcon,
    MinimizeIcon,
    PhoneCallIcon,
    ClipIcon,
    // MoreIcon,
    SendIcon,
    ChatIcon,
    VideoCallIcon,
    MicIcon,
    MicMuteIcon,
    SettingsIcon,
    ArrowIcon,
    ScrollView,
    CoBrowsingIframe,
    ScreenSharing,
    DraggableModal,
    AudioCrossedIcon,
    AudioMobileIcon,
    MessageFile,
    MessageDropzone,
    RatingStars,
    Toolbar,
    LeadForm
  },

  data() {
    return {
      showProceedBtn: false,
      RTDB: null,
      wasAudioVideoCallActivated: false,
      wasVisiorAudioVideoActivated: false,
      agentVideoOn: false,
      agentAudioOn: false,
      agentIsTyping: false,
      visitorIsTyping: false,
      showEndConversationDialog: false,
      msg: '',
      isChat: true,
      isMaximized: true,
      showSettings: false,
      remoteVideoReady: false,
      remoteAudioReady: false,
      isHiddenChat: false,
      hideChatTime: null,
      unsubscribeVisitor: false,
      firstMessageChatTime: null,
      isDropzoneVisible: false,
      isChatFilesUploadComplete: true,
      visitorIsTypingDelayInMS: 5000,
      visitorIsTypingRemainingDelayInMs: 0
    }
  },
  props: {
    logoImage: {
      type: String
    },
    noticeType: {
      type: String
    },
    messages: {
      type: Array
    },
    backgroundImage: {
      type: String,
      required: false
    },
    embedVideoURL: {
      type: String,
      required: false
    },
    displayDialogVideoInAVSettings: {
      type: Boolean,
      default: false,
      required: false
    },
    widthVisitorDialog: {
      type: String,
      required: true
    },
    heightVisitorDialog: {
      type: String,
      required: true
    },

    localVideoKey: {
      type: String,
      required: true
    },
    remoteVideoKey: {
      type: String,
      required: true
    },
    waitingRoomBackgroundImage: {
      type: String,
      required: false
    },
    waitingRoomContentChatMessage: {
      type: Object,
      required: false
    },
    toolBarColor: {
      type: String,
      required: false
    }
  },
  filters: {
    generateAgentAcronym(name) {
      if (!name) {
        return ''
      }

      const fullName = name.split(' ')
      let initials = ''

      if (fullName[0]) {
        initials = fullName[0].charAt(0)
      }

      if (fullName[1]) {
        initials = `${initials}${fullName[1].charAt(0)}`
      }

      return initials.toUpperCase()
    }
  },
  watch: {
    videoEnabled() {
      this.setLocalVideoSource()
    },
    volumeLevel() {
      this.$refs.localVideo.volume = this.volumeLevel

      if (this.$refs.localVideoFullScreen) {
        this.$refs.localVideoFullScreen.volume = this.volumeLevel
      }
    },

    isWaiting() {
      if (this.communicationMode !== 'text' && !this.isWaiting) {
        setTimeout(() => {
          this.setLocalVideoSource()
          this.setRemoteVideoSource()
        }, 200)
      }

      if (!this.isWaiting) {
        this.setAudioOutput()
      }
    },

    localVideoKey() {
      this.setLocalVideoSource()
    },
    remoteStream() {
      if (this.remoteStream) {
        this.setRemoteVideoSource()
      }
    },
    isCobrowse(newVal, oldVal) {
      if (newVal && !this.isMobile) {
        this.isMaximized = false

        this.$emit('coBrowsingModeChanged')
      } else if (!newVal && oldVal && !this.isMobile) {
        this.isMaximized = true

        this.$emit('fullScreenModeChanged', true)
      }
    },
    'selectedOutput.value'() {
      if (this.selectedOutput && this.selectedOutput.value) {
        this.attachSinkId(this.selectedOutput.value)
      }
    },
    msg() {
      if (this.msg && this.msg.trim().length > 0) {
        this.updateHasFileSizeError(false)
      }
    }
  },

  async mounted() {
    if (this.preShowAVSettings) {
      this.showSettings = true
      this.showProceedBtn = true
    }

    this.initDBWatchers()
    this.getAgentVideoInitialState()

    this.$serverBus.$on('media-state', this.setMediaVisitorStates)

    let dragTimer = null
    let lastTarget = null
    const sendChatMessageContainer = this.$refs.sendChatMessageContainer

    function isFile(e) {
      const { dataTransfer } = e

      for (let i = 0; i < dataTransfer.types.length; i++) {
        if (dataTransfer.types[i] === 'Files') {
          return true
        }
      }
      return false
    }
    document.addEventListener(
      'dragover',
      (e) => {
        e.preventDefault()
      },
      false
    )

    document.addEventListener(
      'dragleave',
      (e) => {
        e.preventDefault()
        if (e.target === document || e.target === lastTarget) {
          dragTimer = setTimeout(() => {
            this.isDropzoneVisible = false
          }, 25)
        }
      },
      false
    )
    document.addEventListener(
      'dragenter',
      (e) => {
        e.preventDefault()
        if (isFile(e)) {
          lastTarget = e.target
          this.isDropzoneVisible = true
          window.clearTimeout(dragTimer)
        }
      },
      false
    )

    document.addEventListener(
      'drop',
      (e) => {
        // prevent default action (open as link for some elements)
        e.preventDefault()
        if (e.target !== sendChatMessageContainer) {
          this.isDropzoneVisible = false
        }
      },
      false
    )

    this.$serverBus.$on('chat-files-upload-complete', (isChatFilesUploadComplete) => {
      this.isChatFilesUploadComplete = isChatFilesUploadComplete
    })

    this.setRemoteVideoSource()
  },

  async created() {
    if (window.campaignVisitorTypingDelayInterval) {
      window.clearInterval(window.visitorTypingDelayInterval)
    }

    window.campaignVisitorTypingDelayInterval = setInterval(() => {
      if (this.visitorIsTypingRemainingDelayInMs > 0) {
        this.visitorIsTypingRemainingDelayInMs = this.visitorIsTypingRemainingDelayInMs - 1000
        if (this.visitorIsTypingRemainingDelayInMs <= 0) {
          this.RTDB.update({ visitorIsTyping: false })
        }
      }
    }, 1000)

    if (this.isMobile) {
      this.isMaximized = false
    }
    if (this.visitorInfo && this.visitorInfo.visitorId) {
      this.updateVisitorId(this.visitorInfo.visitorId)
    }

    this.$serverBus.$on('visitor-audio-ready', (t) => {
      if (t && this.remoteStream && this.remoteStream.getVideoTracks()) {
        this.remoteStreamCount = this.remoteStream.getVideoTracks().filter((x) => x.enabled).length
      }
      this.remoteAudioReady = true
    })

    this.$serverBus.$on('visitor-video-ready', (t) => {
      if (t && this.remoteStream && this.remoteStream.getVideoTracks()) {
        this.remoteStreamCount = this.remoteStream.getVideoTracks().filter((x) => x.enabled).length
      }
      this.remoteVideoReady = true
    })
  },

  updated() {
    if (this.unreadChatMessages && this.unreadChatMessages.length > 0) {
      this.scrollToBottom()
    }
  },

  async beforeDestroy() {
    this.$serverBus.$off('chat-files-upload-complete')
    if (this.unsubscribeVisitor) {
      this.unsubscribeVisitor()
    }
    this.$serverBus.$off('media-state', this.setMediaVisitorStates)
    this.showSettings = false

    this.setSelectedAudio(null)
    this.setSelectedVideo(null)
    this.setSelectedOutput(null)

    this.setMicEnabled(false)
    this.setVideoEnabled(false)

    this.setIsAudioVideoAllowed(false)

    this.setMicrophoneOptions([])
    this.setAudioOutputOptions([])
    this.setCameraOptions([])

    this.firstMessageChatTime = null

    if (this.$refs) {
      if (this.$refs.remoteVideo) {
        this.$refs.remoteVideo.srcObject = null
      }

      if (this.$refs.remoteVideoFullScreen) {
        this.$refs.remoteVideoFullScreen.srcObject = null
      }

      if (this.$refs.remoteVideoCobrowse) {
        this.$refs.remoteVideoCobrowse.srcObject = null
      }

      if (this.$refs.localVideo) {
        this.$refs.localVideo.srcObject = null
      }

      if (this.$refs.localVideoFullScreen) {
        this.$refs.localVideoFullScreen.srcObject = null
      }
    }
  },

  computed: {
    ...mapState({
      files: (state) => state.chatFiles.files
    }),
    ...mapGetters({
      selectedAudio: 'webrtc/selectedAudio',
      selectedOutput: 'webrtc/selectedOutput',
      selectedVideo: 'webrtc/selectedVideo',
      cameraStream: 'webrtc/cameraStream',
      localStream: 'webrtc/localStream',
      remoteStream: 'webrtc/remoteStream',
      microphoneOptions: 'webrtc/microphoneOptions',
      cameraOptions: 'webrtc/cameraOptions',
      micEnabled: 'webrtc/micEnabled',
      videoEnabled: 'webrtc/videoEnabled',
      screenSharingStatus: 'webrtc/screenSharingStatus',
      SCREEN_SHARING_STATUS: 'webrtc/SCREEN_SHARING_STATUS',
      visitor: 'visitor',
      visitorId: 'visitorId',
      visitorInfo: 'campaign/visitorInfo',
      callStarted: 'campaign/callStarted',
      communicationMode: 'campaign/communicationMode',
      dialog: 'campaign/dialog',
      isMobile: 'campaign/isMobile',
      agentInfo: 'campaign/agentInfo',
      isWaiting: 'campaign/isWaiting',
      volumeLevel: 'campaign/volumeLevel',
      preShowAVSettings: 'campaign/preShowAVSettings',
      isCobrowse: 'campaign/isCobrowse',
      visitor: 'visitor',
      loadingIds: 'chatFiles/loadingIds',
      hasFilesizeError: 'hasFilesizeError',
      company: 'company'
    }),

    isAudioDisabled() {
      return this.company && this.company.disableAudio
    },
    isVideoDisabled() {
      return this.company && this.company.disableVideo
    },
    isFileSharingDisabled() {
      return this.company && this.company.disableFileSharing
    },

    isAgentScreenSharing() {
      return (
        this.screenSharingStatus &&
        this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.SCREEN_SHARING &&
        this.screenSharingStatus.initiatedBy === 'agent'
      )
    },

    videoStream() {
      return this.cameraStream || this.localStream
    },

    toolBarBackground() {
      return this.dialog && this.dialog.step2ButtonColor ? this.dialog.step2ButtonColor : '#3B86F7'
    },
    toolBarVideoBackground() {
      return this.isVideoDisabled ? '#d3d3d3' : '#ffffff'
    },
    toolBarAudioBackground() {
      return !this.isAudioDisabled ? '#d3d3d3' : '#ffffff'
    },

    showAgentPhoto() {
      if (this.agentInfo && this.agentInfo.connectedAgentImage && this.agentInfo.connectedAgentImage !== '/user.png') {
        return true
      }
      return false
    },
    agentPhoto() {
      if (this.agentInfo && this.agentInfo.connectedAgentImage) {
        return this.agentInfo.connectedAgentImage
      }
    },
    getClassForAudioCallBtn() {
      if (this.isAudioDisabled) return 'pathadvice__visitor-chat__header__communication-controls__btn--disabled'

      if (this.videoEnabled && this.micEnabled) {
        return 'pathadvice__visitor-chat__header__communication-controls__btn--video-call-active'
      }

      if (this.micEnabled) {
        return 'pathadvice__visitor-chat__header__communication-controls__btn--call-active'
      }

      return ''
    },
    getClassForVideoCallBtn() {
      if (this.isVideoDisabled) return 'pathadvice__visitor-chat__header__communication-controls__btn--disabled'

      if (!this.micEnabled && this.videoEnabled) {
        return 'pathadvice__visitor-chat__header__communication-controls__btn--active'
      }

      if (this.videoEnabled) {
        return 'pathadvice__visitor-chat__header__communication-controls__btn--call-active'
      }

      return ''
    },

    canSendMessage() {
      const typedMessage = this.msg.trim()

      if ((typedMessage.length > 0 || (this.files && this.files.length)) && this.isChatFilesUploadComplete) {
        return true
      }

      return false
    },

    modifiedMessages() {
      if (this.messages && this.messages.length > 0) {
        return this.messages
          .filter((item) => !(item.id && item.id !== this.visitor.activeLeadFormId && !item.leadForm))
          .map((item) => {
            return {
              ...item,
              message: Autolinker.link(item.message)
            }
          })
      }
    },

    unreadChatMessages() {
      if (this.filteredMessages && this.filteredMessages.length > 0) {
        return this.filteredMessages.filter(
          (message) =>
            message.sender === 'agent' && this.hideChatTime && message.time && message.time.seconds && this.hideChatTime < message.time.seconds * 1000
        )
      }
      return []
    },
    maxHeight() {
      return !this.isMaximized ? 200 : 330
    },
    chatHeightStyle() {
      if (!this.isMaximized) {
        return {
          maxHeight: `${this.maxHeight}px`,
          height: `${this.maxHeight}px`
        }
      }
      return ''
    }
  },

  methods: {
    ...mapMutations({
      setMicEnabled: 'webrtc/setMicEnabled',
      setVideoEnabled: 'webrtc/setVideoEnabled',

      setSelectedAudio: 'webrtc/setSelectedAudio',
      setSelectedOutput: 'webrtc/setSelectedOutput',
      setSelectedVideo: 'webrtc/setSelectedVideo',

      setIsAudioVideoAllowed: 'webrtc/setIsAudioVideoAllowed',

      setMicrophoneOptions: 'webrtc/setMicrophoneOptions',
      setAudioOutputOptions: 'webrtc/setAudioOutputOptions',
      setCameraOptions: 'webrtc/setCameraOptions',

      updateVisitor: 'UPDATE_VISITOR',
      updateVisitorId: 'UPDATE_VISITOR_ID',
      addLoadingId: 'chatFiles/addLoadingId',
      removeLoadingId: 'chatFiles/removeLoadingId',
      updateHasFileSizeError: 'UPDATE_HAS_FILE_SIZE_ERROR'
    }),
    sanitizeHtml,
    onUploadFiles(files) {
      this.$emit('upload-files', files)
    },
    async attachSinkId(sinkId) {
      if (sinkId === 'default' || sinkId === 'output-off') {
        return
      }
      if (this.$refs.remoteVideo && this.$refs.remoteVideo.setSinkId) {
        await this.$refs.remoteVideo.setSinkId(sinkId)
      }
      if (this.$refs.remoteVideoFullScreen && this.$refs.remoteVideoFullScreen.setSinkId) {
        await this.$refs.remoteVideoFullScreen.setSinkId(sinkId)
      }
      if (this.$refs.remoteVideoCobrowse && this.$refs.remoteVideoCobrowse.setSinkId) {
        await this.$refs.remoteVideoCobrowse.setSinkId(sinkId)
      }
    },
    hideDropzone() {
      this.isDropzoneVisible = false
    },
    onFilesAdded(uploads) {
      if (uploads.length > 0) {
        const ref = this.$refs.filesDropzone.$refs.messageDropzone

        this.$emit('dropzone-upload-files', uploads, ref)
      } else {
        this.isDropzoneVisible = false
      }
    },
    async onDeleteFile(url, index) {
      const fileRef = this.$firebase.storage().refFromURL(url)

      try {
        await fileRef.delete()
        this.$serverBus.$emit('delete-send-file', url, index)
        this.$vs.notify({
          time: 3000,
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('uploads.deleteFile'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    },

    setMediaVisitorStates(data) {
      this.RTDB.update({ visitorVideoOn: data.videoEnabled, visitorAudioOn: data.micEnabled })
    },
    setFocusToField(ref) {
      this.$refs[ref].focus()
    },

    closeNotice() {
      this.$emit('closeNotice')
    },

    goToTheChat() {
      this.$emit('closeNotice')

      if (!this.callStarted && this.showSettings) {
        this.$serverBus.$emit('onJoinMeeting')
      }
    },

    getAgentVideoInitialState() {
      this.$database
        .ref(`/chats/`)
        .child(this.visitorId)
        .once('value', async (dbSnapshot) => {
          const snapshot = dbSnapshot.val()

          if (snapshot) {
            this.agentVideoOn = !!snapshot.agentVideoOn
            this.agentAudioOn = !!snapshot.agentAudioOn

            if (!this.wasAudioVideoCallActivated && (this.agentVideoOn || this.agentAudioOn)) {
              this.wasAudioVideoCallActivated = true
            }
          }
        })
    },
    initDBWatchers() {
      this.RTDB = this.$database.ref(`/chats/${this.visitorId}`)
      // this.$serverBus.$emit('agent-is-typing', false)
      this.RTDB.on('value', async (snapshot) => {
        const data = snapshot.val()

        if (data) {
          // Agent is typing
          this.agentIsTyping = typeof data.agentIsTyping !== 'undefined' ? data.agentIsTyping : false

          if (data && typeof data.visitorIsTyping !== 'undefined') {
            this.visitorIsTyping = data.visitorIsTyping
          }

          // Agent Video Enabled
          this.agentVideoOn = data.agentVideoOn
          this.agentAudioOn = data.agentAudioOn

          if (typeof this.agentVideoOn === Boolean) {
            this.$emit('callActivated', true)
          } else if (typeof this.agentAudioOn === Boolean) {
            this.$emit('callActivated', true)
          }

          if (!this.wasAudioVideoCallActivated && (this.agentVideoOn || this.agentAudioOn)) {
            this.wasAudioVideoCallActivated = true
          }
        }
      })
    },
    onVisitorTyping() {
      this.visitorIsTypingRemainingDelayInMs = this.visitorIsTypingDelayInMS

      if (this.msg.length === 0 && this.visitorIsTyping) {
        this.RTDB.update({ visitorIsTyping: false })
      }
      if (this.msg.length > 0 && !this.visitorIsTyping) {
        this.RTDB.update({ visitorIsTyping: true })
      }
    },
    scrollToBottom() {
      setTimeout(() => {
        const scrollElement = this.$refs.chatScrollArea

        if (scrollElement) {
          const scrollBar = scrollElement.scrollBar

          if (!(scrollBar && scrollBar.size && scrollBar.size.content && scrollBar.size.content.height)) {
            return
          }

          const lastLeadForm = this.modifiedMessages.find((item) => item.type === 'lead-form' && !item.leadForm)

          let time = null

          if (lastLeadForm && lastLeadForm.time && lastLeadForm.time.seconds) {
            time = dayjs(lastLeadForm.time.seconds * 1000)
          }

          if (time) {
            const timeMargin = time.add(3, 'second')
            const now = dayjs()

            const shouldScrollToNewForm = now.isSameOrAfter(time) && now.isSameOrBefore(timeMargin)

            if (shouldScrollToNewForm) {
              const LEAD_FORM_MARGIN = 400
              const value = LEAD_FORM_MARGIN

              return scrollElement.scrollTo(0, scrollBar.size.content.height - value, 600)
            }

            scrollElement.scrollTo(0, scrollBar.size.content.height, 600)
          } else {
            scrollElement.scrollTo(0, scrollBar.size.content.height, 600)
          }
        }
      }, 100)
    },

    chatTime(time) {
      if (time) {
        if (typeof time === 'string') {
          return dayjs(time).locale(this.$i18n.locale).format('HH:mm')
        }
        if (typeof time === 'object' && time.seconds) {
          return dayjs.unix(time.seconds).locale(this.$i18n.locale).format('HH:mm')
        }
      } else {
        if (this.visitor) {
          if (this.visitor.created) {
            if (typeof this.visitor.created === 'string') {
              return dayjs(this.visitor.created).locale(this.$i18n.locale).format('HH:mm')
            }
            if (typeof this.visitor.created === 'object' && this.visitor.created.seconds) {
              return dayjs.unix(this.visitor.created.seconds).locale(this.$i18n.locale).format('HH:mm')
            }
          } else if (this.visitor.firstSeen) {
            if (typeof this.visitor.firstSeen === 'string') {
              return dayjs(this.visitor.firstSeen).locale(this.$i18n.locale).format('HH:mm')
            }
            if (typeof this.visitor.firstSeen === 'object' && this.visitor.firstSeen.seconds) {
              return dayjs.unix(this.visitor.firstSeen.seconds).locale(this.$i18n.locale).format('HH:mm')
            }
          }
          return ''
        }
        return ''
      }
      return ''
    },

    async setupAccessToDevices(types) {
      try {
        this.$emit('setNoticeType', 'request-permissions')

        const constraints = {
          audio: types.includes('microphone'),
          video: types.includes('camera')
        }

        await navigator.mediaDevices.getUserMedia(constraints)

        this.$emit('setNoticeType', '')

        return true
      } catch (error) {
        this.$emit('setNoticeType', 'permissions-denied')

        return false
      }
    },

    async toggleAudioCall() {
      if (this.isAudioDisabled) return

      const custom_event = new CustomEvent('custom_gtm_events')
      custom_event.info = {
        name: 'gtm.click',
        gtm: {
          uniqueEventId:
            Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
          start: new Date().getTime(),
          location: window.location.href,
          event_type: 'Toogle Audio'
        }
      }
      window.dispatchEvent(custom_event)

      if (!this.micEnabled && !this.microphoneOptions.length) {
        const isAccess = await this.setupAccessToDevices(['microphone'])

        if (!isAccess) {
          return
        }
      }

      if (!this.wasAudioVideoCallActivated) {
        this.wasAudioVideoCallActivated = true
      }

      if (!this.micEnabled && !this.videoEnabled) {
        this.$emit('avCallActivated', 'audio')
      }

      this.setMicEnabled(!this.micEnabled)

      if (!this.wasVisiorAudioVideoActivated) {
        this.wasVisiorAudioVideoActivated = true
      }

      if (!this.micEnabled && !this.videoEnabled && this.microphoneOptions.length) {
        setTimeout(() => {
          this.setLocalVideoSource()
        }, 1000)
      }
    },

    async toggleVideoCall() {
      if (this.isVideoDisabled) return

      const custom_event = new CustomEvent('custom_gtm_events')
      custom_event.info = {
        name: 'gtm.click',
        gtm: {
          uniqueEventId:
            Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
          start: new Date().getTime(),
          location: window.location.href,
          event_type: 'Toogle Video'
        }
      }
      window.dispatchEvent(custom_event)

      if (!this.videoEnabled && this.cameraOptions.length <= 1) {
        const isAccess = await this.setupAccessToDevices(['microphone', 'camera'])

        if (!isAccess) {
          return
        }
      }

      if (!this.wasAudioVideoCallActivated) {
        this.wasAudioVideoCallActivated = true
      }

      if (!this.videoEnabled) {
        this.$emit('avCallActivated', 'video')
      }

      this.setVideoEnabled(!this.videoEnabled)

      if (!this.wasVisiorAudioVideoActivated) {
        this.wasVisiorAudioVideoActivated = true

        if (!this.micEnabled) {
          this.setMicEnabled(!this.micEnabled)
        }
      }

      if (!this.videoEnabled && this.cameraOptions.length > 1) {
        setTimeout(() => {
          this.setLocalVideoSource()
        }, 1000)
      }
    },

    toggleMaximize() {
      this.isMaximized = !this.isMaximized

      this.$emit('fullScreenModeChanged', this.isMaximized)
    },

    toggleSettings() {
      this.showSettings = !this.showSettings
      this.showProceedBtn = false
    },

    toggleChat() {
      this.isMobile ? (this.isHiddenChat = false) : (this.isChat = !this.isChat)
      this.hideChatTime = new Date().getTime()
    },

    async settingsClosed() {
      this.showSettings = false

      if (!this.callStarted) {
        /* If call has not started then show chat selection again */
        this.$serverBus.$emit('show-chat-selection')
        this.$emit('startContactFormTimeout')

        return
      }
    },
    async onJoinMeeting() {
      this.showSettings = false

      this.$emit('startContactFormTimeout')
      this.$emit('on-join-meeting')
    },
    onVideoPreviewRequest() {
      this.$emit('deviceSelected')
    },
    visitorIsTypingInWaitingRoom(value) {
      this.$emit('visitorIsTypingInWaitingRoom', value)
    },
    sendMessageFromWaitingRoom(msg) {
      if (this.canSendMessage) {
        if (this.visitor && !this.visitor.connectedAgentId) {
          this.$emit('goToTheChat')
        }
        if (!this.firstMessageChatTime) {
          this.firstMessageChatTime = dayjs().tz(this.$defaultTimezone).diff('2021-01-01')
        }
        this.$emit('sendMessage', msg)
      }
    },
    sendMessage() {
      if (this.canSendMessage) {
        this.$emit('sendMessage', this.msg)
        this.msg = ''
        this.RTDB.update({ visitorIsTyping: false })

        this.scrollToBottom()
      }
    },

    close() {
      if ((this.isMaximized || this.isCobrowse) && !this.isMobile) {
        this.isMaximized = true

        this.$emit('fullScreenModeChanged', true)
      }

      this.showEndConversationDialog = false
      this.$emit('sessionEnd', this.callStarted || !this.isWaiting)
    },

    closeEndConversationDialog() {
      this.showEndConversationDialog = false
    },
    endConversation() {
      this.showEndConversationDialog = true
    },
    // addAttachment() {
    //   this.$emit('invokeCoBrowsing')
    // },
    setLocalVideoSource() {
      if (this.localStream && (this.micEnabled || this.videoEnabled) && this.$refs.localVideo) {
        this.$refs.localVideo.srcObject = this.videoStream

        if (this.$refs.localVideoFullScreen) {
          this.$refs.localVideoFullScreen.srcObject = this.videoStream
        }
      }
    },
    async setRemoteVideoSource() {
      if (this.$refs.remoteVideo && this.remoteStream) {
        this.$refs.remoteVideo.srcObject = this.remoteStream

        this.$refs.remoteVideo.removeEventListener('loadeddata', this.setAudioOutput)
        this.$refs.remoteVideo.addEventListener('loadeddata', this.setAudioOutput)

        if (this.$refs.remoteVideoFullScreen) {
          this.$refs.remoteVideoFullScreen.srcObject = this.remoteStream

          this.$refs.remoteVideoFullScreen.removeEventListener('loadeddata', this.setAudioOutput)
          this.$refs.remoteVideoFullScreen.addEventListener('loadeddata', this.setAudioOutput)
        }

        if (this.$refs.remoteVideoCobrowse) {
          this.$refs.remoteVideoCobrowse.srcObject = this.remoteStream

          this.$refs.remoteVideoCobrowse.removeEventListener('loadeddata', this.setAudioOutput)
          this.$refs.remoteVideoCobrowse.addEventListener('loadeddata', this.setAudioOutput)
        }
      }

      this.setLocalVideoSource()
    },
    async setAudioOutput() {
      if (!this.$refs.remoteVideo || !this.selectedOutput || !this.$refs.remoteVideo.setSinkId) {
        return
      }
      try {
        await this.$refs.remoteVideo.setSinkId(this.selectedOutput.value)

        if (this.$refs.remoteVideoFullScreen) {
          await this.$refs.remoteVideoFullScreen.setSinkId(this.selectedOutput.value)
        }

        if (this.$refs.remoteVideoCobrowse) {
          await this.$refs.remoteVideoCobrowse.setSinkId(this.selectedOutput.value)
        }
      } catch {
        //console.log(`could not set sink ${this.selectedOutput.value}`)
      }
    },

    getFilesExpiration(files) {
      if (files) {
        const expiresAt = dayjs.unix(files[0].expiresAt.seconds)
        const expiresIn = dayjs().to(dayjs(expiresAt))
        return expiresIn
      }
      return ''
    },

    isFilesExpired(files) {
      if (files) {
        const expiresAt = dayjs.unix(files[0].expiresAt.seconds)
        return dayjs().isSameOrAfter(expiresAt, 'day')
      }
      return false
    },

    getChatFilesTransferMessage(files) {
      if (files) {
        const isFilesExpired = this.isFilesExpired(files)
        const filesExpiresIn = this.getFilesExpiration(files)

        if (isFilesExpired) {
          return this.$tp('buttonAndHints.uploadsTransferIsExpired')
        }
        return `${this.$tp('buttonAndHints.uploadsTransferExpires')} ${filesExpiresIn}`
      }
    },

    dowloadFileFromBlob(refUrl, metadata) {
      fetch(refUrl, { method: 'GET' })
        .then((response) => response.blob())
        .then((response) => {
          const [name, ext] = metadata.name.split(/\.(?=[^.]+$)/)
          const blob = new Blob([response], { type: metadata.contentType })
          const link = document.createElement('a')

          link.href = URL.createObjectURL(blob)
          link.download = `${name}.${ext}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((err) => {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    async downloadFile(url, id) {
      this.addLoadingId(id)

      const httpsReference = await this.$firebase.storage().refFromURL(url)
      const metadata = await httpsReference.getMetadata()

      httpsReference
        .getDownloadURL()
        .then((refUrl) => {
          this.dowloadFileFromBlob(refUrl, metadata)
          this.removeLoadingId(id)
        })
        .catch((err) => {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          this.removeLoadingId(id)
        })
    }
  }
}
</script>

<style lang="scss">
.draggable-modal-header {
  height: 100%;
}

.pathadvice__visitor-chat-wrapper {
  .pathadvice__visitor-chat__body__message-body {
    max-width: 100% !important;
  }
}

.pathadvice__visitor-chat__body__message {
  &--widget {
    width: 100%;

    .pathadvice__visitor-chat__body__message-body {
      padding: 0 !important;
      width: 100%;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
      overflow: hidden;
    }
  }
}

.msg-text {
  p {
    display: inline-block;
  }

  ul,
  ol {
    li {
      margin-left: 14px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import './styles/visitor-chat.scss';
</style>
