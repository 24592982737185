var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isChat,
          expression: "isChat",
        },
      ],
      staticClass: "ss-chat-container",
    },
    [
      _c(
        "div",
        {
          staticClass: "ss-chat-container-close-btn",
          on: { click: _vm.showHideChat },
        },
        [
          _c("close-icon", {
            attrs: { color: "#3034F7", width: 24, height: 24 },
          }),
        ],
        1
      ),
      _c("chat-log", { staticClass: "ss-chat-container-chats" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }