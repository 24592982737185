var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isCampaignCheckComplete
    ? _c(
        "div",
        [
          !_vm.isNewFlowCampaign
            ? _c(
                "div",
                {
                  staticClass:
                    "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
                  attrs: { id: "page-meeting-room" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
                    },
                    [
                      _c("vx-card", [
                        _c(
                          "div",
                          {
                            staticClass: "full-page-bg-color",
                            attrs: { slot: "no-body" },
                            slot: "no-body",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-row no-gutter justify-center items-center",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vx-col sm:w-full md:w-full lg:block lg:w-1/2",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mx-auto",
                                      staticStyle: { "max-width": "400px" },
                                      attrs: {
                                        src: require("@/assets/images/pages/meeting.png"),
                                        alt: "login",
                                      },
                                    }),
                                  ]
                                ),
                                !_vm.invalidCampaign
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col flex justify-center items-center sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg",
                                        staticStyle: { "min-height": "400px" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "p-8 login-tabs-container",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-card__title mb-4",
                                              },
                                              [
                                                _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "mb-4 text-large",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("vue.hi"))
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "text-medium",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "vue.meetingWaitMessage"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "vs-progress",
                                              {
                                                attrs: {
                                                  indeterminate: "",
                                                  color: "primary",
                                                },
                                              },
                                              [_vm._v("primary")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.invalidCampaign
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "p-8 login-tabs-container",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vx-card__title m-20",
                                              },
                                              [
                                                _c(
                                                  "h4",
                                                  { staticClass: "mb-4" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "vue.invitationLinkExpired"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "vue.invitationLinkExpiredMessage"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _c("ConnectDialog"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }