var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowedDialog && _vm.visitorId
    ? _c(
        "div",
        [
          _vm.dialog.type === "button-card"
            ? _c(
                "div",
                {
                  staticClass: "pathadvice pathadvice-popup",
                  style: _vm.sizeStylesForVisitorChatWhenCoBrowsing,
                },
                [
                  _vm.displayDialogVideo
                    ? _c("dialog-video", {
                        attrs: { embedVideoURL: _vm.embedVideoURL },
                      })
                    : _vm._e(),
                  _vm.viewType === "connect-dialog"
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticClass: "pathadvice__main-container" },
                            [
                              !_vm.dialog.showVideo
                                ? _c("img", {
                                    ref: "bgImage",
                                    staticClass: "pathadvice__main-container",
                                    attrs: {
                                      src: _vm.backgroundImage,
                                      alt: "bg__image",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _c("banner", {
                            attrs: { logoImage: _vm.logoImage },
                            on: { bannerClicked: _vm.proceedToChatSelection },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          ["button-card"].includes(_vm.dialog.type)
            ? [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.viewType !== "connect-dialog",
                        expression: "viewType !== 'connect-dialog'",
                      },
                    ],
                    staticClass: "pathadvice pathadvice-popup",
                    style: _vm.sizeStylesForVisitorChatWhenCoBrowsing,
                  },
                  [
                    _c("ChatSelection", {
                      attrs: { logoImage: _vm.logoImage },
                      on: {
                        optionSelected: _vm.proceedToChat,
                        proceedToLeadForm: _vm.proceedToLeadForm,
                        sessionEnd: function ($event) {
                          return _vm.endSession(false)
                        },
                      },
                    }),
                    _vm.dialog.isShowLeadFormBeforeConversation &&
                    _vm.viewType === "lead-form"
                      ? _c("LeadForm", {
                          attrs: {
                            contactForm: _vm.dialog.contactForm,
                            leadForm: _vm.dialog.leadForm,
                          },
                          on: {
                            submitLeadForm: _vm.submitLeadForm,
                            goBackToCreateNewRequest:
                              _vm.goBackToCreateNewRequest,
                          },
                        })
                      : _vm._e(),
                    _vm.viewType === "visitor-chat"
                      ? _c("VisitorChat", {
                          attrs: {
                            logoImage: _vm.logoImage,
                            noticeType: _vm.noticeType,
                            messages: _vm.filteredMessages,
                            backgroundImage: _vm.backgroundImage,
                            embedVideoURL: _vm.embedVideoURL,
                            displayDialogVideoInAVSettings:
                              _vm.displayDialogVideoInAVSettings,
                            widthVisitorDialog: _vm.widthVisitorDialog,
                            heightVisitorDialog: _vm.heightVisitorDialog,
                            "local-video-key": _vm.localVideoKey,
                            "remote-video-key": _vm.remoteVideoKey,
                            waitingRoomBackgroundImage:
                              _vm.dialog.waitingRoomBackground,
                            waitingRoomContentChatMessage:
                              _vm.dialog.waitingRoomContentChatMessage,
                          },
                          on: {
                            sendMessage: _vm.sendVisitorMessage,
                            visitorIsTypingInWaitingRoom:
                              _vm.visitorIsTypingInWaitingRoom,
                            sessionEnd: function ($event) {
                              return _vm.endSession($event)
                            },
                            finishCommunication: _vm.finishCommunication,
                            invokeCoBrowsing: _vm.invokeCoBrowsing,
                            deviceSelected: _vm.onDeviceSelected,
                            "on-join-meeting": _vm.onJoinMeeting,
                            avCallActivated: _vm.avCallActivated,
                            startContactFormTimeout:
                              _vm.showContactFormIfNeeded,
                            callActivated: _vm.callActivated,
                            fullScreenModeChanged: _vm.onFullScreenModeChanged,
                            coBrowsingModeChanged: _vm.onCoBrowsingModeChanged,
                            setNoticeType: function ($event) {
                              _vm.noticeType = $event
                            },
                            closeNotice: function ($event) {
                              _vm.noticeType = ""
                            },
                            goToTheChat: function ($event) {
                              return _vm.proceedToChat("chat")
                            },
                            "upload-files": _vm.onUploadFiles,
                            "dropzone-upload-files": _vm.onDropzoneUploadFiles,
                          },
                        })
                      : _vm._e(),
                    _vm.viewType === "visitor-contact-form" &&
                    _vm.trackingData &&
                    _vm.dialog &&
                    _vm.dialog.contactForm
                      ? _c("VisitorContactForm", {
                          attrs: {
                            trackingData: _vm.trackingData,
                            contactForm: _vm.dialog.contactForm,
                          },
                          on: {
                            sessionEnd: _vm.onCloseContactForm,
                            "close-contact-form": _vm.closeContactForm,
                            "hide-contact-form": _vm.hideContactForm,
                          },
                        })
                      : _vm._e(),
                    ![
                      "connect-dialog",
                      "chat-selection",
                      "finish-communication",
                    ].includes(_vm.viewType) && _vm.visitor
                      ? _c("webrtc-communication", {
                          ref: "webrtc",
                          on: {
                            "webrtc-stream": _vm.onWebrtcStream,
                            "update-webrtc-stream": _vm.onWebrtcStreamUpdated,
                            permissionsDenied: _vm.permissionsDenied,
                            requestPermissions: _vm.requestPermissions,
                            permissionsAccepted: _vm.permissionsAccepted,
                            "update-local-video": _vm.onLocalVideoUpdate,
                            "update-remote-video": _vm.onRemoteVideoUpdate,
                          },
                        })
                      : _vm._e(),
                    _vm.viewType === "finish-communication"
                      ? _c("finish-communication", {
                          attrs: {
                            title: _vm.communicationCompleteTitle,
                            message: _vm.communicationCompleteMessage,
                            fontColor: _vm.communicationCompleteFontColor,
                            backgroundColor:
                              _vm.communicationCompleteBackgroundColor,
                            wasAgentConnected: _vm.wasAgentConnected,
                          },
                          on: {
                            "close-finish-communication":
                              _vm.closeFinishCommunication,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.isMobile && _vm.isLandscapeMode
            ? _c("LandscapeNotice")
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }