<template>
  <div class="draggable-modal-wrapper">
    <div
      id="draggable-modal"
      :class="{
        'draggable-modal': isDraggable,
        'stable-modal': !isDraggable,
      }"
      :style="isDraggable ? style : ''"
    >
      <div
        id="draggable-modal-header"
        ref="draggableModalHeader"
        :class="{
          'draggable-modal-header': isDraggable,
          'stable-modal-header': !isDraggable,
        }"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isDraggable: {
      type: Boolean,
      required: true
    },

    width: {
      type: String,
      required: false
    },

    height: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
      elmnt: null
    }
  },

  computed: {
    style() {
      return {
        width: `${this.width}`,
        height: `${this.height}`,
        top: '0',
        right: '0',
      }
    }
  },

  watch: {
    isDraggable(newValue, oldValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.elmnt = document.getElementById('draggable-modal')

          this.dragElement(this.elmnt)

          if (this.$refs.draggableModalHeader) {
            this.$refs.draggableModalHeader.addEventListener('mouseover', this.mouseOverDraggableModalHeader, false)
          }
        })
      } else if (oldValue && !newValue) {
        this.closeDragElement()

        if (this.$refs.draggableModalHeader) {
          this.$refs.draggableModalHeader.removeEventListener('mouseover', this.mouseOverDraggableModalHeader)
        }

        if (this.elmnt) {
          this.elmnt.style.top = ''
          this.elmnt.style.left = ''
          this.elmnt = null
        }
      }
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },

    closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null
      document.onmousemove = null
    },

    elementDrag(e) {
      e = e || window.event
      e.preventDefault()

      // RETURN IF MOUSE IS NOT IN VIEWWABLE AREA
      if (e.clientX < 50 || e.clientY < 50) return
      if (e.clientX > window.screen.availWidth - 50 || e.clientY > window.screen.availHeight - 150) return

      // calculate the new cursor position:
      this.pos1 = this.pos3 - e.clientX
      this.pos2 = this.pos4 - e.clientY
      this.pos3 = e.clientX
      this.pos4 = e.clientY
      // set the element's new position:
      this.elmnt.style.top = `${this.elmnt.offsetTop - this.pos2}px`
      this.elmnt.style.left = `${this.elmnt.offsetLeft - this.pos1}px`
    },

    dragMouseDown(e) {
      e = e || window.event

      // don't move the modal when the event fires on top of the text element
      if (this.$refs.draggableModalHeader && e.target.firstChild && e.target.firstChild.nodeName === '#text' && e.target.textContent) {
        return
      }

      e.preventDefault()

      // get the mouse cursor position at startup:
      this.pos3 = e.clientX
      this.pos4 = e.clientY
      document.onmouseup = this.closeDragElement
      // call a function whenever the cursor moves:
      document.onmousemove = this.elementDrag
    },

    dragElement(elmnt) {
      if (document.getElementById(`${elmnt.id}header`)) {
        // if present, the header is where you move the DIV from:
        document.getElementById(`${elmnt.id}header`).onmousedown = this.dragMouseDown
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = this.dragMouseDown
      }
    },

    mouseOverDraggableModalHeader(event) {
      if (event.target.firstChild && event.target.firstChild.nodeName === '#text' && event.target.textContent) {
        this.$refs.draggableModalHeader.style.cursor = 'default'
      } else {
        this.$refs.draggableModalHeader.style.cursor = 'move'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.draggable-modal {
  position: absolute;
  border-radius: 6px;
  background-color: transparent;
  border: none;
  border-radius: 6px;
  text-align: center;
  z-index: 99999;

  &-wrapper {
    width: 100%;
    height: 100%;
  }

  &-header {
    cursor: move;
    z-index: 100000;
    border-radius: 6px;
    transition: 0.5s;
    box-shadow: 0px 4px 9px rgba(var(--vs-secondary), 0.3);

    &:hover {
      box-shadow: 0px 4px 9px rgba(var(--vs-secondary), 1);
    }
  }
}

.stable-modal {
  width: 100%;
  height: 100%;

  &-header {
    width: 100%;
    height: 100%;
  }
}
</style>
