var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pathadvice__contact-form",
      class: {
        "pathadvice__contact-form_desktop": !_vm.isMobile,
      },
      staticStyle: { width: "100%" },
      style: {
        "--contact-form-font-color": _vm.contactForm.fontColor,
        "--contact-form-background": _vm.contactForm.backgroundColor,
        "--contact-form-darken-background":
          _vm.contactForm.sendButtonBackgroundColor,
        "--contact-form-dark-background":
          _vm.darkСontactFormSendButtonBackgroundColor,
      },
    },
    [
      _c("div", { staticClass: "pathadvice__contact-form__main-container" }, [
        _c(
          "div",
          { staticClass: "pathadvice__contact-form__main-container__actions" },
          [
            _c(
              "button",
              {
                staticClass: "pathadvice__button-action",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.closeContactForm.apply(null, arguments)
                  },
                },
              },
              [_c("CloseIcon", { attrs: { width: 25, height: 25 } })],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "pathadvice__contact-form__main-container__form-wrapper",
            staticStyle: { height: "100%" },
          },
          [
            _c(
              "div",
              {
                staticClass: "pathadvice__contact-form__main-container__form",
                class: {
                  "pathadvice__contact-form__main-container__form_hidden":
                    _vm.isOpenedCalendarWidget,
                },
                style: _vm.contactFormStyle,
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "pathadvice__contact-form__main-container__form--holder",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pathadvice__contact-form__main-container__form__title",
                      },
                      [_vm._v(_vm._s(_vm.$tp("buttonAndHints.contactForm")))]
                    ),
                    _c("div", {
                      staticClass:
                        "pathadvice__contact-form__main-container__form__wording",
                      domProps: { innerHTML: _vm._s(_vm.contactForm.message) },
                    }),
                    _vm.isCorrectTrackingData &&
                    _vm.contactForm.isBookingEnabled
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "flex justify-center items-center mx-auto pathadvice__contact-form__main-container__form__checkbox-wrapper",
                          },
                          [
                            _c(
                              "h4",
                              {
                                staticClass:
                                  "text-center pathadvice__contact-form__main-container__form__checkbox-label",
                                class: {
                                  "text-opacity": _vm.isBookMeetingForm,
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$tp("buttonAndHints.sendAMessage")
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _c("vs-switch", {
                              staticClass:
                                "text-center custom-checkbox pathadvice__contact-form__main-container__form__checkbox",
                              attrs: { color: "#275D73" },
                              model: {
                                value: _vm.isBookMeetingForm,
                                callback: function ($$v) {
                                  _vm.isBookMeetingForm = $$v
                                },
                                expression: "isBookMeetingForm",
                              },
                            }),
                            _c(
                              "h4",
                              {
                                staticClass:
                                  "text-center pathadvice__contact-form__main-container__form__checkbox-label",
                                class: {
                                  "text-opacity": !_vm.isBookMeetingForm,
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$tp("buttonAndHints.bookAMeeting")
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pathadvice__contact-form__main-container__form__fields-holder",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pathadvice__contact-form__main-container__form__fields-holder--left",
                            },
                            [
                              _vm.contactForm.fields.name.enabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pathadvice__contact-form__main-container__form__field",
                                      class: {
                                        "pathadvice__contact-form__main-container__form__field_invalid":
                                          _vm.errors.first("name"),
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.name,
                                            expression: "name",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              required:
                                                _vm.contactForm.fields.name
                                                  .required,
                                              max: 80,
                                            },
                                            expression:
                                              "{\n                      required: contactForm.fields.name.required,\n                      max: 80\n                    }",
                                          },
                                        ],
                                        key: "name",
                                        staticClass:
                                          "pathadvice__contact-form__main-container__form__input",
                                        attrs: {
                                          name: "name",
                                          placeholder: _vm._f(
                                            "modifyInputPlaceholder"
                                          )(
                                            _vm.$tp("buttonAndHints.name"),
                                            _vm.contactForm.fields.name.required
                                          ),
                                        },
                                        domProps: { value: _vm.name },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.name = $event.target.value
                                          },
                                        },
                                      }),
                                      _vm.errors.first("name")
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "pathadvice__contact-form__main-container__form__field__error-checkbox",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    * " +
                                                  _vm._s(
                                                    _vm.errors.first("name")
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("UserIcon", {
                                        staticClass:
                                          "pathadvice__contact-form__main-container__form__field__icon",
                                        attrs: { color: "#262629" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.contactForm.fields.email.enabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pathadvice__contact-form__main-container__form__field",
                                      class: {
                                        "pathadvice__contact-form__main-container__form__field_invalid":
                                          _vm.errors.first("email"),
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.email,
                                            expression: "email",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              required:
                                                _vm.contactForm.fields.email
                                                  .required,
                                              email: true,
                                            },
                                            expression:
                                              "{\n                      required: contactForm.fields.email.required,\n                      email: true\n                    }",
                                          },
                                        ],
                                        key: "email",
                                        staticClass:
                                          "pathadvice__contact-form__main-container__form__input",
                                        attrs: {
                                          name: "email",
                                          placeholder: _vm._f(
                                            "modifyInputPlaceholder"
                                          )(
                                            _vm.$tp(
                                              "buttonAndHints.emailAddress"
                                            ),
                                            _vm.contactForm.fields.email
                                              .required
                                          ),
                                        },
                                        domProps: { value: _vm.email },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.email = $event.target.value
                                          },
                                        },
                                      }),
                                      _vm.errors.first("email")
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "pathadvice__contact-form__main-container__form__field__error",
                                            },
                                            [
                                              _vm._v(
                                                "* " +
                                                  _vm._s(
                                                    _vm.errors.first("email")
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("MailIcon", {
                                        staticClass:
                                          "pathadvice__contact-form__main-container__form__field__icon",
                                        attrs: { color: "#262629" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.isBookMeetingForm &&
                              _vm.contactForm.fields.phone.enabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pathadvice__contact-form__main-container__form__field",
                                      class: {
                                        "pathadvice__contact-form__main-container__form__field_invalid":
                                          _vm.errors.first("phone"),
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.phone,
                                            expression: "phone",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              required:
                                                _vm.contactForm.fields.phone
                                                  .required,
                                              max: 20,
                                            },
                                            expression:
                                              "{\n                      required: contactForm.fields.phone.required,\n                      max: 20\n                    }",
                                          },
                                        ],
                                        key: "phone",
                                        staticClass:
                                          "pathadvice__contact-form__main-container__form__input",
                                        attrs: {
                                          name: "phone",
                                          placeholder: _vm._f(
                                            "modifyInputPlaceholder"
                                          )(
                                            _vm.$tp("buttonAndHints.phone"),
                                            _vm.contactForm.fields.phone
                                              .required
                                          ),
                                        },
                                        domProps: { value: _vm.phone },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.phone = $event.target.value
                                          },
                                        },
                                      }),
                                      _vm.errors.first("phone")
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "pathadvice__contact-form__main-container__form__field__error",
                                            },
                                            [
                                              _vm._v(
                                                "* " +
                                                  _vm._s(
                                                    _vm.errors.first("phone")
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("VerticalPhoneIcon", {
                                        staticClass:
                                          "pathadvice__contact-form__main-container__form__field__icon",
                                        attrs: { color: "#262629" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isBookMeetingForm &&
                              _vm.contactForm.fields.date.enabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pathadvice__contact-form__main-container__form__field",
                                      class: {
                                        "pathadvice__contact-form__main-container__form__field_invalid":
                                          _vm.errors.first("date"),
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.date,
                                            expression: "date",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              required:
                                                _vm.contactForm.fields.date
                                                  .required,
                                            },
                                            expression:
                                              "{\n                      required: contactForm.fields.date.required\n                    }",
                                          },
                                        ],
                                        key: "date",
                                        staticClass:
                                          "pr-8 pathadvice__contact-form__main-container__form__input",
                                        attrs: {
                                          readonly: "",
                                          name: "date",
                                          placeholder: _vm._f(
                                            "modifyInputPlaceholder"
                                          )(
                                            _vm.$tp("buttonAndHints.date"),
                                            _vm.contactForm.fields.date.required
                                          ),
                                        },
                                        domProps: { value: _vm.date },
                                        on: {
                                          click: _vm.openCalendarWidget,
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.date = $event.target.value
                                          },
                                        },
                                      }),
                                      _vm.errors.first("date")
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "pathadvice__contact-form__main-container__form__field__error",
                                            },
                                            [
                                              _vm._v(
                                                "* " +
                                                  _vm._s(
                                                    _vm.errors.first("date")
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("CalendarIcon", {
                                        staticClass:
                                          "pathadvice__contact-form__main-container__form__field__icon",
                                        attrs: { color: "#262629" },
                                      }),
                                      _c("ArrowIcon", {
                                        staticClass:
                                          "pathadvice__contact-form__main-container__form__field__icon-action",
                                        attrs: {
                                          color: _vm.contactForm.fontColor,
                                          direction: "right",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm.contactForm.fields.message.enabled
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "pathadvice__contact-form__main-container__form__field _textarea-field",
                                  class: {
                                    "pathadvice__contact-form__main-container__form__field_invalid":
                                      _vm.errors.first("message"),
                                  },
                                },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.message,
                                        expression: "message",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          required:
                                            _vm.contactForm.fields.message
                                              .required,
                                          max: 500,
                                        },
                                        expression:
                                          "{\n                    required: contactForm.fields.message.required,\n                    max: 500\n                  }",
                                      },
                                    ],
                                    key: "message",
                                    staticClass:
                                      "pathadvice__contact-form__main-container__form__input pathadvice__contact-form__main-container__form__textarea",
                                    attrs: {
                                      name: "message",
                                      placeholder: _vm._f(
                                        "modifyInputPlaceholder"
                                      )(
                                        _vm.$tp("buttonAndHints.message"),
                                        _vm.contactForm.fields.message.required
                                      ),
                                    },
                                    domProps: { value: _vm.message },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.message = $event.target.value
                                      },
                                    },
                                  }),
                                  _vm.errors.first("message")
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "pathadvice__contact-form__main-container__form__field__error",
                                        },
                                        [
                                          _vm._v(
                                            "* " +
                                              _vm._s(
                                                _vm.errors.first("message")
                                              )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("MessageSquareIcon", {
                                    staticClass:
                                      "pathadvice__contact-form__main-container__form__field__icon",
                                    attrs: { color: "#262629" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm.contactForm.isPrivacyInformationEnabled
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "pathadvice__contact-form__main-container__form__field _privacy",
                              class: {
                                "pathadvice__contact-form__main-container__form__field_invalid":
                                  _vm.errors.first("privacy-agreement"),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex flex-row",
                                  class: {
                                    "pathadvice__contact-form__privacy-policy":
                                      _vm.errors.first("privacy-agreement"),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("vs-checkbox", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required:true",
                                            expression: "'required:true'",
                                          },
                                        ],
                                        key: "privacy-agreement",
                                        staticClass:
                                          "pathadvice__contact-form__privacy-policy__checkbox",
                                        style: {
                                          "--contact-form-font-color":
                                            _vm.contactForm.fontColor,
                                          "--contact-form-darken-background":
                                            _vm.contactForm
                                              .sendButtonBackgroundColor,
                                        },
                                        attrs: { name: "privacy-agreement" },
                                        model: {
                                          value: _vm.hasAgreedToPrivacy,
                                          callback: function ($$v) {
                                            _vm.hasAgreedToPrivacy = $$v
                                          },
                                          expression: "hasAgreedToPrivacy",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.contactForm.privacyInformationHtml
                                        ),
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _vm.errors.first("privacy-agreement")
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "pathadvice__contact-form__main-container__form__field__error-checkbox",
                                    },
                                    [
                                      _vm._v(
                                        "\n                * " +
                                          _vm._s(
                                            _vm.errors.first(
                                              "privacy-agreement"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pathadvice__contact-form__main-container__form__actions pathadvice__contact-form__main-container__form__footer",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "pathadvice__contact-form__main-container__form__actions__btn",
                            attrs: { disabled: _vm.isLoadingSubmit },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.submit.apply(null, arguments)
                              },
                            },
                          },
                          [
                            !_vm.isBookMeetingForm
                              ? [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tp("buttonAndHints.sendMessage")
                                    )
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tp("buttonAndHints.bookMeeting")
                                    )
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOpenedCalendarWidget,
                    expression: "isOpenedCalendarWidget",
                  },
                ],
                staticClass:
                  "pathadvice__contact-form__main-container__form pathadvice__contact-form__main-container__form-widget",
                style: _vm.contactFormStyle,
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "pathadvice__button-back",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.isOpenedCalendarWidget = false
                      },
                    },
                  },
                  [
                    _c("ArrowIcon", {
                      staticClass: "mr-2",
                      attrs: { color: _vm.contactForm.fontColor },
                    }),
                    _vm._v(
                      "\n\n          " +
                        _vm._s(_vm.$tp("buttonAndHints.back")) +
                        "\n        "
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pathadvice-calendar-widget-wrapper" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pathadvice__contact-form__main-container__form__title",
                      },
                      [_vm._v(_vm._s(_vm.$tp("buttonAndHints.chooseFreeSlot")))]
                    ),
                    _c("select-timezone", {
                      on: {
                        "timezone-selected": _vm.onTimezoneSelected,
                        "selected-hour-format": _vm.onSelectedHourFormat,
                      },
                    }),
                    !_vm.isLoadingCalendarWidget
                      ? [
                          _c(
                            "div",
                            { staticClass: "pathadvice-calendar-widget" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pathadvice-calendar-widget__inner",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "pathadvice__button-action pathadvice-calendar-widget__button-slide pathadvice-calendar-widget__button-slide-prev",
                                      attrs: {
                                        disabled: _vm.isPrevButtonDisabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.previousWeek()
                                        },
                                      },
                                    },
                                    [
                                      _c("ArrowIcon", {
                                        attrs: {
                                          color: _vm.contactForm.fontColor,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "pathadvice__button-action pathadvice-calendar-widget__button-slide pathadvice-calendar-widget__button-slide-next",
                                      attrs: {
                                        disabled: _vm.isNextButtonDisabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.nextWeek()
                                        },
                                      },
                                    },
                                    [
                                      _c("ArrowIcon", {
                                        attrs: {
                                          color: _vm.contactForm.fontColor,
                                          direction: "right",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pathadvice-calendar-widget__heading",
                                    },
                                    _vm._l(
                                      _vm.selectedWeekDays,
                                      function (weekday) {
                                        return _c(
                                          "div",
                                          {
                                            key: weekday.m.date(),
                                            staticClass:
                                              "pathadvice-calendar-widget__day",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pathadvice-calendar-widget__day__heading",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "pathadvice-calendar-widget__day__heading__date",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          weekday.m.date()
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.$tp(
                                                            "buttonAndHints.systemMonthsShort_" +
                                                              weekday.m
                                                                .locale("en")
                                                                .format("MMM")
                                                                .toLowerCase()
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "pathadvice-calendar-widget__day__heading__day",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$tp(
                                                            "buttonAndHints.systemWeekdaysLong_" +
                                                              weekday.m
                                                                .locale("en")
                                                                .format("ddd")
                                                                .toLowerCase()
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pathadvice-calendar-widget__body",
                                    },
                                    _vm._l(
                                      _vm.selectedWeekDays,
                                      function (weekday) {
                                        return _c(
                                          "div",
                                          {
                                            key: weekday.m.date(),
                                            staticClass:
                                              "pathadvice-calendar-widget__day",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pathadvice-calendar-widget__day__slots-list",
                                              },
                                              [
                                                weekday.times.length
                                                  ? _vm._l(
                                                      weekday.times,
                                                      function (slot, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: slot.dateFormated,
                                                            staticClass:
                                                              "pathadvice-calendar-widget__day__slots-list__button",
                                                            style: {
                                                              "margin-top":
                                                                index !== 0
                                                                  ? _vm.SLOT_MARGIN_TOP +
                                                                    "px"
                                                                  : 0,
                                                              height:
                                                                _vm.SLOT_HEIGHT +
                                                                "px",
                                                              "min-height":
                                                                _vm.SLOT_HEIGHT +
                                                                "px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.selectSlot(
                                                                  slot
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.displaySlotTime(
                                                                    slot
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pathadvice-calendar-widget__day__slots-list__button pathadvice-calendar-widget__day__slots-list__button-full-size",
                                                        style: {
                                                          "min-height":
                                                            _vm.heigthOfNoSlotBlock,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$tp(
                                                                "buttonAndHints.noFreeSlotAvailable"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              2
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      : _c(
                          "div",
                          {
                            staticClass:
                              "pathadvice__contact-form__main-container__form__title pathadvice__contact-form__main-container__form__loading",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tp("buttonAndHints.loading")) +
                                "\n          "
                            ),
                          ]
                        ),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ]),
      _c("powered-by"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }