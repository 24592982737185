<template>
  <div class="landscape-notice">
    <div class="landscape-notice__icon">
      <landscape-icon />
    </div>
    <div class="landscape-notice__wording">
      <span>Please rotate your smartphone to continue the process.</span>
    </div>
  </div>
</template>

<script>
import LandscapeIcon from './icons/LandscapeIcon'
export default {
  name: 'LandscapeNotice',
  components: { LandscapeIcon }
}
</script>

<style lang="scss" scoped>
.landscape-notice {
  position: fixed;
  background: #12598d;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  &__wording {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
  }
}
</style>
