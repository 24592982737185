var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "pathadvice__visitor-chat__body-wrapper",
        class: {
          "pathadvice__visitor-chat__body-wrapper_mobile-co-browsing":
            _vm.isMobile && _vm.isCobrowse,
          "pathadvice__visitor-chat__body-wrapper_hidden":
            _vm.isMobile && _vm.isCobrowse && _vm.isHiddenChat,
        },
      },
      [
        _vm.isMobile && _vm.isCobrowse
          ? _c(
              "button",
              {
                staticClass: "pathadvice__visitor-chat__body__btn-toggle-chat",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.isHiddenChat = true
                  },
                },
              },
              [
                _c("ArrowIcon", {
                  attrs: { color: "rgb(18, 89, 141)", direction: "bottom" },
                  on: {
                    click: function ($event) {
                      _vm.isHiddenChat = true
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            ref: "chatBody",
            staticClass: "pathadvice__visitor-chat__body",
            class: {
              "pathadvice__visitor-chat__body--collapsed":
                !_vm.isMaximized && (_vm.micEnabled || _vm.agentVideoOn),
            },
            style: _vm.isMaximized ? "" : "padding-top: 20px;",
          },
          [
            _c(
              "scroll-view",
              {
                ref: "chatScrollArea",
                style:
                  _vm.isCobrowse && !_vm.isMobile ? _vm.chatHeightStyle : "",
              },
              [
                _vm._l(_vm.modifiedMessages, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.chatTime,
                      staticClass: "pathadvice__visitor-chat__body__message",
                      class:
                        item.sender === "agent"
                          ? "pathadvice__visitor-chat__body__message--received"
                          : "pathadvice__visitor-chat__body__message--sent " +
                            (item.type === "lead-form" && !item.leadForm
                              ? "pathadvice__visitor-chat__body__message--widget"
                              : ""),
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pathadvice__visitor-chat__body__message-body",
                        },
                        [
                          _vm.dialog &&
                          _vm.visitor &&
                          !item.leadForm &&
                          item.type === "lead-form" &&
                          _vm.visitor.activeLeadFormId === item.id
                            ? _c("LeadForm", {
                                attrs: {
                                  contactForm: _vm.dialog.contactForm,
                                  leadForm: _vm.dialog.leadForm,
                                  isInChat: true,
                                  modifiedMessages: _vm.modifiedMessages,
                                  activeLeadFormId:
                                    _vm.visitor.activeLeadFormId,
                                },
                              })
                            : [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pathadvice__visitor-chat__body__message-body__header",
                                  },
                                  [
                                    item.sender === "agent"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pathadvice__visitor-chat__body__message-body__agent-name",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "msg-agent-name" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.agentInfo.connectedAgent
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pathadvice__visitor-chat__body__message-body__time",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "msg-time" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.chatTime(item.time))
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pathadvice__visitor-chat__body__message-body__msg",
                                  },
                                  [
                                    _c("pre", {
                                      staticClass: "msg-text",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.sanitizeHtml(item.message)
                                        ),
                                      },
                                    }),
                                    item.leadForm && item.type === "lead-form"
                                      ? _c("div", { staticClass: "msg-text" }, [
                                          _c("div", [
                                            _vm._v(
                                              "Name: " +
                                                _vm._s(
                                                  item.leadForm &&
                                                    item.leadForm.name
                                                    ? item.leadForm.name
                                                    : ""
                                                )
                                            ),
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              "Email: " +
                                                _vm._s(
                                                  item.leadForm &&
                                                    item.leadForm.email
                                                    ? item.leadForm.email
                                                    : ""
                                                )
                                            ),
                                          ]),
                                          item.leadForm.phone
                                            ? _c("div", [
                                                _vm._v(
                                                  "Phone: " +
                                                    _vm._s(item.leadForm.phone)
                                                ),
                                              ])
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    item.files && item.files.length
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "message-file__wrapper flex flex-row flex-wrap -mb-2 justify-end",
                                            },
                                            [
                                              _vm._l(
                                                item.files,
                                                function (file, index) {
                                                  return [
                                                    _c("message-file", {
                                                      key: index,
                                                      staticClass: "my-2",
                                                      attrs: {
                                                        isWhite: true,
                                                        hasDeleteIcon: false,
                                                        hasDownload: true,
                                                        file: file,
                                                      },
                                                      on: {
                                                        "download-file":
                                                          _vm.downloadFile,
                                                      },
                                                    }),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "chat-message-transfer-date flex flex-row justify-end text-xs w-full",
                                              class: {
                                                _expired: _vm.isFilesExpired(
                                                  item.files
                                                ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.getChatFilesTransferMessage(
                                                      item.files
                                                    )
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.feedbackQuizAnswers
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex flex-row flex-wrap -mb-2 -mr-4 justify-end w-full",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pathadvice__finish-communication__form",
                                                },
                                                [
                                                  item.feedbackQuizAnswers
                                                    .grade1 !== ""
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pathadvice__finish-communication__field",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "10px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pathadvice__finish-communication__field-title",
                                                              staticStyle: {
                                                                "margin-bottom":
                                                                  "5px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.dialog
                                                                      .feedbackFormQuestionFirst
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                          _c("rating-stars", {
                                                            attrs: {
                                                              grade:
                                                                item
                                                                  .feedbackQuizAnswers
                                                                  .grade1,
                                                              maxStars: 5,
                                                              disabled: true,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.feedbackQuizAnswers
                                                    .grade2 !== ""
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pathadvice__finish-communication__field",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "10px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pathadvice__finish-communication__field-title",
                                                              staticStyle: {
                                                                "margin-bottom":
                                                                  "5px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.dialog
                                                                      .feedbackFormQuestionSecond
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                          _c("rating-stars", {
                                                            attrs: {
                                                              grade:
                                                                item
                                                                  .feedbackQuizAnswers
                                                                  .grade2,
                                                              maxStars: 5,
                                                              disabled: true,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.feedbackQuizAnswers
                                                    .grade3 !== ""
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pathadvice__finish-communication__field",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "10px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pathadvice__finish-communication__field-title",
                                                              staticStyle: {
                                                                "margin-bottom":
                                                                  "5px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.dialog
                                                                      .feedbackFormQuestionThird
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                          _c("rating-stars", {
                                                            attrs: {
                                                              grade:
                                                                item
                                                                  .feedbackQuizAnswers
                                                                  .grade3,
                                                              maxStars: 5,
                                                              disabled: true,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.contactFormData
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex flex-row flex-wrap -mb-2 -mr-4 justify-end w-full",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "flex",
                                                  staticStyle: {
                                                    "flex-direction": "column",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "Contact Form Data:"
                                                    ),
                                                  ]),
                                                  _c("div", [
                                                    _vm._v(
                                                      "\n                        Type: "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          item.contactFormData
                                                            .type ===
                                                            "contact-form-info-saved"
                                                            ? "Message"
                                                            : "Booking"
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]),
                                                  _c("div", [
                                                    _vm._v(
                                                      "\n                        Visitor's name: "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          item.contactFormData
                                                            .data.name
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]),
                                                  item.contactFormData.data
                                                    .email
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                        Visitor's email: "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item
                                                                .contactFormData
                                                                .data.email
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  item.contactFormData.data
                                                    .phone
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                        Visitor's phone: "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item
                                                                .contactFormData
                                                                .data.phone
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  item.contactFormData.data
                                                    .message
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                        Visitor's message: "
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item
                                                                .contactFormData
                                                                .data.message
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                        ],
                        2
                      ),
                    ]
                  )
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.agentIsTyping,
                        expression: "agentIsTyping",
                      },
                    ],
                    staticClass: "chat-message-typing",
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "chat-message-typing__wording" },
                      [_vm._v(_vm._s(_vm.$tp("buttonAndHints.agentIsTyping")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "chat-message-typing-indicator" },
                      [
                        _c("span", {
                          staticClass:
                            "chat-message-typing-indicator__animated-dots",
                          staticStyle: {
                            "background-color": "rgba(18, 89, 141, 0.6)",
                          },
                        }),
                        _c("span", {
                          staticClass:
                            "chat-message-typing-indicator__animated-dots",
                          staticStyle: {
                            "background-color": "rgba(18, 89, 141, 0.8)",
                          },
                        }),
                        _c("span", {
                          staticClass:
                            "chat-message-typing-indicator__animated-dots",
                          staticStyle: {
                            "background-color": "rgba(18, 89, 141, 1)",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "pathadvice__visitor-chat__footer",
        class: {
          "pathadvice__visitor-chat__footer_full-screen": _vm.isMaximized,
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "pathadvice__visitor-chat__footer__actions",
            style: {
              "--toolbar-background": _vm.toolBarBackground,
            },
          },
          [_c("toolbar", { on: { "upload-files": _vm.onUploadFiles } })],
          1
        ),
        _c(
          "div",
          {
            ref: "sendChatMessageContainer",
            staticClass:
              "pathadvice__visitor-chat__footer__send-msg send-chat-message-container",
          },
          [
            _c(
              "div",
              {
                staticClass: "pathadvice__visitor-chat__footer__send-msg__icon",
                on: { click: _vm.sendMessage },
              },
              [
                _vm.isMaximized
                  ? _c("send-icon", {
                      attrs: {
                        color: _vm.canSendMessage ? "#12598D" : "#cccccc",
                        width: 16,
                        height: 16,
                      },
                    })
                  : _c("send-icon", {
                      attrs: {
                        color: _vm.canSendMessage ? "#12598D" : "#cccccc",
                        width: 25,
                        height: 25,
                      },
                    }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "pathadvice__visitor-chat__footer__send-msg__area",
              },
              [
                _vm.isDropzoneVisible
                  ? _c("message-dropzone", {
                      ref: "filesDropzone",
                      on: {
                        "files-added": _vm.onFilesAdded,
                        "on-reset": _vm.hideDropzone,
                        "vd-drag-enter": function ($event) {
                          _vm.isDropzoneVisible = true
                        },
                        "vd-drag-over": function ($event) {
                          _vm.isDropzoneVisible = true
                        },
                        "vd-drag-drop": function ($event) {
                          _vm.isDropzoneVisible = true
                        },
                      },
                    })
                  : _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.msg,
                          expression: "msg",
                        },
                      ],
                      ref: "message",
                      staticClass:
                        "pathadvice__visitor-chat__footer__send-msg__area__input",
                      attrs: {
                        placeholder: _vm.$tp("buttonAndHints.writeMessage"),
                      },
                      domProps: { value: _vm.msg },
                      on: {
                        keyup: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            if (
                              $event.ctrlKey ||
                              $event.shiftKey ||
                              $event.altKey ||
                              $event.metaKey
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            return _vm.sendMessage.apply(null, arguments)
                          },
                          _vm.onVisitorTyping,
                        ],
                        click: function ($event) {
                          return _vm.setFocusToField("message")
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.msg = $event.target.value
                        },
                      },
                    }),
                _vm.files && _vm.files.length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "pathadvice__visitor-chat__footer__send-msg__area__files flex flex-wrap",
                      },
                      _vm._l(_vm.files, function (file, index) {
                        return _c("message-file", {
                          key: index,
                          staticClass: "mb-4",
                          attrs: { file: file, index: index },
                          on: { "delete-file": _vm.onDeleteFile },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }