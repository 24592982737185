<template>
  <div class="pa-toggle-icon" :style="`height: ${height}px`">
    <svg
      v-if="enabled"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
      key="start"
    >
      <path
        :fill="computedColor"
        d="M17,7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7M17,15A3,3 0 0,1 14,12A3,3 0 0,1 17,9A3,3 0 0,1 20,12A3,3 0 0,1 17,15Z"
      />
    </svg>
    <svg
      v-if="!enabled"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
      key="stop"
    >
      <path
        :fill="disabledColor"
        d="M17,7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7M7,15A3,3 0 0,1 4,12A3,3 0 0,1 7,9A3,3 0 0,1 10,12A3,3 0 0,1 7,15Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitchIcon',
  props: {
    enabled: {
      type: Boolean,
      required: true,
      default: false
    },
    opacity: {
      type: Number,
      default: 1
    },
    color: {
      type: String,
      default: '#275D73',
      required: false
    },
    disabledColor: {
      type: String,
      default: '#f2f2f2',
      required: false
    },
    width: {
      type: Number,
      default: 35,
      required: false
    },
    height: {
      type: Number,
      default: 35,
      required: false
    }
  },
  computed: {
    computedColor() {
      const { r, g, b } = this.hexToRgb(this.color)
      return `rgba(${r}, ${g}, ${b}, ${this.opacity})`
    }
  },
  methods: {
    hexToRgb(hex) {
      const r = parseInt(hex.substring(1, 3), 16)
      const g = parseInt(hex.substring(3, 5), 16)
      const b = parseInt(hex.substring(5, 7), 16)
      return { r, g, b }
    }
  }
}
</script>

<style lang="scss" scoped>
.pa-toggle-icon {
  cursor: pointer;
}
</style>
