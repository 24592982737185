<template>
  <div v-if="isCampaignCheckComplete">
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-meeting-room" v-if="!isNewFlowCampaign">
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
        <vx-card>
          <div slot="no-body" class="full-page-bg-color">
            <div class="vx-row no-gutter justify-center items-center">
              <div class="vx-col sm:w-full md:w-full lg:block lg:w-1/2">
                <img src="@/assets/images/pages/meeting.png" alt="login" class="mx-auto" style="max-width: 400px" />
              </div>

              <div
                v-if="!invalidCampaign"
                class="vx-col flex justify-center items-center sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg"
                style="min-height: 400px"
              >
                <div class="p-8 login-tabs-container">
                  <div class="vx-card__title mb-4">
                    <h4 class="mb-4 text-large">{{ $t('vue.hi') }}</h4>
                    <p class="text-medium">{{ $t('vue.meetingWaitMessage') }}</p>
                  </div>
                  <vs-progress indeterminate color="primary">primary</vs-progress>
                </div>
              </div>
              <div v-if="invalidCampaign" class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
                <div class="p-8 login-tabs-container">
                  <div class="vx-card__title m-20">
                    <h4 class="mb-4">{{ $t('vue.invitationLinkExpired') }}</h4>
                    <p>{{ $t('vue.invitationLinkExpiredMessage') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <ConnectDialog v-else />
  </div>
</template>

<script>
import 'firebase/functions'
// eslint-disable-next-line
import firebase from 'firebase/app'
import { commonFunctions } from '@/mixins/commonFunctions'
import { mapMutations } from 'vuex'

import ConnectDialog from '@/views/campaigns/visitor/ConnectDialog.vue'

export default {
  components: {
    ConnectDialog
  },
  mixins: [commonFunctions],
  data() {
    return {
      email: '',
      visitorName: '',
      invalidCampaign: false,
      campaign: null,
      isCampaignCheckComplete: false,
      isNewFlowCampaign: false,
      campaignId: null,
      leaderLink: null
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== ''
    }
  },
  methods: {
    ...mapMutations({
      updateCompany: 'UPDATE_COMPANY'
    }),
    async getCampaignById(id) {
      try {
        this.isCampaignCheckComplete = false
        const campaignRef = await this.$db.collection('campaigns').doc(id).get()
        this.campaign = campaignRef.data()

        if (this.campaign) {
          if (this.campaign.company) {
            const companyRef = await this.$db.collection('company').doc(this.campaign.company).get()
            const company = companyRef.data()
            this.updateCompany(company)
          }

          if (Object.prototype.hasOwnProperty.call(this.campaign, 'contactForm')) {
            this.isNewFlowCampaign = true
          }
        }

        this.isCampaignCheckComplete = true
      } catch (error) {
        const { message } = error

        this.isCampaignCheckComplete = true
        this.$vs.notify({
          time: 4000,
          title: this.$i18n.t('vue.error'),
          text: message,
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle'
        })
      } finally {
        this.isCampaignCheckComplete = true
      }
    }
  },

  async mounted() {
    const vm = this
    vm.campaignId = vm.$route.query.cid
    await this.getCampaignById(vm.campaignId)
    if (!this.isNewFlowCampaign) {
      let visitorInfo = null
      if (sessionStorage.getItem('pa-campaign') !== null) {
        visitorInfo = JSON.parse(sessionStorage.getItem('pa-campaign'))
        if (visitorInfo && (!visitorInfo.visitorId || !visitorInfo.campaignId || visitorInfo.campaignId !== vm.campaignId)) {
          sessionStorage.removeItem('pa-campaign')
          visitorInfo = null
        }
      }
      if (!vm.campaignId) {
        vm.invalidCampaign = true
        return
      }
      if (!visitorInfo) {
        const addVisitorForCampaign = this.$functions.httpsCallable('addVisitorForCampaign')
        const result = await addVisitorForCampaign({
          campaignId: vm.campaignId,
          referrer: document.referrer,
          origin: document.location.origin,
          location: document.location.href
        })

        if (result && result.data && result.data && result.data.visitor) {
          visitorInfo = result.data.visitor
          sessionStorage.setItem('pa-campaign', JSON.stringify(visitorInfo))
        } else {
          vm.invalidCampaign = true
        }
      }

      if (visitorInfo && visitorInfo.visitorId && vm.campaignId) {
        const ref = vm.$database.ref(`/status/${visitorInfo.visitorId}`)
        const isOfflineForDatabase = {
          state: 'offline',
          last_changed: firebase.database.ServerValue.TIMESTAMP
        }

        const isOnlineForDatabase = {
          state: 'online',
          location: location.href,
          last_changed: firebase.database.ServerValue.TIMESTAMP
        }
        vm.$database.ref('.info/connected').on('value', function (snapshot) {
          if (snapshot.val() === false) {
            return
          }
          ref
            .onDisconnect()
            .update(isOfflineForDatabase)
            .then(function () {
              ref.update(isOnlineForDatabase)
            })
        })
        const withHttps = (url) => (!/^http[s]?:\/\//i.test(url) ? `https://${url}` : url)
        const agentRequestMeeting = vm.$functions.httpsCallable('agentRequestMeeting')
        const request = {
          url: withHttps(visitorInfo.url),
          visitorId: visitorInfo.visitorId,
          campaignId: vm.campaignId,
          company: visitorInfo.company,
          locale: navigator.language.slice(0, 2) === 'de' ? 'de' : 'en',
          setting: visitorInfo.setting
        }
        const response = await agentRequestMeeting(request)
        vm.leaderLink = response.data.leader_link

        const visitorRef = vm.$database.ref(`status/${visitorInfo.visitorId}`)
        visitorRef.on('value', async (snapshot) => {
          const data = snapshot.val()
          if (data && data.state === 'online' && data.joinURL) {
            sessionStorage.removeItem('pa-campaign')
            setTimeout(() => {
              const name = navigator.language.slice(0, 2) === 'de' ? 'Besucher' : 'Visitor'
              const joinURL = `${data.joinURL}?name=${name}`
              location.href = joinURL
            }, 2000)
          }
        })
      }
    }
  }
}
</script>
