<template>
  <iframe
    allowfullscreen
    frameborder="0"
    class="co-browsing-iframe"
    :class="{
      'co-browsing-iframe_mobile': isMobile
    }"
    :src="surflyURL"
  />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      isMobile: 'campaign/isMobile',
      surflyURL: 'campaign/surflyURL'
    })
  }
}
</script>

<style lang="scss" scoped>
.co-browsing-iframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;

  &_mobile {
    top: 55px;
    height: calc(100% - 125px);
  }
}
</style>
