var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        color: _vm.color,
        viewBox: "0 0 111 90",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M101.867 2H66.2C62.2604 2 59.0667 5.1937 59.0667 9.13333V66.2C59.0667 70.1396 62.2604 73.3333 66.2 73.3333H101.867C105.806 73.3333 109 70.1396 109 66.2V9.13333C109 5.1937 105.806 2 101.867 2Z",
          stroke: "white",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M84.0333 59.0667H84.0683",
          stroke: "white",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M59.0667 37.6666L9.13333 37.6666C5.1937 37.6666 2 40.8603 2 44.8L2 80.4666C2 84.4063 5.1937 87.6 9.13333 87.6L66.2 87.6C70.1396 87.6 73.3333 84.4063 73.3333 80.4666L73.3333 75.1166",
          stroke: "white",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M59.0667 62.6333L59.0667 62.5983",
          stroke: "white",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.6666 23.4L48.3666 12.7L37.6666 2",
          stroke: "white",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.7 26.9666V17.8878C12.7 16.5119 13.4515 15.1924 14.7893 14.2194C16.127 13.2465 17.9414 12.7 19.8333 12.7H44.8",
          stroke: "white",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }