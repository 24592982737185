var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        color: _vm.color,
        viewBox: "0 0 94 37",
        fill: "none",
        stroke: "currentColor",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { opacity: "0.8" } }, [
        _c("path", {
          attrs: {
            d: "M35.2327 18.5898C35.2327 24.1008 35.2327 29.6028 35.2327 35.1139C35.2327 36.1855 34.6754 36.8608 33.6956 36.9869C32.9315 37.095 32.1135 36.5276 31.9517 35.7712C31.8978 35.5371 31.8708 35.294 31.8708 35.0598C31.8708 24.0468 31.8708 13.0248 31.8618 2.01173C31.8618 1.26432 32.0326 0.597956 32.7338 0.219749C33.9113 -0.401591 35.1338 0.354823 35.2237 1.74158C35.2327 1.87666 35.2237 2.01173 35.2237 2.14681C35.2327 7.63081 35.2327 13.1058 35.2327 18.5898Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M10.6207 18.4005C10.6207 13.4928 10.6207 8.58516 10.6207 3.67747C10.6207 2.59688 11.1691 1.93051 12.1489 1.79544C12.9399 1.68738 13.7579 2.25469 13.9107 3.03812C13.9647 3.29026 13.9826 3.5424 13.9826 3.79453C13.9826 13.5919 13.9826 23.3893 13.9916 33.1866C13.9916 33.925 13.8478 34.6184 13.1467 34.9966C11.9152 35.663 10.6567 34.8436 10.6297 33.3848C10.6117 32.5923 10.6297 31.7909 10.6297 30.9984C10.6207 26.8021 10.6207 22.6058 10.6207 18.4005Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M26.5672 18.4188C26.5672 15.2851 26.5672 12.1514 26.5672 9.01765C26.5672 8.31526 26.747 7.70293 27.3852 7.33372C28.5898 6.64035 29.8752 7.45079 29.9201 8.90959C29.9291 9.31481 29.9201 9.72003 29.9201 10.1253C29.9201 16.0595 29.9201 22.0028 29.9201 27.937C29.9201 28.7655 29.6595 29.4408 28.8504 29.792C27.8616 30.2153 26.747 29.5579 26.6122 28.4863C26.5852 28.2522 26.5582 28.009 26.5582 27.7749C26.5672 24.6592 26.5672 21.5345 26.5672 18.4188Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M8.66108 18.5267C8.66108 21.1021 8.66108 23.6865 8.66108 26.2619C8.66108 26.9373 8.4813 27.5046 7.87903 27.8648C6.71045 28.5762 5.39804 27.8108 5.31714 26.388C5.31714 26.325 5.31714 26.2709 5.31714 26.2079C5.31714 21.0301 5.31714 15.8612 5.31714 10.6834C5.31714 9.63882 5.99132 8.89141 6.95315 8.84638C7.76217 8.81036 8.54422 9.46772 8.63411 10.2782C8.66108 10.5033 8.66108 10.7284 8.66108 10.9535C8.66108 13.4929 8.66108 16.0053 8.66108 18.5267Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M19.295 18.5807C19.295 20.5437 19.295 22.5068 19.295 24.4789C19.295 25.1813 19.0882 25.7846 18.441 26.1448C17.2365 26.8202 15.9511 25.9557 15.9331 24.4609C15.9241 22.9481 15.9331 21.4262 15.9331 19.9134C15.9331 17.437 15.9241 14.9607 15.9331 12.4843C15.9331 11.1516 17.0208 10.3411 18.2163 10.7464C18.8815 10.9715 19.286 11.6469 19.286 12.5924C19.295 14.5915 19.295 16.5816 19.295 18.5807Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M37.2016 18.4455C37.2016 16.4374 37.1926 14.4203 37.2016 12.4122C37.2106 11.1245 38.2534 10.3681 39.4399 10.7553C40.1141 10.9714 40.5366 11.6648 40.5366 12.5833C40.5366 15.3478 40.5366 18.1034 40.5366 20.8679C40.5366 22.1286 40.5456 23.3893 40.5366 24.6499C40.5186 25.8926 39.404 26.6761 38.2354 26.2438C37.6241 26.0187 37.2735 25.5414 37.2286 24.8931C37.1837 24.2357 37.2016 23.5784 37.2016 22.912C37.2016 21.4172 37.2016 19.9314 37.2016 18.4455Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M24.6169 18.5536C24.6169 19.382 24.6349 20.2015 24.6079 21.0299C24.5719 22.3356 23.5202 23.1011 22.3157 22.7138C21.7224 22.5247 21.246 21.8494 21.237 21.0479C21.21 19.319 21.21 17.599 21.246 15.8701C21.255 15.1497 21.6235 14.5824 22.3247 14.3032C22.9359 14.0601 23.5022 14.1772 23.9966 14.5914C24.4551 14.9786 24.6079 15.5009 24.6079 16.0772C24.6259 16.8967 24.6169 17.7251 24.6169 18.5536Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M45.8486 18.5626C45.8486 19.4001 45.8486 20.2375 45.8486 21.084C45.8396 21.8944 45.3003 22.6058 44.5722 22.7949C43.88 22.975 43.089 22.6328 42.7204 21.9395C42.5766 21.6603 42.5137 21.3091 42.5137 20.9939C42.4957 19.301 42.4957 17.6081 42.5047 15.9152C42.5137 15.0867 43.035 14.4203 43.7901 14.2402C44.5272 14.0601 45.2913 14.4113 45.6419 15.1497C45.7767 15.4379 45.8217 15.7801 45.8306 16.1043C45.8666 16.9147 45.8486 17.7432 45.8486 18.5626Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M3.33945 18.5805C3.33945 19.463 3.3754 20.3545 3.33046 21.2369C3.27652 22.3716 2.26076 23.0559 1.18207 22.7318C0.498894 22.5246 0.0224727 21.9033 0.0134836 21.1019C-0.00449454 19.3279 -0.00449454 17.5629 0.0134836 15.789C0.0224727 14.8975 0.759578 14.2131 1.67646 14.1771C2.45852 14.1411 3.24956 14.7984 3.30349 15.6539C3.36641 16.6264 3.32147 17.5989 3.32147 18.5805C3.33046 18.5805 3.33046 18.5805 3.33945 18.5805Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M82.8561 18.5898C82.8561 24.1008 82.8561 29.6028 82.8561 35.1139C82.8561 36.1855 82.2988 36.8608 81.319 36.9869C80.5549 37.095 79.7369 36.5276 79.5751 35.7712C79.5212 35.5371 79.4942 35.294 79.4942 35.0598C79.4942 24.0468 79.4942 13.0248 79.4852 2.01173C79.4852 1.26432 79.656 0.597956 80.3572 0.219749C81.5347 -0.401591 82.7573 0.354823 82.8471 1.74158C82.8561 1.87666 82.8471 2.01173 82.8471 2.14681C82.8561 7.63081 82.8561 13.1058 82.8561 18.5898Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M58.244 18.4005C58.244 13.4928 58.244 8.58516 58.244 3.67747C58.244 2.59688 58.7924 1.93051 59.7722 1.79544C60.5632 1.68738 61.3812 2.25469 61.534 3.03812C61.588 3.29026 61.6059 3.5424 61.6059 3.79453C61.6059 13.5919 61.6059 23.3893 61.6149 33.1866C61.6149 33.925 61.4711 34.6184 60.77 34.9966C59.5384 35.663 58.28 34.8436 58.253 33.3848C58.235 32.5923 58.253 31.7909 58.253 30.9984C58.244 26.8021 58.244 22.6058 58.244 18.4005Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M74.1906 18.4188C74.1906 15.2851 74.1906 12.1514 74.1906 9.01765C74.1906 8.31526 74.3704 7.70293 75.0086 7.33372C76.2132 6.64035 77.4986 7.45079 77.5436 8.90959C77.5525 9.31481 77.5436 9.72003 77.5436 10.1253C77.5436 16.0595 77.5436 22.0028 77.5436 27.937C77.5436 28.7655 77.2829 29.4408 76.4739 29.792C75.4851 30.2153 74.3704 29.5579 74.2356 28.4863C74.2086 28.2522 74.1816 28.009 74.1816 27.7749C74.1906 24.6592 74.1906 21.5345 74.1906 18.4188Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M56.2844 18.5267C56.2844 21.1021 56.2844 23.6865 56.2844 26.2619C56.2844 26.9373 56.1046 27.5046 55.5023 27.8648C54.3337 28.5762 53.0213 27.8108 52.9404 26.388C52.9404 26.325 52.9404 26.2709 52.9404 26.2079C52.9404 21.0301 52.9404 15.8612 52.9404 10.6834C52.9404 9.63882 53.6146 8.89141 54.5764 8.84638C55.3855 8.81036 56.1675 9.46772 56.2574 10.2782C56.2844 10.5033 56.2844 10.7284 56.2844 10.9535C56.2844 13.4929 56.2844 16.0053 56.2844 18.5267Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M66.9183 18.5807C66.9183 20.5437 66.9183 22.5068 66.9183 24.4789C66.9183 25.1813 66.7115 25.7846 66.0643 26.1448C64.8598 26.8202 63.5743 25.9557 63.5564 24.4609C63.5474 22.9481 63.5564 21.4262 63.5564 19.9134C63.5564 17.437 63.5474 14.9607 63.5564 12.4843C63.5564 11.1516 64.644 10.3411 65.8396 10.7464C66.5048 10.9715 66.9093 11.6469 66.9093 12.5924C66.9183 14.5915 66.9183 16.5816 66.9183 18.5807Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M84.8249 18.4455C84.8249 16.4374 84.8159 14.4203 84.8249 12.4122C84.8339 11.1245 85.8766 10.3681 87.0632 10.7553C87.7374 10.9714 88.1599 11.6648 88.1599 12.5833C88.1599 15.3478 88.1599 18.1034 88.1599 20.8679C88.1599 22.1286 88.1689 23.3893 88.1599 24.6499C88.1419 25.8926 87.0272 26.6761 85.8587 26.2438C85.2474 26.0187 84.8968 25.5414 84.8519 24.8931C84.8069 24.2357 84.8249 23.5784 84.8249 22.912C84.8249 21.4172 84.8249 19.9314 84.8249 18.4455Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M72.2402 18.5538C72.2402 19.3823 72.2582 20.2017 72.2312 21.0302C72.1952 22.3359 71.1435 23.1013 69.939 22.7141C69.3457 22.525 68.8693 21.8496 68.8603 21.0482C68.8333 19.3192 68.8333 17.5993 68.8693 15.8703C68.8783 15.1499 69.2468 14.5826 69.948 14.3035C70.5592 14.0603 71.1255 14.1774 71.6199 14.5916C72.0784 14.9788 72.2312 15.5011 72.2312 16.0775C72.2492 16.8969 72.2402 17.7254 72.2402 18.5538Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M93.4724 18.5626C93.4724 19.4001 93.4724 20.2375 93.4724 21.084C93.4634 21.8944 92.9241 22.6058 92.196 22.7949C91.5038 22.975 90.7128 22.6328 90.3442 21.9395C90.2004 21.6603 90.1375 21.3091 90.1375 20.9939C90.1195 19.301 90.1195 17.6081 90.1285 15.9152C90.1375 15.0867 90.6588 14.4203 91.4139 14.2402C92.151 14.0601 92.9151 14.4113 93.2657 15.1497C93.4005 15.4379 93.4454 15.7801 93.4544 16.1043C93.4904 16.9147 93.4724 17.7432 93.4724 18.5626Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M50.9629 18.5807C50.9629 19.4632 50.9988 20.3547 50.9539 21.2372C50.8999 22.3718 49.8842 23.0562 48.8055 22.732C48.1223 22.5249 47.6459 21.9035 47.6369 21.1021C47.6189 19.3281 47.6189 17.5632 47.6369 15.7892C47.6459 14.8977 48.383 14.2133 49.2999 14.1773C50.0819 14.1413 50.873 14.7987 50.9269 15.6541C50.9898 16.6267 50.9449 17.5992 50.9449 18.5807C50.9539 18.5807 50.9539 18.5807 50.9629 18.5807Z",
            fill: "#E6ECF1",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }