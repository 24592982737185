<template>
  <div
    class="ss-main"
    :style="{
      '--toolbar-background': toolBarBackground
    }"
  >
    <div class="ss-main-toolbar">
      <div class="ss-main-toolbar-action-button pa-bk-danger" @click="onScreenSharingClicked">
        <share-icon class="toolbar-container-icon" :width="25.41" :height="18"></share-icon>
      </div>

      <div class="ss-main-toolbar-communication-controls">
        <div class="ss-main-toolbar-screenshare"></div>

        <div style="position: relative">
          <div
            class="ss-main-toolbar-communication-controls-button"
            :class="{
              'pa-bk-active': isChat
            }"
            @click="showHideChat"
          >
            <div style="position: relative">
              <chat-icon :class="{ 'pa-icon-default': !isChat }" :width="16" :height="16"> </chat-icon>
            </div>
          </div>
          <div v-if="!isChat && visitor.agentMessages" class="unread-messages">{{ visitor.agentMessages }}</div>
        </div>

        <div
          class="ss-main-toolbar-communication-controls-button"
          :class="{ 'pa-bk-active': micEnabled }"
          :style="{ background: disabledAudioColor, cursor: isAudioDisabled ? 'default' : 'pointer' }"
          @click="onToggleAudioCall"
        >
          <mic-icon v-if="micEnabled" :width="16" :height="16" :color="micEnabled ? '#fff ' : '#262629'" />
          <mic-mute-icon v-else :width="16" :height="16" color="#262629" />
        </div>
        <div
          class="ss-main-toolbar-communication-controls-button"
          :class="{ 'pa-bk-active': videoEnabled }"
          :style="{ background: disabledVideoColor, cursor: isVideoDisabled ? 'default' : 'pointer' }"
          @click="onToggleVideoCall"
        >
          <video-call-icon v-if="videoEnabled" color="#fff" :width="16" :height="16" />
          <video-call-off-icon v-else color="#262629" :width="16" :height="16" />
        </div>
      </div>

      <end-conversation v-if="endConversationPrompt" :isFullScreen="true" @dialogClosed="endConversationPrompt = false" @endConversation="endConversation" />

      <div class="ss-main-toolbar-action-button pa-bk-danger ml-2" @click="endConversationPrompt = true">
        <phone-icon class="chat-header-navbar-icon" :width="18" :height="18"></phone-icon>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ChatIcon from '@/components/icons/ChatIcon.vue'
import VideoCallIcon from '@/components/icons/VideoCallIcon.vue'
import VideoCallOffIcon from '@/components/icons/VideoCallOffIcon.vue'
import PhoneIcon from '@/components/icons/PhoneIcon.vue'
import ShareIcon from '@/components/icons/ShareIcon.vue'
import MicIcon from '@/components/icons/MicIcon.vue'
import MicMuteIcon from '@/components/icons/MicMuteIcon.vue'
import EndConversation from '../EndConversation.vue'

export default {
  name: 'ScreenSharingToolBar',
  components: {
    ChatIcon,
    MicIcon,
    MicMuteIcon,
    VideoCallIcon,
    VideoCallOffIcon,
    PhoneIcon,
    ShareIcon,
    EndConversation
  },
  data() {
    return {
      endConversationPrompt: false,
      SCREENSHARE_RTDB: null
    }
  },
  beforeMount() {
    this.SCREENSHARE_RTDB = this.$database.ref(`/screen-share/${this.visitorId}`)
  },
  computed: {
    ...mapGetters({
      micEnabled: 'webrtc/micEnabled',
      videoEnabled: 'webrtc/videoEnabled',
      company: 'company',
      visitorId: 'visitorId',
      visitor: 'visitor',
      dialog: 'campaign/dialog',
      screenSharingStatus: 'webrtc/screenSharingStatus',
      SCREEN_SHARING_STATUS: 'webrtc/SCREEN_SHARING_STATUS',
      isChat: 'webrtc/isChat'
    }),
    toolBarBackground() {
      return this.dialog && this.dialog.step2ButtonColor ? this.dialog.step2ButtonColor : '#3B86F7'
    },
    disabledAudioColor() {
      if (this.isAudioDisabled) return '#d3d3d3'
      return null
    },
    disabledVideoColor() {
      if (this.isVideoDisabled) return '#d3d3d3'
      return null
    },
    isAudioDisabled() {
      return this.company && this.company.disableAudio
    },
    isVideoDisabled() {
      return this.company && this.company.disableVideo
    }
  },
  methods: {
    ...mapMutations({
      setIsChat: 'webrtc/setIsChat'
    }),
    onToggleAudioCall() {
      if (this.isAudioDisabled) return
      this.$emit('toggle-audio-call')
    },
    onToggleVideoCall() {
      if (this.isVideoDisabled) return
      this.$emit('toggle-video-call')
    },
    async showHideChat() {
      this.setIsChat(!this.isChat)
      await this.$db.collection('visitors').doc(this.visitorId).set({ agentMessages: this.$firebase.firestore.FieldValue.delete() }, { merge: true })
    },
    endConversation() {
      this.$emit('end-conversation')
      this.endConversationPrompt = false
    },
    async onScreenSharingClicked() {
      if (this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.SCREEN_SHARING) {
        this.SCREENSHARE_RTDB.update({
          status: this.SCREEN_SHARING_STATUS.STOPPED,
          initiatedBy: 'agent',
          updateKey: Math.random().toString(36).substring(2, 15)
        })
      }
    },
    onEndSessionWithVisitor() {
      this.$serverBus.$emit('agent-end-call')
    }
  }
}
</script>
<style lang="scss">
.ss-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: calc(100vw);
  height: calc(100vh);
  position: absolute;

  .unread-messages {
    position: absolute;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: rgba(240, 85, 65, 0.8);
    border: none;
    color: #ffffff;
    font-size: 11px;
    font-weight: bold;
    top: -9px;
    right: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pa-bk-active {
    background: var(--toolbar-background);
  }

  .pa-bk-danger {
    background: rgba(240, 85, 65, 0.8) !important;
  }
  .pa-bk-danger:hover {
    background: #f05541 !important;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
  }

  .pa-icon-default {
    color: rgba(38, 38, 41, 1) !important;
    path {
      stroke: rgba(38, 38, 41, 1) !important;
    }
  }

  &-toolbar {
    width: 493.86px;
    height: 65px;
    background: rgba(232, 246, 255, 0.7);
    border-radius: 10.7639px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 100000;

    &-action-button {
      width: 42.39px;
      height: 42.39px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      cursor: pointer;
      transition: 0.5s;
    }

    &-communication-controls {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      &-button {
        width: 121.92px;
        height: 42.39px;
        background: #f7f7f7;
        border-radius: 6px;
        display: flex;
        box-shadow: 0px 2px 4px rgba(196, 196, 196, 0.25);
        cursor: pointer;
        justify-content: center;
        align-items: center;
        transition: 0.5s;

        @media only screen and (min-width: 280px) {
          width: 50.92px;
        }

        @media only screen and (min-width: 360px) {
          width: 75.92px;
        }
        @media only screen and (min-width: 390px) {
          width: 85.92px;
        }
        @media only screen and (min-width: 540px) {
          width: 121.92px;
        }
      }
      &-button:hover {
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
      }
    }
  }
}
</style>
