<template>
  <video
    autoplay
    :loop="!activateVideoSound"
    :muted="!activateVideoSound"
    playsinline
    class="dialog-video"
    :src="videoURL"
    :class="videoClass"
    @click="$emit('click')"
  >
    <source v-if="videoURL.indexOf('.webm') > -1" :src="videoURL" :type="webm" />
    <source v-if="videoURL.indexOf('.mp4') > -1" :src="videoURL" :type="mp4" />
  </video>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      webm: 'video/webm',
      mp4: 'video/mp4'
    }
  },
  props: {
    embedVideoURL: {
      type: String,
      required: true
    },
    videoClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'campaign/dialog'
    }),
    videoURL() {
      const videoURL = this.embedVideoURL || ''
      return videoURL.replace(/&amp;/g, '&')
    },
    activateVideoSound() {
      if (this.dialog && this.dialog.activateVideoSound) return true
      return false
    }
  }
}
</script>
<style lang="scss">
.dialog-video {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  z-index: 1;
}
.video-item {
  &-connect-dialog {
    &-minimized {
      width: 120px;
    }
    &-normal {
      width: 202px;
    }
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    padding: 0;
    border: none;
    flex-shrink: 0;
    height: 114px;
    background-color: transparent;
    background-clip: padding-box;
    cursor: pointer;
    touch-action: manipulation;
    position: relative;
    display: flex;
    z-index: 2;
    object-fit: cover;
  }
  &-chat-selection {
    width: 280px;
    height: 586px;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: 1;
  }

  -o-object-fit: cover;
  object-fit: cover;
}
</style>
