var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pathadvice__visitor-chat-wrapper",
      class: {
        "pathadvice__visitor-chat-wrapper_filled":
          _vm.isMobile && (_vm.isCobrowse || _vm.isAgentScreenSharing),
        "pathadvice__visitor-chat-wrapper_mobile": _vm.isMobile,
      },
    },
    [
      _c(
        "draggable-modal",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isAgentScreenSharing,
              expression: "!isAgentScreenSharing",
            },
          ],
          attrs: {
            isDraggable: !_vm.isMobile && _vm.isCobrowse,
            width: "280px",
            height:
              !_vm.isChat && _vm.isCobrowse && !_vm.isMobile
                ? _vm.wasAudioVideoCallActivated
                  ? "265px"
                  : "115px"
                : _vm.heightVisitorDialog,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "pathadvice__visitor-chat",
              style: {
                "--toolbar-background": _vm.toolBarBackground,
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isMaximized && !_vm.isWaiting,
                      expression: "isMaximized && !isWaiting",
                    },
                  ],
                  staticClass: "pathadvice__visitor-chat__fullscreen-header",
                  style: { background: _vm.toolBarBackground },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "pathadvice__visitor-chat__fullscreen-header__container",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pathadvice__visitor-chat__header__agent__info",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pathadvice__visitor-chat__header__agent__avatar",
                            },
                            [
                              _vm.showAgentPhoto
                                ? _c("img", {
                                    staticClass:
                                      "pathadvice__visitor-chat__header__agent__avatar__img",
                                    attrs: { src: _vm.agentPhoto },
                                  })
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pathadvice__visitor-chat__header__agent__avatar__placeholder",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm._f("generateAgentAcronym")(
                                              _vm.agentInfo.connectedAgent
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                      _c("div", {
                                        staticClass:
                                          "pathadvice__visitor-chat__header__agent__avatar__placeholder--online",
                                      }),
                                    ]
                                  ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pathadvice__visitor-chat__header__agent__info__container",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pathadvice__visitor-chat__header__agent__info__company",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.agentInfo.dialogCompanyName)
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pathadvice__visitor-chat__header__agent__info__name",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.agentInfo.connectedAgent)
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pathadvice__visitor-chat__fullscreen-header__communication-controls",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pathadvice__visitor-chat__fullscreen-header__communication-controls__main",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pathadvice__visitor-chat__fullscreen-header__communication-controls__btn pathadvice__visitor-chat__fullscreen-header__communication-controls__chat",
                              class: _vm.micEnabled
                                ? "pathadvice__visitor-chat__fullscreen-header__communication-controls__btn--all-active"
                                : "pathadvice__visitor-chat__fullscreen-header__communication-controls__btn--active",
                            },
                            [
                              _c("chat-icon", {
                                attrs: { width: 16, height: 16, color: "#fff" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pathadvice__visitor-chat__fullscreen-header__communication-controls__btn pathadvice__visitor-chat__fullscreen-header__communication-controls__audio",
                              class: _vm.getClassForAudioCallBtn,
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.toggleAudioCall.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm.micEnabled
                                ? _c("mic-icon", {
                                    attrs: {
                                      width: 16,
                                      height: 16,
                                      color: _vm.micEnabled
                                        ? "#fff "
                                        : "#12598D",
                                    },
                                  })
                                : _c("mic-mute-icon", {
                                    attrs: {
                                      width: 16,
                                      height: 16,
                                      color: _vm.isAudioDisabled
                                        ? "#fff"
                                        : "#12598d",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pathadvice__visitor-chat__fullscreen-header__communication-controls__btn pathadvice__visitor-chat__fullscreen-header__communication-controls__video",
                              class: _vm.getClassForVideoCallBtn,
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.toggleVideoCall.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm.videoEnabled
                                ? _c("video-call-icon", {
                                    attrs: {
                                      color: "#fff",
                                      width: 16,
                                      height: 16,
                                    },
                                  })
                                : _c("video-call-off-icon", {
                                    attrs: {
                                      color: _vm.isVideoDisabled
                                        ? "#fff"
                                        : "#12598d",
                                      width: 16,
                                      height: 16,
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.micEnabled ||
                      _vm.videoEnabled ||
                      _vm.agentVideoOn ||
                      _vm.agentAudioOn
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "pathadvice__visitor-chat__fullscreen-header__communication-controls__btn--default",
                              on: { click: _vm.toggleSettings },
                            },
                            [
                              _c("settings-icon", {
                                attrs: {
                                  width: 16,
                                  height: 16,
                                  color: "#12598D",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pathadvice__visitor-chat__fullscreen-header__communication-controls__btn--end-call",
                          on: { click: _vm.endConversation },
                        },
                        [
                          _c("phone-call-icon", {
                            attrs: { width: 18, height: 16 },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "pathadvice__visitor-chat__main-container",
                  class: {
                    "pathadvice__visitor-chat__main-container_full-screen":
                      _vm.isMaximized && !_vm.isWaiting,
                  },
                },
                [
                  _c("WaitingRoom", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isWaiting,
                        expression: "isWaiting",
                      },
                    ],
                    attrs: {
                      messages: _vm.modifiedMessages,
                      waitingRoomBackgroundImage:
                        _vm.waitingRoomBackgroundImage,
                      waitingRoomContentChatMessage:
                        _vm.waitingRoomContentChatMessage,
                      toolBarColor: _vm.toolBarColor,
                    },
                    on: {
                      messageSent: _vm.sendMessageFromWaitingRoom,
                      visitorIsTypingInWaitingRoom:
                        _vm.visitorIsTypingInWaitingRoom,
                      sessionEnd: _vm.close,
                      "input-field": function ($event) {
                        _vm.msg = $event
                      },
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isMaximized && !_vm.isWaiting,
                          expression: "isMaximized && !isWaiting",
                        },
                      ],
                      staticClass: "pathadvice__visitor-chat__fullscreen-body",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pathadvice__visitor-chat__fullscreen-body__video-wrapper",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pathadvice__visitor-chat__fullscreen-body__video__inner",
                              style: {
                                "--toolbar-background": _vm.toolBarBackground,
                              },
                            },
                            [
                              !_vm.agentVideoOn
                                ? [
                                    !_vm.agentVideoOn && !_vm.agentAudioOn
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pathadvice__visitor-chat__fullscreen-body__video__muted-icon-wrapper",
                                          },
                                          [
                                            _c("AudioCrossedIcon", {
                                              staticClass:
                                                "pathadvice__visitor-chat__fullscreen-body__video__muted-icon",
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("AudioMobileIcon", {
                                      staticClass:
                                        "pathadvice__visitor-chat__fullscreen-body__video__audio-icon",
                                      attrs: { width: 380, height: 150 },
                                    }),
                                  ]
                                : _vm._e(),
                              _c("video", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.agentVideoOn,
                                    expression: "agentVideoOn",
                                  },
                                ],
                                ref: "remoteVideoFullScreen",
                                staticClass:
                                  "pathadvice__visitor-chat__fullscreen-body__video__output",
                                attrs: {
                                  playsinline: "",
                                  autoplay: "",
                                  id: "fullscreen-remote-video",
                                },
                              }),
                              _vm.wasAudioVideoCallActivated &&
                              !_vm.agentVideoOn
                                ? [
                                    !_vm.agentVideoOn && !_vm.agentAudioOn
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pathadvice__visitor-chat__fullscreen-body__video__muted-icon-wrapper",
                                          },
                                          [
                                            _c("AudioCrossedIcon", {
                                              staticClass:
                                                "pathadvice__visitor-chat__fullscreen-body__video__muted-icon",
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("AudioMobileIcon", {
                                      staticClass:
                                        "pathadvice__visitor-chat__fullscreen-body__video__audio-icon",
                                      attrs: { width: 380, height: 150 },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.agentVideoOn && !_vm.agentAudioOn
                                ? _c(
                                    "div",
                                    { staticClass: "muted-user-icon-wrapper" },
                                    [
                                      _c("mic-mute-icon", {
                                        staticClass: "muted-user-icon",
                                        attrs: {
                                          color: "#ffffff",
                                          width: 18,
                                          height: 18,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              style: {
                                background: _vm.toolBarBackground,
                              },
                            },
                            [
                              _c("video", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.videoEnabled,
                                    expression: "videoEnabled",
                                  },
                                ],
                                ref: "localVideoFullScreen",
                                staticClass:
                                  "pathadvice__visitor-chat__fullscreen-body__video__local",
                                attrs: {
                                  muted: "",
                                  autoplay: "",
                                  playsinline: "",
                                  id: "fullscreen-local-video",
                                },
                                domProps: { muted: true },
                              }),
                            ]
                          ),
                          !_vm.videoEnabled
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pathadvice__visitor-chat__fullscreen-body__video__local",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pathadvice__visitor-chat__fullscreen-body__video__local__wrapper",
                                      },
                                      [
                                        !_vm.videoEnabled && !_vm.micEnabled
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pathadvice__visitor-chat__fullscreen-body__video__local__wrapper__muted",
                                              },
                                              [
                                                _c("AudioCrossedIcon", {
                                                  attrs: {
                                                    width: 65,
                                                    height: 65,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("AudioMobileIcon", {
                                          staticClass:
                                            "pathadvice__visitor-chat__fullscreen-body__video__audio-icon",
                                          attrs: { width: 300, height: 200 },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                  _c("notice", {
                    attrs: { "notice-type": _vm.noticeType },
                    on: {
                      closeNotice: _vm.closeNotice,
                      goToTheChat: _vm.goToTheChat,
                    },
                  }),
                  _vm.showSettings
                    ? _c("settings", {
                        attrs: {
                          logoImage: _vm.logoImage,
                          backgroundImage: _vm.backgroundImage,
                          displayDialogVideoInAVSettings:
                            _vm.displayDialogVideoInAVSettings,
                          embedVideoURL: _vm.embedVideoURL,
                          showProceedBtn: _vm.showProceedBtn,
                          localStream: _vm.localStream,
                          selectedAudio: _vm.selectedAudio,
                          selectedVideo: _vm.selectedVideo,
                          selectedOutput: _vm.selectedOutput,
                          isDesktopMaximized: !_vm.isMobile && _vm.isMaximized,
                        },
                        on: {
                          settingsClosed: _vm.settingsClosed,
                          "on-join-meeting": _vm.onJoinMeeting,
                          videoPreviewRequest: _vm.onVideoPreviewRequest,
                          setNoticeType: function ($event) {
                            return _vm.$emit("setNoticeType", $event)
                          },
                          goToTheChat: _vm.goToTheChat,
                        },
                      })
                    : _vm._e(),
                  _vm.showEndConversationDialog
                    ? _c("end-conversation", {
                        attrs: { isFullScreen: _vm.isMaximized },
                        on: {
                          dialogClosed: _vm.closeEndConversationDialog,
                          endConversation: _vm.close,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isWaiting,
                          expression: "!isWaiting",
                        },
                      ],
                      staticClass: "pathadvice__visitor-chat__container",
                      class: {
                        "pathadvice__visitor-chat__container_full-screen":
                          _vm.isMaximized,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isMaximized,
                              expression: "!isMaximized",
                            },
                          ],
                          staticClass: "pathadvice__visitor-chat__header",
                          class: {
                            "pathadvice__visitor-chat__header_hidden-content":
                              _vm.isMobile && _vm.isCobrowse,
                          },
                          style: {
                            background: _vm.toolBarBackground,
                          },
                        },
                        [
                          !(_vm.isMobile && _vm.isCobrowse)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "pathadvice__visitor-chat__header__container",
                                },
                                [
                                  !(
                                    _vm.isCobrowse &&
                                    (_vm.micEnabled ||
                                      _vm.videoEnabled ||
                                      _vm.agentVideoOn ||
                                      _vm.agentAudioOn)
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pathadvice__visitor-chat__header__agent__info",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pathadvice__visitor-chat__header__agent__avatar",
                                            },
                                            [
                                              _vm.showAgentPhoto
                                                ? _c("img", {
                                                    staticClass:
                                                      "pathadvice__visitor-chat__header__agent__avatar__img",
                                                    attrs: {
                                                      src: _vm.agentPhoto,
                                                    },
                                                  })
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pathadvice__visitor-chat__header__agent__avatar__placeholder",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "generateAgentAcronym"
                                                            )(
                                                              _vm.agentInfo
                                                                .connectedAgent
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                      _c("div", {
                                                        staticClass:
                                                          "pathadvice__visitor-chat__header__agent__avatar__placeholder--online",
                                                      }),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pathadvice__visitor-chat__header__agent__info__container",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pathadvice__visitor-chat__header__agent__info__company",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.agentInfo
                                                          .dialogCompanyName
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pathadvice__visitor-chat__header__agent__info__name",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.agentInfo
                                                          .connectedAgent
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pathadvice__visitor-chat__header__actions",
                                    },
                                    [
                                      !_vm.isMobile && !_vm.isCobrowse
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pathadvice__visitor-chat__header__actions__btn__container",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "pathadvice__visitor-chat__header__actions__btn pathadvice__visitor-chat__header__actions__btn__maximize",
                                                  on: {
                                                    click: _vm.toggleMaximize,
                                                  },
                                                },
                                                [
                                                  _vm.isMaximized
                                                    ? _c("minimize-icon", {
                                                        attrs: {
                                                          width: 16,
                                                          height: 16,
                                                          color: "#12598D",
                                                        },
                                                      })
                                                    : _c("maximize-icon", {
                                                        attrs: {
                                                          width: 16,
                                                          height: 16,
                                                          color: "#12598D",
                                                        },
                                                      }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      (_vm.micEnabled || _vm.videoEnabled) &&
                                      ((_vm.isMobile && !_vm.isCobrowse) ||
                                        (!_vm.isMobile && _vm.isCobrowse) ||
                                        (!_vm.isMobile && !_vm.isCobrowse))
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pathadvice__visitor-chat__header__actions__btn__container",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "pathadvice__visitor-chat__header__actions__btn pathadvice__visitor-chat__header__actions__btn__settings",
                                                  on: {
                                                    click: _vm.toggleSettings,
                                                  },
                                                },
                                                [
                                                  _c("settings-icon", {
                                                    attrs: {
                                                      width: 16,
                                                      height: 16,
                                                      color: "#12598D",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.isMobile && _vm.isCobrowse
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pathadvice__visitor-chat__header__actions__btn__container",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mr-0 pathadvice__visitor-chat__header__communication-controls__btn--end-call pathadvice__visitor-chat__header__communication-controls__end-call",
                                                  on: {
                                                    click: _vm.endConversation,
                                                  },
                                                },
                                                [
                                                  _c("phone-call-icon", {
                                                    attrs: {
                                                      width: 18,
                                                      height: 16,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pathadvice__visitor-chat__header__communication-controls",
                              class: {
                                "pathadvice__visitor-chat__header__communication-controls_cobrowse-mobile":
                                  _vm.isMobile && _vm.isCobrowse,
                                "pathadvice__visitor-chat__header__communication-controls_cobrowse-desktop":
                                  !_vm.isMobile && _vm.isCobrowse,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pathadvice__visitor-chat__header__communication-controls__main",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pathadvice__visitor-chat__header__communication-controls__btn pathadvice__visitor-chat__header__communication-controls__chat",
                                      class:
                                        (!_vm.isChat && _vm.isCobrowse) ||
                                        (_vm.isHiddenChat &&
                                          _vm.isCobrowse &&
                                          _vm.isMobile)
                                          ? _vm.unreadChatMessages.length
                                            ? "pathadvice__visitor-chat__header__communication-controls__btn--unread"
                                            : ""
                                          : "pathadvice__visitor-chat__header__communication-controls__btn--active",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleChat.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "chat-icon",
                                        {
                                          attrs: {
                                            width: 16,
                                            height: 16,
                                            color:
                                              ((!_vm.isChat &&
                                                _vm.isCobrowse) ||
                                                (_vm.isHiddenChat &&
                                                  _vm.isCobrowse &&
                                                  _vm.isMobile)) &&
                                              !_vm.unreadChatMessages.length
                                                ? "#12598D"
                                                : "#ffffff",
                                          },
                                        },
                                        [
                                          ((!_vm.isChat && _vm.isCobrowse) ||
                                            (_vm.isHiddenChat &&
                                              _vm.isCobrowse &&
                                              _vm.isMobile)) &&
                                          _vm.unreadChatMessages.length
                                            ? _c("div", {
                                                staticClass:
                                                  "pathadvice__visitor-chat__header__communication-controls__btn__chat-unread-count",
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pathadvice__visitor-chat__header__communication-controls__btn pathadvice__visitor-chat__header__communication-controls__audio",
                                      class: _vm.getClassForAudioCallBtn,
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleAudioCall.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm.micEnabled
                                        ? _c("mic-icon", {
                                            attrs: {
                                              width: 16,
                                              height: 16,
                                              color: _vm.micEnabled
                                                ? "#fff "
                                                : "#12598D",
                                            },
                                          })
                                        : _c("mic-mute-icon", {
                                            attrs: {
                                              width: 16,
                                              height: 16,
                                              color: _vm.isAudioDisabled
                                                ? "#fff"
                                                : "#12598d",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pathadvice__visitor-chat__header__communication-controls__btn pathadvice__visitor-chat__header__communication-controls__video",
                                      class: _vm.getClassForVideoCallBtn,
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleVideoCall.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm.videoEnabled
                                        ? _c("video-call-icon", {
                                            attrs: {
                                              width: 16,
                                              height: 16,
                                              color: "#fff",
                                            },
                                          })
                                        : _c("video-call-off-icon", {
                                            attrs: {
                                              width: 16,
                                              height: 16,
                                              color: _vm.isVideoDisabled
                                                ? "#fff"
                                                : "#12598d",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pathadvice__visitor-chat__header__communication-controls__btn--end-call pathadvice__visitor-chat__header__communication-controls__end-call",
                                  on: { click: _vm.endConversation },
                                },
                                [
                                  _c("phone-call-icon", {
                                    attrs: { width: 18, height: 16 },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.wasAudioVideoCallActivated &&
                          !_vm.agentVideoOn &&
                          ((_vm.isMobile && !_vm.isCobrowse) ||
                            (!_vm.isMobile && _vm.isCobrowse) ||
                            (!_vm.isMobile && !_vm.isCobrowse))
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "pathadvice__visitor-chat__header__audio-call-body",
                                  class: {
                                    "pathadvice__visitor-chat__header__audio-call-body_audio-only":
                                      !_vm.videoEnabled,
                                    "pathadvice__visitor-chat__header__audio-call-body_desktop":
                                      !_vm.isMobile,
                                    "pathadvice__visitor-chat__header__audio-call-body_desktop-co-browse":
                                      _vm.isCobrowse && !_vm.isMobile,
                                    "pathadvice__visitor-chat__header__audio-call-body_desktop-co-browse-audio-only":
                                      _vm.isCobrowse &&
                                      !_vm.isMobile &&
                                      (_vm.micEnabled ||
                                        !_vm.videoEnabled ||
                                        _vm.agentVideoOn ||
                                        _vm.agentAudioOn ||
                                        !_vm.isChat),
                                  },
                                },
                                [
                                  !_vm.agentAudioOn
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pathadvice__visitor-chat__header__audio-call-body__muted-icon-wrapper",
                                          class: {
                                            "pathadvice__visitor-chat__header__audio-call-body__muted-icon-wrapper_mobile":
                                              _vm.isMobile,
                                          },
                                        },
                                        [
                                          _c("AudioCrossedIcon", {
                                            staticClass:
                                              "pathadvice__visitor-chat__header__audio-call-body__muted-icon",
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("audio-icon", {
                                    staticClass:
                                      "pathadvice__visitor-chat__header__audio-call-body__audio-icon",
                                    attrs: {
                                      width:
                                        !_vm.videoEnabled && !_vm.isMobile
                                          ? 200
                                          : 250,
                                      height:
                                        !_vm.videoEnabled && !_vm.isMobile
                                          ? 62
                                          : 150,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    (_vm.agentVideoOn || _vm.videoEnabled) &&
                                    !(_vm.isMobile && _vm.isCobrowse),
                                  expression:
                                    "(agentVideoOn || videoEnabled) && !(isMobile && isCobrowse)",
                                },
                              ],
                              staticClass:
                                "pathadvice__visitor-chat__header__video-call-body",
                              class: {
                                "pathadvice__visitor-chat__header__video-call-body_showed-remote-video":
                                  _vm.agentVideoOn,
                                "pathadvice__visitor-chat__header__video-call-body_showed-remote-video_mobile":
                                  _vm.agentVideoOn && _vm.isMobile,
                                "pathadvice__visitor-chat__header__video-call-body_showed-remote-video_desktop":
                                  _vm.agentVideoOn && !_vm.isMobile,
                                "pathadvice__visitor-chat__header__video-call-body_showed-remote-video_desktop_cobrowse":
                                  _vm.agentVideoOn &&
                                  !_vm.isMobile &&
                                  _vm.isCobrowse,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.agentVideoOn,
                                      expression: "agentVideoOn",
                                    },
                                  ],
                                  staticClass:
                                    "pathadvice__visitor-chat__header__video-call-body__remote-video",
                                },
                                [
                                  _c("video", {
                                    ref: "remoteVideo",
                                    staticClass:
                                      "pathadvice__visitor-chat__header__video-call-body__remote-video__output",
                                    attrs: {
                                      playsinline: "",
                                      id: "remote-video",
                                      autoplay: "",
                                    },
                                  }),
                                  _vm.agentVideoOn && !_vm.agentAudioOn
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "muted-user-icon-wrapper",
                                        },
                                        [
                                          _c("mic-mute-icon", {
                                            staticClass: "muted-user-icon",
                                            attrs: {
                                              color: "#ffffff",
                                              width: 18,
                                              height: 18,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.videoEnabled ||
                                        _vm.agentVideoOn ||
                                        _vm.micEnabled,
                                      expression:
                                        "videoEnabled || agentVideoOn || micEnabled",
                                    },
                                  ],
                                  staticClass:
                                    "pathadvice__visitor-chat__header__video-call-body__user-video",
                                  class: {
                                    "pathadvice__visitor-chat__header__video-call-body__user-video_muted":
                                      !_vm.videoEnabled,
                                  },
                                },
                                [
                                  !_vm.videoEnabled && !_vm.micEnabled
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pathadvice__visitor-chat__header__video-call-body__user-video__muted-icon-wrapper",
                                        },
                                        [
                                          _c("AudioCrossedIcon", {
                                            staticClass:
                                              "pathadvice__visitor-chat__header__video-call-body__user-video__muted-icon",
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.videoEnabled
                                    ? _c("AudioMobileIcon", {
                                        staticClass:
                                          "pathadvice__visitor-chat__header__video-call-body__user-video__audio-icon",
                                        attrs: { width: 95, height: 37 },
                                      })
                                    : _vm._e(),
                                  _c("video", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.videoEnabled,
                                        expression: "videoEnabled",
                                      },
                                    ],
                                    ref: "localVideo",
                                    staticClass:
                                      "pathadvice__visitor-chat__header__video-call-body__user-video__output",
                                    attrs: {
                                      autoplay: "",
                                      playsinline: "",
                                      id: "local-video",
                                      muted: "muted",
                                    },
                                    domProps: { muted: true },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm.isMobile && _vm.isCobrowse && _vm.isHiddenChat
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "pathadvice__visitor-chat__body__btn-toggle-chat pathadvice__visitor-chat__body__btn-toggle-chat_filled",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.isHiddenChat = false
                                },
                              },
                            },
                            [
                              _c("ArrowIcon", {
                                attrs: {
                                  color: "rgb(18, 89, 141)",
                                  direction: "top",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.isHiddenChat = false
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.agentVideoOn &&
                                _vm.isCobrowse &&
                                _vm.isMobile,
                              expression:
                                "agentVideoOn && isCobrowse && isMobile",
                            },
                          ],
                          staticClass:
                            "pathadvice__visitor-chat__body__user-video",
                        },
                        [
                          _c("video", {
                            ref: "remoteVideoCobrowse",
                            staticClass:
                              "pathadvice__visitor-chat__body__user-video__output",
                            attrs: {
                              autoplay: "",
                              playsinline: "",
                              id: "remote-video-cobrowse",
                              muted: "muted",
                            },
                            domProps: { muted: true },
                          }),
                        ]
                      ),
                      !_vm.isWaiting
                        ? [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !(
                                      !_vm.isChat &&
                                      _vm.isCobrowse &&
                                      !_vm.isMobile
                                    ),
                                    expression:
                                      "!(!isChat && isCobrowse && !isMobile)",
                                  },
                                ],
                                staticClass: "pathadvice__visitor-chat__inner",
                                class: {
                                  "pathadvice__visitor-chat__inner_above":
                                    _vm.isMobile && _vm.isCobrowse,
                                  "pathadvice__visitor-chat__inner_hidden":
                                    _vm.isMobile &&
                                    _vm.isCobrowse &&
                                    _vm.isHiddenChat,
                                  "pathadvice__visitor-chat__inner_rounded":
                                    _vm.isChat &&
                                    _vm.isCobrowse &&
                                    !_vm.isMobile,
                                },
                                style: {
                                  "--toolbar-background": _vm.toolBarBackground,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pathadvice__visitor-chat__body-wrapper",
                                    class: {
                                      "pathadvice__visitor-chat__body-wrapper_mobile-co-browsing":
                                        _vm.isMobile && _vm.isCobrowse,
                                      "pathadvice__visitor-chat__body-wrapper_hidden":
                                        _vm.isMobile &&
                                        _vm.isCobrowse &&
                                        _vm.isHiddenChat,
                                    },
                                  },
                                  [
                                    _vm.isMobile && _vm.isCobrowse
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "pathadvice__visitor-chat__body__btn-toggle-chat",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                _vm.isHiddenChat = true
                                              },
                                            },
                                          },
                                          [
                                            _c("ArrowIcon", {
                                              attrs: {
                                                color: "rgb(18, 89, 141)",
                                                direction: "bottom",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.isHiddenChat = true
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        ref: "chatBody",
                                        staticClass:
                                          "pathadvice__visitor-chat__body",
                                        class: {
                                          "pathadvice__visitor-chat__body--collapsed":
                                            !_vm.isMaximized &&
                                            (_vm.micEnabled ||
                                              _vm.agentVideoOn),
                                        },
                                        style: _vm.isMaximized
                                          ? ""
                                          : "padding-top: 20px;",
                                      },
                                      [
                                        _c(
                                          "scroll-view",
                                          {
                                            ref: "chatScrollArea",
                                            style:
                                              _vm.isCobrowse && !_vm.isMobile
                                                ? _vm.chatHeightStyle
                                                : "",
                                          },
                                          [
                                            _vm._l(
                                              _vm.modifiedMessages,
                                              function (item) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.chatTime,
                                                    staticClass:
                                                      "pathadvice__visitor-chat__body__message",
                                                    class:
                                                      item.sender === "agent"
                                                        ? "pathadvice__visitor-chat__body__message--received"
                                                        : "pathadvice__visitor-chat__body__message--sent " +
                                                          (item.type ===
                                                            "lead-form" &&
                                                          !item.leadForm
                                                            ? "pathadvice__visitor-chat__body__message--widget"
                                                            : ""),
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pathadvice__visitor-chat__body__message-body",
                                                      },
                                                      [
                                                        _vm.dialog &&
                                                        _vm.visitor &&
                                                        !item.leadForm &&
                                                        item.type ===
                                                          "lead-form" &&
                                                        _vm.visitor
                                                          .activeLeadFormId ===
                                                          item.id
                                                          ? _c("LeadForm", {
                                                              attrs: {
                                                                contactForm:
                                                                  _vm.dialog
                                                                    .contactForm,
                                                                leadForm:
                                                                  _vm.dialog
                                                                    .leadForm,
                                                                isInChat: true,
                                                                modifiedMessages:
                                                                  _vm.modifiedMessages,
                                                                activeLeadFormId:
                                                                  _vm.visitor
                                                                    .activeLeadFormId,
                                                              },
                                                            })
                                                          : [
                                                              _vm.loadingIds.find(
                                                                function (x) {
                                                                  return (
                                                                    x.loaderId ===
                                                                    item.chatTime
                                                                  )
                                                                }
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pathadvice__visitor-chat__body__message-body-overlay-inner",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "pathadvice__visitor-chat__body__message-body-overlay-content",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "pathadvice__visitor-chat__body__message-body-spinner",
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pathadvice__visitor-chat__body__message-body__header",
                                                                },
                                                                [
                                                                  item.sender ===
                                                                  "agent"
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "pathadvice__visitor-chat__body__message-body__agent-name",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "msg-agent-name",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .agentInfo
                                                                                    .connectedAgent
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pathadvice__visitor-chat__body__message-body__time",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "msg-time",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.chatTime(
                                                                                item.time
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pathadvice__visitor-chat__body__message-body__msg",
                                                                },
                                                                [
                                                                  _c("pre", {
                                                                    staticClass:
                                                                      "msg-text",
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          _vm.sanitizeHtml(
                                                                            item.message
                                                                          )
                                                                        ),
                                                                    },
                                                                  }),
                                                                  item.leadForm &&
                                                                  item.type ===
                                                                    "lead-form"
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "msg-text",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                "Name: " +
                                                                                  _vm._s(
                                                                                    item.leadForm &&
                                                                                      item
                                                                                        .leadForm
                                                                                        .name
                                                                                      ? item
                                                                                          .leadForm
                                                                                          .name
                                                                                      : ""
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                "Email: " +
                                                                                  _vm._s(
                                                                                    item.leadForm &&
                                                                                      item
                                                                                        .leadForm
                                                                                        .email
                                                                                      ? item
                                                                                          .leadForm
                                                                                          .email
                                                                                      : ""
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          item
                                                                            .leadForm
                                                                            .phone
                                                                            ? _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Phone: " +
                                                                                      _vm._s(
                                                                                        item
                                                                                          .leadForm
                                                                                          .phone
                                                                                      )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item.files &&
                                                                  item.files
                                                                    .length
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "message-file__wrapper flex flex-row flex-wrap -mb-2 justify-end",
                                                                            },
                                                                            [
                                                                              _vm._l(
                                                                                item.files,
                                                                                function (
                                                                                  file,
                                                                                  index
                                                                                ) {
                                                                                  return [
                                                                                    _c(
                                                                                      "message-file",
                                                                                      {
                                                                                        key: index,
                                                                                        staticClass:
                                                                                          "my-2",
                                                                                        attrs:
                                                                                          {
                                                                                            id: item.chatTime,
                                                                                            isWhite: true,
                                                                                            hasDeleteIcon: false,
                                                                                            hasDownload: true,
                                                                                            file: file,
                                                                                          },
                                                                                        on: {
                                                                                          "download-file":
                                                                                            _vm.downloadFile,
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                }
                                                                              ),
                                                                            ],
                                                                            2
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "chat-message-transfer-date flex flex-row justify-end text-xs w-full",
                                                                              class:
                                                                                {
                                                                                  _expired:
                                                                                    _vm.isFilesExpired(
                                                                                      item.files
                                                                                    ),
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm.getChatFilesTransferMessage(
                                                                                      item.files
                                                                                    )
                                                                                  ) +
                                                                                  "\n                              "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item.feedbackQuizAnswers
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "flex flex-row flex-wrap -mb-2 -mr-4 justify-end w-full",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "pathadvice__finish-communication__form",
                                                                                },
                                                                                [
                                                                                  item
                                                                                    .feedbackQuizAnswers
                                                                                    .grade1 !==
                                                                                  ""
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "pathadvice__finish-communication__field",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-bottom":
                                                                                                "10px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pathadvice__finish-communication__field-title",
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "margin-bottom":
                                                                                                    "5px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                      " +
                                                                                                  _vm._s(
                                                                                                    _vm
                                                                                                      .dialog
                                                                                                      .feedbackFormQuestionFirst
                                                                                                  ) +
                                                                                                  "\n                                    "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "rating-stars",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  grade:
                                                                                                    item
                                                                                                      .feedbackQuizAnswers
                                                                                                      .grade1,
                                                                                                  maxStars: 5,
                                                                                                  disabled: true,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  item
                                                                                    .feedbackQuizAnswers
                                                                                    .grade2 !==
                                                                                  ""
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "pathadvice__finish-communication__field",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-bottom":
                                                                                                "10px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pathadvice__finish-communication__field-title",
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "margin-bottom":
                                                                                                    "5px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                      " +
                                                                                                  _vm._s(
                                                                                                    _vm
                                                                                                      .dialog
                                                                                                      .feedbackFormQuestionSecond
                                                                                                  ) +
                                                                                                  "\n                                    "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "rating-stars",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  grade:
                                                                                                    item
                                                                                                      .feedbackQuizAnswers
                                                                                                      .grade2,
                                                                                                  maxStars: 5,
                                                                                                  disabled: true,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  item
                                                                                    .feedbackQuizAnswers
                                                                                    .grade3 !==
                                                                                  ""
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "pathadvice__finish-communication__field",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-bottom":
                                                                                                "10px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pathadvice__finish-communication__field-title",
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "margin-bottom":
                                                                                                    "5px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                      " +
                                                                                                  _vm._s(
                                                                                                    _vm
                                                                                                      .dialog
                                                                                                      .feedbackFormQuestionThird
                                                                                                  ) +
                                                                                                  "\n                                    "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "rating-stars",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  grade:
                                                                                                    item
                                                                                                      .feedbackQuizAnswers
                                                                                                      .grade3,
                                                                                                  maxStars: 5,
                                                                                                  disabled: true,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item.contactFormData
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "flex flex-row flex-wrap -mb-2 -mr-4 justify-end w-full",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex",
                                                                                  staticStyle:
                                                                                    {
                                                                                      "flex-direction":
                                                                                        "column",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Contact Form Data:"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                    Type: "
                                                                                      ),
                                                                                      _c(
                                                                                        "br"
                                                                                      ),
                                                                                      _vm._v(
                                                                                        "\n                                    " +
                                                                                          _vm._s(
                                                                                            item
                                                                                              .contactFormData
                                                                                              .type ===
                                                                                              "contact-form-info-saved"
                                                                                              ? "Message"
                                                                                              : "Booking"
                                                                                          ) +
                                                                                          "\n                                  "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                    Visitor's name: "
                                                                                      ),
                                                                                      _c(
                                                                                        "br"
                                                                                      ),
                                                                                      _vm._v(
                                                                                        "\n                                    " +
                                                                                          _vm._s(
                                                                                            item
                                                                                              .contactFormData
                                                                                              .data
                                                                                              .name
                                                                                          ) +
                                                                                          "\n                                  "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  item
                                                                                    .contactFormData
                                                                                    .data
                                                                                    .email
                                                                                    ? _c(
                                                                                        "div",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                    Visitor's email: "
                                                                                          ),
                                                                                          _c(
                                                                                            "br"
                                                                                          ),
                                                                                          _vm._v(
                                                                                            "\n                                    " +
                                                                                              _vm._s(
                                                                                                item
                                                                                                  .contactFormData
                                                                                                  .data
                                                                                                  .email
                                                                                              ) +
                                                                                              "\n                                  "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  item
                                                                                    .contactFormData
                                                                                    .data
                                                                                    .phone
                                                                                    ? _c(
                                                                                        "div",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                    Visitor's phone: "
                                                                                          ),
                                                                                          _c(
                                                                                            "br"
                                                                                          ),
                                                                                          _vm._v(
                                                                                            "\n                                    " +
                                                                                              _vm._s(
                                                                                                item
                                                                                                  .contactFormData
                                                                                                  .data
                                                                                                  .phone
                                                                                              ) +
                                                                                              "\n                                  "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  item
                                                                                    .contactFormData
                                                                                    .data
                                                                                    .message
                                                                                    ? _c(
                                                                                        "div",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                    Visitor's message: "
                                                                                          ),
                                                                                          _c(
                                                                                            "br"
                                                                                          ),
                                                                                          _vm._v(
                                                                                            "\n                                    " +
                                                                                              _vm._s(
                                                                                                item
                                                                                                  .contactFormData
                                                                                                  .data
                                                                                                  .message
                                                                                              ) +
                                                                                              "\n                                  "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ],
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.agentIsTyping,
                                                    expression: "agentIsTyping",
                                                  },
                                                ],
                                                staticClass:
                                                  "chat-message-typing",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "chat-message-typing__wording",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tp(
                                                          "buttonAndHints.agentIsTyping"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "chat-message-typing-indicator",
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "chat-message-typing-indicator__animated-dots",
                                                      staticStyle: {
                                                        "background-color":
                                                          "rgba(18, 89, 141, 0.6)",
                                                      },
                                                    }),
                                                    _c("span", {
                                                      staticClass:
                                                        "chat-message-typing-indicator__animated-dots",
                                                      staticStyle: {
                                                        "background-color":
                                                          "rgba(18, 89, 141, 0.8)",
                                                      },
                                                    }),
                                                    _c("span", {
                                                      staticClass:
                                                        "chat-message-typing-indicator__animated-dots",
                                                      staticStyle: {
                                                        "background-color":
                                                          "rgba(18, 89, 141, 1)",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pathadvice__visitor-chat__footer",
                                    class: {
                                      "pathadvice__visitor-chat__footer_full-screen":
                                        _vm.isMaximized,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pathadvice__visitor-chat__footer__actions",
                                        style: {
                                          "--toolbar-background":
                                            _vm.toolBarBackground,
                                        },
                                      },
                                      [
                                        _c("toolbar", {
                                          on: {
                                            "upload-files": _vm.onUploadFiles,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        ref: "sendChatMessageContainer",
                                        staticClass:
                                          "pathadvice__visitor-chat__footer__send-msg send-chat-message-container",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pathadvice__visitor-chat__footer__send-msg__icon",
                                            on: { click: _vm.sendMessage },
                                          },
                                          [
                                            _vm.isMaximized
                                              ? _c("send-icon", {
                                                  attrs: {
                                                    color: _vm.canSendMessage
                                                      ? "#12598D"
                                                      : "#cccccc",
                                                    width: 25,
                                                    height: 25,
                                                  },
                                                })
                                              : _c("send-icon", {
                                                  attrs: {
                                                    color: _vm.canSendMessage
                                                      ? "#12598D"
                                                      : "#cccccc",
                                                    width: 25,
                                                    height: 25,
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pathadvice__visitor-chat__footer__send-msg__area",
                                          },
                                          [
                                            _vm.hasFilesizeError
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pathadvice__visitor-chat__footer__send-msg__area__error",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.$tp(
                                                            "buttonAndHints.errorsChatFileUploadLimit"
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.isDropzoneVisible
                                              ? _c("message-dropzone", {
                                                  ref: "filesDropzone",
                                                  on: {
                                                    "files-added":
                                                      _vm.onFilesAdded,
                                                    "on-reset":
                                                      _vm.hideDropzone,
                                                    "vd-drag-enter": function (
                                                      $event
                                                    ) {
                                                      _vm.isDropzoneVisible = true
                                                    },
                                                    "vd-drag-over": function (
                                                      $event
                                                    ) {
                                                      _vm.isDropzoneVisible = true
                                                    },
                                                    "vd-drag-drop": function (
                                                      $event
                                                    ) {
                                                      _vm.isDropzoneVisible = true
                                                    },
                                                  },
                                                })
                                              : _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.msg,
                                                      expression: "msg",
                                                    },
                                                  ],
                                                  ref: "message",
                                                  staticClass:
                                                    "pathadvice__visitor-chat__footer__send-msg__area__input",
                                                  attrs: {
                                                    placeholder: _vm.$tp(
                                                      "buttonAndHints.writeMessage"
                                                    ),
                                                  },
                                                  domProps: { value: _vm.msg },
                                                  on: {
                                                    keyup: [
                                                      function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        if (
                                                          $event.ctrlKey ||
                                                          $event.shiftKey ||
                                                          $event.altKey ||
                                                          $event.metaKey
                                                        ) {
                                                          return null
                                                        }
                                                        $event.preventDefault()
                                                        return _vm.sendMessage.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                      _vm.onVisitorTyping,
                                                    ],
                                                    click: function ($event) {
                                                      return _vm.setFocusToField(
                                                        "message"
                                                      )
                                                    },
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.msg =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                            _vm.files && _vm.files.length
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pathadvice__visitor-chat__footer__send-msg__area__files flex flex-wrap",
                                                  },
                                                  _vm._l(
                                                    _vm.files,
                                                    function (file, index) {
                                                      return _c(
                                                        "message-file",
                                                        {
                                                          key: index,
                                                          staticClass: "mb-4",
                                                          attrs: {
                                                            file: file,
                                                            index: index,
                                                          },
                                                          on: {
                                                            "delete-file":
                                                              _vm.onDeleteFile,
                                                          },
                                                        }
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _c("powered-by", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !(_vm.isCobrowse && !_vm.isMobile),
                            expression: "!(isCobrowse && !isMobile)",
                          },
                        ],
                      }),
                      _vm.isCobrowse && _vm.isMobile
                        ? _c("CoBrowsingIframe")
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm.isCobrowse && !_vm.isMobile ? _c("CoBrowsingIframe") : _vm._e(),
      _vm.isAgentScreenSharing
        ? _c("ScreenSharing", {
            attrs: { "remote-video-key": _vm.remoteVideoKey },
            on: {
              "toggle-audio-call": _vm.toggleAudioCall,
              "toggle-video-call": _vm.toggleVideoCall,
              "end-conversation": _vm.close,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }