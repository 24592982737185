var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ss-main",
      style: {
        "--toolbar-background": _vm.toolBarBackground,
      },
    },
    [
      _c(
        "div",
        { staticClass: "ss-main-toolbar" },
        [
          _c(
            "div",
            {
              staticClass: "ss-main-toolbar-action-button pa-bk-danger",
              on: { click: _vm.onScreenSharingClicked },
            },
            [
              _c("share-icon", {
                staticClass: "toolbar-container-icon",
                attrs: { width: 25.41, height: 18 },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "ss-main-toolbar-communication-controls" }, [
            _c("div", { staticClass: "ss-main-toolbar-screenshare" }),
            _c("div", { staticStyle: { position: "relative" } }, [
              _c(
                "div",
                {
                  staticClass: "ss-main-toolbar-communication-controls-button",
                  class: {
                    "pa-bk-active": _vm.isChat,
                  },
                  on: { click: _vm.showHideChat },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { position: "relative" } },
                    [
                      _c("chat-icon", {
                        class: { "pa-icon-default": !_vm.isChat },
                        attrs: { width: 16, height: 16 },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              !_vm.isChat && _vm.visitor.agentMessages
                ? _c("div", { staticClass: "unread-messages" }, [
                    _vm._v(_vm._s(_vm.visitor.agentMessages)),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass: "ss-main-toolbar-communication-controls-button",
                class: { "pa-bk-active": _vm.micEnabled },
                style: {
                  background: _vm.disabledAudioColor,
                  cursor: _vm.isAudioDisabled ? "default" : "pointer",
                },
                on: { click: _vm.onToggleAudioCall },
              },
              [
                _vm.micEnabled
                  ? _c("mic-icon", {
                      attrs: {
                        width: 16,
                        height: 16,
                        color: _vm.micEnabled ? "#fff " : "#262629",
                      },
                    })
                  : _c("mic-mute-icon", {
                      attrs: { width: 16, height: 16, color: "#262629" },
                    }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "ss-main-toolbar-communication-controls-button",
                class: { "pa-bk-active": _vm.videoEnabled },
                style: {
                  background: _vm.disabledVideoColor,
                  cursor: _vm.isVideoDisabled ? "default" : "pointer",
                },
                on: { click: _vm.onToggleVideoCall },
              },
              [
                _vm.videoEnabled
                  ? _c("video-call-icon", {
                      attrs: { color: "#fff", width: 16, height: 16 },
                    })
                  : _c("video-call-off-icon", {
                      attrs: { color: "#262629", width: 16, height: 16 },
                    }),
              ],
              1
            ),
          ]),
          _vm.endConversationPrompt
            ? _c("end-conversation", {
                attrs: { isFullScreen: true },
                on: {
                  dialogClosed: function ($event) {
                    _vm.endConversationPrompt = false
                  },
                  endConversation: _vm.endConversation,
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "ss-main-toolbar-action-button pa-bk-danger ml-2",
              on: {
                click: function ($event) {
                  _vm.endConversationPrompt = true
                },
              },
            },
            [
              _c("phone-icon", {
                staticClass: "chat-header-navbar-icon",
                attrs: { width: 18, height: 18 },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }