var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pathadvice pathadvice-popup" }, [
    _c(
      "div",
      { staticClass: "pathadvice__finish-communication" },
      [
        _c(
          "div",
          {
            staticClass: "pathadvice__finish-communication__main-container",
            style: _vm.containerStyles,
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "pathadvice__finish-communication__main-container__actions",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "pathadvice__finish-communication__main-container__actions__btn",
                    on: { click: _vm.disposeIFrame },
                  },
                  [_c("close-icon", { attrs: { width: 25, height: 25 } })],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "pathadvice__finish-communication__main-container__header",
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _c(
              "div",
              {
                staticClass:
                  "pathadvice__finish-communication__main-container__wording",
              },
              [_c("span", [_vm._v(_vm._s(_vm.message))])]
            ),
            _vm.wasAgentConnected &&
            _vm.dialog.isShowFeedbackForm &&
            _vm.HAS_FEEDBACKFORM_ACCESS
              ? _c(
                  "div",
                  {
                    staticClass: "pathadvice__finish-communication__container",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "pathadvice__finish-communication__form" },
                      [
                        _vm.dialog.feedbackFormQuestionFirst
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "pathadvice__finish-communication__field",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pathadvice__finish-communication__field-title",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dialog.feedbackFormQuestionFirst
                                      )
                                    ),
                                  ]
                                ),
                                _c("rating-stars", {
                                  attrs: { grade: _vm.grade1, maxStars: 5 },
                                  on: {
                                    "stars-rating": function (grade) {
                                      return (_vm.grade1 = grade)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dialog.feedbackFormQuestionSecond
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "pathadvice__finish-communication__field",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pathadvice__finish-communication__field-title",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dialog.feedbackFormQuestionSecond
                                      )
                                    ),
                                  ]
                                ),
                                _c("rating-stars", {
                                  attrs: { grade: _vm.grade2, maxStars: 5 },
                                  on: {
                                    "stars-rating": function (grade) {
                                      return (_vm.grade2 = grade)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dialog.feedbackFormQuestionThird
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "pathadvice__finish-communication__field",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pathadvice__finish-communication__field-title",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dialog.feedbackFormQuestionThird
                                      )
                                    ),
                                  ]
                                ),
                                _c("rating-stars", {
                                  attrs: { grade: _vm.grade3, maxStars: 5 },
                                  on: {
                                    "stars-rating": function (grade) {
                                      return (_vm.grade3 = grade)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dialog.isSendFeedbackViaMessage
                          ? _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.feedbackMessage,
                                  expression: "feedbackMessage",
                                },
                              ],
                              staticClass:
                                "pathadvice__finish-communication__message",
                              attrs: {
                                placeholder: _vm.$tp(
                                  "buttonAndHints.writeMessage"
                                ),
                              },
                              domProps: { value: _vm.feedbackMessage },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.disposeIFrame.apply(
                                    null,
                                    arguments
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.feedbackMessage = $event.target.value
                                },
                              },
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pathadvice__finish-communication__action-button flex items-center justify-center",
                            staticStyle: {
                              "margin-top": "10px",
                              "background-color": "#12598d",
                            },
                            on: { click: _vm.disposeIFrame },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tp("buttonAndHints.sendMessage")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pathadvice__finish-communication__cancel-button mt-4 flex items-center justify-center",
                            on: { click: _vm.disposeIFrame },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tp("buttonAndHints.close")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c("powered-by"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }