var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "send-chat-message-file",
      class: {
        _white: _vm.isWhite,
        _expired: _vm.isExpired,
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.downloadFile()
        },
      },
    },
    [
      _c(
        "svg",
        {
          staticClass: "send-chat-message-file-logo",
          attrs: {
            width: "20",
            height: "24",
            viewBox: "0 0 20 24",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M10.7952 1H3.13576C2.55536 1 1.99872 1.23178 1.58832 1.64436C1.17791 2.05695 0.947342 2.61652 0.947342 3.2V20.8C0.947342 21.3835 1.17791 21.9431 1.58832 22.3556C1.99872 22.7682 2.55536 23 3.13576 23H16.2663C16.8467 23 17.4033 22.7682 17.8137 22.3556C18.2241 21.9431 18.4547 21.3835 18.4547 20.8V8.7L10.7952 1Z",
              stroke: "#12598D",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
          _c("path", {
            attrs: {
              d: "M10.7953 1V8.7H18.4548",
              stroke: "#12598D",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
        ]
      ),
      _c("span", [_vm._v(_vm._s(_vm.file.name) + " ")]),
      _vm.hasDeleteIcon && _vm.file && _vm.file.percentage === 100
        ? _c(
            "a",
            {
              staticClass: "send-chat-message-file-close",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.deleteFile()
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/visitor/close.svg") },
              }),
            ]
          )
        : _vm._e(),
      _vm.file && _vm.file.percentage < 100
        ? _c("div", {
            staticClass: "send-chat-message-file-progress-bar",
            style: { width: _vm.file && _vm.file.percentage + "%" },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }