<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :color="color"
    viewBox="0 0 26 12"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-phone"
  >
    <path
      d="M3.46587 10.1857L1.54981 8.26959C1.37121 8.09243 1.23158 7.87993 1.13986 7.64569C1.04814 7.41145 1.00636 7.16064 1.01718 6.90932C1.028 6.65799 1.09119 6.4117 1.20271 6.18621C1.31422 5.96072 1.47161 5.76101 1.66477 5.59987C3.84367 3.84807 6.40311 2.63179 9.13743 2.04876C11.6612 1.48618 14.2779 1.48618 16.8017 2.04876C19.5473 2.63557 22.1161 3.86088 24.2999 5.62542C24.4925 5.78607 24.6495 5.98507 24.7609 6.20974C24.8724 6.4344 24.9359 6.67981 24.9473 6.93035C24.9587 7.18088 24.9178 7.43105 24.8272 7.66492C24.7367 7.89879 24.5984 8.11124 24.4212 8.28875L22.5052 10.2048C22.1983 10.5178 21.788 10.7086 21.3509 10.7415C20.9137 10.7744 20.4796 10.6472 20.1293 10.3836C19.4352 9.85133 18.6832 9.39925 17.8875 9.03601C17.5729 8.89334 17.3059 8.6635 17.118 8.37371C16.9301 8.08392 16.8292 7.74634 16.8272 7.40097L16.8272 5.7787C14.319 5.08892 11.6712 5.08892 9.16297 5.7787L9.16297 7.40097C9.16104 7.74634 9.06014 8.08392 8.87224 8.37371C8.68433 8.6635 8.41729 8.89334 8.10275 9.03601C7.30706 9.39925 6.55501 9.85133 5.86095 10.3836C5.50693 10.6501 5.06732 10.7772 4.62571 10.7407C4.18411 10.7042 3.77133 10.5067 3.46587 10.1857Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'PhoneIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    width: {
      type: Number,
      default: 35
    },
    height: {
      type: Number,
      default: 35
    }
  }
}
</script>
