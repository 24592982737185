var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.IS_AGENT_SCREEN_SHARING,
          expression: "IS_AGENT_SCREEN_SHARING",
        },
      ],
      staticClass: "screensharing flex flex-col",
    },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "loader-container" }, [
            _c("div", { staticClass: "loader" }),
          ])
        : _vm._e(),
      _c("video", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading && _vm.IS_AGENT_SCREEN_SHARING,
            expression: "!isLoading && IS_AGENT_SCREEN_SHARING",
          },
        ],
        ref: "screenShareDisplay",
        staticClass: "screensharing_video",
        class: {
          screensharing_mobile: _vm.isMobile,
          screensharing_bck: _vm.IS_AGENT_SCREEN_SHARING,
        },
        attrs: {
          id: "screenshare-video",
          autoplay: "",
          playsinline: "",
          muted: "muted",
        },
        domProps: { muted: true },
      }),
      _c("ScreenSharingLocalVideo", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.IS_AGENT_SCREEN_SHARING && _vm.videoEnabled,
            expression: "IS_AGENT_SCREEN_SHARING && videoEnabled",
          },
        ],
      }),
      _vm.IS_AGENT_SCREEN_SHARING ? _c("ScreenSharingChat") : _vm._e(),
      _vm.IS_AGENT_SCREEN_SHARING
        ? _c("ScreenSharingToolBar", {
            on: {
              "toggle-audio-call": function ($event) {
                return _vm.$emit("toggle-audio-call")
              },
              "toggle-video-call": function ($event) {
                return _vm.$emit("toggle-video-call")
              },
              "end-conversation": function ($event) {
                return _vm.$emit("end-conversation")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }