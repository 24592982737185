<template>
  <vue-dropzone
    ref="messageDropzone"
    id="customdropzone"
    :options="dropzoneOptions"
    @vdropzone-reset="onReset"
    @vdropzone-files-added="afterFilesAdded"
    @vdropzone-drag-enter="vdDragEnter"
    @vdropzone-drop="vdDragDrop"
    @vdropzone-drag-over="vdDragOver"
  />
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import { mapMutations } from 'vuex'
export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      dropzoneOptions: {
        url: '#',
        autoProcessQueue: false,
        thumbnailHeight: 32,
        addRemoveLinks: false,
        previewsContainer: false,
        dictDefaultMessage: `${this.$tp('buttonAndHints.uploadsPlaceYourFilesHere')}`
      }
    }
  },
  methods: {
    ...mapMutations({
      updateHasFileSizeError: 'UPDATE_HAS_FILE_SIZE_ERROR'
    }),
    afterFilesAdded(uploads) {
      if (uploads.find((x) => x.size / 1024 > 10240)) {
        this.updateHasFileSizeError(true)
      } else {
        this.updateHasFileSizeError(false)
      }

      uploads = uploads.filter((x) => x.size / 1024 < 10241)

      this.$emit('files-added', uploads)
    },
    vdDragDrop(e) {
      this.$emit('vd-drag-drop', e)
    },
    vdDragEnter(e) {
      this.$emit('vd-drag-enter', e)
    },
    vdDragOver(e) {
      this.$emit('vd-drag-over', e)
    },
    onReset(payload) {
      this.$emit('on-reset', payload)
    }
  }
}
</script>

<style lang="scss" scoped>
.vue-dropzone {
  min-height: 65px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1.5px dashed #e6ecf1;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 15px;
  color: #12598d;
  font-weight: 500;
  cursor: pointer;
}
</style>
