<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    :stroke="color"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-volume-x"
  >
    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
    <line x1="23" y1="9" x2="17" y2="15"></line>
    <line x1="17" y1="9" x2="23" y2="15"></line>
  </svg>
</template>
<script>
export default {
  name: 'SpeakerOffIcon',
  props: {
    color: {
      type: String,
      default: '#B2B2B2'
    },
    stroke: {
      type: String,
      default: '#fff'
    },
    width: {
      type: Number,
      default: 41
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>
