var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style:
        "position: relative; display: inline-block; width: " +
        _vm.width +
        "px; height: " +
        _vm.height +
        "px",
    },
    [
      _c(
        "svg",
        {
          staticClass: "feather feather-message-square",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: _vm.width,
            height: _vm.height,
            color: _vm.color,
            viewBox: "0 0 24 24",
            fill: "none",
            stroke: "currentColor",
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z",
            },
          }),
        ]
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }