var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pathadvice__waiting-room",
      style: {
        "--waiting-room-background": _vm.waitingRoomBackground,
      },
    },
    [
      _c(
        "div",
        { staticClass: "pathadvice__waiting-room__inner" },
        [
          _c("div", { staticClass: "pathadvice__waiting-room__header" }, [
            _c(
              "div",
              { staticClass: "pathadvice__waiting-room__header__actions" },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "pathadvice__waiting-room__header__actions__close-btn",
                    on: { click: _vm.close },
                  },
                  [_c("phone-call-icon", { attrs: { width: 25, height: 25 } })],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "pathadvice__waiting-room__body",
              class: {
                "pathadvice__waiting-room__body--with-whitelabel":
                  _vm.hasWhiteLabel,
              },
            },
            [
              _c(
                "scroll-view",
                { ref: "waitingRoomScrollArea" },
                _vm._l(_vm.messages, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.timestamp,
                      staticClass: "pathadvice__waiting-room__body__message",
                      class:
                        item.sender === "agent"
                          ? "pathadvice__waiting-room__body__message--received"
                          : "pathadvice__waiting-room__body__message--sent",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pathadvice__waiting-room__body__message__item",
                          class:
                            item.sender === "agent"
                              ? "pathadvice__waiting-room__body__message__item--received"
                              : "pathadvice__waiting-room__body__message__item--sent",
                          style: _vm.waitingRoomContentChatMessageStyle,
                        },
                        [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(item.message) },
                          }),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c("div", { staticClass: "pathadvice__waiting-room__footer" }, [
            _c(
              "div",
              {
                staticClass: "pathadvice__waiting-room__footer__actions",
                style: {
                  "--toolbar-background": _vm.toolBarBackground,
                },
              },
              [
                _c("div", {
                  staticClass:
                    "pathadvice__waiting-room__footer__actions__btn pathadvice__waiting-room__footer__actions__add-attachment",
                }),
                _c("div", {
                  staticClass:
                    "pathadvice__waiting-room__footer__actions__btn pathadvice__waiting-room__footer__actions__other",
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "pathadvice__waiting-room__footer__send-msg" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "pathadvice__waiting-room__footer__send-msg__icon",
                    on: { click: _vm.sendMessage },
                  },
                  [
                    _c("send-icon", {
                      attrs: {
                        width: 25,
                        height: 25,
                        color: _vm.toolBarBackground,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "pathadvice__waiting-room__footer__send-msg__area",
                  },
                  [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.msg,
                          expression: "msg",
                        },
                      ],
                      staticClass:
                        "pathadvice__waiting-room__footer__send-msg__area__input",
                      attrs: {
                        placeholder: _vm.$tp("buttonAndHints.writeMessage"),
                      },
                      domProps: { value: _vm.msg },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.sendMessage.apply(null, arguments)
                        },
                        keydown: _vm.onVisitorTyping,
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.msg = $event.target.value
                          },
                          _vm.inputField,
                        ],
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]),
          _c("powered-by"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }