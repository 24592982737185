var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "permissions-notice" }, [
    _c("div", { staticClass: "permissions-notice__header" }, [
      _c(
        "div",
        { staticClass: "permissions-notice__header__icon" },
        [_c("info-icon", { attrs: { width: 22, height: 22 } })],
        1
      ),
    ]),
    _c("div", { staticClass: "permissions-notice__body" }, [
      _c("div", { staticClass: "permissions-notice__body__wording-main" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.$tp("buttonAndHints.permissionsNoticeDeniedWordingMain")
            ) +
            "\n    "
        ),
      ]),
      _c("div", { staticClass: "permissions-notice__body__img-placeholder" }, [
        _c(
          "div",
          {
            staticClass: "permissions-notice__body__img-placeholder__container",
          },
          [
            _c("settings-video-cam-off-icon", {
              attrs: { color: "#ffffff", width: 186, height: 186 },
            }),
            _c(
              "div",
              {
                staticClass:
                  "permissions-notice__body__img-placeholder__message",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$tp(
                        "buttonAndHints.permissionsNoticeDeniedPlaceholderWording"
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "permissions-notice__body__wording" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$tp("buttonAndHints.permissionsNoticeDeniedWording1")) +
            "\n    "
        ),
      ]),
      _c("div", { staticClass: "permissions-notice__body__wording" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$tp("buttonAndHints.permissionsNoticeDeniedWording2")) +
            "\n    "
        ),
      ]),
      _c("div", { staticClass: "permissions-notice__body__wording" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$tp("buttonAndHints.permissionsNoticeDeniedWording3")) +
            "\n    "
        ),
      ]),
    ]),
    _c("div", { staticClass: "permissions-notice__footer" }, [
      _c(
        "button",
        {
          staticClass: "permissions-notice__footer__btn",
          on: { click: _vm.clickHandler },
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$tp("buttonAndHints.permissionsNoticeDeniedBtn")) +
              "\n    "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }