<template>
  <div :style="{ height: height }" class="pathadvice__face-to-face" @click="onClick">
    <div class="pathadvice__face-to-face__wrapper">
      <img :src="logoImage" alt="visitor-campaign-logo" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    height: {
      type: String,
      default: '100%'
    },
    logoImage: {
      type: String
    }
  },

  methods: {
    onClick() {
      this.$emit('bannerClicked')
    }
  }
}
</script>

<style lang="scss" scoped>
.pathadvice__face-to-face {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 0 30px 30px 0;
  z-index: 10;
  @media (max-width: 767px) {
    padding: 0 20px 20px 0;
  }

  &__header {
    font-size: 36px;
    line-height: 36px;
    @media (max-width: 767px) {
      font-size: 19px;
      line-height: 19px;
    }
  }

  &__wording {
    font-size: 15px;
    line-height: 13px;
    @media (max-width: 767px) {
      font-size: 11px;
      white-space: nowrap;
      line-height: 7px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    img {
      width: 100%;
      max-width: 140px;
      max-height: 57px;
    }
    @media (max-width: 767px) {
      img {
        max-width: 100px;
        max-height: 40px;
      }
    }
  }
  &__text-holder {
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 20px;
    @media (max-width: 767px) {
      margin-right: 0;
      min-height: unset;
    }
  }
}
</style>
