<template>
  <div :style="`position: relative; width: ${width}px; height: ${height}px`">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      :color="color"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-message-square"
    >
      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
    </svg>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ChatIcon',
  props: {
    width: {
      type: Number,
      default: 21,
      required: false
    },
    height: {
      type: Number,
      default: 21,
      required: false
    },
    color: {
      type: String,
      default: '#fff',
      required: false
    }
  }
}
</script>
