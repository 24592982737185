var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 25 23",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M11.0157 4.82458H14.4786C15.0286 4.82458 15.556 5.02605 15.9449 5.38465C16.3338 5.74326 16.5523 6.22963 16.5523 6.73678V9.93013L17.5891 10.8862L23.81 6.73678V16.2977M16.5523 15.3416V16.2977C16.5523 16.8049 16.3338 17.2912 15.9449 17.6498C15.556 18.0085 15.0286 18.2099 14.4786 18.2099H3.07364C2.52367 18.2099 1.99624 18.0085 1.60735 17.6498C1.21847 17.2912 1 16.8049 1 16.2977V6.73678C1 6.22963 1.21847 5.74326 1.60735 5.38465C1.99624 5.02605 2.52367 4.82458 3.07364 4.82458H5.14727L16.5523 15.3416Z",
          stroke: _vm.color,
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M1 1L23.81 22.0341",
          stroke: _vm.color,
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }