<template>
  <div class="pathadvice pathadvice-popup">
    <div class="pathadvice__finish-communication">
      <div class="pathadvice__finish-communication__main-container" :style="containerStyles">
        <div class="pathadvice__finish-communication__main-container__actions">
          <div class="pathadvice__finish-communication__main-container__actions__btn" @click="disposeIFrame">
            <close-icon :width="25" :height="25" />
          </div>
        </div>

        <div class="pathadvice__finish-communication__main-container__header">{{ title }}</div>
        <div class="pathadvice__finish-communication__main-container__wording">
          <span>{{ message }}</span>
        </div>
        <div class="pathadvice__finish-communication__container" v-if="wasAgentConnected && dialog.isShowFeedbackForm && HAS_FEEDBACKFORM_ACCESS">
          <div class="pathadvice__finish-communication__form">
            <div class="pathadvice__finish-communication__field" v-if="dialog.feedbackFormQuestionFirst">
              <div class="pathadvice__finish-communication__field-title">{{ dialog.feedbackFormQuestionFirst }}</div>
              <rating-stars :grade="grade1" :maxStars="5" @stars-rating="(grade) => (grade1 = grade)" />
            </div>
            <div class="pathadvice__finish-communication__field" v-if="dialog.feedbackFormQuestionSecond">
              <div class="pathadvice__finish-communication__field-title">{{ dialog.feedbackFormQuestionSecond }}</div>
              <rating-stars :grade="grade2" :maxStars="5" @stars-rating="(grade) => (grade2 = grade)" />
            </div>
            <div class="pathadvice__finish-communication__field" v-if="dialog.feedbackFormQuestionThird">
              <div class="pathadvice__finish-communication__field-title">{{ dialog.feedbackFormQuestionThird }}</div>
              <rating-stars :grade="grade3" :maxStars="5" @stars-rating="(grade) => (grade3 = grade)" />
            </div>
            <textarea
              class="pathadvice__finish-communication__message"
              v-if="dialog.isSendFeedbackViaMessage"
              :placeholder="$tp('buttonAndHints.writeMessage')"
              v-model="feedbackMessage"
              v-on:keyup.enter="disposeIFrame"
            />
            <div
              style="margin-top: 10px; background-color: #12598d"
              class="pathadvice__finish-communication__action-button flex items-center justify-center"
              @click="disposeIFrame"
            >
              {{ $tp('buttonAndHints.sendMessage') }}
            </div>
            <div class="pathadvice__finish-communication__cancel-button mt-4 flex items-center justify-center" @click="disposeIFrame">
              {{ $tp('buttonAndHints.close') }}
            </div>
          </div>
        </div>
      </div>

      <powered-by />
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

import { mapGetters } from 'vuex'
import PoweredBy from '@/components/dialog/PoweredBy.vue'
import CloseIcon from '@/components/icons/CloseIcon'
import RatingStars from '@/components/rating-stars/RatingStars.vue'

export default {
  name: 'FinishCommunication',
  components: {
    CloseIcon,
    PoweredBy,
    RatingStars
  },
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    fontColor: {
      type: String,
      color: 'rgb(255, 255, 255)'
    },
    backgroundColor: {
      type: String,
      default: 'rgb(18, 89, 141)'
    },
    wasAgentConnected: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      feedbackMessage: '',
      grade1: '',
      grade2: '',
      grade3: ''
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'campaign/dialog',
      company: 'campaign/company'
    }),

    HAS_FEEDBACKFORM_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('contact-form') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    containerStyles() {
      return {
        color: this.fontColor,
        background: this.backgroundColor
      }
    }
  },
  methods: {
    disposeIFrame() {
      const isShowFeedbackForm = this.dialog && this.dialog.isShowFeedbackForm
      if (isShowFeedbackForm) {
        const feedbackQuizAnswers = {
          grade1: this.grade1 || '',
          grade2: this.grade2 || '',
          grade3: this.grade3 || ''
        }
        this.$emit('close-finish-communication', {
          text: this.feedbackMessage,
          feedbackQuizAnswers
        })
        this.feedbackMessage = ''
      } else {
        this.$emit('close-finish-communication')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pathadvice {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 2px 5px #e6ecf1;

  &-popup {
    bottom: 0;
    right: 0;
    font-family: 'Lato', sans-serif;
  }

  &__finish-communication {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    hyphens: auto;

    &__main-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      padding: 30px 20px;
      flex-grow: 1;
      border-radius: 6px 6px 0 0;
      align-items: center;

      &__actions {
        position: absolute;
        top: 32px;
        right: 20px;
        cursor: pointer;
      }

      &__header {
        margin-top: 60px;
        margin-bottom: 17px;
        font-size: 36px;
        font-weight: bold;
        line-height: 46px;
        width: 100%;
        max-width: 475px;
      }

      &__wording {
        width: 100%;
        max-width: 475px;
      }

      &__wording span {
        white-space: pre-wrap;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
    }
    &__container {
      margin-top: 40px;
      max-width: 475px;
      width: 100%;
    }
    &__field {
      margin-bottom: 30px;
      &-title {
        margin-bottom: 20px;
        font-weight: 600;
        color: #fff;
        font-size: 16px;
      }
    }
    &__message {
      display: flex;
      height: 100px;
      width: 100%;
      background-color: rgba(230, 236, 241, 0.8);
      border-radius: 6px;
      color: #12598d;
      padding: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;
      border: none;
      &::placeholder {
        color: #12598d;
        font-weight: 400;
        font-size: 16px;
      }
    }
    &__action-button,
    &__cancel-button {
      height: 35px;
      border-radius: 6px;

      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
      }
    }
    &__cancel-button {
      box-shadow: unset;
      color: #12598d;
      background: transparent;
    }
  }
}
</style>
