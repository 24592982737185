<template>
  <div>
    <div
      class="pathadvice__visitor-chat__body-wrapper"
      :class="{
        'pathadvice__visitor-chat__body-wrapper_mobile-co-browsing': isMobile && isCobrowse,
        'pathadvice__visitor-chat__body-wrapper_hidden': isMobile && isCobrowse && isHiddenChat
      }"
    >
      <button v-if="isMobile && isCobrowse" class="pathadvice__visitor-chat__body__btn-toggle-chat" @click.prevent="isHiddenChat = true">
        <ArrowIcon :color="'rgb(18, 89, 141)'" :direction="'bottom'" @click="isHiddenChat = true" />
      </button>

      <div
        ref="chatBody"
        class="pathadvice__visitor-chat__body"
        :style="isMaximized ? '' : 'padding-top: 20px;'"
        :class="{
          'pathadvice__visitor-chat__body--collapsed': !isMaximized && (micEnabled || agentVideoOn)
        }"
      >
        <scroll-view ref="chatScrollArea" :style="isCobrowse && !isMobile ? chatHeightStyle : ''">
          <div
            class="pathadvice__visitor-chat__body__message"
            v-for="item in modifiedMessages"
            v-bind:key="item.chatTime"
            :class="
              item.sender === 'agent'
                ? 'pathadvice__visitor-chat__body__message--received'
                : `pathadvice__visitor-chat__body__message--sent ${
                    item.type === 'lead-form' && !item.leadForm ? 'pathadvice__visitor-chat__body__message--widget' : ''
                  }`
            "
          >
            <div class="pathadvice__visitor-chat__body__message-body">
              <LeadForm
                v-if="dialog && visitor && !item.leadForm && item.type === 'lead-form' && visitor.activeLeadFormId === item.id"
                :contactForm="dialog.contactForm"
                :leadForm="dialog.leadForm"
                :isInChat="true"
                :modifiedMessages="modifiedMessages"
                :activeLeadFormId="visitor.activeLeadFormId"
              />

              <template v-else>
                <div class="pathadvice__visitor-chat__body__message-body__header">
                  <div class="pathadvice__visitor-chat__body__message-body__agent-name" v-if="item.sender === 'agent'">
                    <span class="msg-agent-name">{{ agentInfo.connectedAgent }}</span>
                  </div>

                  <div class="pathadvice__visitor-chat__body__message-body__time">
                    <span class="msg-time">{{ chatTime(item.time) }}</span>
                  </div>
                </div>

                <div class="pathadvice__visitor-chat__body__message-body__msg">
                  <pre class="msg-text" v-html="sanitizeHtml(item.message)"></pre>

                  <div v-if="item.leadForm && item.type === 'lead-form'" class="msg-text">
                    <div>Name: {{ item.leadForm && item.leadForm.name ? item.leadForm.name : '' }}</div>
                    <div>Email: {{ item.leadForm && item.leadForm.email ? item.leadForm.email : '' }}</div>
                    <div v-if="item.leadForm.phone">Phone: {{ item.leadForm.phone }}</div>
                  </div>

                  <div v-if="item.files && item.files.length">
                    <div class="message-file__wrapper flex flex-row flex-wrap -mb-2 justify-end">
                      <template v-for="(file, index) in item.files">
                        <message-file
                          class="my-2"
                          :isWhite="true"
                          :hasDeleteIcon="false"
                          :hasDownload="true"
                          :key="index"
                          :file="file"
                          @download-file="downloadFile"
                        />
                      </template>
                    </div>
                    <div class="chat-message-transfer-date flex flex-row justify-end text-xs w-full" :class="{ _expired: isFilesExpired(item.files) }">
                      {{ getChatFilesTransferMessage(item.files) }}
                    </div>
                  </div>
                  <div v-if="item.feedbackQuizAnswers">
                    <div class="flex flex-row flex-wrap -mb-2 -mr-4 justify-end w-full">
                      <div class="pathadvice__finish-communication__form">
                        <div class="pathadvice__finish-communication__field" style="margin-bottom: 10px" v-if="item.feedbackQuizAnswers.grade1 !== ''">
                          <div class="pathadvice__finish-communication__field-title" style="margin-bottom: 5px">
                            {{ dialog.feedbackFormQuestionFirst }}
                          </div>
                          <rating-stars :grade="item.feedbackQuizAnswers.grade1" :maxStars="5" :disabled="true" />
                        </div>
                        <div class="pathadvice__finish-communication__field" style="margin-bottom: 10px" v-if="item.feedbackQuizAnswers.grade2 !== ''">
                          <div class="pathadvice__finish-communication__field-title" style="margin-bottom: 5px">
                            {{ dialog.feedbackFormQuestionSecond }}
                          </div>
                          <rating-stars :grade="item.feedbackQuizAnswers.grade2" :maxStars="5" :disabled="true" />
                        </div>
                        <div class="pathadvice__finish-communication__field" style="margin-bottom: 10px" v-if="item.feedbackQuizAnswers.grade3 !== ''">
                          <div class="pathadvice__finish-communication__field-title" style="margin-bottom: 5px">
                            {{ dialog.feedbackFormQuestionThird }}
                          </div>
                          <rating-stars :grade="item.feedbackQuizAnswers.grade3" :maxStars="5" :disabled="true" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.contactFormData">
                    <div class="flex flex-row flex-wrap -mb-2 -mr-4 justify-end w-full">
                      <div class="flex" style="flex-direction: column">
                        <div>Contact Form Data:</div>
                        <div>
                          Type: <br />
                          {{ item.contactFormData.type === 'contact-form-info-saved' ? 'Message' : 'Booking' }}
                        </div>
                        <div>
                          Visitor's name: <br />
                          {{ item.contactFormData.data.name }}
                        </div>
                        <div v-if="item.contactFormData.data.email">
                          Visitor's email: <br />
                          {{ item.contactFormData.data.email }}
                        </div>
                        <div v-if="item.contactFormData.data.phone">
                          Visitor's phone: <br />
                          {{ item.contactFormData.data.phone }}
                        </div>
                        <div v-if="item.contactFormData.data.message">
                          Visitor's message: <br />
                          {{ item.contactFormData.data.message }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div v-show="agentIsTyping" class="chat-message-typing">
            <span class="chat-message-typing__wording">{{ $tp('buttonAndHints.agentIsTyping') }}</span>

            <div class="chat-message-typing-indicator">
              <span class="chat-message-typing-indicator__animated-dots" style="background-color: rgba(18, 89, 141, 0.6)"></span>
              <span class="chat-message-typing-indicator__animated-dots" style="background-color: rgba(18, 89, 141, 0.8)"></span>
              <span class="chat-message-typing-indicator__animated-dots" style="background-color: rgba(18, 89, 141, 1)"></span>
            </div>
          </div>
        </scroll-view>
      </div>
    </div>

    <div
      class="pathadvice__visitor-chat__footer"
      :class="{
        'pathadvice__visitor-chat__footer_full-screen': isMaximized
      }"
    >
      <div
        class="pathadvice__visitor-chat__footer__actions"
        :style="{
          '--toolbar-background': toolBarBackground
        }"
      >
        <toolbar @upload-files="onUploadFiles" />
      </div>

      <div class="pathadvice__visitor-chat__footer__send-msg send-chat-message-container" ref="sendChatMessageContainer">
        <div class="pathadvice__visitor-chat__footer__send-msg__icon" @click="sendMessage">
          <send-icon v-if="isMaximized" :color="canSendMessage ? '#12598D' : '#cccccc'" :width="16" :height="16" />

          <send-icon v-else :color="canSendMessage ? '#12598D' : '#cccccc'" :width="25" :height="25" />
        </div>

        <div class="pathadvice__visitor-chat__footer__send-msg__area">
          <message-dropzone
            v-if="isDropzoneVisible"
            ref="filesDropzone"
            @files-added="onFilesAdded"
            @on-reset="hideDropzone"
            @vd-drag-enter="isDropzoneVisible = true"
            @vd-drag-over="isDropzoneVisible = true"
            @vd-drag-drop="isDropzoneVisible = true"
          />
          <textarea
            v-else
            ref="message"
            class="pathadvice__visitor-chat__footer__send-msg__area__input"
            :placeholder="$tp('buttonAndHints.writeMessage')"
            v-model="msg"
            v-on:keyup.enter.exact.prevent="sendMessage"
            @click="setFocusToField('message')"
            @keyup="onVisitorTyping"
          />
          <div class="pathadvice__visitor-chat__footer__send-msg__area__files flex flex-wrap" v-if="files && files.length">
            <message-file class="mb-4" v-for="(file, index) in files" :key="index" :file="file" :index="index" @delete-file="onDeleteFile" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'firebase/storage'
/* eslint-disable */
import { mapMutations, mapState, mapGetters } from 'vuex'
import Autolinker from 'autolinker'

import ChatIcon from './icons/ChatIcon.vue'
import ClipIcon from './icons/ClipIcon.vue'
import PhoneCallIcon from './icons/PhoneIcon.vue'
import MaximizeIcon from './icons/MaximizeIcon.vue'
import MinimizeIcon from './icons/MinimizeIcon.vue'
// import MoreIcon from './icons/MoreIcon.vue'
import MicIcon from './icons/MicIcon.vue'
import MicMuteIcon from './icons/MicMuteIcon.vue'
import SendIcon from './icons/SendIcon.vue'
import VideoCallIcon from './icons/VideoCallIcon.vue'
import AudioIcon from './icons/AudioIcon.vue'
import SettingsIcon from './icons/SettingsIcon.vue'

import PoweredBy from '@/components/dialog/PoweredBy.vue'
import WaitingRoom from './WaitingRoom'
import ScrollView from '@blackbp/vue-smooth-scrollbar'
import EndConversation from './EndConversation'
import Settings from './Settings'
import VideoCallOffIcon from './icons/VideoCallOffIcon'
import Notice from './Notice'
import CoBrowsingIframe from './CoBrowsingIframe'
import ScreenSharing from './ScreenSharing'
import ArrowIcon from './icons/ArrowIcon'
import AudioCrossedIcon from './icons/AudioCrossedIcon'
import AudioMobileIcon from './icons/AudioMobileIcon'
import DraggableModal from './DraggableModal'
import MessageFile from './MessageFile.vue'
import MessageDropzone from './MessageDropzone.vue'
import RatingStars from '@/components/rating-stars/RatingStars.vue'
import LeadForm from './LeadForm'

import sanitizeHtml from 'sanitize-html'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export default {
  name: 'VisitorChat',

  components: {
    Notice,
    VideoCallOffIcon,
    Settings,
    EndConversation,
    WaitingRoom,
    PoweredBy,
    AudioIcon,
    MaximizeIcon,
    MinimizeIcon,
    PhoneCallIcon,
    ClipIcon,
    // MoreIcon,
    SendIcon,
    ChatIcon,
    VideoCallIcon,
    MicIcon,
    MicMuteIcon,
    SettingsIcon,
    ArrowIcon,
    ScrollView,
    CoBrowsingIframe,
    ScreenSharing,
    DraggableModal,
    AudioCrossedIcon,
    AudioMobileIcon,
    MessageFile,
    MessageDropzone,
    RatingStars,
    LeadForm
  },

  data() {
    return {
      showProceedBtn: false,
      RTDB: null,
      wasAudioVideoCallActivated: false,
      wasVisiorAudioVideoActivated: false,
      agentVideoOn: false,
      agentAudioOn: false,
      agentIsTyping: false,
      visitorIsTyping: false,
      showEndConversationDialog: false,
      msg: '',
      isChat: true,
      isMaximized: true,
      showSettings: false,
      remoteVideoReady: false,
      remoteAudioReady: false,
      isHiddenChat: false,
      hideChatTime: null,
      unsubscribeVisitor: false,
      isDropzoneVisible: false,
      isChatFilesUploadComplete: true,
      visitorIsTypingDelayInMS: 5000,
      visitorIsTypingRemainingDelayInMs: 0
    }
  },
  filters: {
    generateAgentAcronym(name) {
      if (!name) {
        return ''
      }

      const fullName = name.split(' ')
      let initials = ''

      if (fullName[0]) {
        initials = fullName[0].charAt(0)
      }

      if (fullName[1]) {
        initials = `${initials}${fullName[1].charAt(0)}`
      }

      return initials.toUpperCase()
    }
  },
  watch: {
    volumeLevel() {
      this.$refs.localVideo.volume = this.volumeLevel

      if (this.$refs.localVideoFullScreen) {
        this.$refs.localVideoFullScreen.volume = this.volumeLevel
      }
    },

    isWaiting() {
      if (this.communicationMode !== 'text' && !this.isWaiting) {
        setTimeout(() => {
          this.setLocalStream()
          this.setRemoteStream()
        }, 200)
      }

      if (!this.isWaiting) {
        this.setAudioOutput()
      }
    },

    remoteStream() {
      if (this.remoteStream) {
        this.setRemoteStream()
      }
    },
    isCobrowse(newVal, oldVal) {
      if (newVal && !this.isMobile) {
        this.isMaximized = false

        this.$emit('coBrowsingModeChanged')
      } else if (!newVal && oldVal && !this.isMobile) {
        this.isMaximized = true

        this.$emit('fullScreenModeChanged', true)
      }
    },
    msg() {
      if (this.msg && this.msg.trim().length > 0) {
        this.updateHasFileSizeError(false)
      }
    }
  },

  async mounted() {
    if (this.preShowAVSettings) {
      this.showSettings = true
      this.showProceedBtn = true
    }

    this.initDBWatchers()
    this.getAgentVideoInitialState()

    this.$serverBus.$on('media-state', this.setMediaVisitorStates)

    let dragTimer = null
    let lastTarget = null
    const sendChatMessageContainer = this.$refs.sendChatMessageContainer

    function isFile(e) {
      const { dataTransfer } = e

      for (let i = 0; i < dataTransfer.types.length; i++) {
        if (dataTransfer.types[i] === 'Files') {
          return true
        }
      }
      return false
    }
    document.addEventListener(
      'dragover',
      (e) => {
        e.preventDefault()
      },
      false
    )

    document.addEventListener(
      'dragleave',
      (e) => {
        e.preventDefault()
        if (e.target === document || e.target === lastTarget) {
          dragTimer = setTimeout(() => {
            this.isDropzoneVisible = false
          }, 25)
        }
      },
      false
    )
    document.addEventListener(
      'dragenter',
      (e) => {
        e.preventDefault()
        if (isFile(e)) {
          lastTarget = e.target
          this.isDropzoneVisible = true
          window.clearTimeout(dragTimer)
        }
      },
      false
    )

    document.addEventListener(
      'drop',
      (e) => {
        // prevent default action (open as link for some elements)
        e.preventDefault()
        if (e.target !== sendChatMessageContainer) {
          this.isDropzoneVisible = false
        }
      },
      false
    )

    this.$serverBus.$on('chat-files-upload-complete', (isChatFilesUploadComplete) => {
      this.isChatFilesUploadComplete = isChatFilesUploadComplete
    })

    this.scrollToBottom()
  },

  async created() {
    if (window.campaignVisitorTypingDelayInterval) {
      window.clearInterval(window.visitorTypingDelayInterval)
    }

    window.campaignVisitorTypingDelayInterval = setInterval(() => {
      if (this.visitorIsTypingRemainingDelayInMs > 0) {
        this.visitorIsTypingRemainingDelayInMs = this.visitorIsTypingRemainingDelayInMs - 1000
        if (this.visitorIsTypingRemainingDelayInMs <= 0) {
          this.RTDB.update({ visitorIsTyping: false })
        }
      }
    }, 1000)

    if (this.isMobile) {
      this.isMaximized = false
    }
    if (this.visitorInfo && this.visitorInfo.visitorId) {
      this.updateVisitorId(this.visitorInfo.visitorId)
    }

    this.$serverBus.$on('visitor-audio-ready', (t) => {
      if (t && this.remoteStream && this.remoteStream.getVideoTracks()) {
        this.remoteStreamCount = this.remoteStream.getVideoTracks().filter((x) => x.enabled).length
      }
      this.remoteAudioReady = true
    })

    this.$serverBus.$on('visitor-video-ready', (t) => {
      if (t && this.remoteStream && this.remoteStream.getVideoTracks()) {
        this.remoteStreamCount = this.remoteStream.getVideoTracks().filter((x) => x.enabled).length
      }
      this.remoteVideoReady = true
    })
  },

  updated() {
    this.scrollToBottom()
  },

  async beforeDestroy() {
    this.$serverBus.$off('chat-files-upload-complete')
    this.$serverBus.$off('media-state', this.setMediaVisitorStates)
    this.showSettings = false
  },

  computed: {
    ...mapState({
      files: (state) => state.chatFiles.files
    }),
    ...mapGetters({
      microphoneOptions: 'webrtc/microphoneOptions',
      cameraOptions: 'webrtc/cameraOptions',
      visitor: 'visitor',
      visitorId: 'visitorId',
      messages: 'campaign/messages',
      localStream: 'webrtc/localStream',
      remoteStream: 'webrtc/remoteStream',
      selectedAudio: 'webrtc/selectedAudio',
      selectedOutput: 'webrtc/selectedOutput',
      selectedVideo: 'webrtc/selectedVideo',
      microphoneOptions: 'webrtc/microphoneOptions',
      cameraOptions: 'webrtc/cameraOptions',
      micEnabled: 'webrtc/micEnabled',
      videoEnabled: 'webrtc/videoEnabled',
      visitor: 'visitor',
      visitorId: 'visitorId',
      visitorInfo: 'campaign/visitorInfo',
      callStarted: 'campaign/callStarted',
      communicationMode: 'campaign/communicationMode',
      dialog: 'campaign/dialog',
      isMobile: 'campaign/isMobile',
      agentInfo: 'campaign/agentInfo',
      isWaiting: 'campaign/isWaiting',
      volumeLevel: 'campaign/volumeLevel',
      isCobrowse: 'campaign/isCobrowse'
    }),

    toolBarBackground() {
      return this.dialog && this.dialog.step2ButtonColor ? this.dialog.step2ButtonColor : '#3B86F7'
    },
    showAgentPhoto() {
      if (this.agentInfo && this.agentInfo.connectedAgentImage && this.agentInfo.connectedAgentImage !== '/user.png') {
        return true
      }
      return false
    },
    agentPhoto() {
      if (this.agentInfo && this.agentInfo.connectedAgentImage) {
        return this.agentInfo.connectedAgentImage
      }
    },
    getClassForAudioCallBtn() {
      if (this.videoEnabled && this.micEnabled) {
        return 'pathadvice__visitor-chat__header__communication-controls__btn--video-call-active'
      }

      if (this.micEnabled) {
        return 'pathadvice__visitor-chat__header__communication-controls__btn--call-active'
      }

      return ''
    },
    getClassForVideoCallBtn() {
      if (!this.micEnabled && this.videoEnabled) {
        return 'pathadvice__visitor-chat__header__communication-controls__btn--active'
      }

      if (this.videoEnabled) {
        return 'pathadvice__visitor-chat__header__communication-controls__btn--call-active'
      }

      return ''
    },

    canSendMessage() {
      const typedMessage = this.msg.trim()

      if ((typedMessage.length > 0 || (this.files && this.files.length)) && this.isChatFilesUploadComplete) {
        return true
      }

      return false
    },

    filteredMessages() {
      if (!this.messages) {
        return []
      }

      return this.messages.filter(
        (message) => message.type !== 'agent-private-message' && !(message.id && message.id !== this.visitor.activeLeadFormId && !message.leadForm)
      )
    },

    modifiedMessages() {
      return this.filteredMessages.map((item) => {
        return {
          ...item,
          message: Autolinker.link(item.message)
        }
      })
    },

    unreadChatMessages() {
      return this.filteredMessages.filter(
        (message) => message.sender === 'agent' && this.hideChatTime && message.time && message.time.seconds && this.hideChatTime < message.time.seconds * 1000
      )
    },
    maxHeight() {
      return !this.isMaximized ? 200 : 330
    },
    chatHeightStyle() {
      if (!this.isMaximized) {
        return {
          maxHeight: `${this.maxHeight}px`,
          height: `${this.maxHeight}px`
        }
      }
      return ''
    }
  },

  methods: {
    ...mapMutations({
      setMicEnabled: 'webrtc/setMicEnabled',
      setVideoEnabled: 'webrtc/setVideoEnabled',

      setSelectedAudio: 'webrtc/setSelectedAudio',
      setSelectedOutput: 'webrtc/setSelectedOutput',
      setSelectedVideo: 'webrtc/setSelectedVideo',

      setIsAudioVideoAllowed: 'webrtc/setIsAudioVideoAllowed',

      setMicrophoneOptions: 'webrtc/setMicrophoneOptions',
      setAudioOutputOptions: 'webrtc/setAudioOutputOptions',
      setCameraOptions: 'webrtc/setCameraOptions',

      updateVisitor: 'UPDATE_VISITOR',
      updateVisitorId: 'UPDATE_VISITOR_ID',

      updateHasFileSizeError: 'UPDATE_HAS_FILE_SIZE_ERROR'
    }),
    sanitizeHtml,
    hideDropzone() {
      this.isDropzoneVisible = false
    },
    onUploadFiles(files) {
      this.$emit('upload-files', files)
    },
    onFilesAdded(uploads) {
      const ref = this.$refs.filesDropzone.$refs.messageDropzone

      this.$emit('dropzone-upload-files', uploads, ref)
    },
    async onDeleteFile(url, index) {
      const fileRef = this.$firebase.storage().refFromURL(url)

      try {
        await fileRef.delete()
        this.$serverBus.$emit('delete-send-file', url, index)
        this.$vs.notify({
          time: 3000,
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('uploads.deleteFile'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    },

    setMediaVisitorStates(data) {
      this.RTDB.update({ visitorVideoOn: data.videoEnabled, visitorAudioOn: data.micEnabled })
    },
    setFocusToField(ref) {
      this.$refs[ref].focus()
    },

    closeNotice() {
      this.$emit('closeNotice')
    },

    goToTheChat() {
      this.$emit('closeNotice')

      if (!this.callStarted && this.showSettings) {
        this.$serverBus.$emit('onJoinMeeting')
      }
    },

    getAgentVideoInitialState() {
      this.$database
        .ref(`/chats/`)
        .child(this.visitorId)
        .once('value', async (dbSnapshot) => {
          const snapshot = dbSnapshot.val()

          if (snapshot) {
            this.agentVideoOn = !!snapshot.agentVideoOn
            this.agentAudioOn = !!snapshot.agentAudioOn

            if (!this.wasAudioVideoCallActivated && (this.agentVideoOn || this.agentAudioOn)) {
              this.wasAudioVideoCallActivated = true
            }
          }
        })
    },
    initDBWatchers() {
      this.RTDB = this.$database.ref(`/chats/${this.visitorId}`)
      // this.$serverBus.$emit('agent-is-typing', false)
      this.RTDB.on('value', async (snapshot) => {
        const data = snapshot.val()

        if (data) {
          // Agent is typing
          this.agentIsTyping = typeof data.agentIsTyping !== 'undefined' ? data.agentIsTyping : false

          if (data && typeof data.visitorIsTyping !== 'undefined') {
            this.visitorIsTyping = data.visitorIsTyping
          }

          // Agent Video Enabled
          this.agentVideoOn = data.agentVideoOn
          this.agentAudioOn = data.agentAudioOn

          if (typeof this.agentVideoOn === Boolean) {
            this.$emit('callActivated', true)
          } else if (typeof this.agentAudioOn === Boolean) {
            this.$emit('callActivated', true)
          }

          if (!this.wasAudioVideoCallActivated && (this.agentVideoOn || this.agentAudioOn)) {
            this.wasAudioVideoCallActivated = true
          }
        }
      })
    },
    onVisitorTyping() {
      this.visitorIsTypingRemainingDelayInMs = this.visitorIsTypingDelayInMS

      if (this.msg.length === 0 && this.visitorIsTyping) {
        this.RTDB.update({ visitorIsTyping: false })
      }
      if (this.msg.length > 0 && !this.visitorIsTyping) {
        this.RTDB.update({ visitorIsTyping: true })
      }
    },
    scrollToBottom() {
      setTimeout(() => {
        const scrollElement = this.$refs.chatScrollArea

        if (scrollElement) {
          const scrollBar = scrollElement.scrollBar

          if (!(scrollBar && scrollBar.size && scrollBar.size.content && scrollBar.size.content.height)) {
            return
          }

          const lastLeadForm = this.modifiedMessages.find((item) => item.type === 'lead-form' && !item.leadForm)

          let time = null

          if (lastLeadForm && lastLeadForm.time && lastLeadForm.time.seconds) {
            time = dayjs(lastLeadForm.time.seconds * 1000)
          }

          if (time) {
            const timeMargin = time.add(3, 'second')
            const now = dayjs()

            const shouldScrollToNewForm = now.isSameOrAfter(time) && now.isSameOrBefore(timeMargin)

            if (shouldScrollToNewForm) {
              const LEAD_FORM_MARGIN = 350
              const value = LEAD_FORM_MARGIN

              return scrollElement.scrollTo(0, scrollBar.size.content.height - value, 600)
            }

            scrollElement.scrollTo(0, scrollBar.size.content.height, 600)
          } else {
            scrollElement.scrollTo(0, scrollBar.size.content.height, 600)
          }
        }
      }, 100)
    },

    chatTime(time) {
      if (time) {
        if (typeof time === 'string') {
          return dayjs(time).locale(this.$i18n.locale).format('HH:mm')
        }
        if (typeof time === 'object' && time.seconds) {
          return dayjs.unix(time.seconds).locale(this.$i18n.locale).format('HH:mm')
        }
      } else {
        if (this.visitor) {
          if (this.visitor.created) {
            if (typeof this.visitor.created === 'string') {
              return dayjs(this.visitor.created).locale(this.$i18n.locale).format('HH:mm')
            }
            if (typeof this.visitor.created === 'object' && this.visitor.created.seconds) {
              return dayjs.unix(this.visitor.created.seconds).locale(this.$i18n.locale).format('HH:mm')
            }
          } else if (this.visitor.firstSeen) {
            if (typeof this.visitor.firstSeen === 'string') {
              return dayjs(this.visitor.firstSeen).locale(this.$i18n.locale).format('HH:mm')
            }
            if (typeof this.visitor.firstSeen === 'object' && this.visitor.firstSeen.seconds) {
              return dayjs.unix(this.visitor.firstSeen.seconds).locale(this.$i18n.locale).format('HH:mm')
            }
          }
          return ''
        }
        return ''
      }
      return ''
    },

    async setupAccessToDevices(types) {
      try {
        this.$emit('setNoticeType', 'request-permissions')

        const constraints = {
          audio: types.includes('microphone'),
          video: types.includes('camera')
        }

        await navigator.mediaDevices.getUserMedia(constraints)

        this.$emit('setNoticeType', '')

        return true
      } catch (error) {
        this.$emit('setNoticeType', 'permissions-denied')

        return false
      }
    },

    async onJoinMeeting() {
      this.showSettings = false

      this.$emit('startContactFormTimeout')
      this.$emit('on-join-meeting')
    },
    onVideoPreviewRequest() {
      this.$emit('deviceSelected')
    },
    visitorIsTypingInWaitingRoom(value) {
      this.$emit('visitorIsTypingInWaitingRoom', value)
    },
    sendMessage() {
      if (this.canSendMessage) {
        this.$serverBus.$emit('send-visitor-message', this.msg)
        this.msg = ''
        this.RTDB.update({ visitorIsTyping: false })

        this.scrollToBottom()
      }
    },

    close() {
      if ((this.isMaximized || this.isCobrowse) && !this.isMobile) {
        this.isMaximized = true

        this.$emit('fullScreenModeChanged', true)
      }

      this.showEndConversationDialog = false
      this.$emit('sessionEnd', this.callStarted || !this.isWaiting)
    },

    closeEndConversationDialog() {
      this.showEndConversationDialog = false
    },
    endConversation() {
      this.showEndConversationDialog = true
    },
    // addAttachment() {
    //   this.$emit('invokeCoBrowsing')
    // },
    setLocalStream() {
      if (this.localStream && (this.micEnabled || this.videoEnabled) && this.$refs.localVideo) {
        this.$refs.localVideo.srcObject = this.localStream

        if (this.$refs.localVideoFullScreen) {
          this.$refs.localVideoFullScreen.srcObject = this.localStream
        }
      }
    },
    setRemoteStream() {
      if (this.$refs.remoteVideo && this.remoteStream) {
        this.$refs.remoteVideo.srcObject = this.remoteStream

        this.$refs.remoteVideo.removeEventListener('loadeddata', this.setAudioOutput)
        this.$refs.remoteVideo.addEventListener('loadeddata', this.setAudioOutput)

        if (this.$refs.remoteVideoFullScreen) {
          this.$refs.remoteVideoFullScreen.srcObject = this.remoteStream

          this.$refs.remoteVideoFullScreen.removeEventListener('loadeddata', this.setAudioOutput)
          this.$refs.remoteVideoFullScreen.addEventListener('loadeddata', this.setAudioOutput)
        }

        if (this.$refs.remoteVideoCobrowse) {
          this.$refs.remoteVideoCobrowse.srcObject = this.remoteStream

          this.$refs.remoteVideoCobrowse.removeEventListener('loadeddata', this.setAudioOutput)
          this.$refs.remoteVideoCobrowse.addEventListener('loadeddata', this.setAudioOutput)
        }
      }

      this.setLocalStream()
    },
    async setAudioOutput() {
      if (!this.$refs.remoteVideo || !this.selectedOutput || !this.$refs.remoteVideo.setSinkId) {
        return
      }
      try {
        await this.$refs.remoteVideo.setSinkId(this.selectedOutput.value)

        if (this.$refs.remoteVideoFullScreen) {
          await this.$refs.remoteVideoFullScreen.setSinkId(this.selectedOutput.value)
        }

        if (this.$refs.remoteVideoCobrowse) {
          await this.$refs.remoteVideoCobrowse.setSinkId(this.selectedOutput.value)
        }
      } catch {
        //console.log(`could not set sink ${this.selectedOutput.value}`)
      }
    },

    getFilesExpiration(files) {
      if (files) {
        const expiresAt = dayjs.unix(files[0].expiresAt.seconds)
        const expiresIn = dayjs().to(dayjs(expiresAt))
        return expiresIn
      }
      return ''
    },

    isFilesExpired(files) {
      if (files) {
        const expiresAt = dayjs.unix(files[0].expiresAt.seconds)
        return dayjs().isSameOrAfter(expiresAt, 'day')
      }
      return false
    },

    getChatFilesTransferMessage(files) {
      if (files) {
        const isFilesExpired = this.isFilesExpired(files)
        const filesExpiresIn = this.getFilesExpiration(files)

        if (isFilesExpired) {
          return this.$tp('buttonAndHints.uploadsTransferIsExpired')
        }
        return `${this.$tp('buttonAndHints.uploadsTransferExpires')} ${filesExpiresIn}`
      }
    },

    dowloadFileFromBlob(refUrl, metadata) {
      fetch(refUrl, { method: 'GET' })
        .then((response) => response.blob())
        .then((response) => {
          const [name, ext] = metadata.name.split(/\.(?=[^.]+$)/)
          const blob = new Blob([response], { type: metadata.contentType })
          const link = document.createElement('a')

          link.href = URL.createObjectURL(blob)
          link.download = `${name}.${ext}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((err) => {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    async downloadFile(url) {
      const httpsReference = await this.$firebase.storage().refFromURL(url)
      const metadata = await httpsReference.getMetadata()

      httpsReference
        .getDownloadURL()
        .then((refUrl) => {
          this.dowloadFileFromBlob(refUrl, metadata)
        })
        .catch((err) => {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },

    detectFiles(event) {
      const { files } = event.target

      this.$emit('upload-files', files)
    },

    onFileInputClick(event) {
      /* makes it possible to send the same file twice,
      one after another
      (the browser caches the change event for performance purposes) */
      event.target.value = ''
    }
  }
}
</script>

<style lang="scss">
.draggable-modal-header {
  height: 100%;
}

.pathadvice__visitor-chat__body__message {
  &--widget {
    width: 100%;

    .pathadvice__visitor-chat__body__message-body {
      padding: 0 !important;
      width: 100%;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
      overflow: hidden;
    }
  }
}

.msg-text {
  p {
    display: inline-block;
  }

  ul,
  ol {
    li {
      margin-left: 14px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import './styles/visitor-chat.scss';
</style>
