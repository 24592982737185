<template>
  <div class="toolbar-tools flex">
    <div class="flex flex-row items-center" style="justify-content: flex-start; flex-grow: 1">
      <!-- FILE SHARING BUTTON -->
      <vx-tooltip :text="$tp('buttonAndHints.tooltipUploadFile')">
        <label for="files-sharing">
          <div class="toolbar-tools-button">
            <input type="file" id="files-sharing" multiple style="display: none" @change="detectFiles" @click="onFileInputClick" />
            <clip-icon class="toolbar-container-icon" :width="20" :height="20"></clip-icon>
          </div>
        </label>
      </vx-tooltip>

      <!-- SCREEN SHARING BUTTON -->

      <vx-tooltip
        v-if="HAS_SCREENSHARING_ACCESS && HAS_COMMUNICATION_STARTED && HAS_WEBRTC_SESSION_STARTED && !isMobile"
        :text="$tp('buttonAndHints.tooltipScreenSharing')"
      >
        <div class="toolbar-tools-button" @click="onScreenSharingClicked">
          <share-icon
            v-if="HAS_SCREEN_SHARING_STOPPED"
            class="toolbar-container-icon"
            :class="IS_SCREEN_SHARING ? 'toolbar-active' : ''"
            :width="25.41"
            :height="18"
            :color="SCREENSHARING_ICON_COLOR"
          ></share-icon>
          <loader-icon class="toolbar-container-icon" :width="20" :height="20" color="#12598d" v-if="HAS_SCREEN_SHARING_STARTED"></loader-icon>
          <share-icon
            v-if="IS_SCREEN_SHARING"
            class="toolbar-container-icon toolbar-tools-pulse"
            :class="IS_SCREEN_SHARING ? 'toolbar-active' : ''"
            :width="25.41"
            :height="18"
            :color="SCREENSHARING_ICON_COLOR"
          ></share-icon>
        </div>
      </vx-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ClipIcon from '@/components/icons/ClipIcon.vue'
import ShareIcon from '@/components/icons/ShareIcon.vue'
import LoaderIcon from '@/components/icons/LoaderIcon.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

export default {
  name: 'Toolbar',
  components: {
    ClipIcon,
    ShareIcon,
    LoaderIcon
  },
  data() {
    return {
      SCREENSHARE_RTDB: null
    }
  },
  beforeMount() {
    this.SCREENSHARE_RTDB = this.$database.ref(`/screen-share/${this.visitorId}`)
  },
  computed: {
    ...mapGetters({
      visitor: 'visitor',
      visitorId: 'visitorId',
      isMobile: 'campaign/isMobile',
      company: 'campaign/company',
      screenSharingStatus: 'webrtc/screenSharingStatus',
      SCREEN_SHARING_STATUS: 'webrtc/SCREEN_SHARING_STATUS',
      HAS_WEBRTC_SESSION_STARTED: 'webrtc/hasWebrtcSessionStarted'
    }),
    HAS_SCREENSHARING_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('screen-sharing') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    HAS_COMMUNICATION_STARTED() {
      return this.visitor && !this.visitor.agentRequest && this.visitor.connectedAgentId
    },

    SCREENSHARING_ICON_COLOR() {
      return this.HAS_SCREEN_SHARING_STOPPED || this.IS_VISITOR_SCREEN_SHARING ? '#ffffff' : '#C8C8C8'
    },

    IS_SCREEN_SHARING() {
      return this.screenSharingStatus && this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.SCREEN_SHARING
    },
    IS_VISITOR_SCREEN_SHARING() {
      return this.IS_SCREEN_SHARING && this.screenSharingStatus.initiatedBy === 'visitor'
    },
    HAS_SCREEN_SHARING_STARTED() {
      return this.screenSharingStatus && this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.STARTED
    },
    HAS_SCREEN_SHARING_STOPPED() {
      return (
        this.screenSharingStatus &&
        (this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.STOPPED || this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.ENDED)
      )
    }
  },
  methods: {
    ...mapMutations({
      updateHasFileSizeError: 'UPDATE_HAS_FILE_SIZE_ERROR'
    }),
    detectFiles(event) {
      const { files } = event.target
      let hasFilesizeError = false
      for (const file in files) {
        if (files[file] && files[file].size && files[file].size / 1024 > 10240) {
          hasFilesizeError = true
        }
      }

      this.updateHasFileSizeError(hasFilesizeError)
      if (!hasFilesizeError) {
        this.$emit('upload-files', hasFilesizeError ? null : files)
      }
    },

    onFileInputClick(event) {
      /* makes it possible to send the same file twice,
      one after another
      (the browser caches the change event for performance purposes) */
      event.target.value = ''
    },
    onScreenSharingClicked() {
      if (!this.HAS_SCREENSHARING_ACCESS) {
        return
      }

      if (
        this.screenSharingStatus &&
        this.screenSharingStatus.initiatedBy === 'agent' &&
        (this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.STARTED ||
          this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.SCREEN_SHARING)
      ) {
        return
      }

      if (this.screenSharingStatus !== this.SCREEN_SHARING_STATUS.STARTED) {
        switch (this.screenSharingStatus.status) {
          case this.SCREEN_SHARING_STATUS.ENDED:
            this.SCREENSHARE_RTDB.update({
              status: this.SCREEN_SHARING_STATUS.STARTED,
              initiatedBy: 'visitor',
              updateKey: Math.random().toString(36).substring(2, 15)
            })
            break
          case this.SCREEN_SHARING_STATUS.STOPPED:
            this.SCREENSHARE_RTDB.update({
              status: this.SCREEN_SHARING_STATUS.STARTED,
              initiatedBy: 'visitor',
              updateKey: Math.random().toString(36).substring(2, 15)
            })
            break
          case this.SCREEN_SHARING_STATUS.STARTED:
            this.SCREENSHARE_RTDB.update({
              status: this.SCREEN_SHARING_STATUS.SCREEN_SHARING,
              initiatedBy: 'visitor',
              updateKey: Math.random().toString(36).substring(2, 15)
            })

            break
          case this.SCREEN_SHARING_STATUS.SCREEN_SHARING:
            this.SCREENSHARE_RTDB.update({
              status: this.SCREEN_SHARING_STATUS.STOPPED,
              initiatedBy: 'visitor',
              updateKey: Math.random().toString(36).substring(2, 15)
            })
            break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toolbar {
  &-active {
    background-color: #f05541 !important;
  }
  &-disabled {
    background-color: #c4c4c4 !important;
  }
  &-container {
    &-icon:hover {
      margin-top: -2px;
    }
  }
  &-tools {
    margin-top: auto;

    &-button {
      padding-right: 12px;
      width: auto;

      background: transparent;
      border-radius: 6px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      transition: 0.1s;
    }

    &-pulse {
      box-shadow: 0 0 0 rgb(240, 85, 65);
      animation: pulse 2s infinite;
      border-radius: 6px;
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(240, 85, 65, 1);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(240, 85, 65, 1);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
    box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
</style>
