<template>
  <div v-show="IS_AGENT_SCREEN_SHARING" class="screensharing flex flex-col">
    <div class="loader-container" v-if="isLoading">
      <div class="loader"></div>
    </div>
    <video
      v-show="!isLoading && IS_AGENT_SCREEN_SHARING"
      ref="screenShareDisplay"
      id="screenshare-video"
      autoplay
      playsinline
      class="screensharing_video"
      :class="{
        screensharing_mobile: isMobile,
        screensharing_bck: IS_AGENT_SCREEN_SHARING
      }"
      muted="muted"
    />

    <ScreenSharingLocalVideo v-show="IS_AGENT_SCREEN_SHARING && videoEnabled" />

    <ScreenSharingChat v-if="IS_AGENT_SCREEN_SHARING" />

    <ScreenSharingToolBar
      v-if="IS_AGENT_SCREEN_SHARING"
      @toggle-audio-call="$emit('toggle-audio-call')"
      @toggle-video-call="$emit('toggle-video-call')"
      @end-conversation="$emit('end-conversation')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ScreenSharingLocalVideo from './screen-sharing/ScreenSharingLocalVideo.vue'
import ScreenSharingChat from './screen-sharing/ScreenSharingChat.vue'
import ScreenSharingToolBar from './screen-sharing/ScreenSharingToolBar.vue'

export default {
  components: {
    ScreenSharingLocalVideo,
    ScreenSharingChat,
    ScreenSharingToolBar
  },
  props: {
    remoteVideoKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  mounted() {
    this.updateVideoSource()
  },
  computed: {
    ...mapGetters({
      screenSharingStatus: 'webrtc/screenSharingStatus',
      SCREEN_SHARING_STATUS: 'webrtc/SCREEN_SHARING_STATUS',
      videoEnabled: 'webrtc/videoEnabled',
      isMobile: 'campaign/isMobile',
      remoteStream: 'webrtc/remoteStream'
    }),
    IS_AGENT_SCREEN_SHARING() {
      return (
        this.screenSharingStatus &&
        this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.SCREEN_SHARING &&
        this.screenSharingStatus.initiatedBy === 'agent'
      )
    },
    IS_AGENT_SCREEN_SHARING_STARTED() {
      return (
        this.screenSharingStatus && this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.STARTED && this.screenSharingStatus.initiatedBy === 'agent'
      )
    }
  },
  watch: {
    'screenSharingStatus.status'() {
      if (this.IS_AGENT_SCREEN_SHARING) {
        this.updateVideoSource()
      }
      if (this.IS_AGENT_SCREEN_SHARING_STARTED) {
        this.isLoading = true
      } else {
        this.isLoading = false
      }
    },
    remoteVideoKey() {
      this.updateVideoSource()
    }
  },
  methods: {
    updateVideoSource() {
      if (this.$refs.screenShareDisplay && this.remoteStream) {
        this.$refs.screenShareDisplay.srcObject = this.remoteStream
      }
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
        if (this.$refs.screenShareDisplay && this.remoteStream) {
          this.$refs.screenShareDisplay.srcObject = this.remoteStream
        }
      }, 500)
    }
  },
  beforeDestroy() {
    this.isLoading = false
  }
}
</script>
<style lang="scss" scoped>
.screensharing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  &_video {
    width: calc(100vw);
    height: calc(100vh);
    overflow: hidden;
    object-fit: contain;
  }

  &_mobile {
    top: 55px;
    height: calc(100% - 125px);
  }

  &_bck {
    background-color: #3034f7;
  }

  .loader-container {
    //background-image: url('https://app.letsconnect.at/favicon.ico');
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader,
    .loader:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
    .loader {
      margin: 60px auto;
      font-size: 10px;
      text-indent: -9999em;
      border-top: 1.1em solid rgba(255, 255, 255, 0.2);
      border-right: 1.1em solid rgba(255, 255, 255, 0.2);
      border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
      border-left: 1.1em solid #ffffff;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}
</style>
