var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "draggable-modal-wrapper" }, [
    _c(
      "div",
      {
        class: {
          "draggable-modal": _vm.isDraggable,
          "stable-modal": !_vm.isDraggable,
        },
        style: _vm.isDraggable ? _vm.style : "",
        attrs: { id: "draggable-modal" },
      },
      [
        _c(
          "div",
          {
            ref: "draggableModalHeader",
            class: {
              "draggable-modal-header": _vm.isDraggable,
              "stable-modal-header": !_vm.isDraggable,
            },
            attrs: { id: "draggable-modal-header" },
          },
          [_vm._t("default")],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }