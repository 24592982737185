<template>
  <div
    class="pathadvice__contact-form"
    :class="{
      'pathadvice__contact-form_desktop': !isMobile
    }"
    style="width: 100%"
    :style="{
      '--contact-form-font-color': contactForm.fontColor,
      '--contact-form-background': contactForm.backgroundColor,
      '--contact-form-darken-background': contactForm.sendButtonBackgroundColor,
      '--contact-form-dark-background': darkСontactFormSendButtonBackgroundColor
    }"
  >
    <div class="pathadvice__contact-form__main-container">
      <div class="pathadvice__contact-form__main-container__actions">
        <button class="pathadvice__button-action" @click.prevent="closeContactForm">
          <CloseIcon :width="25" :height="25" />
        </button>
      </div>

      <div class="pathadvice__contact-form__main-container__form-wrapper" style="height: 100%">
        <div
          class="pathadvice__contact-form__main-container__form"
          :class="{
            'pathadvice__contact-form__main-container__form_hidden': isOpenedCalendarWidget
          }"
          :style="contactFormStyle"
        >
          <div class="pathadvice__contact-form__main-container__form--holder">
            <div class="pathadvice__contact-form__main-container__form__title">{{ $tp('buttonAndHints.contactForm') }}</div>

            <div class="pathadvice__contact-form__main-container__form__wording" v-html="contactForm.message" />

            <div
              v-if="isCorrectTrackingData && contactForm.isBookingEnabled"
              class="flex justify-center items-center mx-auto pathadvice__contact-form__main-container__form__checkbox-wrapper"
            >
              <h4
                class="text-center pathadvice__contact-form__main-container__form__checkbox-label"
                :class="{
                  'text-opacity': isBookMeetingForm
                }"
              >
                {{ $tp('buttonAndHints.sendAMessage') }}
              </h4>

              <vs-switch
                color="#275D73"
                class="text-center custom-checkbox pathadvice__contact-form__main-container__form__checkbox"
                v-model="isBookMeetingForm"
              />

              <h4
                class="text-center pathadvice__contact-form__main-container__form__checkbox-label"
                :class="{
                  'text-opacity': !isBookMeetingForm
                }"
              >
                {{ $tp('buttonAndHints.bookAMeeting') }}
              </h4>
            </div>

            <div>
              <div class="pathadvice__contact-form__main-container__form__fields-holder">
                <div class="pathadvice__contact-form__main-container__form__fields-holder--left">
                  <div
                    v-if="contactForm.fields.name.enabled"
                    class="pathadvice__contact-form__main-container__form__field"
                    :class="{
                      'pathadvice__contact-form__main-container__form__field_invalid': errors.first('name')
                    }"
                  >
                    <input
                      name="name"
                      key="name"
                      class="pathadvice__contact-form__main-container__form__input"
                      :placeholder="$tp('buttonAndHints.name') | modifyInputPlaceholder(contactForm.fields.name.required)"
                      v-model="name"
                      v-validate="{
                        required: contactForm.fields.name.required,
                        max: 80
                      }"
                    />

                    <p v-if="errors.first('name')" class="pathadvice__contact-form__main-container__form__field__error-checkbox">
                      * {{ errors.first('name') }}
                    </p>

                    <UserIcon color="#262629" class="pathadvice__contact-form__main-container__form__field__icon" />
                  </div>

                  <div
                    v-if="contactForm.fields.email.enabled"
                    class="pathadvice__contact-form__main-container__form__field"
                    :class="{
                      'pathadvice__contact-form__main-container__form__field_invalid': errors.first('email')
                    }"
                  >
                    <input
                      name="email"
                      key="email"
                      class="pathadvice__contact-form__main-container__form__input"
                      :placeholder="$tp('buttonAndHints.emailAddress') | modifyInputPlaceholder(contactForm.fields.email.required)"
                      v-model="email"
                      v-validate="{
                        required: contactForm.fields.email.required,
                        email: true
                      }"
                    />

                    <p v-if="errors.first('email')" class="pathadvice__contact-form__main-container__form__field__error">* {{ errors.first('email') }}</p>

                    <MailIcon color="#262629" class="pathadvice__contact-form__main-container__form__field__icon" />
                  </div>

                  <div
                    v-if="!isBookMeetingForm && contactForm.fields.phone.enabled"
                    class="pathadvice__contact-form__main-container__form__field"
                    :class="{
                      'pathadvice__contact-form__main-container__form__field_invalid': errors.first('phone')
                    }"
                  >
                    <input
                      name="phone"
                      key="phone"
                      class="pathadvice__contact-form__main-container__form__input"
                      :placeholder="$tp('buttonAndHints.phone') | modifyInputPlaceholder(contactForm.fields.phone.required)"
                      v-model="phone"
                      v-validate="{
                        required: contactForm.fields.phone.required,
                        max: 20
                      }"
                    />

                    <p v-if="errors.first('phone')" class="pathadvice__contact-form__main-container__form__field__error">* {{ errors.first('phone') }}</p>

                    <VerticalPhoneIcon color="#262629" class="pathadvice__contact-form__main-container__form__field__icon" />
                  </div>

                  <div
                    v-if="isBookMeetingForm && contactForm.fields.date.enabled"
                    class="pathadvice__contact-form__main-container__form__field"
                    :class="{
                      'pathadvice__contact-form__main-container__form__field_invalid': errors.first('date')
                    }"
                  >
                    <input
                      readonly
                      name="date"
                      key="date"
                      class="pr-8 pathadvice__contact-form__main-container__form__input"
                      :placeholder="$tp('buttonAndHints.date') | modifyInputPlaceholder(contactForm.fields.date.required)"
                      v-model="date"
                      v-validate="{
                        required: contactForm.fields.date.required
                      }"
                      @click="openCalendarWidget"
                    />

                    <p v-if="errors.first('date')" class="pathadvice__contact-form__main-container__form__field__error">* {{ errors.first('date') }}</p>

                    <CalendarIcon color="#262629" class="pathadvice__contact-form__main-container__form__field__icon" />

                    <ArrowIcon class="pathadvice__contact-form__main-container__form__field__icon-action" :color="contactForm.fontColor" :direction="'right'" />
                  </div>
                </div>
                <div
                  v-if="contactForm.fields.message.enabled"
                  class="pathadvice__contact-form__main-container__form__field _textarea-field"
                  :class="{
                    'pathadvice__contact-form__main-container__form__field_invalid': errors.first('message')
                  }"
                >
                  <textarea
                    name="message"
                    key="message"
                    class="pathadvice__contact-form__main-container__form__input pathadvice__contact-form__main-container__form__textarea"
                    :placeholder="$tp('buttonAndHints.message') | modifyInputPlaceholder(contactForm.fields.message.required)"
                    v-model="message"
                    v-validate="{
                      required: contactForm.fields.message.required,
                      max: 500
                    }"
                  />

                  <p v-if="errors.first('message')" class="pathadvice__contact-form__main-container__form__field__error">* {{ errors.first('message') }}</p>

                  <MessageSquareIcon color="#262629" class="pathadvice__contact-form__main-container__form__field__icon" />
                </div>
              </div>
              <div
                v-if="contactForm.isPrivacyInformationEnabled"
                class="pathadvice__contact-form__main-container__form__field _privacy"
                :class="{
                  'pathadvice__contact-form__main-container__form__field_invalid': errors.first('privacy-agreement')
                }"
              >
                <div
                  class="flex flex-row"
                  :class="{
                    'pathadvice__contact-form__privacy-policy': errors.first('privacy-agreement')
                  }"
                >
                  <div>
                    <vs-checkbox
                      name="privacy-agreement"
                      key="privacy-agreement"
                      class="pathadvice__contact-form__privacy-policy__checkbox"
                      :style="{
                        '--contact-form-font-color': contactForm.fontColor,
                        '--contact-form-darken-background': contactForm.sendButtonBackgroundColor
                      }"
                      v-validate="'required:true'"
                      v-model="hasAgreedToPrivacy"
                    >
                    </vs-checkbox>
                  </div>
                  <div>
                    <span v-html="contactForm.privacyInformationHtml"></span>
                  </div>
                </div>
                <p v-if="errors.first('privacy-agreement')" class="pathadvice__contact-form__main-container__form__field__error-checkbox">
                  * {{ errors.first('privacy-agreement') }}
                </p>
              </div>
            </div>

            <div class="pathadvice__contact-form__main-container__form__actions pathadvice__contact-form__main-container__form__footer">
              <button class="pathadvice__contact-form__main-container__form__actions__btn" :disabled="isLoadingSubmit" @click.prevent="submit">
                <template v-if="!isBookMeetingForm">{{ $tp('buttonAndHints.sendMessage') }}</template>
                <template v-else>{{ $tp('buttonAndHints.bookMeeting') }}</template>
              </button>
            </div>
          </div>
        </div>

        <div
          v-show="isOpenedCalendarWidget"
          class="pathadvice__contact-form__main-container__form pathadvice__contact-form__main-container__form-widget"
          :style="contactFormStyle"
        >
          <button class="pathadvice__button-back" @click.prevent="isOpenedCalendarWidget = false">
            <ArrowIcon class="mr-2" :color="contactForm.fontColor" />

            {{ $tp('buttonAndHints.back') }}
          </button>
          <div class="pathadvice-calendar-widget-wrapper">
            <div class="pathadvice__contact-form__main-container__form__title">{{ $tp('buttonAndHints.chooseFreeSlot') }}</div>

            <select-timezone @timezone-selected="onTimezoneSelected" @selected-hour-format="onSelectedHourFormat"></select-timezone>

            <template v-if="!isLoadingCalendarWidget">
              <div class="pathadvice-calendar-widget">
                <div class="pathadvice-calendar-widget__inner">
                  <button
                    class="pathadvice__button-action pathadvice-calendar-widget__button-slide pathadvice-calendar-widget__button-slide-prev"
                    :disabled="isPrevButtonDisabled"
                    @click="previousWeek()"
                  >
                    <ArrowIcon :color="contactForm.fontColor" />
                  </button>

                  <button
                    class="pathadvice__button-action pathadvice-calendar-widget__button-slide pathadvice-calendar-widget__button-slide-next"
                    :disabled="isNextButtonDisabled"
                    @click="nextWeek()"
                  >
                    <ArrowIcon :color="contactForm.fontColor" :direction="'right'" />
                  </button>
                  <div class="pathadvice-calendar-widget__heading">
                    <div v-for="weekday in selectedWeekDays" class="pathadvice-calendar-widget__day" :key="weekday.m.date()">
                      <div class="pathadvice-calendar-widget__day__heading">
                        <p class="pathadvice-calendar-widget__day__heading__date">
                          {{ weekday.m.date() }} {{ $tp('buttonAndHints.systemMonthsShort_' + weekday.m.locale('en').format('MMM').toLowerCase()) }}
                        </p>

                        <p class="pathadvice-calendar-widget__day__heading__day">
                          {{ $tp('buttonAndHints.systemWeekdaysLong_' + weekday.m.locale('en').format('ddd').toLowerCase()) }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="pathadvice-calendar-widget__body">
                    <div v-for="weekday in selectedWeekDays" class="pathadvice-calendar-widget__day" :key="weekday.m.date()">
                      <div class="pathadvice-calendar-widget__day__slots-list">
                        <template v-if="weekday.times.length">
                          <div
                            v-for="(slot, index) in weekday.times"
                            class="pathadvice-calendar-widget__day__slots-list__button"
                            :key="slot.dateFormated"
                            :style="{
                              'margin-top': index !== 0 ? `${SLOT_MARGIN_TOP}px` : 0,
                              height: `${SLOT_HEIGHT}px`,
                              'min-height': `${SLOT_HEIGHT}px`
                            }"
                            @click="selectSlot(slot)"
                          >
                            {{ displaySlotTime(slot) }}
                          </div>
                        </template>

                        <div
                          v-else
                          class="pathadvice-calendar-widget__day__slots-list__button pathadvice-calendar-widget__day__slots-list__button-full-size"
                          :style="{
                            'min-height': heigthOfNoSlotBlock
                          }"
                        >
                          {{ $tp('buttonAndHints.noFreeSlotAvailable') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <div v-else class="pathadvice__contact-form__main-container__form__title pathadvice__contact-form__main-container__form__loading">
              {{ $tp('buttonAndHints.loading') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <powered-by />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'underscore'

import SelectTimezone from '@/components/SelectTimezone'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
const isBetween = require('dayjs/plugin/isBetween')
const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

import CloseIcon from './icons/CloseIcon'
import PoweredBy from '@/components/dialog/PoweredBy.vue'
import UserIcon from './icons/UserIcon'
import MailIcon from './icons/MailIcon'
import VerticalPhoneIcon from './icons/VerticalPhoneIcon'
import MessageSquareIcon from './icons/MessageSquareIcon'
import CalendarIcon from './icons/CalendarIcon'
import ArrowIcon from './icons/ArrowIcon'

export default {
  name: 'VisitorContactForm',

  components: {
    SelectTimezone,
    PoweredBy,
    CloseIcon,
    UserIcon,
    MailIcon,
    VerticalPhoneIcon,
    MessageSquareIcon,
    CalendarIcon,
    ArrowIcon
  },

  props: {
    contactForm: {
      type: Object,
      required: true
    },

    trackingData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    EU_TIMEZONES: require('@/assets/eutimezones.json'),
    SLOT_MARGIN_TOP: 10,
    SLOT_HEIGHT: 35,

    visitorContactsTrackingId: null,
    duration: '',
    leadTime: '',

    name: '',
    email: '',
    phone: '',
    date: '',
    message: '',
    agentId: '',

    isBookMeetingForm: false,
    isOpenedCalendarWidget: false,
    isLoadingCalendarWidget: false,
    isLoadingSubmit: false,
    hasAgreedToPrivacy: false,

    selectedWeekDays: [],
    currentDay: null,

    groups: [],
    timeslotsAgentsByWeekNumber: [],
    selectedSlot: null,
    selectedTimezone: null,
    is24HourFormat: true,
    widgetBodyKey: Math.random().toString(36).substring(2, 15)
  }),

  computed: {
    ...mapGetters({
      isMobile: 'campaign/isMobile',
      visitor: 'visitor',
      visitorId: 'visitorId'
    }),

    selectedTimezoneString() {
      return this.selectedTimezone && this.selectedTimezone.name ? this.selectedTimezone.name : dayjs.tz.guess()
    },
    timezoneFormat() {
      return this.is24HourFormat ? 'HH:mm' : 'hh:mm A'
    },

    isCorrectTrackingData() {
      return !!(this.trackingData && this.trackingData.companyId && this.trackingData.campaignId)
    },

    contactFormStyle() {
      return {
        background: this.contactForm.backgroundColor,
        color: this.contactForm.fontColor
      }
    },

    darkСontactFormSendButtonBackgroundColor() {
      return this.lightenDarkenColor(this.contactForm.backgroundColor, -40)
    },

    isPrevButtonDisabled() {
      return this.selectedWeekDays.find((day) => !day.m.isAfter(dayjs(new Date())))
    },

    isNextButtonDisabled() {
      const nextMonthDate = dayjs(new Date()).add(1, 'M')

      return this.selectedWeekDays.find((day) => day.m.isAfter(nextMonthDate))
    },

    countOfLargerSelectedWeek() {
      let countOfItems = 0

      this.selectedWeekDays.forEach((weekday) => {
        if (weekday.times.length > countOfItems) {
          countOfItems = weekday.times.length
        }
      })

      return countOfItems
    },

    heigthOfNoSlotBlock() {
      if (this.countOfLargerSelectedWeek) {
        const countHeightOfItems = this.countOfLargerSelectedWeek * this.SLOT_HEIGHT
        const countMarginOfItems = (this.countOfLargerSelectedWeek - 1) * this.SLOT_MARGIN_TOP

        return `${countHeightOfItems}${countMarginOfItems}px}`
      }

      return '100%'
    }
  },

  beforeMount() {
    this.is24HourFormat = this.EU_TIMEZONES.includes(dayjs.tz.guess())
  },

  async mounted() {
    if (!this.isCorrectTrackingData) {
      return
    }

    const loader = this.$loading.show()

    this.setDataFromLeadForm()

    await this.fetchAppointmentData()

    const tracking = {
      ...this.trackingData,
      type: 'display',
      created: new Date().getTime()
    }

    await this.sendVisitorContactsTrackingData(tracking)
    this.$emit('sessionEnd', { callTypeState: 'displayed-contact-form' })

    loader.hide()
  },

  methods: {
    setDataFromLeadForm() {
      if (!(this.visitor && this.visitor.chats && this.visitor.chats.length > 0)) {
        return
      }

      const reversedChats = [].concat(this.visitor.chats).reverse()

      let leadFormData = null

      reversedChats.find((item) => {
        if (!leadFormData && item && item.leadForm && item.leadForm.name && item.leadForm.email) {
          leadFormData = item.leadForm
        }
      })

      if (leadFormData) {
        this.name = leadFormData.name
        this.email = leadFormData.email
      }
    },

    displaySlotTime(slot) {
      if (this.selectedTimezone) {
        return slot.timestamp.utcOffset(this.selectedTimezone.dstOffset).format(this.timezoneFormat)
      }
      return slot.timestamp.format(this.timezoneFormat)
    },
    onSelectedHourFormat(val) {
      this.is24HourFormat = val
      this.widgetBodyKey = Math.random().toString(36).substring(2, 15)
    },
    onTimezoneSelected(timezone) {
      this.selectedTimezone = timezone
      this.widgetBodyKey = Math.random().toString(36).substring(2, 15)
    },

    async fetchAppointmentData() {
      if (this.contactForm.selectedAppointment) {
        const appointmentRef = await this.$db
          .collection('company')
          .doc(this.trackingData.companyId)
          .collection('appointments')
          .doc(this.contactForm.selectedAppointment)
          .get()

        const appointmentData = appointmentRef.data()

        this.duration = appointmentData.duration
        this.leadTime = appointmentData.leadTime
      }
    },

    async submit() {
      const result = await this.$validator.validateAll()

      if (!result || !this.trackingData) {
        return
      }

      const loader = this.$loading.show()

      this.isLoadingSubmit = true

      const data = {
        ...this.trackingData,
        name: this.name,
        email: this.email,
        message: this.message ? this.message.replace(/\n/g, '<br>') : '',
        emailSubject: this.$i18n.t('vue.joinMeeting'),
        locale: this.$i18n.locale,
        created: new Date().getTime(),
        contacted: false,
        status: 'confirmed'
      }

      if (!this.isBookMeetingForm) {
        data.phone = this.phone
      } else {
        if (!this.contactForm.selectedAppointment) {
          loader.hide()

          this.isLoadingSubmit = false

          return
        }

        data.agentId = this.agentId
        // This fix (solution) creates a valid date regardless of Daylight Saving Time and Standard Time.
        data.date = this.selectedSlot.timestamp.tz(this.selectedTimezoneString).utc().unix() * 1000
        // data.date = dayjs.tz(this.selectedSlot.dateFormated, 'YYYY-MM-DD HH:mm', this.selectedTimezoneString).valueOf()
        data.selectedAppointment = this.contactForm.selectedAppointment
      }

      await this.$db.collection('visitors').doc(this.visitorId).set({ isPreparedLeadFormToBeSent: false }, { merge: true })

      if (this.isBookMeetingForm) {
        await this.bookMeeting(data)
        await this.sendContactInfo(data)
        this.$emit('hide-contact-form', 'contact-form-booking-complete')
      } else {
        await this.sendContactInfo(data)

        this.$emit('hide-contact-form', 'contact-form-info-saved')
      }

      this.name = ''
      this.email = ''
      this.phone = ''
      this.date = ''
      this.message = ''

      this.$nextTick(() => {
        this.errors.clear()
        this.$validator.reset()
      })

      loader.hide()

      this.isLoadingSubmit = false
      this.$emit('sessionEnd', { callTypeState: 'contact-form-submitted' })
    },

    async sendVisitorContactsTrackingData(data) {
      try {
        await this.$db.collection('visitor-contacts-tracking').add(data)
      } catch (error) {
        /* eslint-disable no-console */
        console.error(error)
      }
    },

    async sendContactInfo(data) {
      /* if data has agentId and date, then save as appointment, else save as form-saved. Used in big query */
      const type = this.isBookMeetingForm ? 'booked-appointment' : 'form-saved'

      if (this.visitor && this.visitor.score) {
        data.visitorScore = this.visitor.score
      }

      const info = await this.$db.collection('visitor-contacts').add({ ...data, type })

      const tracking = {
        ...this.trackingData,
        type,
        visitorContactId: info.id,
        created: new Date().getTime()
      }

      await this.sendVisitorContactsTrackingData(tracking)
    },

    async bookMeeting(meetingData) {
      try {
        const scheduleAppointment = this.$functions.httpsCallable('scheduleAppointment')
        if (this.selectedSlot && this.selectedSlot.visitorTimezone) {
          meetingData.visitorTimezone = this.selectedTimezone && this.selectedTimezone.name ? this.selectedTimezone.name : this.selectedSlot.visitorTimezone
        }
        if (this.selectedSlot && this.selectedSlot.agentTimezone) {
          meetingData.agentTimezone = this.selectedSlot.agentTimezone
        }
        const language = navigator.language.slice(0, 2) || 'en'
        meetingData.clientBrowserLanguage = language
        const { data: responseScheduleAppointment } = await scheduleAppointment(meetingData)

        const meetingInfo = {
          meetingId: responseScheduleAppointment.id,
          env: process.env.NODE_ENV
        }

        const encryptedMeetingInfo = window.btoa(unescape(encodeURIComponent(JSON.stringify(meetingInfo))))

        await this.$db.collection('meetings').doc(responseScheduleAppointment.id).set({ encryptedMeetingInfo }, { merge: true })

        // const sendAppointmentEmail = this.$functions.httpsCallable('sendAppointmentEmail')

        // await sendAppointmentEmail({
        //   ...responseScheduleAppointment,
        //   encryptedMeetingInfo,
        //   agentTimezone: this.selectedSlot && this.selectedSlot.agentTimezone ? this.selectedSlot.agentTimezone : null,
        //   visitorTimezone: this.selectedSlot && this.selectedSlot.visitorTimezone ? this.selectedSlot.visitorTimezone : null
        // })
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error)
      }
    },

    closeContactForm() {
      this.$emit('close-contact-form')
    },

    async openCalendarWidget() {
      this.isOpenedCalendarWidget = true

      if (!this.selectedWeekDays.length) {
        this.isLoadingCalendarWidget = true

        this.currentDay = dayjs()

        this.getWeekDaysOfWeek(dayjs().startOf('day'))
        await this.getCalenderItems()
        this.addWeekDayAndTimes()

        this.isLoadingCalendarWidget = false
      }
    },

    async selectSlot(slot) {
      if (dayjs().diff(slot.timestamp, 'minute') > 0) {
        return
      }

      this.selectedSlot = slot

      const meetingDate = slot.timestamp
      let meetingDateString = meetingDate.format('LLLL')
      if (this.selectedTimezone) {
        meetingDateString = slot.timestamp.utcOffset(this.selectedTimezone.dstOffset).format('LLLL')
      }
      const agentId = slot.users[Math.floor(Math.random() * slot.users.length)]

      this.agentId = agentId
      this.date = meetingDateString
      this.isOpenedCalendarWidget = false
    },

    async getCalenderItems() {
      if (!this.isCorrectTrackingData || !this.contactForm.selectedAppointment) {
        return
      }

      const data = {
        ...this.trackingData,
        selectedAppointment: this.contactForm.selectedAppointment,
        timezone: dayjs.tz.guess(),
        start: dayjs(this.selectedWeekDays[0].m).format(),
        end: dayjs(this.selectedWeekDays[this.selectedWeekDays.length - 1].m)
          .endOf('day')
          .format()
      }

      let response = null

      try {
        const calendarGetAvailableAgentsTimeslots = this.$functions.httpsCallable('calendarGetAvailableAgentsTimeslots')

        response = await calendarGetAvailableAgentsTimeslots(data)
      } catch {
        return
      }

      if (!response) {
        return
      }

      const modifiedAbilityData = this.modifyAbilityData(response.data)

      const timeslotsAgentsArray = {
        data: modifiedAbilityData
      }

      /* saving this data for caching & faster retrieval */
      const ts = this.timeslotsAgentsByWeekNumber.filter((x) => x.leadDayInArray.toString() === this.currentDay.toString())

      if (ts.length > 0) {
        this.timeslotsAgentsByWeekNumber.map(() => {
          return {
            leadDayInArray: this.currentDay,
            timeslotsAgentsArray
          }
        })
      } else {
        this.timeslotsAgentsByWeekNumber.push({
          leadDayInArray: this.currentDay,
          timeslotsAgentsArray
        })
      }

      await this.calculateTimeSlotsAgents(timeslotsAgentsArray)
    },

    async calculateTimeSlotsAgents(timeslotsAgentsArray) {
      let timeslots = []
      // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
      // note that 59 will round up to 60, and moment.js handles that correctly

      /* default notice period if not saved is 2hrs / 120 minutes */
      const noticePeriodInMinutes = Number(this.leadTime)
      const duration = Number(this.duration)

      for (let i = 0; i < timeslotsAgentsArray.data.length; i++) {
        const agent = timeslotsAgentsArray.data[i]
        const selectedTimezone = agent && agent.timezone && agent.timezone.name ? agent.timezone.name : dayjs.tz.guess()

        for (let n = 0; n < agent.windows.length; n++) {
          const slot = agent.windows[n]
          const startDateWithLeadTime = dayjs(slot.start)

          let start = startDateWithLeadTime
          const end = dayjs(slot.end).add(-duration, 'minute')
          const minuteRounded = (Math.round(start.get('minute') / duration) * duration) % 60

          start = start.minute(minuteRounded)

          let current = start.clone()

          if (start.valueOf() < startDateWithLeadTime.valueOf()) {
            current = current.add(duration, 'minute')
          }

          while (current.valueOf() <= end.valueOf()) {
            const match = _.find(timeslots, (timeslot) => {
              return timeslot.dateFormated === current.format('YYYY-MM-DD HH:mm')
            })

            /* Get day of week of current day. Week should start from Monday. i.e Monday should have zero and Sunday = 6*/
            const dayOfWeek = current.day() - 1 < 0 ? 6 : current.day() - 1

            if (match && agent.availableTimeSlots.length > 0 && agent.availableTimeSlots[dayOfWeek].enabled) {
              /* Add to timeslots array only of agent has availability */
              // const format24hr = 'HH:mm'
              /* Loop through the agents available slots for the week day */
              for (let t = 0; t < agent.availableTimeSlots[dayOfWeek].times.length; t++) {
                const beforeHHmm = agent.availableTimeSlots[dayOfWeek].times[t].start.split(':')
                const afterHHmm = agent.availableTimeSlots[dayOfWeek].times[t].end.split(':')
                const time = current.clone()

                let beforeTime = dayjs(time.format('YYYY-MM-DD')).set('hour', beforeHHmm[0]).set('minute', beforeHHmm[1]),
                  afterTime = dayjs(time.format('YYYY-MM-DD')).set('hour', afterHHmm[0]).set('minute', afterHHmm[1])
                beforeTime = dayjs.tz(beforeTime.format('YYYY-MM-DD HH:mm'), selectedTimezone)
                afterTime = dayjs.tz(afterTime.format('YYYY-MM-DD HH:mm'), selectedTimezone)
                /* add to timeslots only if current time is in between the available time slots */

                if (
                  (time.isBetween(beforeTime, afterTime) || time.isSame(beforeTime)) &&
                  (dayjs(startDateWithLeadTime).isBefore(current) || dayjs(startDateWithLeadTime).isSame(current))
                ) {
                  // const timeslot = {
                  //   users: [agent.userId],
                  //   dateFormated: time.format('YYYY-MM-DD HH:mm'),
                  //   timestamp: time.clone(),
                  //   agentTimezone: selectedTimezone,
                  //   visitorTimezone: dayjs.tz.guess()
                  // }
                  // timeslots.push(timeslot)
                  match.users.push(agent.userId)
                  break
                }
              }
            } else {
              /* Check if todays events has atleast notice period time avaiable before it starts */
              /* Ex: if the notice period in minutes is 180, then make sure that if there are any events today, it has atleast 180 minutes left to start  */
              if (dayjs().format('YYYY-MM-DD') === current.format('YYYY-MM-DD')) {
                const minimumTime = dayjs().add(noticePeriodInMinutes, 'minute')
                const differenceTime = current.diff(minimumTime, 'minute')

                if (differenceTime <= 0) {
                  current = current.add(duration, 'minute')

                  continue
                }
              }

              /* Get day of week of current day. Week should start from Monday. i.e Monday should have zero and Sunday = 6*/
              const dayOfWeek = current.day() - 1 < 0 ? 6 : current.day() - 1

              /* Add to timeslots array only of agent has availability */
              if (agent.availableTimeSlots !== undefined && agent.availableTimeSlots.length > 0 && agent.availableTimeSlots[dayOfWeek].enabled) {
                //const format24hr = 'HH:mm'

                /* Loop through the agents available slots for the week day */
                for (let t = 0; t < agent.availableTimeSlots[dayOfWeek].times.length; t++) {
                  const beforeHHmm = agent.availableTimeSlots[dayOfWeek].times[t].start.split(':')
                  const afterHHmm = agent.availableTimeSlots[dayOfWeek].times[t].end.split(':')

                  const time = current.clone()
                  let beforeTime = dayjs(time.format('YYYY-MM-DD')).set('hour', beforeHHmm[0]).set('minute', beforeHHmm[1]),
                    afterTime = dayjs(time.format('YYYY-MM-DD')).set('hour', afterHHmm[0]).set('minute', afterHHmm[1])

                  beforeTime = dayjs.tz(beforeTime.format('YYYY-MM-DD HH:mm'), selectedTimezone).tz(dayjs.tz.guess())
                  afterTime = dayjs.tz(afterTime.format('YYYY-MM-DD HH:mm'), selectedTimezone).tz(dayjs.tz.guess())

                  /* add to timeslots only if current time is in between the available time slots */
                  if (
                    (time.isBetween(beforeTime, afterTime) || time.isSame(beforeTime)) &&
                    (dayjs(startDateWithLeadTime).isBefore(current) || dayjs(startDateWithLeadTime).isSame(current))
                  ) {
                    const timeslot = {
                      users: [agent.userId],
                      dateFormated: time.format('YYYY-MM-DD HH:mm'),
                      timestamp: time.clone(),
                      agentTimezone: selectedTimezone,
                      visitorTimezone: this.selectedTimezone && this.selectedTimezone.name ? this.selectedTimezone.name : dayjs.tz.guess()
                    }

                    timeslots.push(timeslot)

                    break
                  }
                }
              }
            }

            current = current.add(duration, 'minute')
          }
        }
      }

      const occurrenceDay = (timeslot) => {
        return dayjs(timeslot.timestamp).startOf('day').format()
      }

      const groupToDay = (group, day) => {
        return {
          day: dayjs(day).format('YYYY-MM-DD'),
          times: group,
          timestamp: day,
          active: false
        }
      }

      timeslots = timeslots.sort((a, b) => a.timestamp.unix() - b.timestamp.unix())

      this.groups = _.chain(timeslots).groupBy(occurrenceDay).map(groupToDay).sortBy('timestamp').value()

      this.$forceUpdate()
    },

    getWeekDaysOfWeek(day) {
      this.currentDay = day
      this.selectedWeekDays = []

      for (let i = 0; i <= 2; i++) {
        this.selectedWeekDays.push({
          m: dayjs(this.currentDay).add(i, 'days').locale(this.$i18n.locale).startOf('day'),
          times: []
        })
      }
    },

    async previousWeek() {
      this.isLoadingCalendarWidget = true

      this.getWeekDaysOfWeek(dayjs(this.selectedWeekDays[0].m).add(-3, 'days').startOf('day'))

      if (this.timeslotsAgentsByWeekNumber.filter((x) => x.leadDayInArray.toString() === this.currentDay.toString()).length > 0) {
        const tsa = this.timeslotsAgentsByWeekNumber.filter((x) => x.leadDayInArray.toString() === this.currentDay.toString())[0]

        await this.calculateTimeSlotsAgents(tsa.timeslotsAgentsArray)
        this.addWeekDayAndTimes()

        this.isLoadingCalendarWidget = false
      } else {
        await this.getCalenderItems()
        this.addWeekDayAndTimes()

        this.isLoadingCalendarWidget = false
      }
    },

    async nextWeek() {
      this.isLoadingCalendarWidget = true

      this.getWeekDaysOfWeek(
        dayjs(this.selectedWeekDays[this.selectedWeekDays.length - 1].m)
          .add(1, 'days')
          .startOf('day')
      )

      if (this.timeslotsAgentsByWeekNumber.filter((x) => x.leadDayInArray.toString() === this.currentDay.toString()).length > 0) {
        const tsa = this.timeslotsAgentsByWeekNumber.filter((x) => x.leadDayInArray.toString() === this.currentDay.toString())[0]

        await this.calculateTimeSlotsAgents(tsa.timeslotsAgentsArray)
        this.addWeekDayAndTimes()

        this.isLoadingCalendarWidget = false
      } else {
        await this.getCalenderItems()
        this.addWeekDayAndTimes()

        this.isLoadingCalendarWidget = false
      }
    },

    addWeekDayAndTimes() {
      this.selectedWeekDays.filter((weekday) => {
        const day = weekday.m.format('YYYY-MM-DD')
        weekday.day = day
        /* check if day exists in groups */
        const group = this.groups.filter((group) => group.day === day)

        if (group && group.length > 0) {
          weekday.times = group[0].times
        }
      })
    },

    modifyAbilityData(data) {
      if (!data) return []

      const modifiedData = data.map((item) => {
        const modifiedWindows = item.windows.map((dateValues) => {
          const copyDateValues = { ...dateValues }

          Object.keys(copyDateValues).forEach((key) => {
            copyDateValues[key] = this.modifyDate(copyDateValues[key])
          })

          return copyDateValues
        })

        const copyItem = { ...item }

        copyItem.windows = modifiedWindows

        return copyItem
      })

      return modifiedData
    },

    modifyDate(date) {
      if (!date) return null

      const utcDate = dayjs(date).format()
      const utcDateString = new Date(utcDate).toString()
      const localDate = dayjs.utc(new Date(utcDateString)).local().format()

      return localDate
    },

    lightenDarkenColor(color, percent) {
      if (color[0] === '#') {
        color = color.slice(1)
      }

      const num = parseInt(color, 16)
      const amt = Math.round(2.55 * percent)
      const R = (num >> 16) + amt
      const B = ((num >> 8) & 0x00ff) + amt
      const G = (num & 0x0000ff) + amt
      /* eslint-disable no-mixed-operators */
      return `#${(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255))
        .toString(16)
        .slice(1)}`
    }
  },

  filters: {
    modifyInputPlaceholder(string, isRequired) {
      return isRequired ? `${string}*` : string
    }
  }
}
</script>

<style lang="scss">
button {
  font-family: inherit;
}

.pathadvice {
  &__button {
    &-action {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      color: var(--contact-form-font-color);
      font-weight: 700;
      background: var(--contact-form-darken-background);
      border-radius: 6px;
      border: none;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
        transition: all 0.2s;
      }
    }

    &-back {
      display: flex;
      align-items: center;
      position: absolute;
      top: 24px;
      left: 10px;
      cursor: pointer;
      z-index: 2;
      color: inherit;
      font-size: 15px;
      font-weight: 600;
      line-height: 1;
      background: none;
      border: none;

      &:hover {
        opacity: 0.8;
        transition: all 0.2s;
      }
    }
  }

  &__contact-form {
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
    height: 100%;

    &__privacy-policy {
      display: flex !important;
      border: none;
      &__checkbox {
        margin-left: 0;
        input:checked + .vs-checkbox {
          border-width: 2px !important;
          border-style: solid !important;
          border-color: var(--contact-form-font-color) !important;
          background: var(--contact-form-darken-background) !important;
        }
      }
    }

    &__main-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      height: calc(100% - 26px);
      background-size: 1200px 790px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('/assets/images/visitor/MicrosoftTeams-image.jpg');
      @media (max-width: 1023px) {
        height: 100%;
      }

      &__actions {
        position: absolute;
        top: 18px;
        right: 11px;
        z-index: 2;
      }

      &__form {
        display: flex;
        flex-direction: column;
        position: relative;
        max-height: 100%;
        height: 100%;
        overflow-y: auto;
        scrollbar-width: thin;
        justify-content: center;
        margin: 0 auto;
        &--holder {
          width: 100%;
          max-width: 650px;
          margin: 0 auto;
        }
        @media (max-width: 767px) {
          padding: 15px;
        }

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          background: rgba(230, 236, 241, 0.5);
        }

        &::-webkit-scrollbar-thumb:vertical {
          background-color: var(--contact-form-dark-background);
          border-radius: 6px;
        }
        &__fields-holder {
          display: flex;
          @media (max-width: 767px) {
            flex-direction: column;
          }
          .pathadvice__contact-form__main-container__form__field {
            &._textarea-field {
              width: 100%;
              max-width: 320px;
              margin-top: 0;
              textarea {
                height: 100%;
              }
              @media (max-width: 767px) {
                max-width: 767px;
                margin-top: 10px;
              }
            }
          }
          &--left {
            width: 100%;
            max-width: 320px;
            margin-right: 10px;
            @media (max-width: 767px) {
              margin-right: 0;
              max-width: 767px;
            }
          }
        }
        &__title {
          margin-bottom: 30px;
          font-size: 24px;
          font-weight: 700;
          text-align: center;
        }

        &__loading {
          margin: auto 0;
          padding-bottom: 40px;
        }

        &__wording {
          margin-bottom: 30px;
          font-weight: 500;
          font-size: 15px;
          line-height: 19px;
        }

        &__checkbox {
          margin: 0 9px;

          &.vs-switch {
            &-active {
              background: var(--contact-form-darken-background);
            }
          }

          &-wrapper {
            margin-bottom: 20px;
          }

          &-label {
            color: inherit;
            font-size: 14px;
            font-weight: 600;

            &.text-opacity {
              color: rgba(var(--contact-form-font-color), 0.7);
            }
          }
        }

        &__field {
          display: flex;
          flex-direction: column;
          position: relative;
          margin-top: 10px;
          width: 100%;
          &._privacy {
            margin-top: 20px;
          }

          &:first-child {
            margin-top: 0;
          }

          &_invalid {
            border-radius: 6px;

            .pathadvice__contact-form__main-container__form {
              &__input {
                border-color: rgb(240, 85, 65);
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
          }

          &__error {
            padding: 0 14px;
            font-size: 13px;
            line-height: 16px;
            background: rgb(240, 85, 65);
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }

          &__error-checkbox {
            padding: 0 14px;
            font-size: 13px;
            line-height: 16px;
            background: rgb(240, 85, 65);
          }

          &__icon {
            position: absolute;
            top: 16px;
            left: 15px;
            width: 15px;
            height: 15px;
            color: #262629;

            &-action {
              position: absolute;
              top: 17px;
              right: 16px;
            }
          }
        }

        &__input {
          padding: 11px 11px 11px 43px;
          width: 100%;
          color: #262629;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          background: rgba(230, 236, 241, 0.6);
          border: 2px solid transparent;
          border-radius: 6px;
          outline: none;
          font-family: 'Lato', sans-serif;

          &::placeholder {
            color: #262629;
            font-family: 'Lato', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            transition: background-color 5000s;
            -webkit-text-fill-color: var(--contact-form-darken-background) !important;
          }
        }

        &__textarea {
          height: 100px;
          outline: none;
          resize: none;
          overflow: auto;
          scrollbar-width: thin;

          &::-webkit-scrollbar {
            width: 3px;
          }

          &::-webkit-scrollbar-thumb:vertical {
            background-color: var(--contact-form-darken-background);
            border-radius: 6px;
          }
        }

        &__footer {
          margin: 30px auto 10px auto;
          font-size: 14px;
          max-width: 320px;
          width: 100%;
        }

        &__actions {
          &__btn {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 10px;
            color: inherit;
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            text-align: center;
            background: var(--contact-form-darken-background);
            border-radius: 6px;
            border: none;
            outline: none;
            cursor: pointer;

            &_transparent {
              background: transparent;
              box-shadow: unset;
            }
          }
        }

        &-wrapper {
          position: relative;
          max-height: 100%;
          background: rgb(59, 134, 247);
        }

        &-widget {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        &_hidden {
          opacity: 0;
          visibility: hidden;
          overflow: hidden;
        }
      }
    }

    &_desktop {
      .pathadvice {
        &__button-back {
          left: 11px;
          top: 18px;
        }
        &__contact-form {
          &__main-container {
            padding: 0;
            &__form {
              &__title {
                margin-bottom: 0;
              }
            }
          }
        }

        &-calendar-widget {
          padding-top: 28px;
          &__inner {
            padding: 0 90px;
          }
          &__button {
            &-slide {
              top: unset;
            }
          }
          &-wrapper {
            padding-top: 158px;
          }
          &__heading {
            padding: 0 30px;
          }
          &__body {
            padding: 0 30px;
          }
        }
      }
    }
  }

  &-calendar-widget {
    display: flex;
    padding-top: 50px;
    width: 100%;
    height: calc(100% - 35px);
    overflow-y: auto;

    &-wrapper {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 65px 11px 20px;
      width: 100%;
      max-width: 650px;
      height: 100%;
      background: var(--contact-form-background);
      border-radius: 6px;
    }

    &__button {
      &-slide {
        position: absolute;
        top: 132px;
        z-index: 2;

        &-prev {
          left: 11px;
        }

        &-next {
          right: 11px;
        }
      }
    }

    &__inner {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
    }

    &__heading {
      display: flex;
      justify-content: space-between;
      position: relative;
      padding: 0;
    }

    &__body {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      margin-top: 25px;
      padding: 0;
      overflow-y: auto;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb:vertical {
        background-color: var(--contact-form-darken-background);
        border-radius: 6px;
      }
    }

    &__day {
      width: calc(33.33% - 20px / 3);

      &__heading {
        text-align: center;

        &__date {
          color: var(--contact-form-font-color);
          font-size: 15px;
          font-weight: 600;
        }

        &__day {
          color: var(--contact-form-dark-background);
          font-size: 12px;
          font-weight: 500;
        }
      }

      &__slots-list {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          color: var(--contact-form-font-color);
          font-size: 14px;
          line-height: 1;
          font-weight: 500;
          text-align: center;
          background: rgba(230, 236, 241, 0.6);
          border-radius: 6px;
          overflow: hidden;
          cursor: pointer;

          &:hover {
            background: rgba(230, 236, 241, 0.8);
            transition: all 0.2s;
          }

          &:first-child {
            margin-top: 0;
          }

          &-full-size {
            align-items: flex-start;
            height: 100%;
            cursor: default;

            &:hover {
              background: rgba(230, 236, 241, 0.6);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 376px) {
    top: 0;
    right: 0;
  }

  @media only screen and (max-width: 769px) {
    width: 375px;
  }
}
</style>
