var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.viewTypeValue === _vm.VIEW_TYPE_NAME,
          expression: "viewTypeValue === VIEW_TYPE_NAME",
        },
      ],
      staticClass: "pathadvice__chat__selection",
      style: {
        "background-image": _vm.dialog.showVideo ? "none" : _vm.backgroundImage,
      },
    },
    [
      _c(
        "div",
        { staticClass: "pathadvice__chat__selection--faded-background" },
        [
          _c(
            "div",
            {
              staticClass: "pathadvice__chat__selection__main-container",
              class: {
                "pathadvice__chat__selection__main-container_no-more-info":
                  !_vm.dialog.isMoreInformationEnabled,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "pathadvice__chat__selection__main-container__wording",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "pathadvice__chat__selection__main-container__wording__main",
                    },
                    [
                      _c("span", {
                        style: {
                          color: _vm.dialog.step2TextColor
                            ? _vm.dialog.step2TextColor
                            : "#fff",
                        },
                        domProps: { innerHTML: _vm._s(_vm.mainWording) },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "pathadvice__chat__selection__main-container__actions",
                  class: {
                    "pathadvice__chat__selection__main-container__actions_desktop":
                      !_vm.isMobile,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "pathadvice__chat__selection__main-container__actions__btns",
                    },
                    [
                      _vm.SHOW_CHAT_BUTTON
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "pathadvice__chat__selection__main-container__actions__btn",
                              style: { background: _vm.mainColour },
                              on: {
                                click: function ($event) {
                                  return _vm.selectOption("chat")
                                },
                              },
                            },
                            [
                              _c("chat-icon", {
                                staticClass:
                                  "pathadvice__chat__selection__main-container__actions__btn__icon",
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pathadvice__chat__selection__main-container__actions__btn__wording",
                                },
                                [_vm._v(_vm._s(_vm.$tp("buttonAndHints.chat")))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.SHOW_AUDIO_BUTTON
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "pathadvice__chat__selection__main-container__actions__btn",
                              class: {
                                "pathadvice__chat__selection__main-container__actions__btn--disabled":
                                  _vm.isAudioDisabled,
                              },
                              style: { background: _vm.mainAudioColour },
                              on: {
                                click: function ($event) {
                                  return _vm.selectOption("audio")
                                },
                              },
                            },
                            [
                              _c("vertical-phone-icon", {
                                staticClass:
                                  "pathadvice__chat__selection__main-container__actions__btn__icon",
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pathadvice__chat__selection__main-container__actions__btn__wording",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$tp("buttonAndHints.phone"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.SHOW_VIDEO_BUTTON
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "pathadvice__chat__selection__main-container__actions__btn",
                              class: {
                                "pathadvice__chat__selection__main-container__actions__btn--disabled":
                                  _vm.isVideoDisabled,
                              },
                              style: { background: _vm.mainVideoColour },
                              on: {
                                click: function ($event) {
                                  return _vm.selectOption("video")
                                },
                              },
                            },
                            [
                              _c("video-call-icon", {
                                staticClass:
                                  "pathadvice__chat__selection__main-container__actions__btn__icon",
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pathadvice__chat__selection__main-container__actions__btn__wording",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$tp("buttonAndHints.video"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _vm.dialog.isMoreInformationEnabled
                ? _c(
                    "div",
                    {
                      staticClass:
                        "pathadvice__chat__selection__main-container__more-information",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "pathadvice__chat__selection__main-container__more-information__btn",
                          style: {
                            color: _vm.mainColour,
                            "border-color": _vm.mainColour,
                          },
                          on: { click: _vm.redirectByLink },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.dialog.moreInformationButtonText ||
                                  _vm.$tp("buttonAndHints.moreInformation")
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _c("banner", {
        attrs: {
          height: "36px",
          isMinimized: !_vm.isMobile,
          logoImage: _vm.logoImage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }