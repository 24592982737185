var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    staticClass: "co-browsing-iframe",
    class: {
      "co-browsing-iframe_mobile": _vm.isMobile,
    },
    attrs: { allowfullscreen: "", frameborder: "0", src: _vm.surflyURL },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }