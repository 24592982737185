<template>
  <div
    v-show="viewTypeValue === VIEW_TYPE_NAME"
    class="pathadvice__chat__selection"
    :style="{ 'background-image': dialog.showVideo ? 'none' : backgroundImage }"
  >
    <div class="pathadvice__chat__selection--faded-background">
      <!-- <div class="pathadvice__chat__selection__close">
        <button class="pathadvice__chat__selection__close__btn" @click="closeChat" :style="{ background: mainColour }">
          <close-icon :width="25" :height="25" />
        </button>
      </div> -->

      <div
        class="pathadvice__chat__selection__main-container"
        :class="{
          'pathadvice__chat__selection__main-container_no-more-info': !dialog.isMoreInformationEnabled
        }"
      >
        <div class="pathadvice__chat__selection__main-container__wording">
          <div class="pathadvice__chat__selection__main-container__wording__main">
            <span v-html="mainWording" :style="{ color: dialog.step2TextColor ? dialog.step2TextColor : '#fff' }"></span>
          </div>
        </div>

        <div
          class="pathadvice__chat__selection__main-container__actions"
          :class="{
            'pathadvice__chat__selection__main-container__actions_desktop': !isMobile
          }"
        >
          <div class="pathadvice__chat__selection__main-container__actions__btns">
            <div
              v-if="SHOW_CHAT_BUTTON"
              class="pathadvice__chat__selection__main-container__actions__btn"
              :style="{ background: mainColour }"
              @click="selectOption('chat')"
            >
              <chat-icon class="pathadvice__chat__selection__main-container__actions__btn__icon" />
              <div class="pathadvice__chat__selection__main-container__actions__btn__wording">{{ $tp('buttonAndHints.chat') }}</div>
            </div>

            <div
              v-if="SHOW_AUDIO_BUTTON"
              class="pathadvice__chat__selection__main-container__actions__btn"
              :class="{ 'pathadvice__chat__selection__main-container__actions__btn--disabled': isAudioDisabled }"
              :style="{ background: mainAudioColour }"
              @click="selectOption('audio')"
            >
              <vertical-phone-icon class="pathadvice__chat__selection__main-container__actions__btn__icon" />
              <div class="pathadvice__chat__selection__main-container__actions__btn__wording">{{ $tp('buttonAndHints.phone') }}</div>
            </div>

            <div
              v-if="SHOW_VIDEO_BUTTON"
              class="pathadvice__chat__selection__main-container__actions__btn"
              :class="{ 'pathadvice__chat__selection__main-container__actions__btn--disabled': isVideoDisabled }"
              :style="{ background: mainVideoColour }"
              @click="selectOption('video')"
            >
              <video-call-icon class="pathadvice__chat__selection__main-container__actions__btn__icon" />
              <div class="pathadvice__chat__selection__main-container__actions__btn__wording">{{ $tp('buttonAndHints.video') }}</div>
            </div>
          </div>
        </div>

        <div v-if="dialog.isMoreInformationEnabled" class="pathadvice__chat__selection__main-container__more-information">
          <button
            class="pathadvice__chat__selection__main-container__more-information__btn"
            :style="{ color: mainColour, 'border-color': mainColour }"
            @click="redirectByLink"
          >
            {{ dialog.moreInformationButtonText || $tp('buttonAndHints.moreInformation') }}
          </button>
        </div>
      </div>
    </div>

    <banner height="36px" :isMinimized="!isMobile" :logoImage="logoImage" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChatIcon from './icons/ChatIcon.vue'
import VideoCallIcon from './icons/VideoCallIcon.vue'
import VerticalPhoneIcon from './icons/VerticalPhoneIcon.vue'
// import CloseIcon from './icons/CloseIcon.vue'
import Banner from './Banner'

export default {
  name: 'ChatSelection',

  components: {
    Banner,
    ChatIcon,
    VideoCallIcon,
    // CloseIcon,
    VerticalPhoneIcon
  },

  props: {
    logoImage: {
      type: String
    }
  },

  data: () => ({
    VIEW_TYPE_NAME: 'chat-selection',
    webm: 'video/webm',
    mp4: 'video/mp4'
  }),

  computed: {
    ...mapGetters({
      viewType: 'campaign/viewType',
      dialog: 'campaign/dialog',
      isMobile: 'campaign/isMobile',
      company: 'company',
      visitor: 'visitor'
    }),
    SHOW_CHAT_BUTTON() {
      if (!this.dialog.communicationOptions) return true

      return this.dialog.communicationOptions.chat
    },

    SHOW_AUDIO_BUTTON() {
      if (!this.dialog.communicationOptions) return true

      return this.dialog.communicationOptions.audio
    },

    SHOW_VIDEO_BUTTON() {
      if (!this.dialog.communicationOptions) return true

      return this.dialog.communicationOptions.video
    },
    isAudioDisabled() {
      return this.company && this.company.disableAudio
    },
    isVideoDisabled() {
      return this.company && this.company.disableVideo
    },

    mainColour() {
      return this.dialog ? this.dialog.step2ButtonColor : '#12598d'
    },

    mainAudioColour() {
      if (this.isAudioDisabled) return '#d3d3d3'
      return this.dialog ? this.dialog.step2ButtonColor : '#12598d'
    },
    mainVideoColour() {
      if (this.isVideoDisabled) return '#d3d3d3'
      return this.dialog ? this.dialog.step2ButtonColor : '#12598d'
    },

    backgroundImage() {
      return this.dialog && this.dialog.imageURL ? `url(${this.dialog.imageURL})` : 'url(/embed/img/MicrosoftTeams-image.9cd90357.jpg)'
    },

    mainWording() {
      return this.dialog ? this.dialog.textStep2 : ''
    },

    viewTypeValue() {
      if (this.viewType === this.VIEW_TYPE_NAME) {
        this.$emit('performResize', true)
      }

      return this.viewType
    }
  },

  methods: {
    selectOption(type) {
      if (this.isVideoDisabled && type === 'video') return
      if (this.isAudioDisabled && type === 'audio') return

      try {
        const custom_event = new CustomEvent('custom_gtm_events')
        custom_event.info = {
          name: 'gtm.click',
          gtm: {
            uniqueEventId:
              Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            start: new Date().getTime(),
            location: window.location.href,
            event_type: type
          }
        }
        window.dispatchEvent(custom_event)

        if (this.dialog && this.dialog.isShowLeadFormBeforeConversation && !this.visitor.visitorDisplayName) {
          this.$emit('proceedToLeadForm', type)
        } else {
          this.$emit('optionSelected', type)
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
      }
    },

    // closeChat() {
    //   this.$emit('sessionEnd')
    // },

    redirectByLink() {
      window.open(this.dialog.moreInformationLink || '', '_blank')
    }
  }
}
</script>

<style lang="scss">
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}
.ql-size-small {
  font-size: 0.75em;
}
</style>

<style lang="scss" scoped>
.pathadvice {
  &__chat__selection {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: 100%;
    width: 100%;
    background-image: url('`url(/embed/img/MicrosoftTeams-image.9cd90357.jpg)`/assets/img/MicrosoftTeams-image.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(230, 236, 241);
    background-position: center center;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      z-index: 2;
    }

    &__video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
      z-index: 0;
      border-radius: 6px;
    }

    &--faded-background {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.178125) 48.44%, rgba(0, 0, 0, 0.6) 100%);
      z-index: 3;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 767px) {
        justify-content: flex-end;
      }
    }
    &__main-container {
      max-width: 444px;
      margin-left: 80px;
      @media (max-width: 767px) {
        max-width: 100%;
        margin-left: 0;
      }
    }
    &__close {
      position: absolute;
      top: 15px;
      right: 11px;

      &__btn {
        width: 25px;
        height: 25px;
        background: #12598d;
        border-radius: 6px;
        border: none;
        color: #fff;
        font-weight: 700;
        cursor: pointer;
      }
    }

    &__main-container {
      &_no-more-info {
        padding-bottom: 72px;
      }

      &__actions {
        margin-top: 40px;
        &__btns {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0 17px;
        }

        &__btn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin: 0px 10px;
          height: 100px;
          text-align: center;
          background: #3b86f7;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
          border-radius: 13px;
          cursor: pointer;

          &--disabled {
            cursor: default;
          }

          &__wording {
            margin-top: 16px;

            color: #fff;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            @media (max-width: 767px) {
              font-size: 14px;
            }
          }
        }

        &_desktop {
          .pathadvice__chat__selection__main-container__actions {
            &__btn {
              width: 100%;
              margin: 0px 10px;
              height: 120px;
              @media (max-width: 767px) {
                //width: 100px;
                height: 100px;
              }
              @media (max-width: 374px) {
                //width: 75px;
                height: 75px;
              }
            }
          }
        }
      }

      &__wording {
        color: #fff;
        padding: 0 20px;

        &__header {
          font-weight: bold;
          font-size: 18px;
          line-height: 23px;
          margin-bottom: 10px;
        }

        &__main {
          margin-bottom: 25px;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.2;
        }
      }

      &__more-information {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        padding: 20px;
        margin-bottom: 70px;

        &__btn {
          width: 100%;
          height: 35px;
          border: 1.5px solid #3b86f7;
          box-sizing: border-box;
          border-radius: 6px;
          background: transparent;
          color: #3b86f7;

          cursor: pointer;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;
        }
      }
    }
  }

  @media only screen and (max-width: 376px) {
    top: 0;
    right: 0;
  }
}
</style>
