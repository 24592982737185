<template>
  <div
    class="call-settings"
    :class="{
      'call-settings_sized': audioOutputOptions.length < 1,
      '_with-image': !callStarted && !isCobrowse
    }"
    :style="!callStarted && !isCobrowse && callSettingsStyles"
  >
    <dialog-video
      v-show="displayDialogVideoInAVSettings && embedVideoURL"
      :embedVideoURL="embedVideoURL"
      :class="`video-item-${viewType}`"
      style="z-index: -1"
    />
    <div class="call-settings__actions">
      <button class="call-settings__actions__close" :style="{ background: mainColor }" @click="closeSettings">
        <close-icon :width="25" :height="25" />
      </button>
    </div>

    <div class="call-settings__permissions" v-show="['permissions', 'permissions-audio'].includes(viewType)">
      <div class="call-settings__configurations__header _mobile-header">
        {{ viewType === 'permissions' ? $tp('buttonAndHints.videoAndAudio') : $tp('buttonAndHints.audioSettings') }}
      </div>
      <div class="call-settings__configurations__preview">
        <div class="call-settings__configurations__preview__img">
          <div class="call-settings__configurations__preview__img__container">
            <settings-video-cam-off-icon
              v-if="viewType === 'permissions'"
              color="#ffffff"
              class="call-settings__configurations__preview__img__container__icon"
              style="position: absolute"
              :width="186"
              :height="186"
            />

            <microphone-off-icon
              v-else
              color="#ffffff"
              class="call-settings__configurations__preview__img__container__icon"
              style="position: absolute"
              :width="186"
              :height="186"
            />

            <div class="call-settings__configurations__preview__img__container-message" style="z-index: 1">
              {{ viewType === 'permissions' ? $tp('buttonAndHints.cameraAndMicAreNotActive') : $tp('buttonAndHints.micIsNotActive') }}
            </div>
          </div>
        </div>
      </div>
      <div class="call-settings__permissions__info">
        <div class="call-settings__configurations__header _desktop-header">
          {{ viewType === 'permissions' ? $tp('buttonAndHints.videoAndAudio') : $tp('buttonAndHints.audioSettings') }}
        </div>
        <div class="call-settings__permissions__wording">
          <span class="call-settings__permissions__wording-span">{{
            viewType === 'permissions' ? $tp('buttonAndHints.permissionsWording1') : $tp('buttonAndHints.permissionsWording3')
          }}</span>
          <span class="call-settings__permissions__wording-span">{{
            viewType === 'permissions' ? $tp('buttonAndHints.permissionsWording2') : $tp('buttonAndHints.permissionsWording4')
          }}</span>
        </div>

        <div class="call-settings__permissions__actions">
          <button class="call-settings__permissions__actions__btn" :style="{ background: mainColor }" @click="requestPermissions">
            {{ $tp('buttonAndHints.requestPermissions') }}
          </button>
        </div>
      </div>
    </div>

    <div class="call-settings__configurations" v-show="viewType === 'configure'">
      <div class="call-settings__configurations__setup">
        <div class="call-settings__configurations__preview">
          <div class="call-settings__configurations__preview__img">
            <video
              class="call-settings__configurations__preview__img__video"
              ref="localSettingsVideo"
              id="local-settings-video"
              v-show="isCameraOn"
              autoplay
              playsinline
              muted="muted"
            />
            <div class="call-settings__configurations__preview__img__container" v-if="!isCameraOn">
              <settings-video-cam-off-icon
                color="#ffffff"
                class="call-settings__configurations__preview__img__container__icon"
                style="position: absolute"
                :width="186"
                :height="186"
              />
            </div>
          </div>
          <!-- Disable Virtual Background Blur for now -->
          <div v-if="false" class="call-settings__configurations__preview__img-description">
            <div class="call-settings__configurations__preview__img-description__wording">
              <magic-icon class="call-settings__configurations__preview__img-description__icon" color="#12598D" :width="16" :height="16"></magic-icon>
              {{ $tp('buttonAndHints.blurBG') }}
            </div>
            <vs-switch color="#275D73" class="call-settings__configurations__preview__img-description__toggle" v-model="blurBackground" />
          </div>
        </div>
        <div
          class="call-settings__configurations__holder"
          :style="{
            '--main-background': mainColor
          }"
        >
          <div class="call-settings__configurations__header">{{ $tp('buttonAndHints.videoAndAudio') }}</div>
          <div class="call-settings__configurations__device-selection">
            <div class="call-settings__configurations__device-selection__wording">{{ $tp('buttonAndHints.mic') }}</div>
            <div class="call-settings__configurations__device-selection__mic">
              <div class="call-settings__configurations__device-selection__mic-icon" :class="isMicrophoneOn ? 'on' : 'off'" @click="toggleMicrophone">
                <mic-icon v-if="isMicrophoneOn" :width="20" :height="20" />
                <mic-mute-icon v-else color="#262629" :width="20" :height="20" />
              </div>
              <div class="call-settings__configurations__device-selection__mic-dropdown">
                <connect-select :options="microphoneOptions" :default="selectedAudio" @input-selected="onAudioInputSelected" />
              </div>
            </div>
            <div v-if="audioOutputOptions.length > 1">
              <div class="call-settings__configurations__device-selection__wording">
                {{ $tp('buttonAndHints.speakers') }}
              </div>
              <div class="call-settings__configurations__device-selection__output">
                <div class="call-settings__configurations__device-selection__output-icon" :class="isAudioOutputOn ? 'on' : 'off'" @click="toggleOutput">
                  <speaker-on-icon v-if="isAudioOutputOn" :width="20" :height="20" color="#fff" />
                  <speaker-off-icon v-else :width="20" :height="20" color="#262629" />
                </div>
                <div class="call-settings__configurations__device-selection__output-dropdown">
                  <connect-select :options="audioOutputOptions" :default="selectedOutput" @input-selected="onAudioOutputSelected" />
                </div>
              </div>
            </div>
            <div class="call-settings__configurations__device-selection__wording">Camera</div>
            <div class="call-settings__configurations__device-selection__video">
              <div class="call-settings__configurations__device-selection__video-icon" :class="isCameraOn ? 'on' : 'off'" @click="toggleCamera">
                <video-call-icon v-if="isCameraOn" :width="20" :height="20" />
                <no-video-icon v-else :width="20" color="#262629" :height="20" />
              </div>
              <div class="call-settings__configurations__device-selection__video-dropdown">
                <connect-select :options="cameraOptions" :default="selectedVideo" @input-selected="onVideoInputSelected" />
              </div>
            </div>
          </div>
          <div class="call-settings__configurations__actions">
            <button
              class="call-settings__configurations__actions__btn"
              :class="{ 'call-settings__configurations__actions__btn--disabled': isJoinMeetingDisabled }"
              :style="{ background: joinMeetingButtonColor }"
              v-if="showProceedBtn"
              @click="onJoinMeeting"
            >
              {{ $tp('buttonAndHints.joinMeeting') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <visitor-campaign-logo class="call-settings__campaign-logo" :style="!isWaiting ? 'margin-top: unset;' : ''" :logoImage="logoImage" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import CloseIcon from './icons/CloseIcon'
import MagicIcon from './icons/MagicIcon'
import MicIcon from './icons/MicIcon'
import VideoCallIcon from './icons/VideoCallIcon'
import ConnectSelect from './ConnectSelect'
import MicMuteIcon from './icons/MicMuteIcon'
import NoVideoIcon from './icons/NoVideoIcon'
import SettingsVideoCamOffIcon from './icons/SettingsVideoCamOffIcon'
import MicrophoneOffIcon from './icons/MicrophoneOffIcon'
import SpeakerOnIcon from './icons/SpeakerOnIcon'
import SpeakerOffIcon from './icons/SpeakerOffIcon'
import VisitorCampaignLogo from '@/views/campaigns/visitor/VisitorCampaignLogo'
import DialogVideo from './DialogVideo.vue'

export default {
  name: 'Settings',
  components: {
    SpeakerOffIcon,
    SpeakerOnIcon,
    SettingsVideoCamOffIcon,
    NoVideoIcon,
    MicMuteIcon,
    ConnectSelect,
    VideoCallIcon,
    MagicIcon,
    CloseIcon,
    MicIcon,
    MicrophoneOffIcon,
    VisitorCampaignLogo,
    DialogVideo
  },
  data() {
    return {
      defaultVideoBackground: require('@/assets/images/visitor/MicrosoftTeams-image.jpg'),
      blurBackground: false,
      volume: 30,
      videoStream: null
    }
  },

  props: {
    backgroundImage: {
      type: String,
      required: false
    },
    embedVideoURL: {
      type: String,
      required: false
    },
    displayDialogVideoInAVSettings: {
      type: Boolean,
      default: false,
      required: false
    },
    localStream: {
      type: MediaStream,
      required: false,
      default: null
    },
    showProceedBtn: {
      type: Boolean,
      default: true
    },
    isDesktopMaximized: {
      type: Boolean
    },
    logoImage: {
      type: String
    }
  },

  watch: {
    volume() {
      const volumeLevel = this.volume / 100
      this.$refs.localSettingsVideo.volume = volumeLevel
      this.$serverBus.$emit('on-volume-changed', { level: volumeLevel })
    }
  },

  created() {
    this.$serverBus.$on('onJoinMeeting', () => {
      const custom_event = new CustomEvent('custom_gtm_events')
      custom_event.info = {
        name: 'gtm.click',
        gtm: {
          uniqueEventId:
            Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
          start: new Date().getTime(),
          location: window.location.href,
          event_type: 'Join Meeting'
        }
      }
      window.dispatchEvent(custom_event)
      this.onJoinMeeting()
    })
  },

  async mounted() {
    if (this.localStream) {
      this.videoStream = this.localStream.clone()
      this.$refs.localSettingsVideo.srcObject = this.videoStream
    }

    if (this.isAudioVideoAllowed) {
      await this.setupAudioVideoSettingsInCall()
    }
  },

  computed: {
    ...mapGetters({
      selectedAudio: 'webrtc/selectedAudio',
      selectedOutput: 'webrtc/selectedOutput',
      selectedVideo: 'webrtc/selectedVideo',
      micEnabled: 'webrtc/micEnabled',
      videoEnabled: 'webrtc/videoEnabled',
      isAudioVideoAllowed: 'webrtc/isAudioVideoAllowed',
      microphoneOptions: 'webrtc/microphoneOptions',
      audioOutputOptions: 'webrtc/audioOutputOptions',
      cameraOptions: 'webrtc/cameraOptions',
      callStarted: 'campaign/callStarted',
      communicationMode: 'campaign/communicationMode',
      dialog: 'campaign/dialog',
      isWaiting: 'campaign/isWaiting',
      isCobrowse: 'campaign/isCobrowse',
      company: 'company'
    }),

    isAudioDisabled() {
      return this.company && this.company.disableAudio
    },
    isVideoDisabled() {
      return this.company && this.company.disableVideo
    },

    isJoinMeetingDisabled() {
      if (this.selectedAudio && this.selectedVideo && this.selectedAudio.value === 'microphone-off' && this.selectedVideo.value === 'camera-off') {
        return true
      }
      return false
    },

    mainColor() {
      return this.dialog ? this.dialog.step2ButtonColor : '#12598d'
    },

    joinMeetingButtonColor() {
      if (this.isJoinMeetingDisabled) return '#d3d3d3'
      return this.dialog ? this.dialog.step2ButtonColor : '#12598d'
    },

    callSettingsStyles() {
      return {
        background: `url(${this.backgroundImage}) center center no-repeat, linear-gradient(179.98deg, rgba(0, 0, 0, 0) 0.27%, rgba(0, 0, 0, 0.6) 99.99%)`,
        backgroundSize: 'cover'
      }
    },

    isMicrophoneOn() {
      return this.micEnabled && this.selectedAudio && this.selectedAudio.value !== 'microphone-off'
    },
    isCameraOn() {
      return this.videoEnabled && this.selectedVideo && this.selectedVideo.value !== 'camera-off'
    },
    isAudioOutputOn() {
      return this.selectedOutput && this.selectedOutput.value !== 'output-off'
    },

    viewType() {
      if (this.communicationMode === 'audio' && !this.callStarted && !this.isAudioVideoAllowed) {
        return 'permissions-audio'
      } else if (this.isAudioVideoAllowed) {
        return 'configure'
      }

      return 'permissions'
    }
  },
  beforeDestroy() {
    this.stopAllTracks()
  },
  methods: {
    ...mapMutations({
      setSelectedAudio: 'webrtc/setSelectedAudio',
      setSelectedOutput: 'webrtc/setSelectedOutput',
      setSelectedVideo: 'webrtc/setSelectedVideo',

      setMicEnabled: 'webrtc/setMicEnabled',
      setVideoEnabled: 'webrtc/setVideoEnabled',

      setIsAudioVideoAllowed: 'webrtc/setIsAudioVideoAllowed',

      setMicrophoneOptions: 'webrtc/setMicrophoneOptions',
      setAudioOutputOptions: 'webrtc/setAudioOutputOptions',
      setCameraOptions: 'webrtc/setCameraOptions'
    }),

    toggleOutput() {
      if (this.isAudioOutputOn) {
        const output = this.audioOutputOptions.find((x) => x.value === 'output-off')
        this.onAudioOutputSelected(output)
      } else {
        const output = this.audioOutputOptions.find((x) => x.value !== 'output-off')
        if (output) {
          this.onAudioOutputSelected(output)
        }
      }
    },

    async setupAudioVideoSettingsInCall() {
      try {
        if (!(this.cameraOptions || this.microphoneOptions)) {
          const deviceInfos = await navigator.mediaDevices.enumerateDevices()
          this.gotDevices(deviceInfos)
        }

        this.setIsAudioVideoAllowed(true)
      } catch (error) {
        this.errorCallback(error)
      }
    },

    toggleMicrophone() {
      this.setMicEnabled(!this.micEnabled)

      if (this.micEnabled) {
        const mic = this.microphoneOptions.find((x) => x.value === 'microphone-off')

        this.onAudioInputSelected(mic)
      } else {
        const mic = this.microphoneOptions.find((x) => x.value !== 'microphone-off')

        if (mic) {
          this.onAudioInputSelected(mic)
        }
      }
    },

    async toggleCamera() {
      if (this.videoEnabled) {
        const camera = this.cameraOptions.find((x) => x.value === 'camera-off')
        this.onVideoInputSelected(camera)
      } else if (this.cameraOptions.length > 1) {
        const camera = this.cameraOptions.find((x) => x.value !== 'camera-off')
        if (camera) {
          this.onVideoInputSelected(camera)
        }
      } else {
        const savedAudio = this.getSelectedAudio()
        const savedVideo = this.getSelectedVideo()

        const constraints = {
          audio: { echoCancellation: true },
          video: true
        }

        if (savedAudio) {
          constraints.audio = {
            deviceId: savedAudio.value,
            echoCancellation: true
          }
        }

        if (savedVideo) {
          constraints.video = {
            deviceId: 'default',
            width: 1280,
            height: 720
          }
        }

        try {
          if (!this.localStream) {
            const mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
            this.videoStream = mediaStream
            window.stream = mediaStream

            this.$refs.localSettingsVideo.srcObject = mediaStream
          }

          if (!this.cameraOptions.length <= 1) {
            const constraints = {
              audio: { echoCancellation: true },
              video: true
            }

            try {
              await navigator.mediaDevices.getUserMedia(constraints)

              this.setVideoEnabled(!this.videoEnabled)

              const deviceInfos = await navigator.mediaDevices.enumerateDevices()

              this.gotDevices(deviceInfos)
            } catch (error) {
              this.errorCallback(error)
            }

            const camera = this.cameraOptions.find((option) => option.value !== 'camera-off')

            this.onVideoInputSelected(camera)
          }
        } catch (error) {
          this.errorCallback(error)
        }
      }
    },

    closeSettings() {
      const custom_event = new CustomEvent('custom_gtm_events')
      custom_event.info = {
        name: 'gtm.click',
        gtm: {
          uniqueEventId:
            Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
          start: new Date().getTime(),
          location: window.location.href,
          event_type: 'Close Settings'
        }
      }
      window.dispatchEvent(custom_event)

      if (!this.callStarted) {
        this.setSelectedAudio(null)
        this.setSelectedOutput(null)
        this.setSelectedVideo(null)

        this.setMicEnabled(false)
        this.setVideoEnabled(false)
      }

      this.$emit('settingsClosed')
    },

    async onJoinMeeting() {
      if (this.isJoinMeetingDisabled) return
      await this.stopAllTracks()
      this.$emit('on-join-meeting')
    },

    onAudioOutputSelected(option) {
      this.setSelectedOutput(option)
    },

    async onAudioInputSelected(option) {
      if (option && option.value && option.value !== 'microphone-off') {
        this.stopAllTracks()
        const constraints = {}

        constraints.audio = {
          deviceId: option.value,
          echoCancellation: true
        }

        if (this.videoEnabled) {
          constraints.video = {
            deviceId: this.selectedVideo.value
          }
        } else {
          constraints.video = false
        }
        navigator.mediaDevices.getUserMedia(constraints).then(
          (stream) => {
            this.videoStream = stream
            window.stream = stream
            this.$refs.localSettingsVideo.srcObject = stream

            this.setSelectedAudio(option)
          },
          (err) => {
            /* eslint-disable no-console */
            console.log(err)
          }
        )
      } else {
        this.stopAudioTracks()
        this.setSelectedAudio(option)
      }
    },

    async onVideoInputSelected(option) {
      this.stopVideoTracks()

      if (option && option.value && option.value !== 'camera-off') {
        const constraints = {
          audio: {
            echoCancellation: true
          },
          video: {
            deviceId: option.value
          }
        }

        navigator.mediaDevices.getUserMedia(constraints).then(
          async (stream) => {
            this.videoStream = stream
            window.stream = stream
            this.$refs.localSettingsVideo.srcObject = stream

            this.setSelectedVideo(option)
          },
          (err) => {
            /* eslint-disable no-console */
            console.log(err)
          }
        )
      } else {
        this.setSelectedVideo(option)
      }
    },

    gotDevices(deviceInfos) {
      const microphoneOptions = []
      const cameraOptions = []
      const audioOutputOptions = []

      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i]
        const option = {}

        option.value = deviceInfo.deviceId

        if (deviceInfo.kind === 'audioinput' && !this.isAudioDisabled) {
          option.text = deviceInfo.label || `Microphone ${microphoneOptions.length + 1}`

          microphoneOptions.push(option)
        } else if (deviceInfo.kind === 'audiooutput' && !this.isAudioDisabled) {
          option.text = deviceInfo.label || `Speaker ${audioOutputOptions.length + 1}`

          audioOutputOptions.push(option)
        } else if (deviceInfo.kind === 'videoinput' && !this.isVideoDisabled) {
          option.text = deviceInfo.label || `Camera ${cameraOptions.length + 1}`

          cameraOptions.push(option)
        }
      }

      microphoneOptions.push({
        text: 'Microphone Off',
        value: 'microphone-off'
      })

      this.setMicrophoneOptions(microphoneOptions)

      cameraOptions.push({
        text: 'Camera Off',
        value: 'camera-off'
      })

      this.setCameraOptions(cameraOptions)

      audioOutputOptions.push({
        text: 'Audio Output Off',
        value: 'output-off'
      })

      this.setAudioOutputOptions(audioOutputOptions)
    },

    getSelectedAudio() {
      /* eslint-disable indent */
      return this.selectedAudio && this.selectedAudio.value !== 'microphone-off'
        ? this.selectedAudio
        : this.selectedAudio
        ? this.microphoneOptions[this.microphoneOptions.length - 1]
        : this.microphoneOptions[0] || null
    },

    getSelectedVideo() {
      /* eslint-disable indent */
      return this.selectedVideo && this.selectedVideo.value !== 'camera-off'
        ? this.selectedVideo
        : this.selectedVideo
        ? this.cameraOptions[this.cameraOptions.length - 1]
        : this.cameraOptions[0] || null
    },

    onSelectDefaultAudioOutput() {
      const selectedOutput = this.selectedOutput || this.audioOutputOptions[0]

      if (selectedOutput) {
        this.onAudioOutputSelected(selectedOutput)
      }
    },

    stopAudioTracks() {
      if (this.videoStream) {
        this.videoStream.getAudioTracks().forEach(function (track) {
          track.stop()
        })
      }

      if (window.stream) {
        window.stream.getAudioTracks().forEach(function (track) {
          track.stop()
        })
      }
    },
    stopVideoTracks() {
      if (this.videoStream) {
        this.videoStream.getVideoTracks().forEach(function (track) {
          track.stop()
        })
      }

      if (window.stream) {
        window.stream.getVideoTracks().forEach(function (track) {
          track.stop()
        })
      }
    },
    stopAllTracks() {
      if (this.videoStream) {
        if (typeof this.videoStream.getTracks === 'undefined') {
          this.videoStream.stop()
        } else {
          this.videoStream.getAudioTracks().forEach(function (track) {
            track.stop()
          })

          this.videoStream.getVideoTracks().forEach(function (track) {
            track.stop()
          })
        }

        this.videoStream = null

        if (!this.callStarted) {
          window.stream = null
        }
      }

      this.$refs.localSettingsVideo.srcObject = null
    },

    async requestPermissions() {
      const custom_event = new CustomEvent('custom_gtm_events')
      custom_event.info = {
        name: 'gtm.click',
        gtm: {
          uniqueEventId:
            Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
          start: new Date().getTime(),
          location: window.location.href,
          event_type: 'Request permission'
        }
      }
      window.dispatchEvent(custom_event)

      const savedAudio = this.getSelectedAudio()
      const savedVideo = this.getSelectedVideo()

      const constraints = {
        audio: { echoCancellation: true },
        video: true
      }

      if (savedAudio) {
        constraints.audio = {
          deviceId: savedAudio.value,
          echoCancellation: true
        }
      }

      if (savedVideo) {
        constraints.video = {
          deviceId: savedVideo.value,
          width: 1280,
          height: 720
        }
      }

      if (this.communicationMode === 'audio') {
        constraints.video = false
      }

      try {
        if (!this.localStream) {
          const mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
          this.videoStream = mediaStream
          window.stream = mediaStream

          this.$refs.localSettingsVideo.srcObject = mediaStream
        }

        if (this.communicationMode === 'audio') {
          this.setMicEnabled(true)
        }

        if (this.communicationMode === 'video') {
          this.setVideoEnabled(true)
          this.setMicEnabled(true)
        }

        if (!this.cameraOptions.length <= 1 || !this.microphoneOptions.length) {
          const deviceInfos = await navigator.mediaDevices.enumerateDevices()

          this.gotDevices(deviceInfos)
        }

        this.setIsAudioVideoAllowed(true)
        this.onSelectDefaultAudioOutput()

        if ((!this.callStarted && this.communicationMode === 'audio') || (this.callStarted && !this.videoEnabled)) {
          this.setSelectedVideo(this.cameraOptions.find((option) => option.value === 'camera-off'))
        } else {
          this.setSelectedVideo(this.getSelectedVideo())
        }

        if ((!this.callStarted && ['audio', 'video'].includes(this.communicationMode)) || (this.callStarted && !this.micEnabled)) {
          this.setSelectedAudio(this.getSelectedAudio())
        }

        this.$emit('videoPreviewRequest')
      } catch (error) {
        /* eslint-disable no-console */
        this.errorCallback(error)
      }
    },

    errorCallback(e) {
      console.error(e)

      this.$emit('videoPreviewRequest')

      if (this.microphoneOptions.length > 1) {
        this.$emit('setNoticeType', 'permissions-denied-base')
      } else {
        this.$emit('setNoticeType', 'permissions-denied')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.on {
  background: var(--main-background) !important;
}

.off {
  background: #ffffff !important;
}

.call-settings {
  font-family: 'Lato', sans-serif;
  padding-top: 148px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #3b86f7;
  height: 100%;
  position: absolute;
  z-index: 1000;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  &._with-image {
    &:after {
      content: '';
      display: flex;
      height: 100%;
      width: 100%;
      position: absolute;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.9) 100%),
        linear-gradient(179.98deg, rgba(0, 0, 0, 0) 0.27%, rgba(0, 0, 0, 0.6) 99.99%);
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
  &__campaign-logo {
    justify-content: flex-end;
    width: 100%;
    margin-top: auto;
    padding-right: 30px;
    position: static;
  }
  @media (max-width: 1023px) {
    background: #fff;
    padding: 0;
    height: calc(100% - 26px);
    &__campaign-logo {
      display: none;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
    background: rgb(230, 236, 241);
  }

  &::-webkit-scrollbar-thumb:vertical {
    background-color: rgb(18, 89, 141);
    border-radius: 6px;
  }

  &__actions {
    position: absolute;
    top: 10px;
    right: 10px;

    &__close {
      width: 25px;
      height: 25px;
      background: #12598d;
      border-radius: 6px;
      border: none;
      color: #fff;
      font-weight: 700;
      cursor: pointer;
    }
  }

  &__permissions {
    background: #fff;
    padding: 20px 66px 28px 20px;
    border-radius: 6px;
    height: 505px;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1023px;
    margin: 0 auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 80px;
    @media (max-width: 1023px) {
      height: 100%;
      box-shadow: none;
      border-radius: 0;
      flex-direction: column;
      padding: 74px 38px 20px 38px;
      margin-bottom: 0;
    }

    &__header {
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      color: #262629;
    }

    &__preview {
      margin: 20px 0 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-around;

      &__placeholder {
        width: 300px;
        max-width: 100%;
        height: 215px;
        background-image: url('/assets/images/visitor/camera_not_active.png');
        background-size: 300px 215px;
        border-radius: 6px;

        @media (max-height: 550px) {
          width: 200px;
          height: 115px;
          background-size: 200px 140px;
        }
      }
    }

    &__wording {
      display: flex;
      flex-direction: column;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      color: #262629;
      margin-bottom: 25px;

      &-span:first-child {
        margin-bottom: 10px;
      }
    }

    &__actions {
      &__btn {
        font-family: 'Lato', sans-serif;
        cursor: pointer;
        width: 100%;
        background: #12598d;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        border-radius: 6px;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        text-align: center;

        color: #ffffff;
        border: none;
        outline: none;
        padding: 6px 0;

        &--disabled {
          cursor: default;
        }
      }
    }
  }

  &__configurations {
    background: #fff;
    height: 505px;
    padding: 20px 66px 28px 20px;
    border-radius: 6px;
    width: 100%;
    max-width: 1023px;
    margin: 0 auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 80px;
    @media (max-width: 1023px) {
      padding: 74px 38px 20px 38px;
      height: 100%;
      box-shadow: none;
      border-radius: 0;
      margin-bottom: 0;
    }
    &__setup {
      display: flex;
      align-items: center;
      height: 100%;
      @media (max-width: 1023px) {
        display: flex;
        flex-direction: column;
      }
    }

    &__header {
      padding-right: 60px;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      color: #262629;

      margin-bottom: 25px;
      width: 100%;
      &._mobile-header {
        display: none;
      }
      @media (max-width: 1023px) {
        &._mobile-header {
          display: flex;
        }
        &._desktop-header {
          display: none;
        }
      }
    }

    &__preview {
      background: #e6ecf1;
      border-radius: 6px;
      width: 100%;
      max-width: 511px;
      height: 100%;
      max-height: 426px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 57px;
      @media (max-width: 1023px) {
        margin-right: 0;
        max-height: 215px;
        margin-bottom: 30px;
      }
      &__img {
        height: 100%;
        width: 100%;

        &__container {
          display: flex;
          position: relative;
          align-items: center;
          height: 100%;
          justify-content: space-around;

          &__icon {
            max-height: 90%;
          }

          &-message {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            color: #262629;
            max-width: 180px;
            text-align: center;
          }
        }

        &-background {
          background-image: url('/assets/images/visitor/MicrosoftTeams-image.jpg');
          background-size: 300px 200px;
          background-repeat: no-repeat;
          height: 100%;
          width: 100%;
        }

        &__video {
          width: 100%;
          max-width: 100%;
          height: 100%;
          border-radius: 6px;
          object-fit: cover;
        }

        &-description {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          padding: 5px 15px;

          &__icon {
            margin-right: 5px;
          }

          &__wording {
            display: flex;
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            color: #262629;
            z-index: 1;
          }
        }
      }
    }

    &__holder {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 380px;
      @media (max-width: 1023px) {
        max-width: 511px;
      }
    }

    &__device-selection {
      &__wording {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #262629;
        margin-bottom: 10px;
      }

      &__mic {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #e6ecf1;
        border-radius: 6px;
        margin-bottom: 10px;

        &-icon {
          background: #3b86f7;
          border-radius: 6px;
          padding: 5px 15px 0 15px;
        }

        &-dropdown {
          height: 30px;

          .selected {
            white-space: nowrap;
            overflow: hidden;
            width: 220px;
          }
        }
      }

      &__output {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #e6ecf1;
        border-radius: 6px;
        margin-bottom: 10px;

        &-icon {
          background: #3b86f7;
          border-radius: 6px;
          padding: 5px 15px 0 15px;
        }

        &-dropdown {
          height: 30px;

          .selected {
            white-space: nowrap;
            overflow: hidden;
            width: 220px;
          }
        }
      }

      &__volume {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 15px;
        margin-bottom: 10px;

        &-icon {
          margin-right: 15px;
          padding-top: 5px;
        }

        &-slider {
          .con-vs-slider {
            min-width: 255px;
            z-index: 0;
          }
        }
      }

      &__video {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #e6ecf1;
        border-radius: 6px;
        margin-bottom: 20px;

        &-icon {
          background: #3b86f7;
          border-radius: 6px;
          padding: 5px 15px 0 15px;
        }
      }
    }

    &__actions {
      &__btn {
        font-family: 'Lato', sans-serif;
        cursor: pointer;
        width: 100%;
        background: #1c8439;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        border-radius: 6px;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        text-align: center;

        color: #ffffff;
        border: none;
        outline: none;
        padding: 6px 0;

        &--disabled {
          cursor: default;
        }
      }
    }
  }

  &_sized {
    .call-settings {
      &__configurations {
        &__preview {
          &__img {
            height: 200px;
          }
        }
      }
    }
  }

  &_desktop-maximized {
    top: 15px;
    right: 115px;
    width: 730px;
    max-width: calc(100% - 115px);
    height: auto;
    background: unset;
    padding: unset;

    .call-settings {
      &__configurations {
        &__setup {
          display: flex;
          align-items: center;
          height: 100%;
        }

        &__preview {
          width: 300px;
          max-width: 45%;
          height: 200px;

          &__img {
            height: 200px;
          }
        }

        &__device-selection {
          flex-grow: 1;
          padding-left: 15px;
        }
      }
    }
  }
}
</style>
