<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :color="color" viewBox="0 0 17 18" fill="none" stroke="currentColor" class="feather">
    <path
      d="M13.982 2.62274H2.62275C1.72653 2.62274 1 3.34927 1 4.2455V15.6048C1 16.501 1.72653 17.2275 2.62275 17.2275H13.982C14.8783 17.2275 15.6048 16.501 15.6048 15.6048V4.2455C15.6048 3.34927 14.8783 2.62274 13.982 2.62274Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M11.5479 1V4.24551" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.05688 1V4.24551" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1 7.49103H15.6048" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'CalendarIcon',

  props: {
    color: {
      type: String,
      default: '#fff'
    },

    width: {
      type: Number,
      default: 35
    },

    height: {
      type: Number,
      default: 35
    }
  }
}
</script>
