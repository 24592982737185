var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-toggle-icon", style: "height: " + _vm.height + "px" },
    [
      _vm.enabled
        ? _c(
            "svg",
            {
              key: "start",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                version: "1.1",
                width: _vm.width,
                height: _vm.height,
                viewBox: "0 0 24 24",
              },
            },
            [
              _c("path", {
                attrs: {
                  fill: _vm.computedColor,
                  d: "M17,7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7M17,15A3,3 0 0,1 14,12A3,3 0 0,1 17,9A3,3 0 0,1 20,12A3,3 0 0,1 17,15Z",
                },
              }),
            ]
          )
        : _vm._e(),
      !_vm.enabled
        ? _c(
            "svg",
            {
              key: "stop",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                version: "1.1",
                width: _vm.width,
                height: _vm.height,
                viewBox: "0 0 24 24",
              },
            },
            [
              _c("path", {
                attrs: {
                  fill: _vm.disabledColor,
                  d: "M17,7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7M7,15A3,3 0 0,1 4,12A3,3 0 0,1 7,9A3,3 0 0,1 10,12A3,3 0 0,1 7,15Z",
                },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }