<template>
  <draggable-modal class="ss-video-container" :show="videoEnabled" :width="150" :height="100" :top="30" :right="30" bkcolor="#F7F7F7">
    <div class="ss-video-container-video">
      <video ref="localVideo" class="video" width="150" height="100" muted="muted" autoplay playsinline></video>
    </div>
  </draggable-modal>
</template>
<script>
import { mapGetters } from 'vuex'

import DraggableModal from '@/components/DraggableModal.vue'

export default {
  name: 'ScreenSharingLocalVideo',
  components: {
    DraggableModal
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      videoEnabled: 'webrtc/videoEnabled',
      cameraStream: 'webrtc/cameraStream'
    })
  },
  mounted() {
    this.updateVideoSource()
  },
  watch: {
    cameraStream() {
      this.updateVideoSource()
    }
  },
  methods: {
    updateVideoSource() {
      if (this.$refs.localVideo && this.cameraStream) {
        this.$refs.localVideo.srcObject = this.cameraStream
      }
      setTimeout(() => {
        if (this.$refs.localVideo && this.cameraStream) {
          this.$refs.localVideo.srcObject = this.cameraStream
        }
      }, 500)
    }
  }
}
</script>
<style lang="scss" scoped>
.ss-video-container {
  display: flex;
  position: fixed;
  z-index: 54000;

  &-video {
    width: 150px;
    height: 100px;
    background: #c4c4c4;
    border-radius: 6px;
    z-index: 100002;

    video {
      object-fit: fill;
      border-radius: 6px;
      z-index: 100002;
    }
  }
}
</style>
