<template>
  <svg :width="width" :height="height" viewBox="0 0 188 188" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M82.6709 34.8154H110.909C115.394 34.8154 119.695 36.5969 122.866 39.768C126.037 42.9391 127.818 47.24 127.818 51.7245V79.9627L136.273 88.4172L187 51.7245V136.27M127.818 127.815V136.27C127.818 140.755 126.037 145.055 122.866 148.226C119.695 151.398 115.394 153.179 110.909 153.179H17.9091C13.4245 153.179 9.12363 151.398 5.95256 148.226C2.78149 145.055 1 140.755 1 136.27V51.7245C1 47.24 2.78149 42.9391 5.95256 39.768C9.12363 36.5969 13.4245 34.8154 17.9091 34.8154H34.8182L127.818 127.815Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M1 1L187 187" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'SettingsVideoCamOffIcon',
  props: {
    color: {
      type: String,
      default: '#3B86F7'
    },
    width: {
      type: Number,
      default: 35
    },
    height: {
      type: Number,
      default: 35
    }
  }
}
</script>
