import { mapGetters, mapMutations } from 'vuex'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const duration = require('dayjs/plugin/duration')
const isBetween = require('dayjs/plugin/isBetween')
const localizedFormat = require('dayjs/plugin/localizedFormat')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)

export const callcenter = {
  data() {
    return {
      callCenterSettings: null,
      callCenterSettingsId: null,
      callCenterUserStatusRef: null,
      systemWeekdaysShort: [
        'system.weekdays.short.sun',
        'system.weekdays.short.mon',
        'system.weekdays.short.tue',
        'system.weekdays.short.wed',
        'system.weekdays.short.thu',
        'system.weekdays.short.fri',
        'system.weekdays.short.sat'
      ],
      unsubscribeCallCenterSettings: null,
      hasCommunicationStartedOrEndedBetweenAgentAndVisitor: false,
      hasNoOnlineAgentsAvailable: false,
      notifyAgentKey: null
    }
  },
  computed: {
    ...mapGetters({
      onlineUsers: 'onlineUsers',
      callCenterAgentsToBeNotified: 'callCenterAgentsToBeNotified',
      allCallCentersSettings: 'call_center_settings',
      visitor: 'visitor'
    }),
    shouldMoveToNextPriorityAgent() {
      return (
        this.callCenterSettings &&
        this.callCenterAgentsToBeNotified &&
        !this.hasCommunicationStartedOrEndedBetweenAgentAndVisitor &&
        !this.hasNoOnlineAgentsAvailable
      )
    },

    companyOnlineAgents() {
      return this.visitor && this.onlineUsers.filter((x) => x.state === 'online' && x.company === this.visitor.company).map((x) => x.userId)
    },

    areCompanyAgentsAvailable() {
      return this.companyOnlineAgents && this.companyOnlineAgents.length > 0
    },

    callCenterOnlineAgentsToBeNotified() {
      return this.callCenterAgentsToBeNotified.filter((agent) => this.callCenterOnlineAgents.some((ag) => agent.id === ag.uid))
    },

    callCenterOnlineAgents() {
      return this.onlineUsers
        .filter((x) => x.ccstate === 'online' && x.company === this.callCenterSettings.callCenterId)
        .map((x) => {
          return { uid: x.userId, state: x.state, ccstate: x.ccstate }
        })
    },
    callCenterOnlineAvailableAgents() {
      return this.callCenterOnlineAgents.filter((value) => this.callCenterAgentsToBeNotified.map((x) => x.id).includes(value.uid))
    },
    areAgentsAvailable() {
      return this.callCenterOnlineAvailableAgents.length > 0
    },
    callCenterId() {
      return this.callCenterSettings && this.callCenterSettings.callCenterId ? this.callCenterSettings.callCenterId : null
    },
    callCenterName() {
      return this.callCenterSettings && this.callCenterSettings.callCenterName ? this.callCenterSettings.callCenterName : null
    }
  },
  beforeDestroy() {
    if (this.callCenterUserStatusRef) {
      this.callCenterUserStatusRef.off()
    }
    if (this.unsubscribeCallCenterSettings) {
      this.unsubscribeCallCenterSettings()
    }
  },
  watch: {
    visitor() {
      if (!this.visitor || !this.visitor.agentRequest) {
        this.hasCommunicationStartedOrEndedBetweenAgentAndVisitor = true
      } else {
        this.hasCommunicationStartedOrEndedBetweenAgentAndVisitor = false
      }
    },
    notifyAgentKey() {
      if (this.notifyAgentKey) {
        this.notifyNextPriorityAgent()
      }
    },
    callCenterSettingsId() {
      if (this.callCenterSettingsId) {
        this.subscribeCallCenterSettings()
      }
    }
  },
  methods: {
    ...mapMutations({
      addOnlineUsers: 'ADD_ONLINE_USERS',
      removeOnlineUsers: 'REMOVE_ONLINE_USERS',
      updateCallCenterAgentsToBeNotified: 'UPDATE_CALL_CENTER_AGENTS_TO_BE_NOTIFIED'
      // updateCallCenterSettings: 'UPDATE_CALL_CENTER_SETTINGS'
    }),
    getCallCenterCompanyUsers(callCenterSettingsId) {
      if (callCenterSettingsId) {
        this.callCenterSettingsId = callCenterSettingsId
        this.subscribeCallCenterSettings()
      }
    },

    updateCallCenterSettings(setting) {
      this.callCenterSettings = setting
    },

    subscribeCallCenterOnlineUsers() {
      this.callCenterUserStatusRef = this.$firebase.database().ref(`/users/${this.callCenterSettings.callCenterId}`)

      if (this.callCenterUserStatusRef) {
        /* Query realtime db for users online status */
        this.callCenterUserStatusRef.on('value', async (snapshot) => {
          const data = snapshot.val()
          const now = this.$firebase.firestore.Timestamp.now().seconds * 1000
          if (data) {
            for (const key of Object.keys(data)) {
              const entry = data[key]
              const user = {
                userId: key,
                state: entry.state,
                ccstate: entry.ccstate,
                displayName: entry.displayName,
                company: this.callCenterSettings.callCenterId,
                time: (now - entry.last_changed) / 1000
              }
              if (user && user.userId) {
                const index = this.onlineUsers.findIndex((x) => x.userId === user.userId)

                /* Add or update user in onlineUsers list if the user is online */
                if (user.ccstate === 'online' && user.time < 300) {
                  if (index === -1) {
                    /* Add user to the onlineUsers List */
                    const userRef = await this.$db.collection('users').doc(user.userId).get()
                    if (userRef && userRef.data()) {
                      user.photoURL = userRef.data().photoURL
                      user.displayName = userRef.data().displayName || `${userRef.data().firstname} ${userRef.data().lastname}`
                      this.addOnlineUsers(user)
                    }
                  }
                }

                /* Remove from onlineUsers list if user is offline */
                if (user.ccstate !== 'online' || user.time > 300) {
                  if (this.onlineUsers.length > 0) {
                    if (index > -1) {
                      this.removeOnlineUsers(index)
                    }
                  }
                }
              }
            }
          }
        })

        /* Remove user from the list when user logs out */
        this.callCenterUserStatusRef.on('child_removed', (snapshot) => {
          if (this.onlineUsers.length > 0) {
            const index = this.onlineUsers.findIndex((x) => x.userId === snapshot.key)
            if (index > -1) {
              this.onlineUsers.splice(index, 1)
            }
          }
          /* Detach listner of the user */
          const userListener = this.$firebase.database().ref(`/users/${this.callCenterSettings.customerId}/${snapshot.key}`)
          if (userListener && userListener.off) {
            userListener.off()
          }
        })
      }
    },

    isCurrentAgentAvailable(agent) {
      return this.callCenterOnlineAgents.find((x) => x.ccstate === 'online' && x.uid === agent.id)
    },

    async notifyAgents(agents) {
      if (this.visitorId && agents) {
        const getTurnServers = this.$functions.httpsCallable('getturnservers')
        await getTurnServers({ visitorId: this.visitorId })

        const _request = {
          agentRequest: true,
          requestFrom: 'campaign',
          agentRequestType: this.communicationMode,
          modified: dayjs().utc().toDate(),
          ttlDate: dayjs().utc().add(6, 'month').toDate(),
          lastRequestDate: dayjs().utc().toDate(),
          hasSentAutomatedResponse: false,
          shouldNotifyAgents: true,
          connectedAgentId: null,
          connectedAgent: null,
          callCenterId: this.callCenterId,
          callCenterName: this.callCenterName,
          previousCallCenterId: this.callCenterId,
          previousCallCenterName: this.callCenterName,
          reworkData: null,
          callType: 'incoming-request',
          callTypeState: 'incoming',
          audioPlayed: false,
          start_time: dayjs().utc().toDate(),
          campaignId: this.$route.query.cid,
          locale: this.$i18n.locale,
          agentsToBeNotified: agents,
          ccNotificationKey: Math.random().toString(36).substring(2, 15),
          sessionId: Math.random().toString(36).substring(2, 15),
          initiatedBy: 'visitor',
          dialogId: this.$firebase.firestore.FieldValue.delete(),
          abTestId: this.$firebase.firestore.FieldValue.delete(),
          disconnect: this.$firebase.firestore.FieldValue.delete(),
          end_time: this.$firebase.firestore.FieldValue.delete(),
          vegaSessionId: this.$firebase.firestore.FieldValue.delete()
        }
        const visitorRef = this.$db.collection('visitors').doc(this.visitorId)
        await visitorRef.set(_request, { merge: true })
      }
    },

    async notifyNextPriorityAgent() {
      if (this.shouldMoveToNextPriorityAgent) {
        if (this.callCenterSettings.isAgentsPrioritization) {
          if (!this.areAgentsAvailable) {
            /* If there are not agents available, then return. In this case visitor will see the contact form */
            // eslint-disable-next-line
            console.log('No agents are available!!')
            this.$serverBus.$emit('show-contact-form')
          } else {
            const agentToBeNotified = {
              ...this.callCenterOnlineAgentsToBeNotified.reduce((x, y) => {
                return x.priority < y.priority ? x : y
              })
            }
            const maxPriority = Math.max.apply(
              null,
              this.callCenterOnlineAgentsToBeNotified.map((item) => item.priority)
            )

            const allAgentsToBeNotified = this.callCenterAgentsToBeNotified.map((x) => {
              return { ...x }
            })
            allAgentsToBeNotified.find((x) => x.id === agentToBeNotified.id).priority = maxPriority + 1
            this.updateCallCenterAgentsToBeNotified(allAgentsToBeNotified)

            if (this.isCurrentAgentAvailable(agentToBeNotified)) {
              const agents = [agentToBeNotified.id]
              // eslint-disable-next-line
              console.log('Agents is available!', agentToBeNotified.id)
              await this.notifyAgents(agents)
              if (this.callCenterOnlineAvailableAgents.length > 1) {
                setTimeout(() => {
                  this.notifyAgentKey = Math.random().toString(36).substring(2, 15)
                }, parseInt(agentToBeNotified.ringingDuration) * 1000)
              } else {
                /* As no more agents are available, and the only one available has already been notified, STOP THE Loop  */
                // eslint-disable-next-line
                console.log('Notifed Agents.. No more agents are available')
              }
            } else {
              setTimeout(() => {
                this.notifyAgentKey = Math.random().toString(36).substring(2, 15)
              }, 1000)
            }
          }
        } else {
          const agents = this.callCenterAgentsToBeNotified.map((x) => x.id)
          // eslint-disable-next-line
          console.log('Agents are available!', agents)
          this.notifyAgents(agents)
        }
      }
    },

    validateAcceptanceTime() {
      try {
        const callCenterSetting = this.callCenterSettings && this.callCenterSettings.acceptanceTimes ? this.callCenterSettings : null
        if (!callCenterSetting) {
          return false
        }
        const dayOfWeek = this.systemWeekdaysShort[dayjs.utc().tz(callCenterSetting.callCenterTimezone).day()]
        const acceptanceTime = callCenterSetting.acceptanceTimes.find((x) => x.day === dayOfWeek && x.enabled)
        if (!acceptanceTime) {
          return false
        }
        let validatedTime = false
        for (const time of acceptanceTime.times) {
          const startTimeString = time.start
          const endTimeString = time.end

          const [startHour, startMinute] = startTimeString.split(':').map((t) => parseInt(t))
          const [endHour, endMinute] = endTimeString.split(':').map((t) => parseInt(t))

          const startDurationInMinutes = dayjs.duration({ hour: startHour, minute: startMinute }).asMinutes()
          const endDurationInMinutes = dayjs.duration({ hour: endHour, minute: endMinute }).asMinutes()

          const startTime = dayjs.utc().tz(callCenterSetting.callCenterTimezone).startOf('day').add(startDurationInMinutes, 'm')
          const endTime = dayjs.utc().tz(callCenterSetting.callCenterTimezone).startOf('day').add(endDurationInMinutes, 'm')

          const currentTime = dayjs.utc().tz(callCenterSetting.callCenterTimezone)

          validatedTime = currentTime.isBetween(startTime, endTime, 'seconds')
          if (validatedTime) {
            break
          }
        }
        return validatedTime
      } catch (error) {
        // eslint-disable-next-line
        console.error(error.message)
        return false
      }
    },

    waitForNextPriorityAgent(waitingTime) {
      if (this.callCenterSettings && typeof this.callCenterSettings.waitingTime !== 'undefined') {
        if (typeof waitingTime === 'undefined' || waitingTime === null) {
          waitingTime = this.callCenterSettings.waitingTime
        }

        if (!this.validateAcceptanceTime()) {
          // eslint-disable-next-line
          console.log('Could not validate acceptance time')
          return
        }
        this.hasCommunicationStartedOrEndedBetweenAgentAndVisitor = false
        // eslint-disable-next-line
        console.log('Validated Acceptance time')
        if (!waitingTime) {
          this.notifyNextPriorityAgent()
        } else {
          setTimeout(() => {
            this.notifyNextPriorityAgent()
          }, waitingTime * 1000)
        }
      }
    },

    subscribeCallCenterSettings() {
      if (this.callCenterSettingsId) {
        if (this.unsubscribeCallCenterSettings) {
          this.unsubscribeCallCenterSettings()
        }
        const query = this.$db.collection('call-center-settings').doc(this.callCenterSettingsId)

        this.unsubscribeCallCenterSettings = query.onSnapshot(async (ref) => {
          if (ref && ref.data()) {
            const setting = ref.data()
            this.updateCallCenterSettings(setting)
            if (setting.agents) {
              this.updateCallCenterAgentsToBeNotified(setting.agents)
            }
            if (!this.callCenterUserStatusRef) {
              this.subscribeCallCenterOnlineUsers()
            }
          }
        })
      }
    }
  }
}
