var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        color: _vm.color,
        fill: "none",
        stroke: "currentColor",
        viewBox: "0 0 188 188",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M1 1.02954L186.971 187",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M119.346 71.5253V26.3852C119.352 20.0962 117.021 14.0293 112.806 9.3621C108.591 4.69491 102.792 1.76048 96.5345 1.12845C90.2774 0.496418 84.0087 2.21188 78.9452 5.94183C73.8818 9.67177 70.3849 15.1501 69.1335 21.3132M68.6263 68.6512V94.0108C68.6307 99.0232 70.1204 103.922 72.9072 108.088C75.6941 112.254 79.6531 115.501 84.2842 117.419C88.9153 119.337 94.0108 119.839 98.9272 118.862C103.844 117.885 108.36 115.474 111.907 111.932L68.6263 68.6512Z",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M136.252 135.859C128 144.282 117.419 150.047 105.868 152.416C94.3163 154.784 82.3208 153.647 71.4201 149.15C60.5193 144.653 51.2107 137.003 44.6883 127.179C38.1659 117.355 34.7272 105.807 34.8135 94.0158V77.1094M153.158 77.1094V94.0158C153.155 97.5022 152.844 100.982 152.229 104.413",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M93.9856 153.187V187",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M60.1737 187H127.799",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }