<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :color="color" viewBox="0 0 17 17" fill="none" stroke="currentColor" class="feather">
    <path
      d="M16 11C16 11.442 15.8244 11.866 15.5118 12.1785C15.1993 12.4911 14.7754 12.6667 14.3333 12.6667H4.33333L1 16V2.66667C1 2.22464 1.17559 1.80072 1.48816 1.48816C1.80072 1.17559 2.22464 1 2.66667 1H14.3333C14.7754 1 15.1993 1.17559 15.5118 1.48816C15.8244 1.80072 16 2.22464 16 2.66667V11Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'MessageSquareIcon',

  props: {
    color: {
      type: String,
      default: '#fff'
    },

    width: {
      type: Number,
      default: 35
    },

    height: {
      type: Number,
      default: 35
    }
  }
}
</script>
