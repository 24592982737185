var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "call-settings",
      class: {
        "call-settings_sized": _vm.audioOutputOptions.length < 1,
        "_with-image": !_vm.callStarted && !_vm.isCobrowse,
      },
      style: !_vm.callStarted && !_vm.isCobrowse && _vm.callSettingsStyles,
    },
    [
      _c("dialog-video", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.displayDialogVideoInAVSettings && _vm.embedVideoURL,
            expression: "displayDialogVideoInAVSettings && embedVideoURL",
          },
        ],
        class: "video-item-" + _vm.viewType,
        staticStyle: { "z-index": "-1" },
        attrs: { embedVideoURL: _vm.embedVideoURL },
      }),
      _c("div", { staticClass: "call-settings__actions" }, [
        _c(
          "button",
          {
            staticClass: "call-settings__actions__close",
            style: { background: _vm.mainColor },
            on: { click: _vm.closeSettings },
          },
          [_c("close-icon", { attrs: { width: 25, height: 25 } })],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: ["permissions", "permissions-audio"].includes(
                _vm.viewType
              ),
              expression:
                "['permissions', 'permissions-audio'].includes(viewType)",
            },
          ],
          staticClass: "call-settings__permissions",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "call-settings__configurations__header _mobile-header",
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.viewType === "permissions"
                      ? _vm.$tp("buttonAndHints.videoAndAudio")
                      : _vm.$tp("buttonAndHints.audioSettings")
                  ) +
                  "\n    "
              ),
            ]
          ),
          _c("div", { staticClass: "call-settings__configurations__preview" }, [
            _c(
              "div",
              { staticClass: "call-settings__configurations__preview__img" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "call-settings__configurations__preview__img__container",
                  },
                  [
                    _vm.viewType === "permissions"
                      ? _c("settings-video-cam-off-icon", {
                          staticClass:
                            "call-settings__configurations__preview__img__container__icon",
                          staticStyle: { position: "absolute" },
                          attrs: { color: "#ffffff", width: 186, height: 186 },
                        })
                      : _c("microphone-off-icon", {
                          staticClass:
                            "call-settings__configurations__preview__img__container__icon",
                          staticStyle: { position: "absolute" },
                          attrs: { color: "#ffffff", width: 186, height: 186 },
                        }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "call-settings__configurations__preview__img__container-message",
                        staticStyle: { "z-index": "1" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.viewType === "permissions"
                                ? _vm.$tp(
                                    "buttonAndHints.cameraAndMicAreNotActive"
                                  )
                                : _vm.$tp("buttonAndHints.micIsNotActive")
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "call-settings__permissions__info" }, [
            _c(
              "div",
              {
                staticClass:
                  "call-settings__configurations__header _desktop-header",
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.viewType === "permissions"
                        ? _vm.$tp("buttonAndHints.videoAndAudio")
                        : _vm.$tp("buttonAndHints.audioSettings")
                    ) +
                    "\n      "
                ),
              ]
            ),
            _c("div", { staticClass: "call-settings__permissions__wording" }, [
              _c(
                "span",
                { staticClass: "call-settings__permissions__wording-span" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.viewType === "permissions"
                        ? _vm.$tp("buttonAndHints.permissionsWording1")
                        : _vm.$tp("buttonAndHints.permissionsWording3")
                    )
                  ),
                ]
              ),
              _c(
                "span",
                { staticClass: "call-settings__permissions__wording-span" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.viewType === "permissions"
                        ? _vm.$tp("buttonAndHints.permissionsWording2")
                        : _vm.$tp("buttonAndHints.permissionsWording4")
                    )
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "call-settings__permissions__actions" }, [
              _c(
                "button",
                {
                  staticClass: "call-settings__permissions__actions__btn",
                  style: { background: _vm.mainColor },
                  on: { click: _vm.requestPermissions },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tp("buttonAndHints.requestPermissions")) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.viewType === "configure",
              expression: "viewType === 'configure'",
            },
          ],
          staticClass: "call-settings__configurations",
        },
        [
          _c("div", { staticClass: "call-settings__configurations__setup" }, [
            _c(
              "div",
              { staticClass: "call-settings__configurations__preview" },
              [
                _c(
                  "div",
                  {
                    staticClass: "call-settings__configurations__preview__img",
                  },
                  [
                    _c("video", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCameraOn,
                          expression: "isCameraOn",
                        },
                      ],
                      ref: "localSettingsVideo",
                      staticClass:
                        "call-settings__configurations__preview__img__video",
                      attrs: {
                        id: "local-settings-video",
                        autoplay: "",
                        playsinline: "",
                        muted: "muted",
                      },
                      domProps: { muted: true },
                    }),
                    !_vm.isCameraOn
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "call-settings__configurations__preview__img__container",
                          },
                          [
                            _c("settings-video-cam-off-icon", {
                              staticClass:
                                "call-settings__configurations__preview__img__container__icon",
                              staticStyle: { position: "absolute" },
                              attrs: {
                                color: "#ffffff",
                                width: 186,
                                height: 186,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                false
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "call-settings__configurations__preview__img-description",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "call-settings__configurations__preview__img-description__wording",
                          },
                          [
                            _c("magic-icon", {
                              staticClass:
                                "call-settings__configurations__preview__img-description__icon",
                              attrs: {
                                color: "#12598D",
                                width: 16,
                                height: 16,
                              },
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tp("buttonAndHints.blurBG")) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                        _c("vs-switch", {
                          staticClass:
                            "call-settings__configurations__preview__img-description__toggle",
                          attrs: { color: "#275D73" },
                          model: {
                            value: _vm.blurBackground,
                            callback: function ($$v) {
                              _vm.blurBackground = $$v
                            },
                            expression: "blurBackground",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "call-settings__configurations__holder",
                style: {
                  "--main-background": _vm.mainColor,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "call-settings__configurations__header" },
                  [_vm._v(_vm._s(_vm.$tp("buttonAndHints.videoAndAudio")))]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "call-settings__configurations__device-selection",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "call-settings__configurations__device-selection__wording",
                      },
                      [_vm._v(_vm._s(_vm.$tp("buttonAndHints.mic")))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "call-settings__configurations__device-selection__mic",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "call-settings__configurations__device-selection__mic-icon",
                            class: _vm.isMicrophoneOn ? "on" : "off",
                            on: { click: _vm.toggleMicrophone },
                          },
                          [
                            _vm.isMicrophoneOn
                              ? _c("mic-icon", {
                                  attrs: { width: 20, height: 20 },
                                })
                              : _c("mic-mute-icon", {
                                  attrs: {
                                    color: "#262629",
                                    width: 20,
                                    height: 20,
                                  },
                                }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "call-settings__configurations__device-selection__mic-dropdown",
                          },
                          [
                            _c("connect-select", {
                              attrs: {
                                options: _vm.microphoneOptions,
                                default: _vm.selectedAudio,
                              },
                              on: {
                                "input-selected": _vm.onAudioInputSelected,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.audioOutputOptions.length > 1
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "call-settings__configurations__device-selection__wording",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$tp("buttonAndHints.speakers")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "call-settings__configurations__device-selection__output",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "call-settings__configurations__device-selection__output-icon",
                                  class: _vm.isAudioOutputOn ? "on" : "off",
                                  on: { click: _vm.toggleOutput },
                                },
                                [
                                  _vm.isAudioOutputOn
                                    ? _c("speaker-on-icon", {
                                        attrs: {
                                          width: 20,
                                          height: 20,
                                          color: "#fff",
                                        },
                                      })
                                    : _c("speaker-off-icon", {
                                        attrs: {
                                          width: 20,
                                          height: 20,
                                          color: "#262629",
                                        },
                                      }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "call-settings__configurations__device-selection__output-dropdown",
                                },
                                [
                                  _c("connect-select", {
                                    attrs: {
                                      options: _vm.audioOutputOptions,
                                      default: _vm.selectedOutput,
                                    },
                                    on: {
                                      "input-selected":
                                        _vm.onAudioOutputSelected,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "call-settings__configurations__device-selection__wording",
                      },
                      [_vm._v("Camera")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "call-settings__configurations__device-selection__video",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "call-settings__configurations__device-selection__video-icon",
                            class: _vm.isCameraOn ? "on" : "off",
                            on: { click: _vm.toggleCamera },
                          },
                          [
                            _vm.isCameraOn
                              ? _c("video-call-icon", {
                                  attrs: { width: 20, height: 20 },
                                })
                              : _c("no-video-icon", {
                                  attrs: {
                                    width: 20,
                                    color: "#262629",
                                    height: 20,
                                  },
                                }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "call-settings__configurations__device-selection__video-dropdown",
                          },
                          [
                            _c("connect-select", {
                              attrs: {
                                options: _vm.cameraOptions,
                                default: _vm.selectedVideo,
                              },
                              on: {
                                "input-selected": _vm.onVideoInputSelected,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "call-settings__configurations__actions" },
                  [
                    _vm.showProceedBtn
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "call-settings__configurations__actions__btn",
                            class: {
                              "call-settings__configurations__actions__btn--disabled":
                                _vm.isJoinMeetingDisabled,
                            },
                            style: { background: _vm.joinMeetingButtonColor },
                            on: { click: _vm.onJoinMeeting },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tp("buttonAndHints.joinMeeting")) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
      _c("visitor-campaign-logo", {
        staticClass: "call-settings__campaign-logo",
        style: !_vm.isWaiting ? "margin-top: unset;" : "",
        attrs: { logoImage: _vm.logoImage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }