var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "feather",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        color: _vm.color,
        viewBox: "0 0 18 15",
        fill: "none",
        stroke: "currentColor",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2.62275 1H15.6048C16.4973 1 17.2275 1.73024 17.2275 2.62275V12.3593C17.2275 13.2518 16.4973 13.982 15.6048 13.982H2.62275C1.73024 13.982 1 13.2518 1 12.3593V2.62275C1 1.73024 1.73024 1 2.62275 1Z",
          stroke: _vm.color,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.2275 2.62274L9.11377 8.30238L1 2.62274",
          stroke: _vm.color,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }