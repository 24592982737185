<template>
  <svg :width="width" :height="height" :color="color" viewBox="0 0 64 64" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2.00952L62 62.0095" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M40.1825 24.7537V10.1901C40.1845 8.16105 39.4326 6.20366 38.0726 4.69788C36.7126 3.1921 34.8417 2.24535 32.8229 2.04144C30.8042 1.83753 28.7817 2.39099 27.1481 3.59439C25.5145 4.79779 24.3862 6.56526 23.9825 8.5537M23.8188 23.8264V32.0082C23.8203 33.6254 24.3009 35.2059 25.2 36.5501C26.0991 37.8942 27.3764 38.9418 28.8706 39.5605C30.3647 40.1791 32.0087 40.3411 33.5949 40.026C35.181 39.7109 36.6383 38.9329 37.7825 37.7901L23.8188 23.8264Z" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M45.6367 45.5098C42.9743 48.2274 39.5607 50.0874 35.8338 50.8515C32.107 51.6155 28.2368 51.2486 24.7199 49.7979C21.203 48.3472 18.1997 45.8788 16.0954 42.7094C13.991 39.54 12.8816 35.8141 12.9094 32.0098V26.5553L23.5 33M51.0913 26.5553V32.0098C51.0903 33.1347 50.9899 34.2572 50.7913 35.3644" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M32 51.1V62.0091" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.0918 62.0093H42.91" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'AudioIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    width: {
      type: Number,
      default: 64
    },
    height: {
      type: Number,
      default: 64
    }
  }
}
</script>
