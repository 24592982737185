<template>
  <div class="custom-select" :tabindex="tabindex" @blur="onBlur">
    <div class="selected" :class="{ open: open }" @click="onClickSelect">
      {{ selected ? selected.text : '' }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option
          open = false
          $emit('input-selected', option)
        "
      >
        {{ option.text }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConnectSelect',
  props: {
    options: {
      type: Array,
      required: true
    },
    default: {
      type: Object,
      required: false,
      default: null
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      selected: {},
      open: false
    }
  },
  watch: {
    options() {
      if (this.options && this.options.length > 0) {
        this.selected = this.default ? this.default : this.options[0]
      }
    },
    default() {
      if (this.default) {
        this.selected = this.default
      }
    }
  },
  mounted() {
    this.selectDefaultDevice()
  },
  methods: {
    selectDefaultDevice() {
      this.selected = this.default ? this.default : this.options.length > 0 ? this.options[0] : null
    },
    onClickSelect() {
      this.open = !this.open
      this.$emit('input-click', this.selected)
    },
    onBlur() {
      this.open = false
      this.$emit('input-blur', this.selected)
    }
  }
}
</script>
<style scoped>
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 30px;
  line-height: 30px;
}

.custom-select .selected {
  color: #262629 !important;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-left: 1em;
  padding-right: 2em;
  cursor: pointer;
  user-select: none;
  background: #e6ecf1;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
}

.custom-select .selected.open {
  border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: '';
  top: 14px;
  right: 0.5em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #262629 transparent transparent transparent !important ;
}

.custom-select .items {
  color: #fff;
  overflow: auto;
  position: absolute;
  background-color: #e6ecf1;
  left: 0;
  right: 0;
  width: calc(100% + 15px);
  max-height: 150px;
  z-index: 1;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.custom-select .items div {
  color: #262629;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  color: #fff;
  background-color: #262629;
}

.selectHide {
  display: none;
}
</style>
