var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "feather",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        color: _vm.color,
        viewBox: "0 0 15 17",
        fill: "none",
        stroke: "currentColor",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M13.982 15.6048V13.982C13.982 13.1213 13.6401 12.2958 13.0314 11.6871C12.4228 11.0785 11.5973 10.7365 10.7365 10.7365H4.24551C3.38475 10.7365 2.55924 11.0785 1.95059 11.6871C1.34194 12.2958 1 13.1213 1 13.982V15.6048",
          stroke: _vm.color,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7.49099 7.49102C9.28344 7.49102 10.7365 6.03795 10.7365 4.24551C10.7365 2.45306 9.28344 1 7.49099 1C5.69855 1 4.24548 2.45306 4.24548 4.24551C4.24548 6.03795 5.69855 7.49102 7.49099 7.49102Z",
          stroke: _vm.color,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }