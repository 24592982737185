var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "landscape-notice" }, [
    _c(
      "div",
      { staticClass: "landscape-notice__icon" },
      [_c("landscape-icon")],
      1
    ),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "landscape-notice__wording" }, [
      _c("span", [
        _vm._v("Please rotate your smartphone to continue the process."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }