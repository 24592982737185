var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "end-conversation",
      class: {
        "end-conversation_full-screen": _vm.isFullScreen,
      },
    },
    [
      _c("div", { staticClass: "end-conversation__container" }, [
        _c("div", { staticClass: "end-conversation__header" }, [
          _c(
            "div",
            { staticClass: "end-conversation__header__btn" },
            [_c("phone-call-icon", { attrs: { width: 20, height: 20 } })],
            1
          ),
        ]),
        _c("div", { staticClass: "end-conversation__body" }, [
          _c("div", { staticClass: "end-conversation__body__wording" }, [
            _c("span", { staticClass: "end-conversation__body__wording--h1" }, [
              _vm._v(_vm._s(_vm.$tp("buttonAndHints.endConversation"))),
            ]),
            _c("span", { staticClass: "end-conversation__body__wording--h2" }, [
              _vm._v(
                _vm._s(_vm.$tp("buttonAndHints.shouldEndConversationText"))
              ),
            ]),
          ]),
          _c("div", { staticClass: "end-conversation__body__actions" }, [
            _c(
              "button",
              {
                staticClass:
                  "end-conversation__body__actions__btn end-conversation__body__actions__btn__end-conv",
                on: { click: _vm.endConversation },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$tp("buttonAndHints.endConversation")) +
                    "\n        "
                ),
              ]
            ),
            _c(
              "button",
              {
                staticClass:
                  "end-conversation__body__actions__btn end-conversation__body__actions__btn__cancel",
                on: { click: _vm.close },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$tp("buttonAndHints.cancel")) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }