<template>
  <div class="permissions-notice">
    <div class="permissions-notice">
      <div class="permissions-notice__header">
        <div class="permissions-notice__header__icon">
          <info-icon :width="22" :height="22" />
        </div>
      </div>
      <div class="permissions-notice__body">
        <div class="permissions-notice__body__wording-main">
          {{ $tp('buttonAndHints.permissionsNoticeMainWordingMain') }}
        </div>
        <div class="permissions-notice__body__wording">
          {{ $tp('buttonAndHints.permissionsNoticeMainWording1') }}
        </div>
        <div class="permissions-notice__body__icon">
          <div class="permissions-notice__body__icon-container">
            <mic-mute-icon :width="20" :height="20" color="#3B86F7" />
          </div>
        </div>
        <div class="permissions-notice__body__wording">
          {{ $tp('buttonAndHints.permissionsNoticeMainWording2') }}
        </div>
        <div class="permissions-notice__body__icon">
          <div class="permissions-notice__body__icon-container">
            <video-call-off-icon :width="20" :height="20" color="#3B86F7" />
          </div>
        </div>
        <div class="permissions-notice__body__wording">
          {{ $tp('buttonAndHints.permissionsNoticeMainWording3') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MicMuteIcon from './icons/MicMuteIcon.vue'
import VideoCallOffIcon from './icons/VideoCallOffIcon'
import InfoIcon from './icons/InfoIcon'

export default {
  name: 'PermissionsRequested',
  components: {
    MicMuteIcon,
    VideoCallOffIcon,
    InfoIcon
  }
}
</script>

<style scoped lang="scss">
.permissions-notice {
  &__header {
    background: #3b86f7;
    height: 35px;
    border-radius: 6px 6px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    &__icon {
      padding: 7px 7px 0 0;
    }
  }

  &__body {
    &__icon {
      display: flex;
      margin-bottom: 10px;
      padding-left: 30px;

      &-container {
        background: #fff;
        width: 100px;
        height: 35px;
        display: flex;
        justify-content: space-around;
        border-radius: 6px;
        padding-top: 8px;
      }
    }
  }
}
</style>
