<template>
  <div
    class="pathadvice-notice"
    :class="{
      'pathadvice-notice_full-size': isFullSize
    }"
  >
    <permissions-requested v-if="noticeType === 'request-permissions'" />
    <permissions-request-failed v-if="noticeType === 'permissions-denied'" @buttonClicked="goToTheChat" />
    <permission-denied v-if="noticeType === 'permissions-denied-base'" @buttonClicked="closeNotice" />
  </div>
</template>

<script>
import PermissionsRequested from './PermissionsRequested'
import PermissionsRequestFailed from './PermissionsRequestFailed'
import PermissionDenied from './PermissionDenied'

export default {
  name: 'Notice',
  props: {
    noticeType: {
      type: String
    }
  },
  components: {
    PermissionDenied,
    PermissionsRequestFailed,
    PermissionsRequested
  },

  computed: {
    isFullSize() {
      return ['permissions-denied-base'].includes(this.noticeType)
    }
  },

  methods: {
    closeNotice() {
      this.$emit('closeNotice')
    },

    goToTheChat() {
      this.$emit('goToTheChat')
    }
  }
}
</script>

<style lang="scss">
.pathadvice-notice {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 20px);
  max-width: 375px;
  max-height: 95%;
  font-family: "Lato", sans-serif;
  box-shadow: -3px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  overflow-y: auto;
  z-index: 1001;
  transform: translate(-50%, -50%);

  &_full-size {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}

.permissions-notice {
  background: #e6ecf1;
  border-radius: 6px;

  &__body {
    padding: 30px 0 10px 0;

    &__wording-main {
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      color: #12598d;
      margin: 0 10px 20px 10px;
    }

    &__wording {
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      color: #12598d;
      margin: 0 30px 10px 30px;
    }
  }
}
</style>
