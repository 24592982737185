var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "permissions-notice" }, [
    _c("div", { staticClass: "permissions-notice" }, [
      _c("div", { staticClass: "permissions-notice__header" }, [
        _c(
          "div",
          { staticClass: "permissions-notice__header__icon" },
          [_c("info-icon", { attrs: { width: 22, height: 22 } })],
          1
        ),
      ]),
      _c("div", { staticClass: "permissions-notice__body" }, [
        _c("div", { staticClass: "permissions-notice__body__wording-main" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.$tp("buttonAndHints.permissionsNoticeMainWordingMain")
              ) +
              "\n      "
          ),
        ]),
        _c("div", { staticClass: "permissions-notice__body__wording" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$tp("buttonAndHints.permissionsNoticeMainWording1")) +
              "\n      "
          ),
        ]),
        _c("div", { staticClass: "permissions-notice__body__icon" }, [
          _c(
            "div",
            { staticClass: "permissions-notice__body__icon-container" },
            [
              _c("mic-mute-icon", {
                attrs: { width: 20, height: 20, color: "#3B86F7" },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "permissions-notice__body__wording" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$tp("buttonAndHints.permissionsNoticeMainWording2")) +
              "\n      "
          ),
        ]),
        _c("div", { staticClass: "permissions-notice__body__icon" }, [
          _c(
            "div",
            { staticClass: "permissions-notice__body__icon-container" },
            [
              _c("video-call-off-icon", {
                attrs: { width: 20, height: 20, color: "#3B86F7" },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "permissions-notice__body__wording" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$tp("buttonAndHints.permissionsNoticeMainWording3")) +
              "\n      "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }