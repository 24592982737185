<template>
  <div
    class="end-conversation"
    :class="{
      'end-conversation_full-screen': isFullScreen
    }"
  >
    <div class="end-conversation__container">
      <div class="end-conversation__header">
        <div class="end-conversation__header__btn">
          <phone-call-icon :width="20" :height="20" />
        </div>
      </div>
      <div class="end-conversation__body">
        <div class="end-conversation__body__wording">
          <span class="end-conversation__body__wording--h1">{{ $tp('buttonAndHints.endConversation') }}</span>
          <span class="end-conversation__body__wording--h2">{{ $tp('buttonAndHints.shouldEndConversationText') }}</span>
        </div>
        <div class="end-conversation__body__actions">
          <button class="end-conversation__body__actions__btn end-conversation__body__actions__btn__end-conv" @click="endConversation">
            {{ $tp('buttonAndHints.endConversation') }}
          </button>
          <button class="end-conversation__body__actions__btn end-conversation__body__actions__btn__cancel" @click="close">
            {{ $tp('buttonAndHints.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneCallIcon from './icons/PhoneIcon'

export default {
  name: 'EndConversation',

  components: {
    PhoneCallIcon
  },

  props: {
    isFullScreen: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  methods: {
    close() {
      this.$emit('dialogClosed', true)
    },
    endConversation() {
      const custom_event = new CustomEvent('custom_gtm_events')
      custom_event.info = {
        name: 'gtm.click',
        gtm: {
          uniqueEventId:
            Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
          start: new Date().getTime(),
          location: window.location.href,
          event_type: 'End Call'
        }
      }
      window.dispatchEvent(custom_event)
      this.$serverBus.$emit('on-conversation-ended')
      this.$emit('endConversation')
    }
  }
}
</script>

<style lang="scss" scoped>
.end-conversation {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 20px;
  width: 100%;
  max-width: 650px;
  font-family: 'Lato', serif;
  transform: translate(-50%, -50%);
  z-index: 10000;
  &__container {
    border-radius: 6px;
    overflow: hidden;
  }

  &__header {
    background: #f05541;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;

    &__btn {
      padding: 7px;
    }
  }

  &__body {
    background: #e6ecf1;
    border-radius: 0 0 6px 6px;

    &__wording {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #12598d;
      padding: 20px 25px;

      &--h1 {
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 10px;
      }

      &--h2 {
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 25px;

      &__btn {
        font-family: 'Lato', serif;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;

        font-weight: 600;
        font-size: 18px;
        line-height: 23px;

        margin-bottom: 10px;

        &__end-conv {
          color: #f05541;
          width: 100%;
          border: 1px solid #f05541;
          border-radius: 6px;
          padding: 5px 0;
        }

        &__cancel {
          color: #3b86f7;
        }
      }
    }
  }

  &_full-screen {
    &.end-conversation {
      max-width: 420px;
    }
  }
}
</style>
