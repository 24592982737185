<template>
  <div class="pathadvice__visitor-campaign-logo">
    <div class="pathadvice__visitor-campaign-logo__wrapper">
      <img :src="logoImage" alt="visitor-campaign-logo" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logoImage: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.pathadvice__visitor-campaign-logo {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 10;
  color: #fff;

  &__wrapper {
    display: flex;
    align-items: center;
    img {
      width: 100%;
      max-width: 140px;
      max-height: 57px;
    }
    @media (max-width: 767px) {
      img {
        max-width: 100px;
        max-height: 40px;
      }
    }
  }
}
</style>
