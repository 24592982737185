var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "permissions-notice" }, [
    _c("div", { staticClass: "permissions-notice__header" }, [
      _c(
        "div",
        { staticClass: "permissions-notice__header__icon" },
        [_c("info-icon", { attrs: { width: 22, height: 22 } })],
        1
      ),
    ]),
    _c("div", { staticClass: "permissions-notice__body" }, [
      _c("div", { staticClass: "permissions-notice__body__wording-main" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$tp("buttonAndHints.permissionsNoticeFailWordingMain")) +
            "\n    "
        ),
      ]),
      _c("div", { staticClass: "permissions-notice__body__wording" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$tp("buttonAndHints.permissionsNoticeFailWording1")) +
            "\n    "
        ),
      ]),
      _c("div", { staticClass: "permissions-notice__body__wording" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$tp("buttonAndHints.permissionsNoticeFailWording2")) +
            "\n    "
        ),
      ]),
      _c("div", { staticClass: "permissions-notice__body__icon" }, [
        _c(
          "div",
          { staticClass: "permissions-notice__body__icon-container" },
          [
            _c("mic-mute-icon", {
              attrs: { width: 20, height: 20, color: "#f05541" },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "permissions-notice__body__icon" }, [
        _c(
          "div",
          { staticClass: "permissions-notice__body__icon-container" },
          [
            _c("video-call-off-icon", {
              attrs: { width: 20, height: 20, color: "#f05541" },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "permissions-notice__footer" }, [
      _c(
        "button",
        {
          staticClass: "permissions-notice__footer__btn",
          on: { click: _vm.clickHandler },
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$tp("buttonAndHints.permissionsNoticeFailBtn")) +
              "\n    "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }